import * as Yup from "yup";
import { useEffect, useState } from "react";
import CustomModal from "../CustomModal";
import ButtonLightTextIcon from "./ButtonLightTextIcon";
import ButtonTextIcon from "./ButtonTextIcon";
import { Field, Form, Formik } from "formik";
import CustomSelect from "./CustomSelect";

const Label = ({ title, required }) => {
  return (
    <p className="color-primary f-w-600" style={{ paddingBottom: "5px" }}>
      {title}
      {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
    </p>
  );
};

const MonthYearInput = ({ onApply, onCancel, initialValue = {} }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null); 

  useEffect(() => {
    setSelectedMonth(initialValue?.month ? initialValue.month : null);
    setSelectedYear(initialValue?.year ? initialValue.year : null);
  }, [initialValue]);

  const displaySelectedFilter = () => {
    if (!selectedYear) return "Select Year and Month";
     
    return selectedMonth
      ? `${selectedMonth?.label}, ${selectedYear?.label}`
      : `${selectedYear?.label}`;
  };
 
  const months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = Array.from({ length: 20 }, (_, i) => ({
    value: (new Date().getFullYear() - i).toString(),
    label: (new Date().getFullYear() - i).toString(),
  }));


  return (
    <CustomModal
      selector={
        <div className="pos-rel dis-i-b">
          <div
            className="ui-like-input col-1-1 cursor-pointer"
            style={{ display:"flex", justifyContent:"space-between", whiteSpace: "nowrap", minWidth: "200px" }}
          >
             <div>{displaySelectedFilter()}&nbsp;</div> 
            <div>
              <svg
                clipRule="evenodd"
                fillRule="evenodd"
                strokeLinejoin="round"
                strokeMiterlimit="2"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                width="19.995"
                height="19.996"
                style={{
                  width: "16px",
                  height: "16px",
                }}
                fill="#373737"
              >
                <path
                  d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"
                  fillRule="nonzero"
                />
              </svg>
            </div>
          </div>
        </div>
      }
      content={<ModalContent initialValue={initialValue} onApply={onApply} onCancel={onCancel}  months={months} years={years} />}
    />
  );
};

const ModalContent = ({ initialValue,  months, years, onApply, onCancel, setShow }) => {
  const [initialValues, setInitialValues] = useState({ month: "", year: "" });

  useEffect(() => { setInitialValues(initialValue) }, [initialValue]);
  const validationSchema = Yup.object().shape({  
    year: Yup.mixed()
                .nullable()
                .test("year-required", "Year is required", function (value) {   
                        return value !== null && value.value; 
                }),
  });

  return (
    <div>
      <section id="opl_coremax">
        <section
          style={{
            position: "fixed",
            inset: 0,
            zIndex: 9999,
            backgroundColor: "rgba(255, 255, 255, 0.86)",
          }}
        >
          <section
            className="col-1-1 flex center-center"
            style={{
              height: "100vh",
              width: "100vw",
            }}
          >
            <div
              style={{
                width: "486px",
                padding: "24px",
                height: "auto",
                maxHeight: "80vh",
                border: "solid 1px #f0f0f0",
                background: "white",
                borderRadius: "8px",
                margin: "0 auto",
                boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
              }}
            >
              <div
                className="col-1-1 flex center-left"
                style={{
                  borderBottom: "solid 1px #f0f0f0",
                  paddingBottom: "12px",
                  marginBottom: "16px",
                }}
              >
                <h6
                  className="color-primary f-w-700 opl-tooltip"
                  data-tooltip="View entire history of the activity below."
                >
                  Filter
                </h6>
                <div
                  className="mar-l-a cursor-pointer"
                  onClick={() => setShow(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                      fill="#283f54"
                    />
                  </svg>
                </div>
              </div>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={(values) => {
                  try {
                    onApply(values);
                    setShow(false);
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched, 
                  setFieldValue, 
                  setFieldTouched ,
                  validateForm
                }) => (
                  <Form>
                    <div>
                      <section>
                        <div>
                          <div
                            className="flex center-center"
                            style={{ gap: "10px" }}
                          >
                            <div
                              style={{ width: "100%", paddingBottom: "10px" }}
                            >
                              <Label title="Month" />
                              <CustomSelect 
                                options={months}
                                setTouched={()=>{setFieldTouched ("month", true);}}
                                placeHolder="Select an option..."
                                selectedValue={values.month}
                                error={errors.month && touched.month}
                                onChange={(option) => setFieldValue("month", option) }
                              />
                            </div>
                          </div>

                          <div
                            className="flex center-center"
                            style={{ gap: "10px" }}
                          >
                            <div style={{ width: "100%" }}>
                              <Label title="Year" required={true} />
                              <CustomSelect 
                                options={years}
                                setTouched={()=>{setFieldTouched ("year", true);}}
                                placeHolder="Select an option..."
                                selectedValue={values.year}
                                error={errors.year && touched.year}
                                onChange={async (option) => { await setFieldValue("year", option);  await validateForm(); }}
                               /> 
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                    <div
                      className="col-1-1 flex center-left"
                      style={{
                        marginTop: "16px",
                        borderTop: "solid 1px #f0f0f0",
                        paddingTop: "16px",
                      }}
                    >
                      <ButtonLightTextIcon
                        type="button"
                        title="Clear"
                        onClick={() => { onCancel(); setShow(false); }}
                        icon={
                          <svg
                            clipRule="evenodd"
                            fillRule="evenodd"
                            strokeLinejoin="round"
                            strokeMiterlimit="2"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                            width="19.995"
                            height="19.996"
                          >
                            <path
                              d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                              fillRule="nonzero"
                            />
                          </svg>
                        }
                      />

                      <div style={{ marginLeft: "auto" }}></div>
                      <ButtonTextIcon
                        title="Confirm" 
                        type="submit"
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                          >
                            <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1 17l-5-5.299 1.399-1.43 3.574 3.736 6.572-7.007 1.455 1.403-8 8.597z" />
                          </svg>
                        }
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </section>
        </section>
      </section>
    </div>
  );
};

export default MonthYearInput;
