import { withRouter } from "react-router-dom";

const Pagination = ({ history, location, totalRecords, removeParamKey }) => {
  const queryParams = new URLSearchParams(location.search);
  const pageNo = parseInt(queryParams.get("pageNo") || 0);
  const pageSize = queryParams.get("pageSize") || 12;

  const handleQueryParamChange = (key, value) => {
    value ? queryParams.set(key, value) : queryParams.set(key, 0);
    if (removeParamKey) {
      queryParams.delete(removeParamKey);
    }
    history.push({ search: queryParams.toString() });
  };

  const maxPagesToShow = 5;
  const totalPages = Math.ceil(totalRecords / pageSize);

  const handlePageClick = (pageIndex) => {
    if (pageIndex !== pageNo && pageIndex >= 0 && pageIndex < totalPages) {
      handleQueryParamChange("pageNo", pageIndex);
    }
  };

  const DoubleArrowLeft = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="48"
      viewBox="0 0 30 24"
      style={{ width: "9px", height: "9px" }}
    >
       <path
        d="M16.67,0,19.5,2.829,10.161,12,19.5,21.171,16.67,24,4.5,12Z"
        transform="translate(-4.5)"
      />
	   <path
        d="M16.67,0,19.5,2.829,10.161,12,19.5,21.171,16.67,24,4.5,12Z"
        transform="translate(10)"
      />
    </svg>
  );

  const ArrowLeft = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="24"
      viewBox="0 0 15 24"
    >
      <path
        d="M16.67,0,19.5,2.829,10.161,12,19.5,21.171,16.67,24,4.5,12Z"
        transform="translate(-4.5)"
      />
    </svg>
  );

  const ArrowRight = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="24"
      viewBox="0 0 15 24"
    >
      <path
        d="M7.33,24,4.5,21.171,13.839,12,4.5,2.829,7.33,0,19.5,12Z"
        transform="translate(-4.5)"
      />
    </svg>
  );

  const DoubleArrowRight = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="48"
      viewBox="0 0 30 24"
      style={{ width: "9px", height: "9px" }}
    >
      <path
        d="M7.33,24,4.5,21.171,13.839,12,4.5,2.829,7.33,0,19.5,12Z"
        transform="translate(-4.5)"
      />
      <path
        d="M7.33,24,4.5,21.171,13.839,12,4.5,2.829,7.33,0,19.5,12Z"
        transform="translate(10)"
      />
    </svg>
  );
  
  

  const Dots = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="4.5"
      viewBox="0 0 18 4.5"
      style={{ height: "3px", width: "12px", marginTop: "10px" }}
    >
      <path
        d="M16.5,11.995a2.25,2.25,0,1,1,2.25,2.25A2.251,2.251,0,0,1,16.5,11.995Zm-6.75,0A2.25,2.25,0,1,1,12,14.245,2.251,2.251,0,0,1,9.75,11.995Zm-6.75,0a2.25,2.25,0,1,1,2.25,2.25A2.251,2.251,0,0,1,3,11.995Z"
        transform="translate(-3 -9.745)"
        fillRule="evenodd"
      />
    </svg>
  );

  const getPaginationUI = () => {
    if (totalPages > 0) {
      let startPage = Math.max(0, pageNo - Math.floor(maxPagesToShow / 2));
      let endPage = startPage + maxPagesToShow - 1;

      if (endPage >= totalPages) {
        endPage = totalPages - 1;
        startPage = Math.max(0, endPage - maxPagesToShow + 1);
      }

      let allData = [];

      // Go to First Page button
      allData.push(
        <li
          key="first"
          onClick={() => handlePageClick(0)}
          disabled={pageNo === 0}
          className={pageNo === 0 ? "cursor-not-allowed" : ""}
        >
          <DoubleArrowLeft />
        </li>
      );

      // Previous page button
      allData.push(
        <li
          key="prev"
          onClick={() => handlePageClick(pageNo - 1)}
          disabled={pageNo === 0}
          className={pageNo === 0 ? "cursor-not-allowed" : ""}
        >
          <ArrowLeft />
        </li>
      );

      // Ellipsis before the first page number
      if (startPage > 0) {
        allData.push(
          <li key="start-ellipsis">
            <Dots />
          </li>
        );
      }

      // Page numbers
      for (let index = startPage; index <= endPage; index++) {
        allData.push(
          <li
            key={index}
            className={`${index === pageNo ? "active" : ""}`}
            onClick={() => handlePageClick(index)}
          >
            {index + 1}
          </li>
        );
      }

      // Ellipsis after the last page number
      if (endPage < totalPages - 1) {
        allData.push(
          <li key="end-ellipsis">
            <Dots />
          </li>
        );
      }

      // Next page button
      allData.push(
        <li
          key="next"
          onClick={() => handlePageClick(pageNo + 1)}
          disabled={pageNo === totalPages - 1}
          className={pageNo === totalPages - 1 ? "cursor-not-allowed" : ""}
        >
          <ArrowRight />
        </li>
      );

      // Go to Last Page button
      allData.push(
        <li
          key="last"
          onClick={() => handlePageClick(totalPages - 1)}
          disabled={pageNo === totalPages - 1}
          className={pageNo === totalPages - 1 ? "cursor-not-allowed" : ""}
        >
          <DoubleArrowRight/>
        </li>
      );

      return allData;
    } else {
      return [];
    }
  };

  return <ul className="opl-pagination">{getPaginationUI()}</ul>;
};

export default withRouter(Pagination);
