import React, { useState, useEffect, useCallback } from 'react';

const PhoneNumberInput = ({
    callback,
    value = '',
    className = '',
    error = '',
    placeholder = 'Enter phone number',
    name = '',
    setTouched = () => {},
}) => {

    const [phoneNumber, setPhoneNumber] = useState('');
 
    const formatPhoneNumber = useCallback((number) => {
        if (!number) return '';
        const match = number.match(/^([0-9]{0,3})([0-9]{0,3})([0-9]{0,})$/);
        return match ? [match[1], match[2], match[3]].filter(Boolean).join('-') : number;
    }, []);
 
    useEffect(() => {
        setPhoneNumber(formatPhoneNumber(value));
    }, [value, formatPhoneNumber]);
 
    const handleInputChange = (e) => {
        let inputValue = e.target.value.replace(/\D/g, '');  
        inputValue = inputValue.slice(0, 15); // Limit input to 15 digits
        const formatted = formatPhoneNumber(inputValue);
        setPhoneNumber(formatted);
        callback(inputValue.replace(/-/g, ''));  
    };

    return (
        <div>
            <input
                type="text"
                name={name}
                value={phoneNumber}
                onBlur={() => setTouched()}
                onChange={handleInputChange}
                placeholder={placeholder}
                className={`primary ${className} ${error ? 'error' : ''}`} 
            /> 
        </div>
    );
};

export default PhoneNumberInput;
