import "../../styles/base.scss";
import "../../styles/pages.scss";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useState } from "react";
import {
    API_URL
} from "../../opl-utilities/constants";
import { useUI } from "../../context/ui";
import { formatInputPhoneNumber, handleDatePickerChange } from "../../opl-utilities/helpers";
import DatePicker from "react-datepicker";
import { formatISO, parseISO } from "date-fns";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import PhoneNumberInput from "../../components/common/ui/PhoneNumberInput";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";

const SingleStep = ({ title, subtitle, completion, style }) => {
    const getClassName = () => {
        const hasEmptyElement = completion.some(
            (element) => element === ""
        );
        if (hasEmptyElement) {
            return "incomplete";
        } else {
            return "complete";
        }
    };
    const dynamicStyle = {
        fontSize: "12px",
        ...style // Spread the additional styles from the `style` prop
    };

    return (
        <div className={`step t-a-c ${getClassName()}`}>
            <p
                className='color-primary'
                style={dynamicStyle}>
                <span className='f-w-700'>0{title}</span> {subtitle}
            </p>
            <div className={`b-r-100 icon`}>
                <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'>
                    <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z' />
                </svg>
            </div>
        </div>
    );
};

const Label = ({ title, required }) => {
    return (
        <p
            className='color-primary f-w-600'
            style={{ paddingBottom: "5px" }}>
            {title}
            {required && (
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
            )}
        </p>
    );
};

const AddForm = ({ history }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const { addToastMessage } = useUI();

    const initialValues = {
        contractContactDetails: [],
        contractTypeId: null,
        startDate: "",
        endDate: "", 
        facilityId: null,
    };

    const validationSchema = Yup.object({
        // contactPersonName: Yup.string().required("Person Name Required"), 
        // contactPersonPhone: Yup.string()
        //             .matches(/^\d+$/, "Phone Number must contain only numbers")
        //             .max(15, "Phone Number must be at most 15 digits")
        //             .required("Phone Number is required"),
        // contactPersonEmail: Yup.string()
        //             .email("Invalid email address")
        //             .required("Email is required"),
        contractTypeId: Yup.mixed()
        .nullable()
        .test("Contract Type required", "Contract Type is Required",
            function (value) { return value !== null && value.value; }),
        startDate: Yup.string().required("Start Date required"),
        endDate: Yup.date().required("End Date is required")
            .test("is-after-startDate", "End date must be greater then Start date", function (value) {
                const { startDate } = this.parent;
                return !startDate || !value || new Date(value) > new Date(startDate);
            }),
        // endDate: Yup.string().required("End Date required"),
        facilityId: Yup.mixed()
            .nullable()
            .test("facility-required", "Facility is Required",
                function (value) { return value !== null && value.value; }),
    });

    const createContract = async (values) => {
        setLoading(true);
        setStatus("");
        try {

            if(values?.contractContactDetails?.length === 0){
                setStatus("At least one contact detail is required to create a contract. Please provide a valid contact detail.");
                addToastMessage({
                    status: false,
                    message: `At least one contact detail is required to create a contract. Please provide a valid contact detail.`,
                });
                return false;
            }

            const payload = JSON.stringify({
                contractTypeId:values.contractTypeId.value,
                startDate:values.startDate,
                endDate:values.endDate,
                contactPersonDetails: values.contractContactDetails ? values.contractContactDetails.map((item) => ({
                    contactPersonEmail: item?.contactPersonEmail || null,
                    contactPersonPhone: item?.contactPersonPhone || null,
                    contactPersonName: item?.contactPersonName || null,
                    contactPersonTitle: item?.contactPersonTitle || null,
                })) : [],
                facilityId:values.facilityId.value,
            });
            const resp = await fetch(API_URL + `/api/1.0.0/contract`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token")
                },
                body: payload,
            });
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                  addToastMessage({
                    status: false,
                    message:
                      "Access denied. You do not have permission to perform this action.",
                  });
                    setLoading(false);
                    setStatus("Access denied. You do not have permission to perform this action.");
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    if(response.result.responseCode === 200){
                        setStatus(
                            "You have successfully created a contract, thank you."
                        );
                        addToastMessage({
                            status: true,
                            message: `You have successfully created a contract, thank you.`,
                        })
                        history.push({
                            pathname:"/edit-contracts",
                            state: { id: response.data.id }
                        });
                    }
                    return response.result.responseCode === 200 ? true : false;
                } else {
                    const response = await resp.json();
                    if (response) {
                        setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
                        addToastMessage({
                            status: false,
                            message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                        });
                        return false;
                    }
                    setStatus("Something went wrong, please try again later.");
                    addToastMessage({
                        status: false,
                        message: `Something went wrong, please try again later.`,
                    });
                    return false;
                }
            } else {
                const response = await resp.json();
                if (response) {
                    setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
                    addToastMessage({
                        status: false,
                        message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                    }); 
                    return false;
                }
                setStatus("Something went wrong, please try again later.");
                addToastMessage({
                    status: false,
                    message: `Something went wrong, please try again later.`,
                });
                return false;
            }
            return false;
        } catch (e) {
            console.log("Error:", e);
            setStatus("Something went wrong, please try again later.");
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
            return false;
        } finally {
            setLoading(false);
        }
    };


    const Label = ({ title, required }) => {
        return (
            <p
                className='color-primary f-w-600'
                style={{ paddingBottom: "5px" }}>
                {title}
                {required && (
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                )}
            </p>
        );
    };

const mapOptions = (records, valueKey, labelFormatter) =>
    records.map((record) => ({
        value: record[valueKey],
        label: labelFormatter(record),
        obj: record,
    }));

const labelSpecilityFunc = (o) => `${o.name}`;
const labelOnCallTypeFunc = (o) => `${o.typeName}`;

    const labelFacilityFunc = (o) => `${o.facilityName}`;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                const api = await createContract(values);
                if (api) {
                    resetForm(); 
                }
                setLoading(false);
            }}
        >
            {({
                values,
                setFieldValue,
                setFieldTouched,
                errors,
                touched,
                handleSubmit,
                validateForm,
            }) => (
                <Form>
                     <section
                        id='stepper-should-fix'
                        className='config-application-stepper'>
                        <div
                            className='steps flex center-center'
                            style={{ gap: "20px" }}>
                            <SingleStep
                                title='1'
                                subtitle='Basic Information'
                                style={{marginRight:"20px" }}
                                completion={[
                                    values.contractTypeId,
                                    values.facilityId,
                                    values.startDate,
                                    values.endDate,
                                    values?.contractContactDetails?.length > 0 ? "values" : "",
                                    // values.contactPersonPhone,
                                    // values.contactPersonName,
                                    // values.contactPersonEmail,
                                ]}

                            // completion={[data?.tenant + ""]}
                            />
                           
                            <SingleStep
                                title='2'
                                subtitle='Procedure Configuration'
                                completion={[
                                    values?.procedureConfiguration?.length > 0 ? "values" : "",
                                ]}
                            />
                             <SingleStep
                                title='3'
                                subtitle='On Call Configuration'
                                completion={[
                                    values?.onCallConfiguration?.length > 0 ? "values" : "",
                                ]}
                            />
                        </div>
                    </section>
                    <br />
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        <h5 className="color-primary">
                                            1. Basic Information
                                        </h5>
                                    </td>
                                </tr>
                                <br />
                                <tr>
                                <td width="50%">
                                    <Label title="Contract Type" required={true} />
                                    <PaginatedDropdown
                                        datakey="records"
                                        url={`${API_URL}/api/1.0.0/reference/contract-types?`}
                                        mapOptions={(records) =>
                                            mapOptions(
                                                records,
                                                "id",
                                                labelOnCallTypeFunc
                                            )
                                        }
                                        placeHolder='Search'
                                        selectedValue={values.contractTypeId}
                                        error={errors.contractTypeId && touched.contractTypeId}
                                        onChange={(v) => {
                                            setFieldValue(
                                                "contractTypeId",
                                                v
                                            );
                                        }}
                                    />
                                </td>
                                    <td width='50%'>
                                        <Label title='Facility' required={true} />
                                        <PaginatedDropdown
                                            url={`${API_URL}/api/1.0.0/reference/facility?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "id",
                                                    labelFacilityFunc
                                                )
                                            }
                                            error={errors.facilityId && touched.facilityId}
                                            placeHolder='Search Facility'
                                            selectedValue={values.facilityId}
                                            onChange={(v) =>
                                                setFieldValue("facilityId", v)
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width="50%">
                                        <Label title="Start Date" required={true} />
                                        <DatePicker
                                            className={`primary ${errors.startDate && touched.startDate ? "error" : ""
                                                }`}
                                            isClearable
                                            portalId="root-portal"
                                            onBlur={() => setFieldTouched("startDate", true)}
                                            selected={values.startDate ? parseISO(values.startDate) : ""}
                                            onChange={(date, e) => handleDatePickerChange(setFieldValue, "startDate", date, e)}
                                            showYearDropdown
                                            placeholderText="MM/DD/YYYY"
                                            dateFormat="MM/dd/yyyy"
                                        />
                                        <ErrorMessage
                                            name="startDate"
                                            component="p"
                                            className="error-messages"
                                        />
                                    </td>
                                    <td width="50%">
                                        <Label title="End Date" required={true} />
                                        <DatePicker
                                            className={`primary ${errors.endDate && touched.endDate ? "error" : ""
                                                }`}
                                            isClearable
                                            portalId="root-portal"
                                            onBlur={() => setFieldTouched("endDate", true)}
                                            selected={values.endDate ? parseISO(values.endDate) : ""}
                                            onChange={(date, e) => handleDatePickerChange(setFieldValue, "endDate", date, e)}
                                            showYearDropdown
                                            placeholderText="MM/DD/YYYY"
                                            dateFormat="MM/dd/yyyy"
                                        />
                                        <ErrorMessage
                                            name="endDate"
                                            component="p"
                                            className="error-messages"
                                        />
                                    </td>
                                </tr><br/>
                                <tr>
                                    <td colSpan="2">
                                        <NestedPersonalDetailForm
                                            onAdd={(newProcedure) => {
                                                setFieldValue('contractContactDetails', [
                                                    ...values.contractContactDetails,
                                                    newProcedure,
                                                ]);
                                            }}
                                        />

                                    </td>
                                </tr>
                                {values.contractContactDetails && (
                                    <tr>
                                        <td colSpan="2" >
                                            <div className="contect-date">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Designation</th>
                                                            <th>Name</th>
                                                            <th>Phone</th>
                                                            <th>Email</th>
                                                            <th style={{ textAlign: 'center' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {values.contractContactDetails.map((contact, index) => (
                                                            <tr>
                                                                <td><span>{contact?.contactPersonTitle || "-"}</span></td>
                                                                <td><span>{contact?.contactPersonName || "-"}</span></td>
                                                                <td><span>{contact.contactPersonPhone ? formatInputPhoneNumber(contact.contactPersonPhone.trim()) : "-"}</span></td>
                                                                <td><span>{contact?.contactPersonEmail || "-"}</span></td>
                                                               
                                                                <td>
                                                                    <div className="flex center-center">
                                                                        <span className="cursor-pointer"
                                                                            onClick={() => {
                                                                                const updatedConfiguration = values.contractContactDetails.filter((_, i) => i !== index);
                                                                                setFieldValue("contractContactDetails", updatedConfiguration);
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                height="15px"
                                                                                width="15px"
                                                                                viewBox="0 0 330 330"
                                                                                fill="red"
                                                                            >
                                                                                <g id="XMLID_6_">
                                                                                    <g id="XMLID_11_">
                                                                                        <path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
                                                                                    </g>
                                                                                    <g id="XMLID_18_">
                                                                                        <path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
                                                                                    </g>
                                                                                    <g id="XMLID_23_">
                                                                                        <path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>

                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => history.push("/contracts")}
                            icon={
                                <svg
                                    clipRule="evenodd"
                                    fillRule="evenodd"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="2"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width='19.995'
                                    height='19.996'
                                >
                                    <path
                                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                        fillRule="nonzero"
                                    />
                                </svg>
                            }
                        />
                        <ButtonTextIcon
                            type='button'
                            disabled={loading}
                            title='Create Now'
                            onClick={async () => {
                                const errors = await validateForm(); 
                                if (Object.keys(errors).length > 0) {
                                  const firstErrorMessage = Object.values(errors)[0];
                                  addToastMessage({
                                    status: false,
                                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                                  });
                                }
                                handleSubmit(); 
                              }}
                            className='mar-l-a'
                            icon={
                                loading ? (
                                    <LoaderIcon />
                                ) : (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='19.995'
                                        height='19.996'
                                        viewBox='0 0 19.995 19.996'>
                                        <path
                                            d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                                            transform='translate(-2.014 -1.995)'
                                        />
                                    </svg>
                                )
                            }
                        />
                    </div>
                    {/* <div style={{ opacity: "0.5" }}>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan="2">
                                        <br />
                                        <hr />
                                        <br />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <h5 className="color-primary">
                                            2. Procedure Configuration
                                        </h5>
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan="2">
                                        <br />
                                    </td>
                                </tr>
                                
                                <tr>
                                    <td colSpan={2}>
                                        <h5 className="color-primary">
                                            3. On Call Configuration
                                        </h5>
                                    </td>
                                </tr>
                                <br />

                            </tbody>
                        </table>
                    </div> */}
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

const NestedPersonalDetailForm = ({ onAdd }) => {
    const validationSchema = Yup.object().shape({
        contactPersonName: Yup.string().required("Person Name Required"), 
        contactPersonPhone: Yup.string()
                    .matches(/^\d+$/, "Phone Number must contain only numbers")
                    .max(15, "Phone Number must be at most 15 digits")
                    .required("Phone Number is required"),
        contactPersonEmail: Yup.string()
                    .email("Invalid email address")
                    .required("Email is required"),
    });

    return (
        <Formik
            initialValues={{
                contactPersonEmail: "",
                contactPersonPhone: "",
                contactPersonName: "",
                contactPersonTitle:"",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                try {
                    onAdd(values);
                    resetForm();
                } catch (error) {
                    console.error("Error during form submission:", error);
                }
            }}
        >
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
                <Form>
                    <table>
                        <tbody>
                            <tr>
                                <td width='50%'>
                                    <Label title='Contact Person Designation' />
                                    <Field
                                        type='text'
                                        placeholder='Eg: Doctor'
                                        name='contactPersonTitle'
                                        className={`col-1-1 primary ${errors.contactPersonTitle &&
                                            touched.contactPersonTitle
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name="contactPersonTitle"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>
                                <td width='50%'>
                                    <Label title='Contact Person Name' required={true} />
                                    <Field
                                        type='text'
                                        placeholder='Enter Name'
                                        name='contactPersonName'
                                        className={`col-1-1 primary ${errors.contactPersonName &&
                                            touched.contactPersonName
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name="contactPersonName"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td width='50%'>
                                    <Label title='Contact Person Phone' required={true} />
                                    <PhoneNumberInput
                                        name="contactPersonPhone"
                                        placeholder="Eg: 888-888-8888"
                                        error={errors.contactPersonPhone && touched.contactPersonPhone}
                                        value={values.contactPersonPhone}
                                        setTouched={() => setFieldTouched("contactPersonPhone", true)}
                                        callback={(v) => setFieldValue("contactPersonPhone", v)}
                                    />
                                    <ErrorMessage
                                        name="contactPersonPhone"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>
                                <td width='50%'>
                                    <Label title='Contact Person Email' required={true} />
                                    <Field
                                        type='text'
                                        placeholder='Enter Email'
                                        name='contactPersonEmail'
                                        className={`col-1-1 primary ${errors.contactPersonEmail &&
                                            touched.contactPersonEmail
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name="contactPersonEmail"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td colSpan={6}>
                                    <div className="flex bottom-right">
                                        <div>
                                            {/* <Label title="&nbsp;" /> */}
                                            <ButtonLightTextIcon
                                                title="Add Contact Details"
                                                type="submit"
                                                icon={
                                                    <svg
                                                        fill="#000000"
                                                        version="1.1"
                                                        width="800px"
                                                        height="800px"
                                                        viewBox="0 0 400 400"
                                                        xmlSpace="preserve"
                                                    >
                                                        <g>
                                                            <g>
                                                                <path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
                                                                <path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            )}
        </Formik>
    );
};

export default AddForm;
