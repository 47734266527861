import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import Pagination from "../components/common/Pagination";
import TableFilter from "../pages/pbfusa/TableFilter"
import CustomModal from "../components/common/CustomModal";
import { useUI } from "../context/ui";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import PatientRegistrationDetail from "./pbfusa/PatientRegistrationDetail";
import { formatInputPhoneNumber, getPBFUSAaccess } from "../opl-utilities/helpers";

const jsonToQueryParams = (json) => {
    const queryParams = Object.keys(json)
        .filter((key) => json[key] !== null && json[key] !== "")
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
        .join("&");

    return queryParams;
};
const PatientRegistrationPage = ({ history, location, activePage }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState(null);
    const queryParams = new URLSearchParams(location.search);
    const pageSize = queryParams.get("pageSize") || 12;
    const pageNo = queryParams.get("pageNo") || 0;

    const search = queryParams.get("search") || "";

    const { addToastMessage } = useUI();

    const resetFilters = () => {
        updateQueryParam("pageNo", 0);
        updateQueryParam("search", "");
    };

    if (!getPBFUSAaccess()) {
        history.push("/login");
    }  
    const getApi = async () => {
        setLoading(true);
        try {
            const query = jsonToQueryParams({
                search: search,
            });
            const resp = await fetch(
                `${API_URL}/api/1.0.0/pfb-patient?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token"),
                    },
                }
            );
            if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
				} else if (resp.status === 403) {
					addToastMessage({
						status: false, 
						message: "Access denied. You do not have permission to perform this action.",
                    });
                    setLoading(false);
                    
				}
				return false; 
			}
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    setData(response.data);
                }
                else {
                    setData(null);
                }
            }
            else {
                setData(null);
            }

        } catch (e) {
            console.log("Error:", e);
        } finally {
            setLoading(false);
        }
    };

    const updateQueryParam = (key, value) => {
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    };
   
    useEffect(() => {
        getApi();
    }, [location.search]);

    return (
        <section id="opl_coremax">
            <section className="main-section">
                <Header active={activePage} location={location} />
                <section id="category">
                    <div className="col-1-1 flex center-left pad-0-10">
                        <h5 className="page-active color-primary f-w-700">Patient Registration</h5>
                    </div>
                    <TableFilter resetFilters={resetFilters} />
                    <div>
                        {data && !loading && (
                            <div
                                className="flex center-left"
                                style={{
                                    padding: "10px 15px 10px 15px",
                                    gap: "12px",
                                }}
                            >
                                <p className="f-w-300" style={{ fontSize: "11px" }}>
                                    Page no:
                                </p>
                                <Pagination
                                    totalRecords={data.totalRecords}
                                    pageNo={data.pageNo}
                                    pageSize={data.pageSize}
                                />
                            </div>
                        )}
                    </div>
                    {loading ? (
						<section
							style={{
								height: "100vh",
								paddingBottom: "20vh",
								backgroundColor: "#fafaff"
							}}
							className='flex center-center'>
							<LoaderIcon
								style={{ height: "32px", width: "32px" }}
							/>
						</section>
					) : data?.list.length ? (
                        <div className="rs-table-holder">
                            <table className="rs-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Phone</th>
                                        <th>Address</th>
                                        <th style={{ textAlign: 'center' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data && data?.list.map((item, index) => (
                                        <tr key={item.id}>
                                            <td>{pageNo * pageSize + index + 1}</td>
                                            <td>{item.firstName + " " + item.lastName}</td>
                                            <td>{item.email}</td>
                                            <td>{formatInputPhoneNumber(item.contactNo.trim())}</td>
                                            <td>{item.address}</td>
                                            <td>
                                                <div
                                                    className="flex center-center"
                                                    style={{ gap: "12px" }}
                                                >
                                                    <div className="color-secondary transition hover-color-primary cursor-pointer ">

                                                        <CustomModal
                                                            selector={
                                                                <ButtonTertiary
                                                                    actionType='view'
                                                                    title='View'
                                                                />
                                                            }
                                                            content={
                                                                <PatientRegistrationDetail
                                                                    id={item.id} // Pass the correct ID
                                                                    data={item}
                                                                    history={history}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                   ) : (
                    <section
                        style={{
                            height: "80vh",
                            paddingBottom: "20vh",
                            backgroundColor: "#fff",
                        }}
                        className='flex center-center'>
                        <p>No records found.</p>
                    </section>
                )}
                    <div>
                        {data && !loading && (
                            <div
                                className="flex center-center"
                                style={{
                                    padding: "24px 15px 32px 15px",
                                    gap: "12px",
                                }}
                            >
                                <p className="f-w-300" style={{ fontSize: "11px" }}>
                                    Page no:
                                </p>
                                <Pagination
                                    totalRecords={data.totalRecords}
                                    pageNo={data.pageNo}
                                    pageSize={data.pageSize}
                                />
                            </div>
                        )}
                    </div>
                </section>
            </section>
        </section>
    );
};

export default withRouter(PatientRegistrationPage);
