import React, { useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import ButtonTextIcon from "./ButtonTextIcon"; 
import ButtonLightTextIcon from "./ButtonLightTextIcon";
import { parse, format } from 'date-fns';
import { formatServerDate } from "../../../opl-utilities/helpers";


const DateRangeInput = ({ onApply, onCancel, initialRange={} }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false);
  const [selectedRange, setSelectedRange] = useState( {
      startDate: null,
      endDate: null,
      key: "selection",
      color:"#5dc6b3",
      borderColor:"#5dc6b3", 
    }
  );

  const isValidRange =
    selectedRange.startDate instanceof Date &&
    !isNaN(selectedRange.startDate) &&
    selectedRange.endDate instanceof Date &&
    !isNaN(selectedRange.endDate);


  useEffect(() => { 
      setSelectedRange({
        startDate: initialRange.startDate ? new Date(initialRange.startDate) : null,
        endDate: initialRange.endDate ? new Date(initialRange.endDate) : null,
        key: "selection",
        color: "#5dc6b3",
        borderColor: "#5dc6b3",
      }); 
  }, [initialRange]);

  const handleSelect = (ranges) => {
    setSelectedRange(ranges.selection);
  };

  const handleApply = () => {
    if (onApply) { 
      onApply({
        startDate : selectedRange?.startDate ? format(selectedRange?.startDate, 'yyyy-MM-dd') :  new Date().toISOString().split('T')[0],
        endDate:  selectedRange?.endDate ? format(selectedRange?.endDate, 'yyyy-MM-dd') :  new Date().toISOString().split('T')[0],
      }); 
    }
    setIsPickerOpen(false);
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setIsPickerOpen(false);
  };
  
//   const formateDate = (date) => {
//     try {
//         const parsedDate = parse(date, 'MM/dd/yyyy', new Date()); // Parse specific format
//         return format(parsedDate, 'MM/dd/yyyy'); // Format as needed
//     } catch {
//         return "MM/DD/YYYY" 
//     }
// };

  return (
    <div className="pos-rel dis-i-b">
      <div
        className="ui-like-input col-1-1 cursor-pointer"
        style={{ whiteSpace: "nowrap" }}
        onClick={() => setIsPickerOpen(!isPickerOpen)}
      >
        {initialRange?.startDate ? (
          <span className="color-primary f-w-500">
            {formatServerDate(initialRange.startDate)}
          </span>
        ) : (
          "MM/DD/YYYY"
        )}
        &nbsp;to&nbsp;
        {initialRange?.endDate ? (
          <span className="color-primary f-w-500">
            {formatServerDate(initialRange.endDate)}
          </span>
        ) : (
          "MM/DD/YYYY"
        )}
        &nbsp;&nbsp;
        <span>
          <svg
            clipRule="evenodd"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            width="19.995"
            height="19.996"
            style={{
              width: "16px",
              height: "16px",
            }}
            fill="#373737"
          >
            <path
              d="M24 2v22h-24v-22h3v1c0 1.103.897 2 2 2s2-.897 2-2v-1h10v1c0 1.103.897 2 2 2s2-.897 2-2v-1h3zm-2 6h-20v14h20v-14zm-2-7c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-14 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2z"
              fillRule="nonzero"
            />
          </svg>
        </span>
      </div>

      {isPickerOpen && (
        <>
          {/* Background Overlay */}
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              backgroundColor: "rgba(255, 255, 255, 0.72)",
              zIndex: 999,
            }}
            onClick={handleCancel}
          />

          {/* Date Picker */}
          <div  
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1000,
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              maxWidth: "90%",  
              width: "auto",
              padding: "10px",
              overflowX: "scroll",
              scrollbarWidth: "thin"
            }}
          >
            <DateRangePicker
              className="date-range"
              color="#17181f"
              ranges={
                isValidRange
                  ? [selectedRange]
                  : [
                      {
                        startDate: new Date(),
                        endDate: new Date(),
                        key: "selection",
                        color: "#5dc6b3",
                        borderColor: "#5dc6b3",
                      },
                    ]
              }
              onChange={handleSelect}
              months={2}
              direction="horizontal"
              // editableDateInputs={true}
              moveRangeOnFirstSelection={false}
              retainEndDateOnFirstSelection={true}
            />
            <div className="flex center-right pad-10" style={{ gap: "10px" }}>
              <div>
                <ButtonLightTextIcon
                  icon={
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.995"
                      height="19.996"
                    >
                      <path
                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                        fillRule="nonzero"
                      />
                    </svg>
                  }
                  type="button"
                  title="Cancel"
                  onClick={handleCancel}
                />
              </div>
              <div>
                <ButtonTextIcon
                  icon={
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.995"
                      height="19.996"
                    >
                      <path
                        d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zm-1.5 13.794L7.206 12.5a.75.75 0 0 1 1.088-1.032l2.206 2.21 4.706-4.707a.75.75 0 1 1 1.06 1.06l-5.25 5.251a.75.75 0 0 1-1.06 0z"
                        fillRule="nonzero"
                      />
                    </svg>
                  }
                  type="button"
                  title="Apply"
                  onClick={() => handleApply(selectedRange)}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default DateRangeInput;
