import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../components/common/ResultsPerPage";
import Pagination from "../components/common/Pagination";
import ActivityFilter from "./activities/ActivityFilter";
import PaginatedDropdown from "../components/common/ui/PaginatedDropdown";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import { decodeModuleAccessFromToken, formatInputPhoneNumber } from "../opl-utilities/helpers";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import FacilityFilter from "./facilities/FacilityFilter";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import PopUpModal from "../components/common/PopUpModal";
import TableFilter from "./contract/TableFilter";
import ContractDetails from "./contract/ContractDetails";

const jsonToQueryParams = (json) => {
    const queryParams = Object.keys(json)
        .filter((key) => json[key] !== null && json[key] !== "")
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`)
        .join("&");

    return queryParams;
};
const getRandomColor = () => {
    const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};
const ContractPage = ({ history, location, activePage }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
    const pageSize = new URLSearchParams(location.search).get("pageSize") || 12;
    const { addToastMessage } = useUI();
    const queryParams = new URLSearchParams(location.search);
    const permissions = decodeModuleAccessFromToken("contracts");
    const [isOpen, setIsOpen] = useState(false);
    const [viewContract,setViewContract] = useState(null);
    // Redirect to login if no permissions
    useEffect(() => {
        if (!permissions) {
            history.push("/login");
        }
    }, [permissions, history]);

    const resetFilters = () => {
        updateQueryParam("search", "");
        updateQueryParam("pageNo", 0);
    };
    const updateQueryParam = (key, value) => {
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    };

    const callListApi = async () => {
        setLoading(true);
        try {
            const query = jsonToQueryParams({
                search: queryParams.get("search") || "",
            });
            const resp = await fetch(
                `${API_URL}/api/1.0.0/contract?pageNo=${pageNo}&pageSize=${pageSize}&sortOrder=DESC&${query}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token"),
                    },
                }
            );
            if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
				} else if (resp.status === 403) {
					addToastMessage({
						status: false, 
						message: "Access denied. You do not have permission to perform this action.",
                    });
                    setLoading(false);
				}
				return false; 
			}

            if (resp.ok) {
                const response = await resp.json();
                if (response.result.responseCode === 200) {
                    setData(response.data);
                } else {
                    setData(null);
                }
            } else {
                setData(null);
            }
        } catch (e) {
            console.error("Error", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        callListApi();
    }, [location.search]);

    const handleDelete = async (id) => {
        try {
            if (!id) {
                return false;
            }
            const response = await fetch(
                API_URL + `/api/1.0.0/contract/${id}`,
                {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token"),
                    },
                }
            );
            if (response.status === 401 || response.status === 403) {
				if (response.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
				} else if (response.status === 403) {
					addToastMessage({
						status: false, 
						message: "Access denied. You do not have permission to perform this action.",
					});
				}
				return false; 
			}
            if (response.status === 200) {
                callListApi();
                addToastMessage({
                    status: true,
                    message: `Contract deleted successfully.`,
                });
                return true;
            }

            addToastMessage({
                status: false,
                message: `Something went wrong while deleting procedure. Please try again.`,
            });

            return false;
        } catch (error) {
            addToastMessage({
                status: false,
                message: `Something went wrong while deleting procedure. Please try again.`,
            });
            return false;
        }
    };
    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
        }));
    return (
        <section id="opl_coremax">
            <section className="main-section">
                <Header active={activePage} location={location} />
                <section id="activity-v2">
                    <div className="col-1-1 flex center-left pad-0-10 main-section-top-row">
                        <h5 className="page-active color-primary f-w-700">
                        Contracts
                        </h5>
                        {permissions?.isCreateAllowed && (
                            <ButtonTextIcon
                                title="Add New"
                                className="mar-l-a"
                                onClick={() => history.push("/add-contracts")}
                                icon={
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="19.995"
                                        height="19.995"
                                        viewBox="0 0 19.995 19.995"
                                    >
                                        <path
                                            d="M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z"
                                            transform="translate(-2.005 -2)"
                                        />
                                    </svg>
                                }
                            />
                        )}
                        &nbsp;
                    </div>
                    <TableFilter resetFilters={resetFilters} />
                    <br />
                    <br />
                    <div>
                        {data && !loading && (
                            <div
                                className="flex center-left"
                                style={{
                                    padding: "10px 15px 10px 15px",
                                    gap: "12px",
                                }}
                            >
                                <p className="f-w-300" style={{ fontSize: "11px" }}>
                                    Page no:
                                </p>
                                <Pagination
                                    totalRecords={data.totalRecords}
                                    pageNo={data.pageNo}
                                    pageSize={data.pageSize}
                                />
                            </div>
                        )}
                    </div>
                    {loading ? (
                        <section
                            style={{
                                height: "100vh",
                                paddingBottom: "20vh",
                                backgroundColor: "#fafaff",
                            }}
                            className="flex center-center"
                        >
                            <LoaderIcon style={{ height: "32px", width: "32px" }} />
                        </section>
                    ) : data?.records.length ? (
                        <div className="rs-table-holder">
                            <table className="rs-table">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Facility</th>
                                        <th>Contract Type</th>
                                        <th>Contact Person Name</th>
                                        <th>Contact Person Phone</th>
                                        <th>Contact Person Email</th>
                                        <th style={{ textAlign: 'center' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data &&
                                        data.records.map((data, index) => (
                                            <tr key={index}>
                                                <td>{pageNo * pageSize + index + 1}</td>
                                                <td>{data.facility?.facilityName}</td>
                                                <td>{data?.contractType?.typeName || "-"}</td>
                                                <td>
                                                    {data?.contactPersonDetails?.length > 0 && data.contactPersonDetails[0]?.contactPersonName?.trim() 
                                                    ? data.contactPersonDetails[0].contactPersonName 
                                                    : "-"}
                                                </td>
                                                <td>
                                                    {data?.contactPersonDetails?.length > 0 && data.contactPersonDetails[0]?.contactPersonPhone?.trim() 
                                                    ? formatInputPhoneNumber(data.contactPersonDetails[0].contactPersonPhone) 
                                                    : "-"}
                                                </td> 
                                                <td>
                                                    {data?.contactPersonDetails?.length > 0 && data.contactPersonDetails[0]?.contactPersonEmail?.trim() 
                                                    ? data.contactPersonDetails[0].contactPersonEmail 
                                                    : "-"}
                                                </td>
                                               <td>
                                                    <div
                                                        className="flex center-center"
                                                        style={{ gap: "12px" }}
                                                    >
                                                        {permissions?.isReadAllowed && (
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                                                     <ButtonTertiary
                                                                            actionType='view'
                                                                            title='View'
																			onClick={()=>{setViewContract(data?.id)}}
                                                                        />
                                                            </div> 
                                                        )}
                                                        {permissions?.isUpdateAllowed && (
                                                            <div>
                                                                <NavLink
                                                                    to={{
                                                                        pathname: "/edit-contracts",
                                                                        search: `?next=${window.location.pathname + window.location.search}`,
                                                                        state: { id: data?.id },
                                                                    }}
                                                                >
                                                                    <ButtonTertiary
                                                                        actionType="edit"
                                                                        title="Edit"
                                                                    />
                                                                </NavLink>
                                                            </div>
                                                        )}
                                                        {permissions?.isDeleteAllowed && (
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer ">
                                                                <CustomModal
                                                                    selector={
                                                                        <ButtonTertiary
                                                                            actionType="delete"
                                                                            title="Delete"
                                                                        />
                                                                    }
                                                                    content={
                                                                        <ConfirmationModal
                                                                            onConfirm={async () => {
                                                                                await handleDelete(data.id);
                                                                                return true;
                                                                            }}
                                                                            message="Are you sure you want to delete this contract ?"
                                                                        />
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <section
                            style={{
                                height: "80vh",
                                paddingBottom: "20vh",
                                backgroundColor: "#fff",
                            }}
                            className="flex center-center"
                        >
                            <p>No records found.</p>
                        </section>
                    )}
                    <div>
                        {data && !loading && (
                            <div
                                className="flex center-center"
                                style={{
                                    padding: "10px 15px 10px 15px",
                                    gap: "12px",
                                }}
                            >
                                <p className="f-w-300" style={{ fontSize: "11px" }}>
                                    Page no:
                                </p>
                                <Pagination
                                    totalRecords={data.totalRecords}
                                    pageNo={data.pageNo}
                                    pageSize={data.pageSize}
                                />
                            </div>
                        )}
                    </div>
                </section>
            </section>
            {viewContract && 
            <PopUpModal>
				<ContractDetails
				    setViewContract={setViewContract}
					id={viewContract}
					history={history}
			    />
            </PopUpModal>
			}
        </section>
    );
};



export default withRouter(ContractPage);
