import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import Pagination from "../components/common/Pagination";
import InsuranceFilter from "./insurance/InsuranceFilter";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import { decodeModuleAccessFromToken, formatInputPhoneNumber } from "../opl-utilities/helpers";
import { useUI } from "../context/ui";
import CustomModal from "../components/common/CustomModal";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";
import InsuranceDetails from "./insurance/InsuranceDetails";

const jsonToQueryParams = (json) => {
	const queryParams = Object.keys(json)
		.filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
		.join("&");

	return queryParams;
};

const InsurancePage = ({ history, location, activePage }) => {
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [viewInsurance,setViewInsurance] = useState(null);
	const queryParams = new URLSearchParams(location.search);
	const pageSize = queryParams.get("pageSize") || 12;
	const pageNo = queryParams.get("pageNo") || 0;
	const { addToastMessage } = useUI();

	// Decode permissions for the "users" module
	const permissions = decodeModuleAccessFromToken("insurance");

	// Redirect to login if no permissions
	useEffect(() => {
		if (!permissions) {
			history.push("/login");
		}
	}, [permissions, history]);


	// ====== FILTER QUERY PARAMS
    const description	= queryParams.get("description") || "";

	// ====== RESET FILTER
	const resetFilters = () => {
		updateQueryParam("description", "");
		updateQueryParam("pageNo", 0);
	};



	const getListApi = async (s) => {
		setLoading(true);
		try {
			const query = jsonToQueryParams({
				description:description ,
				pageNo: queryParams.get("pageNo") || 0,
			});
			const resp = await fetch(
				API_URL +
				`/api/1.0.0/insurance?pageSize=${pageSize}&${query}&sortOrder=DESC`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token")
					},
				}
			);
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
				} else if (resp.status === 403) {
					addToastMessage({
						status: false, 
						message: "Access denied. You do not have permission to perform this action.",
					});
					setLoading(false);
				}
				return false; 
			}
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					setData(response.data);

				}
			}
		} catch (e) {
			console.log("Error:", e);
		} finally {
			setLoading(false);
		}
	};

	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	useEffect(() => {
		getListApi();
	}, [location.search]);
  
	const handleDelete = async (id) => {
		try {
			if (!id) {
				addToastMessage({
					status: false,
					message: `Something went wrong while deleting insurance. Please try again.`,
				});
				return false;
			}
			const response = await fetch(
				API_URL +
				`/api/1.0.0/insurance/${id}`,
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token")
					},
				}
			);
			if (response.status === 401 || response.status === 403) {
				if (response.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
				} else if (response.status === 403) {
					addToastMessage({
						status: false, 
						message: "Access denied. You do not have permission to perform this action.",
					});
				}
				return false; 
			}
			if (response.status === 200) {
				getListApi();
				addToastMessage({
					status: true,
					message: `Insurance deleted successfully.`,
				});
				return true;
			}
			addToastMessage({
				status: false,
				message: `Something went wrong while deleting insurance. Please try again.`,
			});
			return false;
		} catch (error) {
			addToastMessage({
				status: false,
				message: `Something went wrong while deleting insurance. Please try again.`,
			});
			return false;
		}
	};

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='insurance'>
					<div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
						<h5 className='page-active color-primary f-w-700'>
							Insurance
						</h5>
						{permissions?.isCreateAllowed &&
							<ButtonTextIcon
								title='Add New'
								className='mar-l-a'
								onClick={() => history.push("/add-insurance")}
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='19.995'
										height='19.995'
										viewBox='0 0 19.995 19.995'>
										<path
											d='M12,2a10,10,0,1,1-10,10A10,10,0,0,1,12,2Zm0,1.5A8.5,8.5,0,1,0,20.5,12,8.5,8.5,0,0,0,12,3.5Zm-.747,7.75h-3.5a.75.75,0,0,0,0,1.5h3.5v3.5a.75.75,0,0,0,1.5,0v-3.5h3.5a.75.75,0,0,0,0-1.5h-3.5V7.75a.75.75,0,0,0-1.5,0Z'
											transform='translate(-2.005 -2)'
										/>
									</svg>
								}
							/>
						}
						&nbsp;
					</div>
					<InsuranceFilter
						resetFilters={() => {
							resetFilters();
						}}
					/>
					<div>
						{data && !loading && (
							<div
								className='flex center-left'
								style={{
									padding: "10px 15px 10px 15px",
									gap: "12px",
								}}>
								<p
									className='f-w-300'
									style={{ fontSize: "11px" }}>
									Page no:
								</p>
								<Pagination
									totalRecords={data.totalRecords}
									pageNo={data.pageNo}
									pageSize={data.pageSize}
								/>
							</div>
						)}
					</div>
					{loading ? (
						<section
							style={{
								height: "100vh",
								paddingBottom: "20vh",
								backgroundColor: "#fafaff"
							}}
							className='flex center-center'>
							<LoaderIcon
								style={{ height: "32px", width: "32px" }}
							/>
						</section>
					) : data?.list.length ? (
						<div className='rs-table-holder'>
							<table className='rs-table'>
								<thead>
									<tr>
										<th>#</th>
										<th>Insurance Name</th>
										<th>Address</th>
										<th>Phone</th>
										<th>Fax</th>
										<th>Email</th>
										<th>Electronic Payer ID</th>
										<th>Master Payer ID</th>
										<th style={{ textAlign: 'center' }}>Action</th>
									</tr>
								</thead>
								<tbody>
									{data && data.list.map((data, index) => (
										<tr key={index}>
											<td>{pageNo * pageSize + index + 1}</td>
											<td>{data.description || "-"}</td>
											<td>{data.addressLine1 || "-"}</td>
											<td>
												{/* {data.insuranceContactDetails
													.filter((contact) => contact.type === "Phone")
													.map((contact, index, arr) => (
														<div key={index}>
															{contact.information && contact.information.trim() ? contact.information : "-"}
															{index < arr.length - 1 && ","}
														</div>
													))} */}
												{data.insuranceContactDetails
												.filter((contact) => contact.type === "Phone")
												.map((contact, index, arr) => (
												<div key={index}>
													{contact.information && contact.information.trim() 
														? formatInputPhoneNumber(contact.information.trim()) 
														: "-"}
													{index < arr.length - 1 && ","}
												</div>
												))}

											</td>
											<td>
												{data.insuranceContactDetails
													.filter((contact) => contact.type === "Fax")
													.map((contact, index, arr) => (
														<div key={index}>
															{contact.information && contact.information.trim() ? contact.information : "-"}
															{index < arr.length - 1 && ","}
														</div>
													))}
											</td>
											<td>
												{data.insuranceContactDetails
													.filter((contact) => contact.type === "Email")
													.map((contact, index, arr) => (
														<div key={index}>
															{contact.information && contact.information.trim() ? contact.information : "-"}
															{index < arr.length - 1 && ","}
														</div>
													))}
											</td>
											<td>{data.electronicPayerId || "-"}</td>
											<td>{data.masterPayerId || "-"}</td>
											<td>
												<div className="flex center-center" style={{ gap: '12px' }}>
												{permissions?.isReadAllowed && (
                                                            <div className="color-secondary transition hover-color-primary cursor-pointer ">

                                                                {/* <CustomModal
                                                                    selector={ */}
                                                                        <ButtonTertiary
                                                                            actionType='view'
                                                                            title='View'
																			onClick={()=>{setViewInsurance(data?.id)}}
                                                                        />
                                                                    {/* }
                                                                    content={
                                                                                <InsuranceDetails
                                                                                    id={data?.id}
                                                                                    history={history}
                                                                                />
                                                                    }
                                                                /> */}
                                                            </div>

                                                        )}
													{permissions?.isUpdateAllowed &&
															<NavLink
																to={{
																	pathname: '/edit-insurance',
																	search: `?next=${window.location.pathname + window.location.search}`,
																	state: { id: data.id }
																}}
															>
																<ButtonTertiary
																	actionType='edit'
																	title='Edit'
																/>
															</NavLink>
													}
													{permissions?.isDeleteAllowed &&
																<div className="color-secondary transition hover-color-primary cursor-pointer " >
																	<CustomModal
																		selector={<ButtonTertiary
																			actionType='delete'
																			title='Delete'
																		/>}
																		content={
																			<ConfirmationModal
																				onConfirm={async () => { await handleDelete(data.id); return true; }}
																				message="Are you sure you want to delete this insurance?"
																			/>
																		}
																	/>
																</div>
															}  
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					) : (
						<section
							style={{
								height: "80vh",
								paddingBottom: "20vh",
								backgroundColor: "#fff",
							}}
							className='flex center-center'>
							<p>No records found.</p>
						</section>
					)}
					<div>
						{data && !loading && (
							<div
								className='flex center-center'
								style={{
									padding: "10px 15px 10px 15px",
									gap: "12px",
								}}>
								<p
									className='f-w-300'
									style={{ fontSize: "11px" }}>
									Page no:
								</p>
								<Pagination
									totalRecords={data.totalRecords}
									pageNo={data.pageNo}
									pageSize={data.pageSize}
								/>
							</div>
						)}
					</div>
				</section>
			</section>
			{viewInsurance && 
				<InsuranceDetails
				    setViewInsurance={setViewInsurance}
					id={viewInsurance}
					history={history}
			    />
			}
		</section>
	);
};

export default withRouter(InsurancePage);