import "../styles/base.scss";
import "../styles/pages.scss";
import { withRouter } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import {
	getLogInUserDetails,
	getScanfloeStatusInfo,
	isManager,
} from "../opl-utilities/helpers";
import {
	API_URL,
	SCANFLOW_STATUS_LIST,
	SYSTEM_TOKEN,
} from "../opl-utilities/constants";
import Header from "../components/common/ui/Header";
import Tabs from "./scanflow/Tabs";
import TableFilter from "./scanflow/TableFilter";
import SingleTask from "./scanflow/SingleTask";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import TaskDetails from "./scanflow/TaskDetails";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import { UIProvider, useUI } from "../context/ui";
import { ScanflowProvider, useScanflow } from "./scanflow/scanflow-context";
import CustomModal from "../components/common/CustomModal";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Pagination from "../components/common/Pagination";

const jsonToQueryParams = (json) => {
	const queryParams = Object.keys(json)
		.filter((key) => json[key] !== null && json[key] !== "")
		.map(
			(key) =>
				`${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
		)
		.join("&");

	return queryParams;
};

const ScanflowV2 = ({ history, location, activePage }) => {
	const [controller, setController] = useState(null); 
	const [taskController, setTaskController] = useState(null); 
	const containerRef = useRef(null);
	const {
		random,
		stats,
		taskList,
		setTaskList,
		task,
		setTask,
		taskLoading,
		setTaskLoading,
		setStats,
	} = useScanflow();
	const [dataLoading, setDataLoading] = useState(false); 
	const queryParams = new URLSearchParams(location.search);

	// ====== FILTER QUERY PARAMS
	const pageSize = queryParams.get("pageSize") || 12;
	const pageNo = queryParams.get("pageNo") || 0;
	const defaultFilterVal = queryParams.get("defaultFilterVal") || "";
	const tab = queryParams.get("tab") || "not-started";
	const department = queryParams.get("department") || null;
	const id = queryParams.get("id");
	const billStatus = queryParams.get("billStatus") || "";
	const startDate = queryParams.get("startDate") || "";
	const endDate = queryParams.get("endDate") || "";
	const claimStatus = queryParams.get("claimStatus") || "";
	const hospitalContract = queryParams.get("hospitalContract") || "";
	const patient = queryParams.get("patient") || null;
	const assistant = queryParams.get("assistant") || null;
	const insurance = queryParams.get("insurance") || null;
	const facility = queryParams.get("facility") || null;
	const practice = queryParams.get("practice") || null;
	const patientChartNo = queryParams.get("patientChartNo") || "";

	// ====== RESET FILTER
	const resetFilters = () => {
		updateQueryParam("defaultFilterVal", "")
		updateQueryParam("department", null);
		updateQueryParam("pageNo", 0);
		updateQueryParam("billStatus", "");
		updateQueryParam("startDate", "");
		updateQueryParam("endDate", "");
		updateQueryParam("claimStatus", "");
		updateQueryParam("hospitalContract", "");
		updateQueryParam("patient", null);
		updateQueryParam("assistant", null);
		updateQueryParam("insurance", null);
		updateQueryParam("facility", null);
		updateQueryParam("practice", null);
		updateQueryParam("patientChartNo", "")
	};

	const updateQueryParam = (key, value) => {
		value ? queryParams.set(key, value) : queryParams.delete(key);
		history.push({ search: queryParams.toString() });
	};

	// ====== Remove this code after generating the token with OPL, as it retrieves the user's ID and role.
	const userId = getLogInUserDetails("id");
	const role = getLogInUserDetails("type");

	const callTaskByIdApi = async (id) => { 
		taskController?.abort();
        const newController = new AbortController();
        setTaskController(newController);
        const { signal } = newController;
		setTaskLoading(true);
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/scanflow-v2/stepExecution/${id}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					signal
				}
			);
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (resp.status === 403) {
					setTaskLoading(false);
				}
				return false;
			  }
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setTask(response.data);
				}
			}
		} catch (e) {
			console.log("error", e);
		} finally {
			setTaskLoading(false);
		}
	};

	const callTaskApi = async () => {
		controller?.abort();
        const newController = new AbortController();
        setController(newController);
        const { signal } = newController;
		setDataLoading(true);
		try {
			const query = jsonToQueryParams({
				role: role,
				userId: userId,
				statusId:
					tab !== "all"
						? getScanfloeStatusInfo("slug", tab).id
						: null,
				pageNo: pageNo,
				pageSize: pageSize,
				billStatus,
				startDate,
				endDate,
				claimStatus,
				hospitalContract,
				departmentId: department ? JSON.parse(department).value : null,
				patientId: patient ? JSON.parse(patient)?.value : null,
				practiceId: practice ? JSON.parse(practice)?.value : null,
				facilityId: facility ? JSON.parse(facility)?.value : null,
				insuranceCompanyId: insurance
					? JSON.parse(insurance)?.value
					: null,
				assistantId: assistant ? JSON.parse(assistant)?.value : null,
				patientChartNo: patientChartNo,
				// isAssigned: true,
			});
			const resp = await fetch(
				API_URL + `/api/1.0.0/scanflow-v2/tasks?${query}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					signal,
				}, 
			);
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (resp.status === 403) {
						setDataLoading(false);
				}
				return false;
			  }
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					setTaskList(response.data);
					setStats(response.data.stats || {
						notStarted: 0,
						completed: 0,
						inProgress: 0,
						forwarded: 0,
						paused: 0
					});
				}
			}
			setDataLoading(false);
		} catch (e) {
			if (e.name === "AbortError") {
                console.log("Previous request was aborted");
            }else{
				setDataLoading(false);
				setTaskList(null);
				setStats({
					notStarted: 0,
					completed: 0,
					inProgress: 0,
					forwarded: 0,
					paused: 0
				});
			}
			console.log("error", e);
		} finally {
		}
	};


	useEffect(() => {
		if (containerRef.current) {
			if (id) { 
				const targetElement = containerRef.current.querySelector(`#scanflow-${id}`);
				if (targetElement) { 
					targetElement.scrollIntoView({
					behavior: "smooth",
					block: "center",  
					});
					targetElement.focus();  
				}
			}else{
				const firstElement = containerRef.current.querySelector(".one");
				if (firstElement) {
				  firstElement.scrollIntoView({
					behavior: "smooth",
					block: "center", // Scroll to the center of the container
				  });
				  firstElement.focus(); // Focus the first task
				}
			}
		}
	  }, [taskList, id]); 

	// const callTaskCountApi = async () => {
	// 	try {
	// 		const role = getLogInUserDetails("type");
	// 		const resp = await fetch(
	// 			API_URL + `/api/1.0.0/department/taskCount?userType=${role}`,
	// 			{
	// 				method: "GET",
	// 				headers: {
	// 					"Content-Type": "application/json", 
	// 					"X-JWT-Assertion": localStorage.getItem("token"), 
	// 					"X-JWT-Assertion": localStorage.getItem("token"), 
	// 				},
	// 			}
	// 		);
	// 		if (resp.ok) {
	// 			const response = await resp.json();
	// 			if (response.result.responseCode === 200) {
	// 				setTaskCount({
	// 					...response.data.departmentTaskCountProjection,
	// 					autoAssign: response.data.autoAssign,
	// 				});
	// 			}
	// 		}
	// 	} catch (e) {
	// 		console.log("error", e);
	// 	} finally {
	// 	}
	// };

	useEffect(() => {
		// if (!defaultFilterVal) {
		// 	updateQueryParam("defaultFilterVal", "set");
		// 	updateQueryParam("startDate", new Date().toISOString().split("T")[0]);
		// 	updateQueryParam("endDate", new Date().toISOString().split("T")[0]);
		// }
		callTaskApi();
		// if (isManager(role)) {
		// 	callTaskCountApi();
		// }
		return () => {
            if (controller) {
                controller.abort();
            }
        };
	}, [location.search, tab, random]);

	useEffect(() => {
		if (id) {
			callTaskByIdApi(id);
		} else {
			setTask(null);
		}
		return () => {
            if (taskController) {
                taskController.abort();
            }
        };
	}, [location.search, id, random]);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='scanflow-v2'>
					<div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
						<h5 className='page-active color-primary f-w-700'>
							Scanflow
						</h5>
						<Tabs
							options={[
								{
									name: "All",
									slug: "all",
								},
								...SCANFLOW_STATUS_LIST,
							].map((o) => ({
								title: o.name,
								id: o.slug,
								content: <></>,
							}))}
							active={tab}
							setActive={(v) => {
								updateQueryParam("id", null);
								updateQueryParam("pageNo", 0); 
								updateQueryParam("tab", v); 
							}}
						/>
						{isManager(role) && (
							<div className='tickers mar-l-a'>
								<div
									className='flex center-center'
									style={{ gap: "12px" }}>
									{/* <div>
										<div>
											<CustomModal
												selector={
													<div
														className='flex center-center b-r-20 opl-tooltip'
														data-tooltip='Click to update settings.'
														style={{
															background:
																"#f0f0f0",
															padding:
																"10px 16px",
															gap: "6px",
															whiteSpace:
																"nowrap",
														}}>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															style={{
																height: "14px",
																width: "auto",
															}}>
															<path
																d='M24 13.616v-3.232c-1.651-.587-2.694-.752-3.219-2.019v-.001c-.527-1.271.1-2.134.847-3.707l-2.285-2.285c-1.561.742-2.433 1.375-3.707.847h-.001c-1.269-.526-1.435-1.576-2.019-3.219h-3.232c-.582 1.635-.749 2.692-2.019 3.219h-.001c-1.271.528-2.132-.098-3.707-.847l-2.285 2.285c.745 1.568 1.375 2.434.847 3.707-.527 1.271-1.584 1.438-3.219 2.02v3.232c1.632.58 2.692.749 3.219 2.019.53 1.282-.114 2.166-.847 3.707l2.285 2.286c1.562-.743 2.434-1.375 3.707-.847h.001c1.27.526 1.436 1.579 2.019 3.219h3.232c.582-1.636.75-2.69 2.027-3.222h.001c1.262-.524 2.12.101 3.698.851l2.285-2.286c-.744-1.563-1.375-2.433-.848-3.706.527-1.271 1.588-1.44 3.221-2.021zm-12 2.384c-2.209 0-4-1.791-4-4s1.791-4 4-4 4 1.791 4 4-1.791 4-4 4z'
																fill='#54BAEC'
															/>
														</svg>
														<p className='l-h-1 f-w-300 color-primary'>
															{taskCount.autoAssign
																? "Auto"
																: "Manual"}{" "}
															Assignment
														</p>
														<svg
															xmlns='http://www.w3.org/2000/svg'
															width='24'
															height='24'
															viewBox='0 0 24 24'
															style={{
																height: "14px",
																width: "auto",
															}}>
															<path
																d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM10.649,6.493a1.358,1.358,0,1,1,2.7,0l-.8,8.01a.553.553,0,0,1-1.1,0ZM12,19.25A1.25,1.25,0,1,1,13.25,18,1.25,1.25,0,0,1,12,19.25Z'
																fill='#E65464'
															/>
														</svg>
													</div>
												}
												content={
													<AutoAssignConfigContent
														autoAssign={
															taskCount.autoAssign
														}
														callback={(
															autoAssign
														) => {
															setTaskCount(
																(o) => ({
																	...o,
																	autoAssign,
																})
															);
														}}
													/>
												}
											/>
										</div>
									</div> */} 
								</div>
							</div>
						)}
					</div>
					<TableFilter
						resetFilters={() => {
							resetFilters();
						}}
					/>
					<div>
					{taskList && (
						<div
							className='flex center-left'
							style={{
								padding: "10px 15px 10px 15px",
								gap: "12px",
							}}>
							<p
								className='f-w-300'
								style={{ fontSize: "11px" }}>
								Page no:
							</p>
							<Pagination
								totalRecords={taskList?.totalRecords}
								pageNo={taskList?.pageNo}
								pageSize={taskList?.pageSize}
								removeParamKey={"id"}
							/>
						</div>
					)}
					</div>
					{/* {(isManager(role)) && ( */}
						<div className="col-1-1 " style={{ backgroundColor: "#e7e7e7", padding: "10px 10px", overflowX: 'scroll' }}>
							<ul
								className='info flex center-left'
								style={{ gap: "4px" }}>
								<li
									className='l-s-n flex center-center b-r-20'
									style={{
										background: "#F08D3B",
										padding: "10px 16px",
										gap: "4px",
										whiteSpace: "nowrap",
									}}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='32'
										height='32'
										viewBox='0 0 32 32'
										style={{
											height: "14px",
											width: "auto",
										}}>
										<path
											d='M16,32A16,16,0,0,1,4.686,4.686,16,16,0,1,1,27.314,27.314,15.9,15.9,0,0,1,16,32ZM13,10a3,3,0,0,0-3,3v6a3,3,0,0,0,3,3h6a3,3,0,0,0,3-3V13a3,3,0,0,0-3-3Z'
											fill='white'
										/>
									</svg>
									<p className='l-h-1 color-white f-w-600'>
										{stats.notStarted || 0}
									</p>
									<p className='l-h-1 color-white f-w-300' style={{
										whiteSpace: "nowrap"
									}}>
										Not Started
									</p>
								</li>
								<li
									className='l-s-n flex center-center b-r-20'
									style={{
										background: "#E65464",
										padding: "10px 16px",
										gap: "4px",
									}}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										style={{
											height: "14px",
											width: "auto",
										}}>
										<path
											d='M14,22a2,2,0,1,1-2-2A2,2,0,0,1,14,22ZM12,0a2,2,0,1,0,2,2A2,2,0,0,0,12,0ZM22,10a2,2,0,1,1-2,2A2,2,0,0,1,22,10ZM0,12a2,2,0,1,0,2-2A2,2,0,0,0,0,12ZM19,3a2,2,0,1,1-2,2A2,2,0,0,1,19,3Zm0,14a2,2,0,1,1-2,2A2,2,0,0,1,19,17ZM5,3A2,2,0,1,1,3,5,2,2,0,0,1,5,3ZM5,17a2,2,0,1,1-2,2A2,2,0,0,1,5,17Z'
											fill='white'
										/>
									</svg>
									<p className='l-h-1 color-white f-w-600' >
										{stats.inProgress || 0}
									</p>
									<p className='l-h-1 color-white f-w-300' style={{
										whiteSpace: "nowrap"
									}}>
										In Progress
									</p>
								</li>
								<li
									className='l-s-n flex center-center b-r-20'
									style={{
										background: "#56BAEC",
										padding: "10px 16px",
										gap: "4px",
									}}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										style={{
											height: "14px",
											width: "auto",
										}}>
										<path
											d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm2,16V13.375S8.381,13.38,5,17c1.588-6.916,9-8.292,9-8.292V6l5,5.021Z'
											fill='white'
										/>
									</svg>
									<p className='l-h-1 color-white f-w-600'>
										{stats.forwarded || 0}
									</p>
									<p className='l-h-1 color-white f-w-300' style={{
										whiteSpace: "nowrap"
									}}>
										Forwarded
									</p>
								</li>
								<li
									className='l-s-n flex center-center b-r-20'
									style={{
										background: "#8e44ad",
										padding: "10px 16px",
										gap: "4px",
									}}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='32'
										height='32'
										viewBox='0 0 32 32'
										style={{
											height: "14px",
											width: "auto",
										}}>
										<path
											d='M16,32A16,16,0,0,1,4.686,4.686,16,16,0,1,1,27.314,27.314,15.9,15.9,0,0,1,16,32ZM18,8V24h6V8ZM8,8V24h6V8Z'
											fill='white'
										/>
									</svg>
									<p className='l-h-1 color-white f-w-600'>
										{stats.paused || 0}
									</p>
									<p className='l-h-1 color-white f-w-300' style={{
										whiteSpace: "nowrap"
									}}>
										Paused
									</p>
								</li>
								<li
									className='l-s-n flex center-center b-r-20'
									style={{
										background: "#27ae60",
										padding: "10px 16px",
										gap: "4px",
									}}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										style={{
											height: "14px",
											width: "auto",
										}}>
										<path
											d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11,17,6,11.7l1.4-1.43,3.574,3.736L17.545,7,19,8.4Z'
											fill='white'
										/>
									</svg>
									<p className='l-h-1 color-white f-w-600'>
										{stats.completed || 0}
									</p>
									<p className='l-h-1 color-white f-w-300' style={{
										whiteSpace: "nowrap"
									}}>
										Completed
									</p>
								</li>
							</ul>
						</div>
					{/* )} */}
					<div className='col-1-1 flex full-row'>
						<div className='left'>
							{dataLoading && (
								<div
									className='col-1-1 flex center-center'
									style={{ height: "120px" }}>
									<LoaderIcon
										style={{
											width: "20px",
											height: "20px",
										}}
									/>
								</div>
							)}
							<div ref={containerRef}  className='all'>
								{taskList &&
									taskList?.list &&
									taskList?.list?.length > 0 &&
									taskList?.list?.map((o, _i) => ( 
										<SingleTask key={_i} id={id} data={o} /> 
									))}
								{taskList &&
									taskList?.list &&
									taskList?.list?.length === 0 && (
										<div className='one flex center-center'>
											<p>No record found.</p>
										</div>
									)}
								{!taskList && !dataLoading && (
									<div className='one flex center-center'>
										<p>
											Sorry, we couldn't load the records.
										</p>
									</div>
								)}
							</div>
						</div>
						<div className='right flex'>
							{task && id && <TaskDetails />}
							{!task && taskLoading && (
								<div
									className='col-1-1 flex center-center'
									style={{ height: "240px" }}>
									<LoaderIcon
										style={{
											width: "20px",
											height: "20px",
										}}
									/>
								</div>
							)}
							{!task && !taskLoading && id && (
								<div
									className='col-1-1 flex center-center'
									style={{ height: "240px" }}>
									<p>Sorry, we couldn't load the details.</p>
								</div>
							)}
						</div>
					</div>
				</section>
			</section>
		</section>
	);
};

const AutoAssignConfigContent = ({ autoAssign, callback, setShow }) => {
	const [loading, setLoading] = useState(false);
	const { addToastMessage } = useUI();
	const validationSchema = Yup.object().shape({
		autoAssign: Yup.boolean().required("Required"),
	});

	const handleSubmit = async (values) => {
		setLoading(true);
		try {
			const role = getLogInUserDetails("type");
			const resp = await fetch(
				`${API_URL}/api/1.0.0/step-config/update`,
				{
					method: "PUT",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify({
						role,
						autoAssign: values.autoAssign,
					}),
				}
			);
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (resp.status === 403) {
				  addToastMessage({
					status: false,
					message:
					  "Access denied. You do not have permission to perform this action.",
				  });
					setLoading(false);
				}
				return false;
			  }
			if (resp.ok) {
				addToastMessage({
					status: true,
					message: `${values.autoAssign ? "Enabled" : "Disabled"
						} auto assign config.`,
				});
				callback(values.autoAssign);
				setShow(false);
			} else {
				addToastMessage({
					status: false,
					message: `Failed to ${values.autoAssign ? "enable" : "disable"
						} auto assign. Please try again.`,
				});
			}
		} catch (e) {
			addToastMessage({
				status: false,
				message: `Failed to ${values.autoAssign ? "enable" : "disable"
					} auto assign. Please try again.`,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<section id='opl_coremax'>
			<section
				style={{
					position: "fixed",
					inset: 0,
					zIndex: 9999,
					backgroundColor: "rgba(255, 255, 255, 0.86)",
				}}>
				<section
					className='col-1-1 flex center-center'
					style={{
						height: "100vh",
						width: "100vw",
					}}>
					<div
						style={{
							width: "486px",
							padding: "24px",
							height: "auto",
							maxHeight: "80vh",
							border: "solid 1px #f0f0f0",
							background: "white",
							borderRadius: "8px",
							margin: "0 auto",
							boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
						}}>
						<div
							className='col-1-1 flex center-left'
							style={{
								borderBottom: "solid 1px #f0f0f0",
								paddingBottom: "12px",
								marginBottom: "16px",
							}}>
							<h6 className='color-primary f-w-300'>
								Automatic Assignment Settings
							</h6>
							<div
								className='mar-l-a cursor-pointer opl-tooltip'
								data-tooltip='Close to return to the previous section.'
								onClick={() => setShow(false)}>
								<svg
									xmlns='http://www.w3.org/2000/svg'
									width='24'
									height='24'
									viewBox='0 0 24 24'>
									<path
										d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z'
										fill='#283f54'
									/>
								</svg>
							</div>
						</div>
						<Formik
							initialValues={{ autoAssign }}
							validationSchema={validationSchema}
							onSubmit={handleSubmit}
							enableReinitialize>
							{({ values }) => (
								<Form>
									<div
										style={{
											height: "auto",
											// overflowY: "auto",
											background: "white",
										}}>
										<section>
											<div>
												<p
													className='title f-w-700 color-primary'
													style={{
														padding: "10px 0",
													}}>
													Auto-Assign to Employee with
													Lowest Workload
												</p>
												<label
													htmlFor='checkId'
													style={{
														display: "flex",
														alignItems: "center",
														gap: "12px",
														fontSize: "10px",
														cursor: "pointer",
													}}>
													<Field
														type='checkbox'
														className='primary'
														id='checkId'
														name='autoAssign'
													/>
													<p
														className='title f-w-600 color-primary'
														style={{
															paddingTop: "5px",
														}}>
														Enabled
													</p>
												</label>
												<ErrorMessage
													name='autoAssign'
													component='p'
													className='error-messages'
												/>
												<p
													className='l-h-1 color-light'
													style={{
														fontSize: "12px",
														padding: "10px 0",
													}}>
													Configure the settings for
													automatically assigning
													tasks to employees with the
													least workload. This ensures
													a balanced distribution of
													work among the team.
												</p>
											</div>
										</section>
									</div>
									<div
										className='col-1-1 flex center-left'
										style={{
											marginTop: "16px",
											borderTop: "solid 1px #f0f0f0",
											paddingTop: "16px",
										}}>
										<ButtonLightTextIcon
											title='Cancel'
											onClick={() => setShow(false)}
											icon={
												<svg
													clipRule="evenodd"
													fillRule="evenodd"
													strokeLinejoin="round"
													strokeMiterlimit="2"
													viewBox="0 0 24 24"
													xmlns="http://www.w3.org/2000/svg"
													width='19.995'
													height='19.996'
												>
													<path
														d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
														fillRule="nonzero" 
													/>
												</svg>
											}
										/>
										<ButtonTextIcon
											title={
												loading
													? "Saving..."
													: "Save Changes"
											}
											type='submit'
											disabled={loading}
											style={{
												marginLeft: "auto",
											}}
											icon={
												loading ? (
													<LoaderIcon />
												) : (
													<svg
														stroke='currentColor'
														fill='currentColor'
														strokeWidth='0'
														viewBox='0 0 24 24'
														height='1em'
														width='1em'>
														<g id='Circle_Check'>
															<g>
																<path d='M15.81,10.4a.5.5,0,0,0-.71-.71l-3.56,3.56L9.81,11.52a.5.5,0,0,0-.71.71l2.08,2.08a.513.513,0,0,0,.71,0Z'></path>
																<path d='M12,21.934A9.934,9.934,0,1,1,21.933,12,9.945,9.945,0,0,1,12,21.934ZM12,3.067A8.934,8.934,0,1,0,20.933,12,8.944,8.944,0,0,0,12,3.067Z'></path>
															</g>
														</g>
													</svg>
												)
											}
										/>
									</div>
								</Form>
							)}
						</Formik>
					</div>
				</section>
			</section>
		</section>
	);
};

const WrappedScanflowV2 = (props) => (
	<UIProvider>
		<ScanflowProvider>
			<ScanflowV2 {...props} />
		</ScanflowProvider>
	</UIProvider>
);

export default withRouter(WrappedScanflowV2);
