import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
	API_URL,
	BILLING_LISTING_OPTIONS,
	SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import Accordion from "../../components/common/Accordion";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { getLogInUserDetails, handleDatePickerChange } from "../../opl-utilities/helpers";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import { useUI } from "../../context/ui";
import DatePicker from "react-datepicker";
import { formatISO, parseISO } from "date-fns";
import PopUpModal from "../../components/common/PopUpModal";
import PhoneNumberInput from "../../components/common/ui/PhoneNumberInput";
import CustomModal from "../../components/common/CustomModal";
import InsuranceSelect from "./InsuranceSelect";

const LikeInput = ({ title, ...props }) => {
	return (
		<div
			className='ui-like-input col-1-1 cursor-not-allowed'
			{...props}>
			{title ? title : " "}
		</div>
	);
};
const AddForm = ({ history }) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const userId = getLogInUserDetails("id");
	const { addToastMessage } = useUI(); 

	const initialValues = {
		firstName: '',
		lastName: '',
		middleName: '',
		gender: '',
		workPhone: '',
		homePhone: '',
		cellPhone: '',
		fax: '',
		accountNumber: '',
		email: '',
		dob: '',
		serviceDate: '',
		patientChartNo: '',
		ssn: '',
		address1: '',
		address2: '',
		zipCode: '',
		state: '',
		city: '',
		country: '',
		notes: '',
		primaryBillableType: '',
		tertiaryBillableType:"",
		secondaryBillableType:'',
		primaryPayerID: '',
		primaryInsuranceName: '',
		primaryMasterPayerID: '',
		primaryInsuranceTypeCode: '',
		primaryInsurancePhone: '',
		primaryInsuranceFax: '',
		primaryInsuranceAddress: '',
		primarySubscriberFirstName: '',
		primarySubscriberLastName: '',
		primaryMemberNumber: '',
		primaryGroupNumber: '',
		primarySubscriberDOB: '',
		guarantorFirstName: '',
		guarantorLastName: '',
		guarantorAddressLine1: '',
		guarantorAddressLine2: '',
		guarantorZipCode: '',
		guarantorState: '',
		guarantorCity: '',
		guarantorSpouseOrParentsName: '',
		guarantorSpousePartnerPhone: '',
		guarantorRelationshipToPatient: '',
		secondaryInsuranceName: '',
		secondaryPayerID: '',
		secondaryMasterPayerID: '',
		secondaryInsuranceTypeCode: '',
		secondaryInsurancePhone: '',
		secondaryInsuranceFax: '',
		secondaryInsuranceAddress: '',
		secondarySubscriberFirstName: '',
		secondarySubscriberLastName: '',
		secondaryMemberNumber: '',
		secondaryGroupNumber: '',
		secondarySubscriberDOB: '',
		tertiaryInsuranceName: '',
		tertiaryPayerID: '',
		tertiaryMasterPayerID: '',
		tertiaryInsuranceTypeCode: '',
		tertiaryInsurancePhone: '',
		tertiaryInsuranceFax: '',
		tertiaryInsuranceAddress: '',
		tertiarySubscriberFirstName: '',
		tertiarySubscriberLastName: '',
		tertiaryMemberNumber: '',
		tertiaryGroupNumber: '',
		tertiarySubscriberDOB: '',
		patientCondition: '',
		employerName: '',
		illnessIndicator: '',
		dateOfInjury: '',
		nickName: "",
		ethnicity: "",
		primaryPhysicianPhoneName: "",
		mrn: "",
		salutation: "",
		nickName: "",
		signature: "",
		assignedUser: "",
		status: "",
		ethnicity: "",
		race: "",
		specialNeed: "",
		sourceDetail: "",
		diagnosisCode1: "",
		diagnosisCode2: "",
		primaryDiagnosisCode: "",
		additionalDiagnosisCode: "",
		secondaryLastName: "",
		secondaryFirstName: "",
		secondaryPhoneNumber: "",
		secondaryEmail: "",
		primaryPhysicianLastName: "",
		primaryPhysicianFirstName: "",
		primaryPhysicianPhone: "",
		primaryPhysicianFax: "",
		responsibleLastName: "",
		responsibleFirstName: "",
		responsiblePhone: "",
		responsibleEmail: "",
		primaryInsuranceId: null,
		tertiaryInsuranceId: null,
		secondaryInsuranceId: null,
	};

	const validationSchema = Yup.object({
		firstName: Yup.string()
		.min(2, "First Name must be at least 2 characters long")
		.required('First Name is required'),
		lastName: Yup.string().min(2, "Last Name must be at least 2 characters long").required('Last Name is required'),
		gender: Yup.string().required('Gender is required'),
		workPhone: Yup.string()
		.matches(/^\d+$/, "Work Phone Number must contain only numbers")
        .max(15, "Work Phone Number must be at most 15 digits") 
		.required('Work Phone is required'),
		homePhone: Yup.string()
		.matches(/^\d+$/, "Home Phone Number must contain only numbers")
        .max(15, "Home Phone must be at most 15 digits") 
		.notRequired(),
		cellPhone: Yup.string()
		.matches(/^\d+$/, "Cell Phone Number must contain only numbers")
        .max(15, "Cell Phone Number must be at most 15 digits") 
		.required('Cell Phone is required'),
		accountNumber: Yup.string().required('Account # is required'),
		dob: Yup.date().required('DOB is required'),
        email: Yup.string()
            .email("Please enter a valid email address") // Validates only if a value is entered
            .nullable(),
		ssn: Yup.string().required('Social Security number is required'),
		// address1: Yup.string().required('Address Line 1 is required'),
		// zipCode: Yup.string().required('Zip Code is required'),
		// city: Yup.string().required('City is required'),
		// country: Yup.string().required('Country is required'),
    // notes: Yup.string().required('Notes are required'),
    primaryInsuranceId: Yup.object()
        .nullable()
        .test("unique-ids", "Primary Insurance ID must be unique", function (value) {
            const { primaryInsuranceId, secondaryInsuranceId, tertiaryInsuranceId } = this.parent;

            // Extract the current field's value and compare with others
            const primaryId = value?.value; // Current value of primaryInsuranceId
            const secondaryId = secondaryInsuranceId?.value; // value of secondaryInsuranceId
            const tertiaryId = tertiaryInsuranceId?.value; // value of tertiaryInsuranceId

            // Only check if Primary ID has conflicts
            if (primaryId && (primaryId === secondaryId || primaryId === tertiaryId)) {
                return this.createError({ message: "Insurances must be unique" });
            }

            return true;
        }),

    secondaryInsuranceId: Yup.object()
        .nullable()
        .test("unique-ids", "Secondary Insurance ID must be unique", function (value) {
            const { primaryInsuranceId, secondaryInsuranceId, tertiaryInsuranceId } = this.parent;

            // Extract the current field's value and compare with others
            const primaryId = primaryInsuranceId?.value; // value of primaryInsuranceId
            const secondaryId = value?.value; // Current value of secondaryInsuranceId
            const tertiaryId = tertiaryInsuranceId?.value; // value of tertiaryInsuranceId

            // Only check if Secondary ID has conflicts
            if (secondaryId && (secondaryId === primaryId || secondaryId === tertiaryId)) {
                return this.createError({ message: "Insurances must be unique" });
            }

            return true;
        }),

    tertiaryInsuranceId: Yup.object()
        .nullable()
        .test("unique-ids", "Tertiary Insurance ID must be unique", function (value) {
            const { primaryInsuranceId, secondaryInsuranceId, tertiaryInsuranceId } = this.parent;

            // Extract the current field's value and compare with others
            const primaryId = primaryInsuranceId?.value; // value of primaryInsuranceId
            const secondaryId = secondaryInsuranceId?.value; // value of secondaryInsuranceId
            const tertiaryId = value?.value; // Current value of tertiaryInsuranceId

            // Only check if Tertiary ID has conflicts
            if (tertiaryId && (tertiaryId === primaryId || tertiaryId === secondaryId)) {
                return this.createError({ message: "Insurances must be unique" });
            }

            return true;
        }),
	});

	function convertToMMDDYYYY(dateString) {
		const date = new Date(dateString);
		const formattedDate =
			("0" + (date.getMonth() + 1)).slice(-2) +
			"/" +
			("0" + date.getDate()).slice(-2) +
			"/" +
			date.getFullYear();
		return formattedDate;
	}

	function convertTo12HourFormat(timeString) {
		const [hours, minutes] = timeString.split(":");
		const hour = parseInt(hours, 10);
		const period = hour >= 12 ? "PM" : "AM";
		const convertedHour = hour % 12 || 12;
		return `${("0" + convertedHour).slice(-2)}:${minutes} ${period}`;
	}

	const createPatient = async (values) => {
		setLoading(true);
		setStatus("");
		try {
			const payload = JSON.stringify({
				userId: userId,
				accountNumber: values.accountNumber,
				additionalNumber: values.workPhone,
				address1: values.address1,
				address2: values.address2,
				cellNumber: values.cellPhone,
				city: values?.city?.label,
				country: values?.country?.value,
				dateOfBirth: values.dob,
				serviceDate: values.serviceDate,
				patientChartNo: values.patientChartNo,
				primaryBillableType:values?.primaryBillableType,
				tertiaryBillableType:values?.tertiaryBillableType,
				secondaryBillableType:values?.secondaryBillableType,
				email: values.email,
				fax: values.fax,
				firstName: values.firstName,
				gender: values.gender,
				mrn: values.mrn,
				salutation: values.salutation,
				nickName: values.nickName,
				signature: values.signature,
				assignedUser: values.assignedUser,
				status: values.status,
				ethnicity: values.ethnicity,
				race: values.race,
				specialNeed: values.specialNeed,
				sourceDetail: values.sourceDetail,
				diagnosisCode1: values.diagnosisCode1,
				diagnosisCode2: values.diagnosisCode2,
				primaryDiagnosisCode: values.primaryDiagnosisCode,
				additionalDiagnosisCode: values.additionalDiagnosisCode,
				secondaryLastName: values.secondaryLastName,
				secondaryFirstName: values.secondaryFirstName,
				secondaryPhoneNumber: values.secondaryPhoneNumber,
				secondaryEmail: values.secondaryEmail,
				primaryPhysicianLastName: values.primaryPhysicianLastName,
				primaryPhysicianFirstName: values.primaryPhysicianFirstName,
				primaryPhysicianPhoneName: values.primaryPhysicianPhoneName,
				primaryPhysicianFaxName: values.primaryPhysicianFaxName,
				guarantor: {
					guarantorFirstName: values.guarantorFirstName,
					guarantorLastName: values.guarantorLastName,
					guarantorAddress1: values.guarantorAddressLine1,
					guarantorAddress2: values.guarantorAddressLine2,
					guarantorCity: values?.guarantorCity?.label,
					guarantorSpousePhone: values.guarantorSpousePartnerPhone,
					guarantorRelationship: values.guarantorRelationshipToPatient,
					guarantorSpouseName: values.guarantorSpouseOrParentsName,
					guarantorState: values?.guarantorState?.value,
					guarantorZip: values.guarantorZipCode
				},
				lastName: values.lastName,
				middleName: values.middleName,
				notes: values.notes,
				phoneNumber: values.homePhone,
				primary: {
					subscriberFirstName: values.primarySubscriberFirstName,
					subscriberLastName: values.primarySubscriberLastName,
					subscriberDob: values.primarySubscriberDOB,
					subscriberGroupNumber: values.primaryGroupNumber,
					subscriberMemberNumber: values.primaryMemberNumber
				},
				primaryInsuranceId: values?.primaryInsuranceId?.value
          ? values?.primaryInsuranceId?.value
          : "",
				responsibleFirstName: values.responsibleFirstName,
				responsibleLastName: values.responsibleLastName ? values.responsibleLastName : "",
				responsiblePhone: values.responsiblePhone ? values.responsiblePhone : "",
				secondary: {
					subscriberFirstName: values.secondarySubscriberFirstName ? values.secondarySubscriberFirstName : "",
					subscriberLastName: values.secondarySubscriberLastName ? values.secondarySubscriberLastName : "",
					subscriberDob: values.secondarySubscriberDOB ? values.secondarySubscriberDOB : "",
					subscriberGroupNumber: values?.secondaryGroupNumber,
					subscriberMemberNumber: values.secondaryMemberNumber ? values.secondaryMemberNumber : ""
				},
        secondaryInsuranceId: values?.secondaryInsuranceId?.value
        ? values?.secondaryInsuranceId?.value
          : "",
        ssn: values.ssn ? values.ssn : "",
				state: values?.state?.value,
				tertiaryInsuranceId: values?.tertiaryInsuranceId?.value
          ? values?.tertiaryInsuranceId?.value
          : "",
        tertiary: {
					subscriberFirstName: values.tertiarySubscriberFirstName ? values.tertiarySubscriberFirstName : "",
					subscriberLastName: values.tertiarySubscriberLastName ? values.tertiarySubscriberLastName : "",
					subscriberDob: values.tertiarySubscriberDOB ? values.tertiarySubscriberDOB : "",
					subscriberGroupNumber: values.tertiaryGroupNumber ? values.tertiaryGroupNumber : "",
					subscriberMemberNumber: values.tertiaryMemberNumber ? values.tertiaryMemberNumber : ""
				},
				workerComp: {
					patientCondition: values.patientCondition,
					employerName: values.employerName,
					dateOfInjury: values.dateOfInjury,
					illnessIndicator: values.illnessIndicator ? values.illnessIndicator : "",
				},
				zip: values.zipCode ? values.zipCode : ""
			});

			console.log("Payload:::", payload);
			const resp = await fetch(API_URL + `/api/1.0.0/patient`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token")
				},
				body: payload,
      });
      if (resp.status === 401 || resp.status === 403) {
        if (resp.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (resp.status === 403) {
          addToastMessage({
            status: false,
            message:
              "Access denied. You do not have permission to perform this action.",
          });
          setLoading(false);
          setStatus(
            "Access denied. You do not have permission to perform this action.");
        }
        return false;
      }
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					return response.result.responseCode === 200 ? true : false;
				} else {
					const response = await resp.json();
					if (response) {
						setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
						addToastMessage({
							status: false,
							message: `${response.responseDescription || "Something went wrong, please try again later."}`,
						});
						return false;
					}
					setStatus("Something went wrong, please try again later.");
					addToastMessage({
						status: false,
						message: `Something went wrong, please try again later.`,
					});
					return false;
				}
			} else {
				const response = await resp.json();
				if (response) {
					setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
					addToastMessage({
						status: false,
						message: `${response.responseDescription || "Something went wrong, please try again later."}`,
					});
					return false;
				}
				setStatus("Something went wrong, please try again later.");
				addToastMessage({
					status: false,
					message: `Something went wrong, please try again later.`,
				});
				return false;
			}
		} catch (e) {
			setStatus("Something went wrong, please try again later.");
			addToastMessage({
				status: false,
				message: `Something went wrong, please try again later.`,
			});
			console.log("Error:", e);
			return false;
		} finally {
			setLoading(false);
		}
	};

	const Label = ({ title, required , style}) => {
		return (
			<p
				className='color-primary f-w-600'
				style={{ paddingBottom: "5px" , ...style}}>
				{title}
				{required && (
					<span style={{ color: "red", marginLeft: "4px" }}>*</span>
				)}
			</p>
		);
	};

	const mapOptions = (records, valueKey, labelFormatter) =>
		records.map((record) => ({
			value: record[valueKey],
			label: labelFormatter(record),
			obj: record,
		}));
	const labelInsuranceFunc = (o) => {
    return {
      "Master Payer ID": `${o.masterPayerId}`,
      Name: `${o.description}`,
      Address: `${o.addressLine1}`,
    };
  };
	const labelStateFunc = (o) => `${o.name}`;
	const labelCountryFunc = (o) => `${o.name}`;
	const labelCityFunc = (o) => `${o.name}`;


	return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={async (values, { resetForm }) => {
        setLoading(true);
        const api = await createPatient(values);
        if (api) {
          resetForm();
          setStatus("You have successfully created a patient, thank you.");
          addToastMessage({
            status: true,
            message: `You have successfully created a patient, thank you.`,
          });
          history.push("/patients");
        }
      }}
    >
      {({
        values,
        setFieldValue,
        setValues,
        errors,
        touched,
        handleSubmit,
        validateForm,
        setFieldTouched,
      }) => (
        <Form>
          <div className="col-1-1">
            <table>
              <tbody>
                <tr>
                  <td width="50%">
                    <Label title="First Name" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter First Name "
                      name="firstName"
                      className={`col-1-1 primary ${
                        errors.firstName && touched.firstName ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td width="50%">
                    <Label title="Middle Name" />
                    <Field
                      type="text"
                      placeholder="Enter Middle Name "
                      name="middleName"
                      className={`col-1-1 primary ${
                        errors.middleName && touched.middleName ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="Last Name" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter Last Name "
                      name="lastName"
                      className={`col-1-1 primary ${
                        errors.lastName && touched.lastName ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Gender" required={true} />
                    <Field
                      as="select"
                      placeholder="Select Gender"
                      name="gender"
                      className={`col-1-1 primary ${
                        errors.gender && touched.gender ? "error" : ""
                      }`}
                    >
                      <option value="">Select Gender</option>
                      {["Male", "Female", "Other"].map((d, i) => (
                        <option value={d} key={i}>
                          {" "}
                          {d}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage
                      name="gender"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Phone Number (Work)" required={true} />
                    <PhoneNumberInput
                        name="workPhone"
                        placeholder="Eg: 888-888-8888"
                        error={errors.workPhone && touched.workPhone}
                        value={values.workPhone}
                        setTouched={()=> setFieldTouched("workPhone",true)}
                        callback={(v)=>setFieldValue("workPhone", v)}
                    />
                    <ErrorMessage
                      name="workPhone"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Phone Number (Home)" />
                    <PhoneNumberInput
                        name="homePhone"
                        placeholder="Eg: 888-888-8888"
                        error={errors.homePhone && touched.homePhone}
                        value={values.homePhone}
                        setTouched={()=> setFieldTouched("homePhone",true)}
                        callback={(v)=>setFieldValue("homePhone", v)}
                    />
                    <ErrorMessage
                      name="homePhone"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Cell Phone" required={true} />
                    <PhoneNumberInput
                        name="cellPhone"
                        placeholder="Eg: 888-888-8888"
                        error={errors.cellPhone && touched.cellPhone}
                        value={values.cellPhone}
                        setTouched={()=> setFieldTouched("cellPhone",true)}
                        callback={(v)=>setFieldValue("cellPhone", v)}
                    />
                    <ErrorMessage
                      name="cellPhone"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Fax" />
                    <Field
                      type="text"
                      placeholder="Enter Fax"
                      name="fax"
                      className={`col-1-1 primary ${
                        errors.fax && touched.fax ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Account #" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter Account #"
                      name="accountNumber"
                      className={`col-1-1 primary ${
                        errors.accountNumber && touched.accountNumber
                          ? "error"
                          : ""
                      }`}
                    />
                    <ErrorMessage
                      name="accountNumber"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Email Address" />
                    <Field
                      type="text"
                      placeholder="Enter Email Address"
                      name="email"
                      className={`col-1-1 primary ${
                        errors.email && touched.email ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="DOB" required={true} />
                    <DatePicker
                      className={`primary ${
                        errors.dob && touched.dob ? "error" : ""
                      }`}
                      isClearable
                      portalId="root-portal" 
                      selected={values.dob ? parseISO(values.dob) : ""}
                      onChange={(date, e) => handleDatePickerChange(setFieldValue, "dob", date, e)} 
                      showYearDropdown
                      placeholderText="MM/DD/YYYY"
                      dateFormat="MM/dd/yyyy"
                    />
                    <ErrorMessage
                      name="dob"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                  <td>
                    <Label title="Social Security number" required={true} />
                    <Field
                      type="text"
                      placeholder="Enter Social Security number"
                      name="ssn"
                      className={`col-1-1 primary ${
                        errors.ssn && touched.ssn ? "error" : ""
                      }`}
                    />
                    <ErrorMessage
                      name="ssn"
                      component="p"
                      className="error-messages"
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Date of Service" />
                    <DatePicker
                      className={`primary ${
                        errors.serviceDate && touched.serviceDate ? "error" : ""
                      }`}
                      isClearable
                      portalId="root-portal"
                      selected={
                        values.serviceDate ? parseISO(values.serviceDate) : ""
                      }
                      onChange={(date, e) => handleDatePickerChange(setFieldValue, "serviceDate", date, e)}  
                      showYearDropdown
                      placeholderText="MM/DD/YYYY"
                      dateFormat="MM/dd/yyyy"
                    />
                  </td>
                  <td>
                    <Label title="Patient Chart No." />
                    <Field
                      type="text"
                      placeholder="Eg: RTY907"
                      name="patientChartNo"
                      className={`col-1-1 primary ${
                        errors.patientChartNo && touched.patientChartNo
                          ? "error"
                          : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Address Line 1" />
                    <Field
                      as="textarea"
                      placeholder="Enter Address Line 1"
                      name="address1"
                      className={`col-1-1 primary ${
                        errors.address1 && touched.address1 ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Address Line 2" />
                    <Field
                      as="textarea"
                      placeholder="Enter Address Line 2"
                      name="address2"
                      className={`col-1-1 primary ${
                        errors.address2 && touched.address2 ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td width="50%">
                    <Label title="Zip Code" />
                    <Field
                      type="text"
                      placeholder="Enter Zip Code"
                      name="zipCode"
                      className={`col-1-1 primary ${
                        errors.zipCode && touched.zipCode ? "error" : ""
                      }`}
                    />
                  </td>
                  <td>
                    <Label title="Country" />
                    <PaginatedDropdown
                      datakey="records"
                      url={`${API_URL}/api/1.0.0/reference/countries?`}
                      mapOptions={(records) =>
                        mapOptions(records, "code", labelCountryFunc)
                      }
                      selectedValue={values.country}
                      placeHolder="Search"
                      onChange={(v) => {
                        setFieldValue("country", v);
                        setFieldValue("state", null);
                        setFieldValue("city", null);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <Label title="State" />
                    <PaginatedDropdown
                      datakey="records"
                      url={`${API_URL}/api/1.0.0/reference/states/${values.country?.value}?`}
                      mapOptions={(records) =>
                        mapOptions(records, "code", labelStateFunc)
                      }
                      selectedValue={values.state}
                      placeHolder="Search"
                      onChange={(v) => {
                        setFieldValue("state", v);
                        setFieldValue("city", null);
                      }}
                    />
                  </td>
                  <td width="50%">
                    <Label title="City" />
                    <PaginatedDropdown
                      datakey="records"
                      url={`${API_URL}/api/1.0.0/reference/cities/${values.country?.value}/${values.state?.value}?`}
                      mapOptions={(records) =>
                        mapOptions(records, "name", labelCityFunc)
                      }
                      selectedValue={values.city}
                      placeHolder="Search"
                      onChange={(v) => {
                        setFieldValue("city", v);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <Label title="Notes" />
                    <Field
                      as="textarea"
                      placeholder="Enter Notes"
                      name="notes"
                      className={`col-1-1 primary ${
                        errors.notes && touched.notes ? "error" : ""
                      }`}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="4">
                    <Accordion
                      items={[
                        {
                          title: (
                            <span>
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-shield w-4 h-4 mr-2"
                                data-id="55"
                              >
                                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
                              </svg>
                              <span>Primary Insurance</span>
                            </span>
                          ),
                          content: (
                            <div className="pad-20">
                              <table>
                                <tbody>
                                  <tr>
                                    <td colSpan="2">
                                      <ButtonLightTextIcon
                                        title="Remove Payer"
                                        type="button"
                                        onClick={() => {
                                          setValues((prevValues) => ({
                                            ...prevValues,
                                            primarySubscriberFirstName: "",
                                            primarySubscriberLastName: "",
                                            primaryMemberNumber: "",
                                            primaryInsuranceFax: "",
                                            primaryGroupNumber: "",
                                            primarySubscriberDOB: "",
                                            primaryInsuranceId: null,
                                            primaryInsuranceName: "",
                                            primaryPayerID: "",
                                            primaryMasterPayerID: "",
                                            primaryInsuranceTypeCode: "",
                                            primaryInsuranceAddress: "",
                                            primaryInsurancePhone: "",
                                            primaryInsuranceEmail: "",
                                            primaryInsuranceCity: "",
                                            primaryInsuranceState: "",
                                            primaryInsuranceZip: "",
                                            primaryInsuranceCountry: "",
                                            primaryBillableType: "",
                                          }));
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Billable / Non-Billable / Non-Billable Commercial" />
                                      <Field
                                        as="select"
                                        name="primaryBillableType"
                                        className={`col-1-1 primary ${
                                          errors.primaryBillableType &&
                                          touched.primaryBillableType
                                            ? "error"
                                            : ""
                                        }`}
                                      >
                                        <option value="">Select</option>
                                        {BILLING_LISTING_OPTIONS.map((d, i) => (
                                          <option value={d.value} key={i}>
                                            {d.label}
                                          </option>
                                        ))}
                                      </Field>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Primary Insurance Name" />
                                      <CustomModal
                                        selector={
                                          <div>
                                          <Field
                                            type="text"
                                            placeholder="Select Insurance"
                                            name="primaryInsuranceName"
                                            value={values.primaryInsuranceName}
                                            className={`col-1-1 primary cursor-pointer ${
                                              errors.primaryInsuranceId &&
                                              touched.primaryInsuranceId
                                                ? "error"
                                                : ""
                                                }`}
                                                
                                          />
                                          <ErrorMessage
                                          name="primaryInsuranceId"
                                          component="p"
                                          className="error-messages"
                                        />
                                          </div>
                                        }
                                        content={
                                          <InsuranceSelect
                                            setTouched={async () => {
                                              await setFieldTouched("primaryInsuranceName", true); 
                                            }}
                                            callback={async (v) => {
                                            
                                              console.log("Insurance Selected:::", v);
                                              if (v) {
                                                await setFieldValue(
                                                  "primaryInsuranceId",
                                                  v
                                                );
                                                await setFieldValue(
                                                  "primaryInsuranceName",
                                                  v.label
                                                );
                                                await setFieldValue(
                                                  "primaryPayerID",
                                                  v.obj.electronicPayerId
                                                );
                                                await setFieldValue(
                                                  "primaryMasterPayerID",
                                                  v.obj.masterPayerId
                                                );
                                                await setFieldValue(
                                                  "primaryInsuranceTypeCode",
                                                  v.obj.insuranceType
                                                );
                                                await setFieldValue(
                                                  "primaryInsuranceAddress",
                                                  v.obj.addressLine1
                                                );
                                                const phoneContact =
                                                  v.obj.insuranceContactDetails.find(
                                                    (contact) =>
                                                      contact.type === "Phone"
                                                  );
                                                  await setFieldValue(
                                                  "primaryInsurancePhone",
                                                  phoneContact
                                                    ? phoneContact.information
                                                    : ""
                                                ); // Default to empty if no phone contact found
    
                                                // Handle insuranceContactDetails for "Fax"
                                                const faxContact =
                                                  v.obj.insuranceContactDetails.find(
                                                    (contact) =>
                                                      contact.type === "Fax"
                                                  );
                                                  await setFieldValue(
                                                  "primaryInsuranceFax",
                                                  faxContact
                                                    ? faxContact.information
                                                    : ""
                                                );
                                              }else {
                                                // If no value is selected (deselected), clear the values
                                                await setFieldValue("primaryInsuranceId", "");
                                                await setFieldValue("primaryInsuranceName", "");
                                                await setFieldValue("primaryPayerID", "");
                                                await setFieldValue("primaryMasterPayerID", "");
                                                await setFieldValue("primaryInsuranceTypeCode", "");
                                                await setFieldValue("primaryInsuranceAddress", "");
                                                await setFieldValue("primaryInsurancePhone", "");
                                                await setFieldValue("primaryInsuranceFax", "");
                                              }
                                            }}
                                          />
                                        }
                                      />
                                    </td>
                                    <td width="50%">
                                      <Label title="Primary Electronic Payer ID" />
                                      <LikeInput
                                        title={values.primaryPayerID || "-"}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Master Payer ID" />
                                      {/* <Field
																		type='text'
																		placeholder='Enter Master Payer ID'
																		name='primaryMasterPayerID'
																		className={`col-1-1 primary ${errors.primaryMasterPayerID &&
																			touched.primaryMasterPayerID
																			? "error"
																			: ""
																			}`}
																	/> */}
                                      <LikeInput
                                        title={
                                          values.primaryMasterPayerID || "-"
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Label title="Insurance Type Code" />

                                      <LikeInput
                                        title={
                                          values.primaryInsuranceTypeCode || "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Primary Insurance Phone" />
                                      <LikeInput
                                        title={
                                          values.primaryInsurancePhone || "-"
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Label title="Primary Insurance Fax" />
                                      <LikeInput
                                        title={
                                          values.primaryInsuranceFax || "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <Label title="Primary Insurance Address" />
                                      <LikeInput
                                        title={
                                          values.primaryInsuranceAddress || "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <ButtonTextIcon
                                        title="Fill Form With Patient Info"
                                        type="button"
                                        onClick={() => {
                                          setValues((prevValues) => ({
                                            ...prevValues, // Spread the existing form values
                                            primarySubscriberFirstName:
                                              values.firstName,
                                            primarySubscriberLastName:
                                              values.lastName,
                                            primaryMemberNumber:
                                              values.homePhone,
                                            primaryGroupNumber:
                                              values.workPhone,
                                            primarySubscriberDOB: values.dob,
                                          }));
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Subscriber First Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Subscriber First Name"
                                        name="primarySubscriberFirstName"
                                        className={`col-1-1 primary ${
                                          errors.primarySubscriberFirstName &&
                                          touched.primarySubscriberFirstName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td>
                                      <Label title="Subscriber Last Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Subscriber Last Name"
                                        name="primarySubscriberLastName"
                                        className={`col-1-1 primary ${
                                          errors.primarySubscriberLastName &&
                                          touched.primarySubscriberLastName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Member Number" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Member Number"
                                        name="primaryMemberNumber"
                                        className={`col-1-1 primary ${
                                          errors.primaryMemberNumber &&
                                          touched.primaryMemberNumber
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td>
                                      <Label title="Group Number" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Group Number"
                                        name="primaryGroupNumber"
                                        className={`col-1-1 primary ${
                                          errors.primaryGroupNumber &&
                                          touched.primaryGroupNumber
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Subscriber DOB" />
                                      {/* <div className="pos-rel"> */}
                                      {/* <PopUpModal> */}
                                      <DatePicker
                                        className={`primary ${
                                          errors.primarySubscriberDOB &&
                                          touched.primarySubscriberDOB
                                            ? "error"
                                            : ""
                                        }`}
                                        isClearable
                                        portalId="root-portal" 
                                        selected={
                                          values.primarySubscriberDOB
                                            ? parseISO(
                                                values.primarySubscriberDOB
                                              )
                                            : ""
                                        }
                                        onChange={(date, e) => handleDatePickerChange(setFieldValue, "primarySubscriberDOB", date, e)} 
                                        showYearDropdown
                                        placeholderText="MM/DD/YYYY"
                                        dateFormat="MM/dd/yyyy"
                                      />
                                      {/* </PopUpModal></div> */}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ),
                        },
                        {
                          title: (
                            <span>
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-shield w-4 h-4 mr-2"
                                dataId="55"
                              >
                                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
                              </svg>
                              <span>Guarantor</span>
                            </span>
                          ),
                          content: (
                            <div className="pad-20">
                              <table>
                                <tbody>
                                  <tr>
                                    <td colSpan="2">
                                      <div className="flex" style={{
                                        gap:"10px"
                                      }}>
                                        <ButtonLightTextIcon
                                          title="Remove Payer"
                                          type="button"
                                          onClick={() => {
                                            setValues((prevValues) => ({
                                              ...prevValues,
                                              guarantorFirstName: "",
                                              guarantorLastName: "",
                                              guarantorAddressLine1: "",
                                              guarantorAddressLine2: "",
                                              guarantorZipCode: "",
                                              guarantorState: "",
                                              guarantorCity: "",
                                              guarantorSpouseOrParentsName: "",
                                              guarantorSpousePartnerPhone: "",
                                              guarantorRelationshipToPatient: "",
                                            }));
                                          }}
                                        />
                                        
                                        <ButtonTextIcon
                                            type="button"
                                            title="Fill Form With Patient Info"
                                            onClick={() => { 
                                              setValues((prevValues) => ({
                                                ...prevValues, 
                                                ...(values?.country?.value === "US" && {
                                                  guarantorFirstName: values.firstName,
                                                  guarantorLastName: values.lastName,
                                                  guarantorAddressLine1: values.address1,
                                                  guarantorAddressLine2: values.address2,
                                                  guarantorState: values.state,
                                                  guarantorCity: values.city,
                                                  guarantorZipCode: values.zipCode,
                                                }),
                                              }));
                                            }}
                                          />
                                        </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Guarantor First Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Guarantor First Name"
                                        name="guarantorFirstName"
                                        className={`col-1-1 primary ${
                                          errors.guarantorFirstName &&
                                          touched.guarantorFirstName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td width="50%">
                                      <Label title="Guarantor Last Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Guarantor Last Name"
                                        name="guarantorLastName"
                                        className={`col-1-1 primary ${
                                          errors.guarantorLastName &&
                                          touched.guarantorLastName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Address Line 1" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Address Line 1"
                                        name="guarantorAddressLine1"
                                        className={`col-1-1 primary ${
                                          errors.guarantorAddressLine1 &&
                                          touched.guarantorAddressLine1
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td width="50%">
                                      <Label title="Address Line 2" />
                                      <Field
                                        type="text"
                                        placeholder="Address Line 2"
                                        name="guarantorAddressLine2"
                                        className={`col-1-1 primary ${
                                          errors.guarantorAddressLine2 &&
                                          touched.guarantorAddressLine2
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Zip Code" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Zip Code"
                                        name="guarantorZipCode"
                                        className={`col-1-1 primary ${
                                          errors.guarantorZipCode &&
                                          touched.guarantorZipCode
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td width="50%">
                                      <Label title="State" />
                                      <PaginatedDropdown
                                        datakey="records"
                                        url={`${API_URL}/api/1.0.0/reference/states/US?`}
                                        mapOptions={(records) =>
                                          mapOptions(
                                            records,
                                            "code",
                                            labelStateFunc
                                          )
                                        }
                                        selectedValue={values.guarantorState}
                                        placeHolder="Search"
                                        onChange={(v) => {
                                          setFieldValue("guarantorState", v);
                                          setFieldValue("guarantorCity", null);
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="City" />
                                      <PaginatedDropdown
                                        datakey="records"
                                        selectedValue={values.guarantorCity}
                                        url={`${API_URL}/api/1.0.0/reference/cities/US/${values.guarantorState?.value}?`}
                                        mapOptions={(records) =>
                                          mapOptions(
                                            records,
                                            "name",
                                            labelCityFunc
                                          )
                                        }
                                        placeHolder="Search"
                                        onChange={(v) => {
                                          setFieldValue("guarantorCity", v);
                                        }}
                                      />
                                    </td>
                                    <td width="50%">
                                      <Label
                                        style={{ fontSize: "11px" }}
                                        title="Spouse or Partner's/Parents Name(if patient minor)"
                                      />
                                      <Field
                                        type="text"
                                        placeholder="Enter Spouse or Parents name"
                                        name="guarantorSpouseOrParentsName"
                                        className={`col-1-1 primary ${
                                          errors.guarantorSpouseOrParentsName &&
                                          touched.guarantorSpouseOrParentsName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Spouse, Partner or Partners Phone" />
                                      <PhoneNumberInput
                                        name="guarantorSpousePartnerPhone"
                                        placeholder="Eg: 888-888-8888"
                                        error={errors.guarantorSpousePartnerPhone && touched.guarantorSpousePartnerPhone}
                                        value={values.guarantorSpousePartnerPhone}
                                        setTouched={()=> setFieldTouched("guarantorSpousePartnerPhone",true)}
                                        callback={(v)=>setFieldValue("guarantorSpousePartnerPhone", v)}
                                      />
                                      <ErrorMessage
                                        name="guarantorSpousePartnerPhone"
                                        component="p"
                                        className="error-messages"
                                      />
                                    </td>
                                    <td width="50%">
                                      <Label title="Relationship To Patient" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Relationship To Patient"
                                        name="guarantorRelationshipToPatient"
                                        className={`col-1-1 primary ${
                                          errors.guarantorRelationshipToPatient &&
                                          touched.guarantorRelationshipToPatient
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ),
                        },
                        {
                          title: (
                            <span>
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-shield w-4 h-4 mr-2"
                                data-id="55"
                              >
                                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
                              </svg>
                              <span>Secondary Insurance</span>
                            </span>
                          ),
                          content: (
                            <div className="pad-20">
                              <table>
                                <tbody>
                                  <tr>
                                    <td colSpan="2">
                                      <ButtonLightTextIcon
                                        title="Remove Payer"
                                        type="button"
                                        onClick={() => {
                                          setValues((prevValues) => ({
                                            ...prevValues,
                                            secondarySubscriberFirstName: "",
                                            secondarySubscriberLastName: "",
                                            secondaryMemberNumber: "",
                                            secondaryGroupNumber: "",
                                            secondarySubscriberDOB: "",
                                            secondaryInsuranceId: null,
                                            secondaryInsuranceName: "",
                                            secondaryInsuranceAddress: "",
                                            secondaryInsuranceCity: "",
                                            secondaryInsuranceState: "",
                                            secondaryInsuranceZip: "",
                                            secondaryInsurancePhone: "",
                                            secondaryInsuranceFax: "",
                                            secondaryMasterPayerID: "",
                                            secondaryPayerID: "",
                                            secondaryInsuranceTypeCode: "",
                                            secondaryBillableType: "",
                                          }));
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Billable / Non-Billable / Non-Billable Commercial" />
                                      <Field
                                        as="select"
                                        name="secondaryBillableType"
                                        className={`col-1-1 primary ${
                                          errors.secondaryBillableType &&
                                          touched.secondaryBillableType
                                            ? "error"
                                            : ""
                                        }`}
                                      >
                                        <option value="">Select</option>
                                        {BILLING_LISTING_OPTIONS.map((d, i) => (
                                          <option value={d.value} key={i}>
                                            {d.label}
                                          </option>
                                        ))}
                                      </Field>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <Label title="Secondary Insurance Name" />
                                       <CustomModal
                                        selector={
                                          <div>
                                          <Field
                                            type="text"
                                            placeholder="Select Insurance"
                                            name="secondaryInsuranceName"
                                            value={values.secondaryInsuranceName}
                                            className={`col-1-1 primary ${
                                              errors.secondaryInsuranceId &&
                                              touched.secondaryInsuranceId
                                                ? "error"
                                                : ""
                                                }`}
                                            />
                                            <ErrorMessage
                                              name="secondaryInsuranceId"
                                              component="p"
                                              className="error-messages" 
                                            />
                                            </div>
                                        }
                                        content={
                                          <InsuranceSelect
                                            setTouched={()=> setFieldTouched("secondaryInsuranceId",true)}
                                          callback={async (v) => {
                                            if (v) {
                                              await setFieldValue(
                                                "secondaryInsuranceId",
                                                v
                                              );
                                              await setFieldValue(
                                                "secondaryInsuranceName",
                                                v.label
                                              );
                                              await setFieldValue(
                                                "secondaryPayerID",
                                                v.obj.electronicPayerId
                                              );
                                              await setFieldValue(
                                                "secondaryMasterPayerID",
                                                v.obj.masterPayerId
                                              );
                                              await setFieldValue(
                                                "secondaryInsuranceTypeCode",
                                                v.obj.insuranceType
                                              );
                                              await setFieldValue(
                                                "secondaryInsuranceAddress",
                                                v.obj.addressLine1
                                              );
                                              const phoneContact =
                                                v.obj.insuranceContactDetails.find(
                                                  (contact) =>
                                                    contact.type === "Phone"
                                                );
                                                await setFieldValue(
                                                "secondaryInsurancePhone",
                                                phoneContact
                                                  ? phoneContact.information
                                                  : ""
                                              ); // Default to empty if no phone contact found
  
                                              // Handle insuranceContactDetails for "Fax"
                                              const faxContact =
                                                v.obj.insuranceContactDetails.find(
                                                  (contact) =>
                                                    contact.type === "Fax"
                                                );
                                                await setFieldValue(
                                                "secondaryInsuranceFax",
                                                faxContact
                                                  ? faxContact.information
                                                  : ""
                                              );
                                            }else {
                                              await setFieldValue(
                                                "secondaryInsuranceId",
                                                ""
                                              );
                                              await setFieldValue(
                                                "secondaryInsuranceName",
                                                ""
                                              );
                                              await setFieldValue(
                                                "secondaryPayerID",
                                                ""
                                              );
                                              await setFieldValue(
                                                "secondaryMasterPayerID",
                                                ""
                                              );
                                              await setFieldValue(
                                                "secondaryInsuranceTypeCode",
                                                ""
                                              );
                                              await setFieldValue(
                                                "secondaryInsuranceAddress",
                                                ""
                                              );
                                              await setFieldValue(
                                                "secondaryInsurancePhone",
                                                ""
                                              );
                                              await setFieldValue(
                                                "secondaryInsuranceFax",
                                                ""
                                              );
                                            }
                                          }}
                                          />
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Label title="Secondary Payer ID" />
                                      <LikeInput
                                        title={values.secondaryPayerID || "-"}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Master Payer ID" />
                                      <LikeInput
                                        title={
                                          values.secondaryMasterPayerID || "-"
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Label title="Insurance Type Code" />
                                      <LikeInput
                                        title={
                                          values.secondaryInsuranceTypeCode ||
                                          "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Secondary Insurance Phone" />
                                      <LikeInput
                                        title={
                                          values.secondaryInsurancePhone || "-"
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Label title="Secondary Insurance Fax" />
                                      <LikeInput
                                        title={
                                          values.secondaryInsuranceFax || "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <Label title="Secondary Insurance Address" />
                                      <LikeInput
                                        title={
                                          values.secondaryInsuranceAddress ||
                                          "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <ButtonTextIcon
                                        title="Fill Form With Patient Info"
                                        type="button"
                                        onClick={() => {
                                          setValues((prevValues) => ({
                                            ...prevValues, // Spread the existing form values
                                            secondarySubscriberFirstName:
                                              values.firstName,
                                            secondarySubscriberLastName:
                                              values.lastName,
                                            secondaryMemberNumber:
                                              values.homePhone,
                                            secondaryGroupNumber:
                                              values.workPhone,
                                            secondarySubscriberDOB: values.dob,
                                          }));
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Subscriber First Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Subscriber First Name"
                                        name="secondarySubscriberFirstName"
                                        className={`col-1-1 primary ${
                                          errors.secondarySubscriberFirstName &&
                                          touched.secondarySubscriberFirstName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td>
                                      <Label title="Subscriber Last Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Subscriber Last Name"
                                        name="secondarySubscriberLastName"
                                        className={`col-1-1 primary ${
                                          errors.secondarySubscriberLastName &&
                                          touched.secondarySubscriberLastName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Member Number" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Member Number"
                                        name="secondaryMemberNumber"
                                        className={`col-1-1 primary ${
                                          errors.secondaryMemberNumber &&
                                          touched.secondaryMemberNumber
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td>
                                      <Label title="Group Number" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Group Number"
                                        name="secondaryGroupNumber"
                                        className={`col-1-1 primary ${
                                          errors.secondaryGroupNumber &&
                                          touched.secondaryGroupNumber
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Subscriber DOB" />
                                      {/* <Field
                                        type="date"
                                        placeholder="Enter Subscriber DOB"
                                        name="secondarySubscriberDOB"
                                        className={`col-1-1 primary ${
                                          errors.secondarySubscriberDOB &&
                                          touched.secondarySubscriberDOB
                                            ? "error"
                                            : ""
                                        }`}
                                      /> */}
                                      
                                      <DatePicker
                                        className={`primary ${
                                          errors.secondarySubscriberDOB &&
                                          touched.secondarySubscriberDOB
                                            ? "error"
                                            : ""
                                        }`}
                                        isClearable
                                        portalId="root-portal" 
                                        selected={
                                          values.secondarySubscriberDOB
                                            ? parseISO(
                                                values.secondarySubscriberDOB
                                              )
                                            : ""
                                        }
                                        onChange={(date, e) => handleDatePickerChange(setFieldValue, "secondarySubscriberDOB", date, e)} 
                                        showYearDropdown
                                        placeholderText="MM/DD/YYYY"
                                        dateFormat="MM/dd/yyyy"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ),
                        },
                        {
                          title: (
                            <span>
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-shield w-4 h-4 mr-2"
                                data-id="55"
                              >
                                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
                              </svg>
                              <span>Tertiary Insurance</span>
                            </span>
                          ),
                          content: (
                            <div className="pad-20">
                              <table>
                                <tbody>
                                  <tr>
                                    <td colSpan="2">
                                      <ButtonLightTextIcon
                                        title="Remove Payer"
                                        type="button"
                                        onClick={() => {
                                          setValues((prevValues) => ({
                                            ...prevValues,
                                            tertiarySubscriberFirstName: "",
                                            tertiarySubscriberLastName: "",
                                            tertiaryMemberNumber: "",
                                            tertiaryGroupNumber: "",
                                            tertiarySubscriberDOB: "",
                                            tertiaryInsuranceId: null,
                                            tertiaryInsuranceName: "",
                                            tertiaryPayerID: "",
                                            tertiaryMasterPayerID: "",
                                            tertiaryInsuranceTypeCode: "",
                                            tertiaryInsurancePhone: "",
                                            tertiaryInsuranceFax: "",
                                            tertiaryInsuranceAddress: "",
                                            tertiaryBillableType: "",
                                          }));
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Billable / Non-Billable / Non-Billable Commercial" />
                                      <Field
                                        as="select"
                                        name="tertiaryBillableType"
                                        className={`col-1-1 primary ${
                                          errors.tertiaryBillableType &&
                                          touched.tertiaryBillableType
                                            ? "error"
                                            : ""
                                        }`}
                                      >
                                        <option value="">Select</option>
                                        {BILLING_LISTING_OPTIONS.map((d, i) => (
                                          <option value={d.value} key={i}>
                                            {d.label}
                                          </option>
                                        ))}
                                      </Field>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <Label title="Tertiary Insurance Name" />
                                       <CustomModal
                                        selector={
                                          <div>
                                          <Field
                                            type="text"
                                            placeholder="Select Insurance"
                                            name="tertiaryInsuranceName"
                                            className={`col-1-1 primary ${
                                              errors.tertiaryInsuranceId &&
                                              touched.tertiaryInsuranceId
                                                ? "error"
                                                : ""
                                                }`}
                                          />
                                          <ErrorMessage
                                          name="tertiaryInsuranceId"
                                          component="p"
                                          className="error-messages"
                                        />
                                    </div>
                                        }
                                        content={
                                          <InsuranceSelect
                                            setTouched={() => setFieldTouched("tertiaryInsuranceId", true)}
                                          callback={async (v) => {
                                            if (v) {
                                              await setFieldValue(
                                                "tertiaryInsuranceId",
                                                v
                                              );
                                              await setFieldValue(
                                                "tertiaryInsuranceName",
                                                v.label
                                              );
                                              await setFieldValue(
                                                "tertiaryPayerID",
                                                v.obj.electronicPayerId
                                              );
                                              await setFieldValue(
                                                "tertiaryMasterPayerID",
                                                v.obj.masterPayerId
                                              );
                                              await setFieldValue(
                                                "tertiaryInsuranceTypeCode",
                                                v.obj.insuranceType
                                              );
                                              await setFieldValue(
                                                "tertiaryInsuranceAddress",
                                                v.obj.addressLine1
                                              );
                                              await setFieldValue(
                                                "secondaryInsuranceAddress",
                                                v.obj.addressLine1
                                              );
                                              const phoneContact =
                                                v.obj.insuranceContactDetails.find(
                                                  (contact) =>
                                                    contact.type === "Phone"
                                                );
                                                await setFieldValue(
                                                "tertiaryInsurancePhone",
                                                phoneContact
                                                  ? phoneContact.information
                                                  : ""
                                              ); // Default to empty if no phone contact found
  
                                              // Handle insuranceContactDetails for "Fax"
                                              const faxContact =
                                                v.obj.insuranceContactDetails.find(
                                                  (contact) =>
                                                    contact.type === "Fax"
                                                );
                                                await setFieldValue(
                                                "tertiaryInsuranceFax",
                                                faxContact
                                                  ? faxContact.information
                                                  : ""
                                              );
                                            }else {
                                              await setFieldValue(
                                                "tertiaryInsuranceName",
                                                ""
                                              );
                                              await setFieldValue(
                                                "tertiaryPayerID",
                                                ""
                                              );
                                              await setFieldValue(
                                                "tertiaryMasterPayerID",
                                                ""
                                              );
                                              await setFieldValue(
                                                "tertiaryInsuranceTypeCode",
                                                ""
                                              );
                                              await setFieldValue(
                                                "tertiaryInsuranceAddress",
                                                ""
                                              );
                                              await setFieldValue(
                                                "tertiaryInsurancePhone",
                                                ""
                                              );
                                              await setFieldValue(
                                                "tertiaryInsuranceFax",
                                                ""
                                              );
                                            }
                                          }}
                                          />
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Label title="Tertiary Payer ID" />
                                      {/* <Field
																		type='text'
																		placeholder='Enter Tertiary Payer ID'
																		name='tertiaryPayerID'
																		className={`col-1-1 primary ${errors.tertiaryPayerID &&
																			touched.tertiaryPayerID
																			? "error"
																			: ""
																			}`}
																	/> */}
                                      <LikeInput
                                        title={values.tertiaryPayerID || "-"}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Master Payer ID" />
                                      <LikeInput
                                        title={
                                          values.tertiaryMasterPayerID || "-"
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Label title="Insurance Type Code" />
                                      <LikeInput
                                        title={
                                          values.tertiaryInsuranceTypeCode ||
                                          "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Tertiary Insurance Phone" />
                                      <LikeInput
                                        title={
                                          values.tertiaryInsurancePhone || "-"
                                        }
                                      />
                                    </td>
                                    <td>
                                      <Label title="Tertiary Insurance Fax" />
                                      <LikeInput
                                        title={
                                          values.tertiaryInsuranceFax || "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td colSpan="2">
                                      <Label title="Tertiary Insurance Address" />
                                      <LikeInput
                                        title={
                                          values.tertiaryInsuranceAddress || "-"
                                        }
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <ButtonTextIcon
                                        title="Fill Form With Patient Info"
                                        type="button"
                                        onClick={() => {
                                          setValues((prevValues) => ({
                                            ...prevValues, // Spread the existing form values
                                            tertiarySubscriberFirstName:
                                              values.firstName,
                                            tertiarySubscriberLastName:
                                              values.lastName,
                                            tertiaryMemberNumber:
                                              values.homePhone,
                                            tertiaryGroupNumber:
                                              values.workPhone,
                                            tertiarySubscriberDOB: values.dob,
                                          }));
                                        }}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Subscriber First Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Subscriber First Name"
                                        name="tertiarySubscriberFirstName"
                                        className={`col-1-1 primary ${
                                          errors.tertiarySubscriberFirstName &&
                                          touched.tertiarySubscriberFirstName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td>
                                      <Label title="Subscriber Last Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Subscriber Last Name"
                                        name="tertiarySubscriberLastName"
                                        className={`col-1-1 primary ${
                                          errors.tertiarySubscriberLastName &&
                                          touched.tertiarySubscriberLastName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Member Number" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Member Number"
                                        name="tertiaryMemberNumber"
                                        className={`col-1-1 primary ${
                                          errors.tertiaryMemberNumber &&
                                          touched.tertiaryMemberNumber
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td>
                                      <Label title="Group Number" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Group Number"
                                        name="tertiaryGroupNumber"
                                        className={`col-1-1 primary ${
                                          errors.tertiaryGroupNumber &&
                                          touched.tertiaryGroupNumber
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Subscriber DOB" />
                                      {/* <Field
                                        type="date"
                                        placeholder="Enter Subscriber DOB"
                                        name="tertiarySubscriberDOB"
                                        className={`col-1-1 primary ${
                                          errors.tertiarySubscriberDOB &&
                                          touched.tertiarySubscriberDOB
                                            ? "error"
                                            : ""
                                        }`}
                                      /> */}
                                      <DatePicker
                                        className={`primary ${
                                          errors.tertiarySubscriberDOB &&
                                          touched.tertiarySubscriberDOB
                                            ? "error"
                                            : ""
                                        }`}
                                        isClearable
                                        portalId="root-portal" 
                                        selected={
                                          values.tertiarySubscriberDOB
                                            ? parseISO(
                                                values.tertiarySubscriberDOB
                                              )
                                            : ""
                                        }
                                        onChange={(date, e) => handleDatePickerChange(setFieldValue, "tertiarySubscriberDOB", date, e)} 
                                        showYearDropdown
                                        placeholderText="MM/DD/YYYY"
                                        dateFormat="MM/dd/yyyy"
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ),
                        },
                        {
                          title: (
                            <span>
                              <svg
                                width="15"
                                height="15"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-shield w-4 h-4 mr-2"
                                data-id="55"
                              >
                                <path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z"></path>
                              </svg>
                              <span>
                                Worker's Comp or Other Property & Casualty Claim
                                Details (For Event ID)
                              </span>
                            </span>
                          ),
                          content: (
                            <div className="pad-20">
                              <table>
                                <tbody>
                                  <tr>
                                    <td>
                                      <Label title="Patient Condition Related To" />
                                      <Field
                                        type="text"
                                        placeholder="Select Patient Condition Related To"
                                        name="patientCondition"
                                        className={`col-1-1 primary ${
                                          errors.patientCondition &&
                                          touched.patientCondition
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                    <td>
                                      <Label title="Employer Name" />
                                      <Field
                                        type="text"
                                        placeholder="Enter Employer Name"
                                        name="employerName"
                                        className={`col-1-1 primary ${
                                          errors.employerName &&
                                          touched.employerName
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td width="50%">
                                      <Label title="Date of Injury" />
                                      <DatePicker
                                        className={`primary ${
                                          errors.dateOfInjury &&
                                          touched.dateOfInjury
                                            ? "error"
                                            : ""
                                        }`}
                                        isClearable
                                        portalId="root-portal" 
                                        selected={
                                          values.dateOfInjury
                                            ? parseISO(values.dateOfInjury)
                                            : ""
                                        }
                                        onChange={(date, e) => handleDatePickerChange(setFieldValue, "dateOfInjury", date, e)} 
                                        showYearDropdown
                                        placeholderText="MM/DD/YYYY"
                                        dateFormat="MM/dd/yyyy"
                                      />
                                    </td>
                                    <td>
                                      <Label title="Illness Indicator" />
                                      <Field
                                        type="text"
                                        placeholder="Select Illness Indicator"
                                        name="illnessIndicator"
                                        className={`col-1-1 primary ${
                                          errors.illnessIndicator &&
                                          touched.illnessIndicator
                                            ? "error"
                                            : ""
                                        }`}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ),
                        },
                      ]}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <div className="flex center-left">
            <ButtonTextIcon
              type="button"
              className="light"
              title="Cancel"
              onClick={() => history.push("/patients")}
              icon={
                <svg
                  clipRule="evenodd"
                  fillRule="evenodd"
                  strokeLinejoin="round"
                  strokeMiterlimit="2"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.995"
                  height="19.996"
                >
                  <path
                    d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                    fillRule="nonzero"
                  />
                </svg>
              }
            />
            <ButtonTextIcon
              type="button"
              disabled={loading}
              title="Create Now"
              onClick={async () => {
                const errors = await validateForm(); 
                if (Object.keys(errors).length > 0) {
                  const firstErrorMessage = Object.values(errors)[0];
                  addToastMessage({
                    status: false,
                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                  });
                }
                handleSubmit(); 
              }}
              className="mar-l-a"
              icon={
                loading ? (
                  <LoaderIcon />
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="19.995"
                    height="19.996"
                    viewBox="0 0 19.995 19.996"
                  >
                    <path
                      d="M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z"
                      transform="translate(-2.014 -1.995)"
                    />
                  </svg>
                )
              }
            />
          </div>
          {status && (
            <>
              <br />
              <p className="color-primary f-w-700">{status}</p>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default AddForm;
