import "../../styles/pages.scss";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
    API_URL,
    FACILITY_LISTING_OPTIONS,
    PATIENT_LISTING_OPTIONS,
    PREFRENCE_CARD_LISTING_OPTIONS,
    SURGEON_LISTING_OPTIONS, 
} from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import { useUI } from "../../context/ui";
import { sleep } from "../../opl-utilities/helpers";


const UpdateForm = ({ history, groupData, }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
	const [webModule, setWebModule] = useState(null);
	const [appModule, setAppModule] = useState(null);
	const { addToastMessage } = useUI(); 
    const url =  window.location.href;
    const nextUrl = url.split('next=')[1];  

    const [initialValues, setInitialValues] = useState({
        id: null,
        preferenceCardListingOption: "",
        name: "",
        subCatagoryDTOS: [],
        facilityListingOption: "",
        patientListingOption: "",
        surgeonListingOption: "",
        webModules: [],
        appModules: [], 
    }); 

    const validationSchema = Yup.object({
        name: Yup.string().required("Name is required"),
    });

    useEffect(() => {
        setInitialValues({ 
            id: groupData?.id,
            name: groupData?.groupName || null,
            subCatagoryDTOS: [],
            facilityListingOption: groupData?.facilityListingOption || null,
            patientListingOption: groupData?.patientListingOption || null,
            surgeonListingOption: groupData?.surgeonListingOption || null,
            webModules: groupData?.webModules
                ? groupData?.webModules.map((item) => ({
                    val: item.module.id,
                    name: item.module.moduleName,
                    isAddAccess: item.isAddAccess,
                    isListAccess: item.isListAccess,
                    isUpdateAccess: item.isUpdateAccess,
                    isDeleteAccess: item.isDeleteAccess
                }))
                : [],
            appModules: groupData?.appModules
                ? groupData?.appModules.map((item) => ({
                    val: item.module.id,
                    name: item.module.moduleName,
                    isAddAccess: item.isAddAccess,
                    isListAccess: item.isListAccess,
                    isUpdateAccess: item.isUpdateAccess,
                    isDeleteAccess: item.isDeleteAccess
                }))
                : [],
            preferenceCardListingOption: groupData?.preferenceCardListingOption || null,
        });

    }, [groupData]);

    const updateGroup = async (values) => {
        setLoading(true);
        setStatus("");
        
        if (!values?.id) { return false;  }
        
        try {
            const payload = JSON.stringify({
                name: values.name,
                preferenceCardListingOption: values.preferenceCardListingOption,
                patientListingOption: values.patientListingOption,
                surgeonListingOption: values.surgeonListingOption,
                facilityListingOption: values.facilityListingOption,
                appModules: values.appModules ? values.appModules.map((item) => ({
                    id: item.val,
                    isAddAccess: item.isAddAccess,
                    isListAccess: item.isListAccess,
                    isUpdateAccess: item.isUpdateAccess,
                    isDeleteAccess: item.isDeleteAccess
                })) : [],
                webModules: values.webModules ? values.webModules.map((item) => ({
                    id: item.val,
                    isAddAccess: item.isAddAccess,
                    isListAccess: item.isListAccess,
                    isUpdateAccess: item.isUpdateAccess,
                    isDeleteAccess: item.isDeleteAccess
                })) : [],
            });
  
            const resp = await fetch(API_URL + `/api/1.0.0/groups/${values?.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token")
                },
                body: payload,
            });
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                  addToastMessage({
                    status: false,
                    message:
                      "Access denied. You do not have permission to perform this action.",
                  });
                  setLoading(false);
                  setStatus("Access denied. You do not have permission to perform this action.");
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    return response.result.responseCode === 200 ? true : false;
                } else {
                    const responseData = await resp.json();
                    if (responseData) {
                        setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
                        addToastMessage({
                            status: false,
                            message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
                        });
                        return false;
                    }
                    setStatus(`Something went wrong, please try again later.`);
                    addToastMessage({
                        status: false,
                        message: `Something went wrong, please try again later.`,
                    });
                    return false;
                }
            } else {
                const response = await resp.json();
                if (response) {
                    setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
                    addToastMessage({
                        status: false,
                        message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
                setStatus("Something went wrong, please try again later.");
                addToastMessage({
                    status: false,
                    message: `Something went wrong, please try again later.`,
                });
                return false;
            }
        } catch (e) {
            console.log("Error:", e);
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
            return false;
        } finally {
            setLoading(false);
        }
    };


    const Label = ({ title, required }) => {
        return (
            <p
                className='color-primary f-w-600'
                style={{ paddingBottom: "5px" }}>
                {title}
                {required && (
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                )}
            </p>
        );
    };

    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelModuleFunc = (o) => `${o.moduleName}`;


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                const api = await updateGroup(values);
                if (api) {
                    resetForm();
                    setStatus("You have successfully updated a group, thank you.");
                    addToastMessage({
                        status: true,
                        message: `You have successfully updated a group, thank you.`,
                    }); 
                    if (nextUrl) {   
                        history.push(nextUrl); 
                    } else { 
                      history.push("/groups");
                    }
                }
            }}
        >
            {({
                values,
                setFieldValue,
                errors,
                touched,
                validateForm,
                handleSubmit 
            }) => (
                <Form>
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td colSpan='3' width='50%'>
                                        <Label title='Group Name' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Group Name'
                                            name='name'
                                            className={`col-1-1 primary ${errors.name &&
                                                touched.name
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td colSpan='3' width='50%'>
                                        <Label
                                            title='Patient Listing Options'
                                        />
                                        <Field
                                            as='select'
                                            name='patientListingOption'
                                            className={`col-1-1 primary ${errors.patientListingOption &&
                                                touched.patientListingOption
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {PATIENT_LISTING_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='3'>
                                        <Label title='Facility Listing Options' />
                                        <Field
                                            as='select'
                                            name='facilityListingOption'
                                            className={`col-1-1 primary ${errors.facilityListingOption &&
                                                touched.facilityListingOption
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {FACILITY_LISTING_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                    <td colSpan='3'>
                                        <Label
                                            title='Surgeon Listing Options'
                                        />
                                        <Field
                                            as='select'
                                            name='surgeonListingOption'
                                            className={`col-1-1 primary ${errors.surgeonListingOption &&
                                                touched.surgeonListingOption
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {SURGEON_LISTING_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='3'>
                                        <Label title='Preference Card Listing Options' />
                                        <Field
                                            as='select'
                                            name='preferenceCardListingOption'
                                            className={`col-1-1 primary ${errors.preferenceCardListingOption &&
                                                touched.preferenceCardListingOption
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {PREFRENCE_CARD_LISTING_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan='6'>
                                        <Label title='Web Module ' />
                                        <PaginatedDropdown
                                            searchkey="moduleName"
                                            datakey="list"
                                            url={`${API_URL}/api/1.0.0/reference/module?isOnApp=${false}&`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "id",
                                                    labelModuleFunc
                                                )
                                            }
                                            selectedValue={webModule}
                                            placeHolder='Search'
                                            onChange={async (v) => {
                                                if (v) {
												    setWebModule(v);
                                                    const p = values.webModules;
                                                    const n = {
                                                        val: v.value,
                                                        name: v.label,
                                                        isAddAccess : false,
                                                        isDeleteAccess : false,
                                                        isListAccess : false,
                                                        isUpdateAccess : false,
                                                    };
                                                    if (
                                                        !p.some((item) => item.val === v.value)
                                                    ) {
                                                        setFieldValue(
                                                            "webModules",
                                                            [ ...values.webModules, n ]
                                                        );
                                                    }
                                                }
                                                await sleep();
												setWebModule(null);
                                            }}
                                        />

                                        {values.webModules.length > 0 && (
                                            <ul
                                                className='col-1-1 t-a-l'
                                                style={{
                                                    padding: "10px 0",
                                                }}>
                                                {values.webModules.map(
                                                    (p, j) => (
                                                        <li
                                                            data-tooltip={`Click to delete the module.`}
                                                            onClick={() => {
                                                                const filtered =
                                                                    values.webModules.filter(
                                                                        (obj) =>
                                                                            obj.val !==
                                                                            p.val
                                                                    );
                                                                setFieldValue(
                                                                    "webModules",
                                                                    filtered
                                                                );
                                                            }}
                                                            style={{
                                                                listStyle:
                                                                    "none",
                                                                marginRight:
                                                                    "4px",
                                                                marginBottom:
                                                                    "4px",
                                                                borderRadius:
                                                                    "2px",
                                                                lineHeight:
                                                                    "32px",
                                                                padding:
                                                                    "10px 12px",
                                                                verticalAlign:
                                                                    "middle",
                                                            }}
                                                            key={j}
                                                            className='opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition'>
                                                            <div className='flex center-left'>
                                                                <p
                                                                    className='of-hid'
                                                                    style={{
                                                                        whiteSpace:
                                                                            "nowrap",
                                                                        width: "108px",
                                                                        textOverflow:
                                                                            "ellipsis",
                                                                        fontSize:
                                                                            "12px",
                                                                    }}>
                                                                    {p.name}
                                                                </p>
                                                                <svg
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}>
                                                                    <path
                                                                        fill='#283f54'
                                                                        d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.151,17.943-4.143-4.1L7.891,18,6.058,16.167l4.1-4.157L6,7.891,7.833,6.058l4.155,4.1L16.094,6l1.849,1.849-4.1,4.141L18,16.094Z'
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        )}

                                    </td>
                                </tr>
                                 {/* {Web Module} */}
                                 {values.webModules.length > 0 && (
                                    <FieldArray name='webModules'>
                                        {({ push, remove }) => (
                                            <>
                                                <tr>
                                                    <td className="color-primary">Module</td>
                                                    <td className="color-primary">List</td>
                                                    <td className="color-primary">Add</td>
                                                    <td className="color-primary">Update</td>
                                                    <td className="color-primary">Delete</td>
                                                    <td className="color-primary">Action</td>
                                                </tr>

                                                {values.webModules.length > 0 && (
                                                    <>                                                    {values.webModules.map((val, index) => (
                                                        <tr key={index}>
                                                            <td><p className="color-primary">{val.name}</p></td>
                                                            <td width="12%">
                                                                <Field
                                                                    name={`webModules.${index}.isListAccess`}
                                                                    type='checkbox' 
                                                                    className="checkbox-field primary"

                                                                />
                                                            </td>
                                                            <td width="12%">
                                                                <Field
                                                                    name={`webModules.${index}.isAddAccess`}
                                                                    type='checkbox'
                                                                    className="primary checkbox-field "

                                                                />
                                                            </td>
                                                            <td width="12%">
                                                                <Field
                                                                    name={`webModules.${index}.isUpdateAccess`}
                                                                    type='checkbox'
                                                                    className="primary checkbox-field "

                                                                />
                                                            </td>
                                                            <td width="12%">
                                                                <Field
                                                                    name={`webModules.${index}.isDeleteAccess`}
                                                                    type='checkbox'
                                                                    className="primary checkbox-field"
                                                                />
                                                            </td>
                                                            <td width="12%">

                                                                <button
                                                                    type="button"
                                                                    data-button="delete"
                                                                    onClick={() =>
                                                                        remove(
                                                                            index
                                                                        )
                                                                    }
                                                                >
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                )}

                                <tr>
                                    <td colSpan='6'>
                                        <Label title='App Module ' />
                                        <PaginatedDropdown
                                            searchkey="moduleName"
                                            datakey="list"
                                            url={`${API_URL}/api/1.0.0/reference/module?isOnApp=${true}&`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "id",
                                                    labelModuleFunc
                                                )
                                            }
                                            selectedValue={appModule}
                                            placeHolder='Search'
                                            onChange={async (v) => {
                                                if (v) {
												    setAppModule(v);
                                                    const p = values.appModules;
                                                    const n = {
                                                        val: v.value,
                                                        name: v.label,
                                                        isAddAccess : false,
                                                        isDeleteAccess : false,
                                                        isListAccess  : false,
                                                        isUpdateAccess : false,
                                                    };
                                                    if (
                                                        !p.some(
                                                            (item) =>
                                                                item.val ===
                                                                v.value
                                                        )
                                                    ) {
                                                        setFieldValue(
                                                            "appModules",
                                                            [
                                                                ...values.appModules,
                                                                n,
                                                            ]
                                                        );
                                                    }
                                                }
                                                await sleep();
                                                setAppModule(null);
                                            }}
                                        />

                                        {values.appModules.length > 0 && (
                                            <ul
                                                className='col-1-1 t-a-l'
                                                style={{
                                                    padding: "10px 0",
                                                }}>
                                                {values.appModules.map(
                                                    (p, j) => (
                                                        <li
                                                            data-tooltip={`Click to delete the module.`}
                                                            onClick={() => {
                                                                const filtered =
                                                                    values.appModules.filter(
                                                                        (obj) =>
                                                                            obj.val !==
                                                                            p.val
                                                                    );
                                                                setFieldValue(
                                                                    "appModules",
                                                                    filtered
                                                                );
                                                            }}
                                                            style={{
                                                                listStyle:
                                                                    "none",
                                                                marginRight:
                                                                    "4px",
                                                                marginBottom:
                                                                    "4px",
                                                                borderRadius:
                                                                    "2px",
                                                                lineHeight:
                                                                    "32px",
                                                                padding:
                                                                    "10px 12px",
                                                                verticalAlign:
                                                                    "middle",
                                                            }}
                                                            key={j}
                                                            className='opl-tooltip bg-color-faded dis-i-b cursor-pointer color-primary hover-background-secondary transition'>
                                                            <div className='flex center-left'>
                                                                <p
                                                                    className='of-hid'
                                                                    style={{
                                                                        whiteSpace:
                                                                            "nowrap",
                                                                        width: "108px",
                                                                        textOverflow:
                                                                            "ellipsis",
                                                                        fontSize:
                                                                            "12px",
                                                                    }}>
                                                                    {p.name}
                                                                </p>
                                                                <svg
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    style={{
                                                                        height: "16px",
                                                                        width: "16px",
                                                                    }}>
                                                                    <path
                                                                        fill='#283f54'
                                                                        d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0Zm4.151,17.943-4.143-4.1L7.891,18,6.058,16.167l4.1-4.157L6,7.891,7.833,6.058l4.155,4.1L16.094,6l1.849,1.849-4.1,4.141L18,16.094Z'
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        )}

                                    </td>
                                </tr>
                                 {/* {App Module} */}
                                 {values.appModules.length > 0 && (
                                    <FieldArray name='appModules'>
                                        {({ push, remove }) => (
                                            <>
                                                <tr>
                                                    <td className="color-primary">Module</td>
                                                    <td className="color-primary">List</td>
                                                    <td className="color-primary">Add</td>
                                                    <td className="color-primary">Update</td>
                                                    <td className="color-primary">Delete</td>
                                                    <td className="color-primary">Action</td>
                                                </tr>
                                                {values.appModules.length > 0 && (
                                                    <>
                                                        {values.appModules.map((val, index) => (
                                                            <tr key={index}>
                                                                <td className="color-primary">{val.name} </td>
                                                                <td>
                                                                    <Field
                                                                        name={`appModules.${index}.isListAccess`}
                                                                        type='checkbox'
                                                                        className="primary ceneter-center checkbox-field "

                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                        name={`appModules.${index}.isAddAccess`}
                                                                        type='checkbox'
                                                                        className="primary checkbox-field "

                                                                    />
                                                                </td>
                                                                <td className="center-center">
                                                                    <Field
                                                                        name={`appModules.${index}.isUpdateAccess`}
                                                                        type='checkbox'
                                                                        className="primary center-center checkbox-field "

                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Field
                                                                        name={`appModules.${index}.isDeleteAccess`}
                                                                        type='checkbox'
                                                                        className="primary "
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        data-button="delete"
                                                                        type="button"
                                                                        onClick={() =>
                                                                            remove(
                                                                                index
                                                                            )
                                                                        }
                                                                    >
                                                                        Delete
                                                                    </button>

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </FieldArray>
                                )}

                            </tbody>
                        </table>
                       
                    </div>

                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => {
                                if (nextUrl) {   
                                    history.push(nextUrl); 
                                } else { 
                                  history.push("/groups");
                                }
                            }}
                            icon={
                                <svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
                            }
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Update Now'
                            onClick={async () => {
                                const errors = await validateForm(); 
                                if (Object.keys(errors).length > 0) {
                                  const firstErrorMessage = Object.values(errors)[0];
                                  addToastMessage({
                                    status: false,
                                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                                  });
                                }
                                handleSubmit(); 
                              }}
                            className='mar-l-a'
                            icon={
                                loading ? (
                                    <LoaderIcon />
                                ) : (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='19.995'
                                        height='19.996'
                                        viewBox='0 0 19.995 19.996'>
                                        <path
                                            d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                                            transform='translate(-2.014 -1.995)'
                                        />
                                    </svg>
                                )
                            }
                        />
                    </div>
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default UpdateForm;
