import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
    API_URL,
} from "../../opl-utilities/constants";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import PaginatedDropdown from '../../components/common/ui/PaginatedDropdown';
import { useUI } from "../../context/ui";
import { formatInputPhoneNumber, formatRateUSD, handleDatePickerChange } from "../../opl-utilities/helpers";
import DatePicker from "react-datepicker"; 
import { parseISO } from "date-fns"; 
import PhoneNumberInput from "../../components/common/ui/PhoneNumberInput";  

const Label = ({ title, required }) => {
    return (
        <p
            className='color-primary f-w-600'
            style={{ paddingBottom: "5px" }}>
            {title}
            {required && (
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
            )}
        </p>
    );
};


const UpdateForm = ({ data, history, location }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    // const id = location.state?.id;
    const { addToastMessage } = useUI();
    const labelFacilityFunc = (o) => `${o.facilityName}`;
    const url =  window.location.href;
    const nextUrl = url.split('next=')[1];   

    const [initialValues, setInitialValues] = useState({
        id: null,
        procedureConfiguration: [],
        onCallChild: [],
        contractTypeId: null,
        startDate: "",
        endDate: "",
        contractContactDetails:[],
        facilityId: null,
    });

    useEffect(() => {
        setInitialValues({
            id: data?.contractDetails?.id || null,
            contractTypeId: data?.contractDetails?.contractType ? {
                value: data?.contractDetails?.contractType?.id,
                label: data?.contractDetails?.contractType?.typeName
            } : null,
            startDate: data?.contractDetails?.startDate || null,
            endDate: data?.contractDetails?.endDate || null,
            contractContactDetails: data?.contractDetails?.contactPersonDetails
                ? data?.contractDetails?.contactPersonDetails.map((item) => ({
                    contactPersonTitle: item.contactPersonTitle || null,
                    contactPersonEmail: item.contactPersonEmail || null,
                    contactPersonPhone: item.contactPersonPhone || null,
                    contactPersonName: item.contactPersonName || null,
                }))
                : [],
                facilityId: data?.contractDetails?.facility ? {
                value: data?.contractDetails?.facility?.id,
                label: data?.contractDetails?.facility?.facilityName
            } : null,
            procedureConfiguration: data?.procedureConfiguration
                ? data?.procedureConfiguration.map((item) => ({
                    specialityId: item?.specialty ? {
                        value: item?.specialty?.id,
                        label: item?.specialty?.name
                    } : null,
                    contractId: item.contractId,
                    rateType: item.rateTypeDetails ? {
                        value: item?.rateTypeDetails?.id,
                        label: item?.rateTypeDetails?.typeName
                    } : null,
                    numberOfHours: item.numberOfHours,
                    hourlyRate: item.hourlyRate,
                    contractId: item.contractId,
                    staggeredRate: item.staggeredRate,
                    rate: item.rate
                }))
                : [],
            onCallTypeId: data?.onCallConfiguration?.onCallType ? {
                value: data?.onCallConfiguration?.onCallType?.id,
                label: data?.onCallConfiguration?.onCallType?.typeName
            } : null,
            onCallChild: data?.onCallConfiguration?.onCallChild
                ? data?.onCallConfiguration?.onCallChild.map((child) => ({
                    onCallTypeId: data?.onCallConfiguration?.onCallType ? {
                        value: data?.onCallConfiguration?.onCallType?.id,
                        label: data?.onCallConfiguration?.onCallType?.typeName
                    } : null,
                    rate: child.rate || null,
                    daysPerWeek: child.daysPerWeek || null,
                    dailyRate: child.dailyRate || null,
                    rateTierLimit: child.rateTierLimit || null,
                    staggeredRate: child.staggeredRate || null,
                    numberOfHour: child.numberOfHours || null,
                    hourlyRate: child.hourlyRate || null,
                }))
                : [],

        });

    }, [data]);

    const validationSchema = Yup.object({
        contractTypeId: Yup.mixed()
        .nullable()
        .test("Contract Type required", "Contract type is required",
            function (value) { return value !== null && value.value; }),
        startDate: Yup.string().required("Start Date required"),
        endDate: Yup.date().required("End Date is required")
        .test("is-after-startDate", "End date must be greater then Start date", function (value) {
            const { startDate } = this.parent;
            return !startDate || !value || new Date(value) > new Date(startDate);
        }),
        facilityId: Yup.mixed()
            .nullable()
            .test("facility-required", "Facility is required",
                function (value) { return value !== null && value.value; }),
    });

    const updateContract = async (values) => {
        setLoading(true);
        setStatus("");
        try {

            if(values?.contractContactDetails?.length === 0){
                setStatus("At least one contact detail is required to update a contract. Please provide a valid contact detail.");
                addToastMessage({
                    status: false,
                    message: `At least one contact detail is required to update a contract. Please provide a valid contact detail.`,
                });
                return false;
            }

            let payload = {
                procedureConfiguration: values.procedureConfiguration ? values.procedureConfiguration.map((item) => ({
                    rateType: item?.rateType?.value || null,
                    rate: item.rate || null,
                    daysPerWeek: item.daysPerWeek || null,
                    dailyRate: item.dailyRate || null,
                    staggeredRate: item.staggeredRate || null,
                    hourlyRate: item.hourlyRate || null,
                    specialtyId: item?.specialityId?.value || null,
                    numberOfHours: item?.numberOfHours || null,
                })) : [],
                contractTypeId:values.contractTypeId.value,
                startDate: values.startDate,
                endDate: values.endDate,
                contactPersonDetails: values.contractContactDetails ? values.contractContactDetails.map((item) => ({
                    contactPersonEmail: item?.contactPersonEmail || null,
                    contactPersonPhone: item?.contactPersonPhone || null,
                    contactPersonName: item?.contactPersonName || null,
                    contactPersonTitle:  item?.contactPersonTitle || null,
                })) : [], 
                facilityId: values.facilityId.value || null,
            };

            if (values.onCallChild?.length > 0) {
                payload["onCallConfiguration"] = {
                    onCallTypeId: values.onCallChild[0]?.onCallTypeId?.value || null,
                    onCallChild: values.onCallChild
                        ? values.onCallChild.map((item) => ({
                            rate: item.rate || null,
                            daysPerWeek: item.daysPerWeek || null,
                            dailyRate: item.dailyRate || null,
                            numberOfHours: item.numberOfHour || null,
                            hourlyRate: item.hourlyRate || null,
                            rateTierLimit: item.rateTierLimit || null,
                            staggeredRate: item.staggeredRate || null,
                        }))
                        : [],
                };
            } else {
                payload["onCallConfiguration"] = null
            } 
            const resp = await fetch(API_URL + `/api/1.0.0/contract/${values.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token")
                },
                body: JSON.stringify(payload),
            });
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                  addToastMessage({
                    status: false,
                    message:
                      "Access denied. You do not have permission to perform this action.",
                  });
                    setLoading(false);
                    setStatus("Access denied. You do not have permission to perform this action.");
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    return response.result.responseCode === 200 ? true : false;
                }  else {
                    const responseData = await resp.json();
                    if (responseData) {
                        setStatus(`${responseData.result.responseDescription || "Something went wrong, please try again later."}`);
                        addToastMessage({
                            status: false,
                            message: `${responseData.result.responseDescription || "Something went wrong, please try again later."}`,
                        });
                        return false;
                    }
                    setStatus(`Something went wrong, please try again later.`);
                    addToastMessage({
                        status: false,
                        message: `Something went wrong, please try again later.`,
                    });
                    return false;
                }
            } else {
                const response = await resp.json();
                if (response) {
                    const { responseDescription, responseCode } = response.result || {};
                    setStatus(responseDescription || "Something went wrong, please try again later.");
                    addToastMessage({
                        status: false,
                        message: responseDescription || "Something went wrong, please try again later.",
                    });
                    return false;
                }
                setStatus("Something went wrong, please try again later.");
                addToastMessage({
                    status: false,
                    message: `Something went wrong, please try again later.`,
                });
                return false;
            }
        } catch (e) {
            console.log(e)
            setStatus("Something went wrong, please try again later.");
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
            console.log("Error:", e);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const SingleStep = ({ title, subtitle, completion, style }) => {
        const getClassName = () => {
            const hasEmptyElement = completion.some(
                (element) => element === ""
            );
            if (hasEmptyElement) {
                return "incomplete";
            } else {
                return "complete";
            }
        };
        const dynamicStyle = {
            fontSize: "12px",
            ...style // Spread the additional styles from the `style` prop
        };

        return (
            <div className={`step t-a-c ${getClassName()}`}>
                <p
                    className='color-primary'
                    style={dynamicStyle}
                    >
                    <span className='f-w-700'>0{title}</span> {subtitle}
                </p>
                <div className={`b-r-100 icon`}>
                    <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24'
                        height='24'
                        viewBox='0 0 24 24'>
                        <path d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.25 16.518l-4.5-4.319 1.396-1.435 3.078 2.937 6.105-6.218 1.421 1.409-7.5 7.626z' />
                    </svg>
                </div>
            </div>
        );
    };


    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                const api = await updateContract(values);
                if (api) {
                    resetForm();
                    setStatus(
                        "You have successfully updated a contract, thank you."
                    );
                    addToastMessage({
                        status: true,
                        message: `You have successfully updated a contract, thank you.`,
                    })
                    if (nextUrl) {   
                        history.push(nextUrl); 
                    } else { 
                        history.push("/contracts");
                    } 
                } 
            }}
        >
            {({
                values,
                setFieldValue,
                setFieldTouched,
                errors,
                touched,
                handleSubmit,
                validateForm,
            }) => (
                <Form>
                    <section
                        id='stepper-should-fix'
                        className='config-application-stepper'>
                        <div
                            className='steps flex center-center'
                            style={{ gap: "20px" }}>
                            <SingleStep
                                title='1'
                                subtitle='Basic  Information'
                                style={{marginRight:"20px" }}
                                completion={[
                                    values.contractTypeId,
                                    values.facilityId,
                                    values.startDate,
                                    values.endDate,
                                    values?.contractContactDetails?.length > 0 ? "values" : "",
                                    // values.contactPersonPhone,
                                    // values.contactPersonName,
                                    // values.contactPersonEmail,
                                ]}
                            />

                            <SingleStep
                                title='2'
                                subtitle='Procedure Configuration'
                                completion={[
                                    values?.procedureConfiguration?.length > 0 ? "values" : "",
                                ]}
                            />
                            <SingleStep
                                title='3'
                                subtitle='On Call Configuration'
                                completion={[
                                    values?.onCallChild?.length > 0 ? "values" : "",
                                ]}
                            />
                        </div>
                    </section>
                    <br />
                    <section id='stepper-should-impact'>
                        <div className='col-1-1'>
                            <table>
                                <tbody>
                                    <tr>
                                        <td colSpan={2}>
                                            <h5 className="color-primary">
                                                1. Basic Information
                                            </h5>
                                        </td>
                                    </tr>
                                    <br />
                                    <tr>
                                        <td width="50%">
                                            <Label title="Contract Type" required={true} />
                                            <PaginatedDropdown
                                                datakey="records"
                                                url={`${API_URL}/api/1.0.0/reference/contract-types?`}
                                                mapOptions={(records) =>
                                                    mapOptions(
                                                        records,
                                                        "id",
                                                        labelOnCallTypeFunc
                                                    )
                                                }
                                                placeHolder='Search'
                                                selectedValue={values.contractTypeId}
                                                error={errors.contractTypeId && touched.contractTypeId}
                                                onChange={(v) => {
                                                    setFieldValue(
                                                        "contractTypeId",
                                                        v
                                                    );
                                                }}
                                            />
                                        </td>
                                        <td width='50%'>
                                            <Label title='Facility' required={true} />
                                            <PaginatedDropdown
                                                url={`${API_URL}/api/1.0.0/reference/facility?`}
                                                mapOptions={(records) =>
                                                    mapOptions(
                                                        records,
                                                        "id",
                                                        labelFacilityFunc
                                                    )
                                                }
                                                error={errors.facilityId && touched.facilityId}
                                                placeHolder='Search Facility'
                                                selectedValue={values.facilityId}
                                                onChange={(v) =>
                                                    setFieldValue("facilityId", v)
                                                }
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            <Label title="Start Date" required={true} />
                                            <DatePicker
                                                className={`primary ${errors.startDate && touched.startDate ? "error" : ""
                                                    }`}
                                                isClearable
                                                portalId="root-portal"
                                                onBlur={() => setFieldTouched("startDate", true)}
                                                selected={values.startDate ? parseISO(values.startDate) : ""}
                                                onChange={(date, e) => handleDatePickerChange(setFieldValue, "startDate", date, e)}
                                                showYearDropdown
                                                placeholderText="MM/DD/YYYY"
                                                dateFormat="MM/dd/yyyy"
                                            />
                                            <ErrorMessage
                                                name="startDate"
                                                component="p"
                                                className="error-messages"
                                            />
                                        </td>
                                        <td width="50%">
                                            <Label title="End Date" required={true} />
                                            <DatePicker
                                                className={`primary ${errors.endDate && touched.endDate ? "error" : ""
                                                    }`}
                                                isClearable
                                                portalId="root-portal"
                                                onBlur={() => setFieldTouched("endDate", true)}
                                                selected={values.endDate ? parseISO(values.endDate) : ""}
                                                onChange={(date, e) => handleDatePickerChange(setFieldValue, "endDate", date, e)}
                                                showYearDropdown
                                                placeholderText="MM/DD/YYYY"
                                                dateFormat="MM/dd/yyyy"
                                            />
                                            <ErrorMessage
                                                name="endDate"
                                                component="p"
                                                className="error-messages"
                                            />
                                        </td>
                                    </tr>
                                    <br />
                                    <tr>
                                        <td colSpan="2">
                                            <NestedPersonalDetailForm
                                                onAdd={(newProcedure) => {
                                                    setFieldValue('contractContactDetails', [
                                                        ...values.contractContactDetails,
                                                        newProcedure,
                                                    ]);
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    {values.contractContactDetails && (
                                        <tr>
                                            <td colSpan="2" >
                                                <div className="contect-date">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Designation</th>
                                                                <th>Name</th>
                                                                <th>Phone</th>
                                                                <th>Email</th>
                                                                <th style={{ textAlign: 'center' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.contractContactDetails.map((contact, index) => (
                                                                <tr>
                                                                    <td><span>{contact?.contactPersonTitle || "-"}</span></td>
                                                                    <td><span>{contact?.contactPersonName || "-"}</span></td>
                                                                    <td><span>{contact.contactPersonPhone ? formatInputPhoneNumber(contact.contactPersonPhone.trim()) : "-"}</span></td>
                                                                    <td><span>{contact?.contactPersonEmail || "-"}</span></td>
                                                                    <td>
                                                                        <div className="flex center-center">
                                                                            <span className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    const updatedConfiguration = values.contractContactDetails.filter((_, i) => i !== index);
                                                                                    setFieldValue("contractContactDetails", updatedConfiguration);
                                                                                }}
                                                                            >
                                                                                <svg
                                                                                    height="15px"
                                                                                    width="15px"
                                                                                    viewBox="0 0 330 330"
                                                                                    fill="red"
                                                                                >
                                                                                    <g id="XMLID_6_">
                                                                                        <g id="XMLID_11_">
                                                                                            <path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
                                                                                        </g>
                                                                                        <g id="XMLID_18_">
                                                                                            <path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
                                                                                        </g>
                                                                                        <g id="XMLID_23_">
                                                                                            <path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    {/* <tr>
                                    <td width='50%'>
                                        <Label title='Contact Person Name' required={true} />
                                        <Field
                                            type='text'
                                            placeholder='Enter Name'
                                            name='contactPersonName'
                                            className={`col-1-1 primary ${errors.contactPersonName &&
                                                touched.contactPersonName
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Contact Person Phone' required={true} />
                                        <PhoneNumberInput
                                            name="contactPersonPhone"
                                            placeholder="Eg: 888-888-8888"
                                            error={errors.contactPersonPhone && touched.contactPersonPhone}
                                            value={values.contactPersonPhone}
                                            setTouched={() => setFieldTouched("contactPersonPhone", true)}
                                            callback={(v) => setFieldValue("contactPersonPhone", v)}
                                        />
                                        <ErrorMessage
                                            name="contactPersonPhone"
                                            component="p"
                                            className="error-messages"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Contact Person Email' required={true} />
                                        <Field
                                            type='text'
                                            placeholder='Enter Email'
                                            name='contactPersonEmail'
                                            className={`col-1-1 primary ${errors.contactPersonEmail &&
                                                touched.contactPersonEmail
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>

                                </tr> */}
                                    <tr>
                                        <td colSpan="2">
                                            <br />
                                            <hr />
                                            <br />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2}>
                                            <h5 className="color-primary">
                                                2. Procedure Configuration
                                            </h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <NestedProcedureForm
                                                list={values.procedureConfiguration}
                                                onAdd={(newProcedure) => {
                                                    setFieldValue('procedureConfiguration', [
                                                        ...values.procedureConfiguration,
                                                        newProcedure,
                                                    ]);
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    {values.procedureConfiguration && (
                                        <tr>
                                            <td colSpan="2" >
                                                <div className="contect-date">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>Specialty</th>
                                                                <th>Rate Type</th>
                                                                <th>Number Hours</th>
                                                                <th>Rate</th>
                                                                <th style={{ textAlign: 'center' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.procedureConfiguration.map((contact, index) => (
                                                                <tr>
                                                                    <td><span>{contact?.specialityId?.label || "-"}</span></td>
                                                                    <td><span>{contact.rateType?.label || "-"}</span></td>
                                                                    {/* <td>
                                                                    <span>
                                                                        {contact?.rateType?.value === 3 ? `${contact.numberOfHours}` : "-"}
                                                                    </span>
                                                                </td> */}
                                                                    <td>
                                                                        <span>
                                                                            {contact?.rateType?.value === 2
                                                                                ? `${contact.numberOfHours} `
                                                                                : contact?.rateType?.value === 3
                                                                                    ? `${contact.numberOfHours}` : "-"}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {contact?.rateType?.value === 1
                                                                                ? `${contact.rate ? formatRateUSD(contact.rate.toFixed(2)) : 0.00} `
                                                                                : contact?.rateType?.value === 2
                                                                                    ? ` ${contact.hourlyRate ? formatRateUSD(contact.hourlyRate.toFixed(2)) : 0.00}`
                                                                                    : ` ${contact.staggeredRate ? formatRateUSD(contact.staggeredRate.toFixed(2)) : 0.00}`}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="flex center-center">
                                                                            <span className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    const updatedConfiguration = values.procedureConfiguration.filter((_, i) => i !== index);
                                                                                    setFieldValue("procedureConfiguration", updatedConfiguration);
                                                                                }}
                                                                            >
                                                                                <svg
                                                                                    height="15px"
                                                                                    width="15px"
                                                                                    viewBox="0 0 330 330"
                                                                                    fill="red"
                                                                                >
                                                                                    <g id="XMLID_6_">
                                                                                        <g id="XMLID_11_">
                                                                                            <path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
                                                                                        </g>
                                                                                        <g id="XMLID_18_">
                                                                                            <path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
                                                                                        </g>
                                                                                        <g id="XMLID_23_">
                                                                                            <path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                    <tr>
                                        <td colSpan="2">
                                            <br />
                                            <hr />
                                            <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <h5 className="color-primary">
                                                3. On Call Configuration
                                            </h5>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <br />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <NestedOnCallForm
                                                defaltOnCallTypeId={values.onCallTypeId}
                                                list={values.onCallChild}
                                                onAdd={(newDetails) => {
                                                    setFieldValue('onCallChild', [
                                                        ...values.onCallChild,
                                                        newDetails,
                                                    ]);
                                                }}
                                                onReplace={(newDetails) => {
                                                    setFieldValue('onCallChild', [
                                                        newDetails
                                                    ]);
                                                }}
                                                removeOnCallConfig={() => { setFieldValue('onCallChild', []); }}
                                            />
                                        </td>
                                    </tr>
                                    {values.onCallChild && (
                                        <tr>
                                            <td colSpan="2" >
                                                <div className="contect-date">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th>On Call Type</th>
                                                                <th>Number Of Hours Or Days</th>
                                                                <th>Rate</th>
                                                                <th style={{ textAlign: 'center' }}>Action</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {values.onCallChild.map((contact, index) => (
                                                                <tr>
                                                                    <td><span>{contact.onCallTypeId?.label || "-"}</span></td>
                                                                    <td>
                                                                        <span>
                                                                            {contact?.onCallTypeId?.value === 1
                                                                                ? `${contact?.daysPerWeek ? contact.daysPerWeek : "0"}`
                                                                                : contact.onCallTypeId?.value === 2
                                                                                    ? `${contact?.numberOfHour ? contact.numberOfHour : "0"}  `
                                                                                    : `${contact?.rateTierLimit ? contact.rateTierLimit.toFixed(2) : "0.00"}`}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {contact?.onCallTypeId?.value === 1
                                                                                ? `${contact.dailyRate ? formatRateUSD(contact.dailyRate.toFixed(2)) : "0.00"}`
                                                                                : contact?.onCallTypeId?.value === 2
                                                                                    ? ` ${contact.hourlyRate ? formatRateUSD(contact.hourlyRate.toFixed(2)) : "0.00"}`
                                                                                    : ` ${contact.staggeredRate ? formatRateUSD(contact.staggeredRate.toFixed(2)) : "0.00"}`}
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <div className="flex center-center">
                                                                            <span className="cursor-pointer"
                                                                                onClick={() => {
                                                                                    const updatedConfiguration = values.onCallChild.filter((_, i) => i !== index);
                                                                                    setFieldValue("onCallChild", updatedConfiguration);
                                                                                }}
                                                                            >
                                                                                <svg
                                                                                    height="15px"
                                                                                    width="15px"
                                                                                    viewBox="0 0 330 330"
                                                                                    fill="red"
                                                                                >
                                                                                    <g id="XMLID_6_">
                                                                                        <g id="XMLID_11_">
                                                                                            <path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
                                                                                        </g>
                                                                                        <g id="XMLID_18_">
                                                                                            <path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
                                                                                        </g>
                                                                                        <g id="XMLID_23_">
                                                                                            <path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
                                                                                        </g>
                                                                                    </g>
                                                                                </svg>
                                                                            </span>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                                </tbody>
                            </table>
                        </div>
                        <br />
                        <div className='flex center-left'>
                            <div>
                                <ButtonTextIcon
                                    type='button'
                                    className='light'
                                    title='Cancel'
                                    onClick={(event) => {
                                        event.preventDefault();
                                        if (nextUrl) {   
                                            history.push(nextUrl); 
                                        } else { 
                                            history.push("/contracts");
                                        }
                                    }}
                                    icon={
                                        <svg
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="2"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width='19.995'
                                            height='19.996'
                                        >
                                            <path
                                                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                                fillRule="nonzero"
                                            />
                                        </svg>
                                    }
                                />
                            </div>
                            <div className='mar-l-a' >
                                <ButtonTextIcon
                                    type='button'
                                    disabled={loading}
                                    title='Update Now'
                                    onClick={async () => {
                                        const errors = await validateForm(); 
                                        if (Object.keys(errors).length > 0) {
                                          const firstErrorMessage = Object.values(errors)[0];
                                          addToastMessage({
                                            status: false,
                                            message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                                          });
                                        }
                                        handleSubmit(); 
                                      }}
                                    icon={
                                        loading ? (
                                            <LoaderIcon />
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='19.995'
                                                height='19.996'
                                                viewBox='0 0 19.995 19.996'>
                                                <path
                                                    d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                                                    transform='translate(-2.014 -1.995)'
                                                />
                                            </svg>
                                        )
                                    }
                                />
                            </div>
                        </div>
                        {status && (
                            <>
                                <br />
                                <p className='color-primary f-w-700'>{status}</p>
                            </>
                        )}
                    </section>
                </Form>
            )}
        </Formik>
    );
};

const NestedPersonalDetailForm = ({ onAdd }) => {
    const validationSchema = Yup.object().shape({
        contactPersonName: Yup.string().required("Person Name Required"), 
        contactPersonPhone: Yup.string()
                    .matches(/^\d+$/, "Phone Number must contain only numbers")
                    .max(15, "Phone Number must be at most 15 digits")
                    .required("Phone Number is required"),
        contactPersonEmail: Yup.string()
                    .email("Invalid email address")
                    .required("Email is required"), 
    });

    return (
        <Formik
            initialValues={{
                contactPersonEmail: "",
                contactPersonPhone: "",
                contactPersonName: "",
                contactPersonTitle:"",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                try {
                    onAdd(values);
                    resetForm();
                } catch (error) {
                    console.error("Error during form submission:", error);
                }
            }}
        >
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
                <Form>
                    <table>
                        <tbody>
                            <tr>
                                <td width='50%'>
                                    <Label title='Contact Person Designation' />
                                    <Field
                                        type='text'
                                        placeholder='Eg: Doctor'
                                        name='contactPersonTitle'
                                        className={`col-1-1 primary ${errors.contactPersonTitle &&
                                            touched.contactPersonTitle
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name="contactPersonTitle"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>
                                <td width='50%'>
                                    <Label title='Contact Person Name' required={true} />
                                    <Field
                                        type='text'
                                        placeholder='Enter Name'
                                        name='contactPersonName'
                                        className={`col-1-1 primary ${errors.contactPersonName &&
                                            touched.contactPersonName
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name="contactPersonName"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td width='50%'>
                                    <Label title='Contact Person Phone' required={true} />
                                    <PhoneNumberInput
                                        name="contactPersonPhone"
                                        placeholder="Eg: 888-888-8888"
                                        error={errors.contactPersonPhone && touched.contactPersonPhone}
                                        value={values.contactPersonPhone}
                                        setTouched={() => setFieldTouched("contactPersonPhone", true)}
                                        callback={(v) => setFieldValue("contactPersonPhone", v)}
                                    />
                                    <ErrorMessage
                                        name="contactPersonPhone"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>
                                <td width='50%'>
                                    <Label title='Contact Person Email' required={true} />
                                    <Field
                                        type='text'
                                        placeholder='Enter Email'
                                        name='contactPersonEmail'
                                        className={`col-1-1 primary ${errors.contactPersonEmail &&
                                            touched.contactPersonEmail
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                    <ErrorMessage
                                        name="contactPersonEmail"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td colSpan={6}>
                                    <div className="flex bottom-right">
                                        <div>
                                            {/* <Label title="&nbsp;" /> */}
                                            <ButtonLightTextIcon
                                                title="Add Contact Details"
                                                type="submit"
                                                icon={
                                                    <svg
                                                        fill="#000000"
                                                        version="1.1"
                                                        width="800px"
                                                        height="800px"
                                                        viewBox="0 0 400 400"
                                                        xmlSpace="preserve"
                                                    >
                                                        <g>
                                                            <g>
                                                                <path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
                                                                <path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            )}
        </Formik>
    );
};

const mapOptions = (records, valueKey, labelFormatter) =>
    records.map((record) => ({
        value: record[valueKey],
        label: labelFormatter(record),
        obj: record,
    }));

const labelSpecilityFunc = (o) => `${o.name}`;
const labelOnCallTypeFunc = (o) => `${o.typeName}`;

function isValidDecimal(input) {
    const regex = /^(?!0(\.0{1,2})?$)\d+(\.\d{1,2})?$/;
    return regex.test(input);
}

function isValidNumber(input) {
    if (typeof input !== "string" && typeof input !== "number") {
        return false;
    }
    const value = input.toString().trim();
    const regex = /^[1-9]\d*$/;
    return regex.test(value);
}

const NestedProcedureForm = ({ onAdd, list }) => {
    const [status, setStatus] = useState("");
    const { addToastMessage } = useUI();
    const validationSchema = Yup.object().shape({
    
        rate: Yup.string().test(
            "required-if-1",
            "Rate is required",
            function (value) {
                const { rateType } = this.parent;
                if (rateType?.value === 1) {
                    return value;
                }
                return true;
            }
        ).test(
            "required-if-1",
            "Rate must be a valid number with up to two decimal places.",
            function (value) {
                const { rateType } = this.parent;
                if (rateType?.value === 1) {
                    return isValidDecimal(value);
                }
                return true;
            }
        ),
        staggeredRate: Yup.string().test(
            "required-if-3",
            "Staggered rate is required",
            function (value) {
                const { rateType } = this.parent;
                if ( rateType?.value === 3) {
                    return value;
                }
                return true;
            }
        ).test(
            "required-if-3",
            "Staggered rate must be a valid number with up to two decimal places.",
            function (value) {
                const { rateType } = this.parent;
                if (rateType?.value === 3) {
                    return isValidDecimal(value);
                }
                return true;
            }
        ),
        numberOfHours: Yup.string()
        .test("required-if-rateType", "Number of hours is required", function (value) {
            const { rateType } = this.parent;
            if (rateType?.value === 2 || rateType?.value === 3) {
                return !!value; // Ensure the value is not empty
            }
            return true;
        })
        .test("valid-number-if-rateType", "Number of hours must be a valid number.", function (value) {
            const { rateType } = this.parent;
            // console.log("Validating numberOfHour (number):", value, "rateType:", rateType);
            if ((rateType?.value === 2 || rateType?.value === 3) && value) {
                return isValidNumber(value); // Validate the value is a number
            }
            return true; 
        }),
        hourlyRate: Yup.string()
        .test("required-if-2", "Hourly rate is required", function (value) {
          const { rateType } = this.parent;
          if (rateType?.value === 2) {
            return !!value;
          }
          return true;
        })
        .test("valid-number", "Hourly Rate must be a valid number.", function (value) {
          const { rateType } = this.parent;
        //   console.log("Validating hourlyRate (number):", value, "rateType:", rateType?.value);
          if (rateType?.value === 2) {
            return isValidDecimal(value);
          }
          return true;
        }),
        rateType: Yup.mixed()
            .nullable()
            .test("Rate type required", "Rate type is required",
                function (value) { return value !== null && value.value; }),
        specialityId: Yup.mixed()
            .nullable()
            .test("specialty-required", "Specialty is required",
                function (value) { return value !== null && value.value; }),
    });
  
    return (
        <Formik
            initialValues={{
                rateType: null,
                specialityId: null,
                rate: "",
                numberOfHours: "",
                staggeredRate:"",
                hourlyRate:"",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm ,setFieldValue }) => {
                setStatus("");
                try {
                    const isSpecialityPresentWithDifferentRateType = list?.length > 0 && list.some(
                        (config)=>   config.specialityId?.label === values?.specialityId?.label && config.rateType?.label !== values?.rateType?.label
                    ); 

                    if(isSpecialityPresentWithDifferentRateType){
                        setStatus(`Specialty exists with a different rate type, and cannot be added with '${values?.rateType?.label}' rate type.`)
                        addToastMessage({
                            status: false,
                            message: `Specialty exists with a different rate type, and cannot be added with '${values?.rateType?.label}' rate type.`,
                        });
                        return false;
                    }

                    const isSpecialityPresent = list?.length > 0 && list.some(
                        (config) => config.specialityId?.label === values?.specialityId?.label
                    );
                    const isSpecialityWithFixedPresent = list?.some(
                        (config) => config.specialityId?.label === values?.specialityId?.label && config.rateType?.label === "Fixed (daily)"
                    );
                    // Check if the speciality exists for the "OnDemand (hourly variable)" rate type
                    const isSpecialityWithOnDemandVariablePresent = list?.some(
                        (config) => config.specialityId?.label === values?.specialityId?.label && config.rateType?.label === "OnDemand (hourly variable)"
                    );
                    // Check if the speciality exists for the "OnDemand (hourly fixed)" rate type
                    const isSpecialityWithOnDemandFixedPresent = list?.some(
                        (config) => config.specialityId?.label === values?.specialityId?.label && config.rateType?.label === "OnDemand (hourly fixed)"
                    );
                    // If the rateType is "Fixed (daily)", only allow one speciality
                    if (values?.rateType?.label === "Fixed (daily)") {
                        if (isSpecialityWithFixedPresent || isSpecialityWithOnDemandVariablePresent || isSpecialityWithOnDemandFixedPresent) {
                            addToastMessage({
                                status: false,
                                message: "A Specialty can have only one fixed(daily) rate type configuration.",
                            });
                            setStatus("A Specialty can have only one fixed(daily) rate type configuration")
                            return;  // Stop the submission if speciality already exists with "Fixed (daily)"
                        }
                    } 
            
                    else if (values?.rateType?.label === "OnDemand (hourly variable)") {
                        if (isSpecialityWithOnDemandVariablePresent) {
                            // setStatus("Speciality already exists in the configuration list with 'OnDemand (hourly variable)' rate type, but it will be added again.")
                            // addToastMessage({
                            //     status: true,  
                            //     message: "Speciality already exists in the configuration list with 'OnDemand (hourly variable)'.",
                            // });
                        } else if (isSpecialityWithOnDemandFixedPresent || isSpecialityWithFixedPresent) {
                            setStatus("Specialty exists with a different rate type, and cannot be added with 'OnDemand (hourly variable)' rate type.")
                            addToastMessage({
                                status: false,
                                message: "Specialty exists with a different rate type, and cannot be added with 'OnDemand (hourly variable)' rate type.",
                            });
                            return;
                        }
                    }
            
                    //If the rateType is "OnDemand (hourly fixed)", allow same speciality multiple times only with the same rate type
                    else if (values?.rateType?.label === "OnDemand (hourly fixed)") {
                        if (isSpecialityWithOnDemandFixedPresent) {
                            // addToastMessage({
                            //     status: true,  // Success or info, as we allow the speciality to be added multiple times with same rate type
                            //     message: "Speciality already exists in the configuration list with 'OnDemand (hourly fixed)' rate type, but it will be added again.",
                            // });
                        } else if (isSpecialityWithOnDemandVariablePresent || isSpecialityWithFixedPresent) {
                            setStatus("Specialty exists with a different rate type, and cannot be added with 'OnDemand (hourly fixed)' rate type.")
                            addToastMessage({
                                status: false,
                                message: "Specialty exists with a different rate type, and cannot be added with 'OnDemand (hourly fixed)' rate type.",
                            });
                            return;
                        }
                    }
                    
                     // check
                      
                     if (values.rateType.value === 2) {

                        if (
                            list?.some(
                              (config) => config.specialityId?.value === values?.specialityId?.value &&
                                config.rateType?.value ===  values.rateType?.value  && 
                                parseFloat(config.numberOfHours) === parseFloat(values.numberOfHours)
                            )
                          ) {
                            setStatus("Procedure configration already exists.");
                            addToastMessage({
                              status: false,
                              message: "Procedure configration already exists.",
                            });
                            return;
                          }

                      if (
                        list?.some(
                          (config) => config.specialityId?.value === values?.specialityId?.value &&
                            config.rateType?.value ===  values.rateType?.value  &&
                            parseFloat(config.hourlyRate) === parseFloat(values.hourlyRate) &&
                            parseFloat(config.numberOfHours) === parseFloat(values.numberOfHours)
                        )
                      ) {
                        setStatus("Procedure configration already exists.");
                        addToastMessage({
                          status: false,
                          message: "Procedure configration already exists.",
                        });
                        return;
                      }
                    }

                    if (values.rateType.value === 3) {

                        if (
                            list?.some(
                              (config) => config.specialityId?.value === values?.specialityId?.value &&
                                config.rateType?.value ===  values.rateType?.value  && 
                                parseFloat(config.numberOfHours) === parseFloat(values.numberOfHours)
                            )
                          ) {
                            setStatus("Procedure configration already exists.");
                            addToastMessage({
                              status: false,
                              message: "Procedure configration already exists.",
                            });
                            return;
                          }

                        if (
                          list?.some(
                            (config) => config.specialityId?.value === values?.specialityId?.value &&
                              config.rateType?.value ===  values.rateType?.value  &&
                              parseFloat(config.staggeredRate) === parseFloat(values.staggeredRate) &&
                              parseFloat(config.numberOfHours) === parseFloat(values.numberOfHours)
                          )
                        ) {
                          setStatus("Procedure configration already exists.");
                          addToastMessage({
                            status: false,
                            message: "Procedure configration already exists.",
                          });
                          return;
                        }
                      }
                    onAdd(values);
                    resetForm();
                } catch (error) {
                    console.error("Error during form submission:", error);
                }
            }}
        >
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
                <Form>
                    <table>
                        <tbody>
                            <tr>
                                <td width="50%">
                                    <Label title="Specialty" required={true} />
                                    <PaginatedDropdown
                                        datakey="records"
                                        url={`${API_URL}/api/1.0.0/reference/specialities?`}
                                        mapOptions={(records) =>
                                            mapOptions(
                                                records,
                                                "id",
                                                labelSpecilityFunc
                                            )
                                        }
                                        placeHolder='Search'
                                        selectedValue={values.specialityId}
                                        error={errors.specialityId && touched.specialityId}
                                        onChange={(v) => {
                                            setFieldValue(
                                                "specialityId",
                                                v
                                            );
                                            setFieldValue("rateType", null);
                                            setFieldValue("rate", "");
                                            setFieldValue("numberOfHours", "");
                                            setFieldValue("hourlyRate", "");
                                            setFieldValue("staggeredRate", "");
                                        }}
                                    />
                                </td>
                                <td width="50%">
                                    <Label title="Rate Type" required={true} />
                                    <PaginatedDropdown
                                        datakey="records"
                                        url={`${API_URL}/api/1.0.0/reference/onCall-types?`}
                                        mapOptions={(records) =>
                                            mapOptions(
                                                records,
                                                "id",
                                                labelOnCallTypeFunc
                                            )
                                        }
                                        placeHolder='Search'
                                        selectedValue={values.rateType}
                                        error={errors.rateType && touched.rateType}
                                        onChange={(v) => {
                                            setFieldValue(
                                                "rateType",
                                                v
                                            );
                                            setFieldValue("rate", "");
                                            setFieldValue("numberOfHours", "");
                                            setFieldValue("hourlyRate", "");
                                            setFieldValue("staggeredRate", "");
                                        }}
                                    />
                                </td>
                            </tr>
                            {values?.rateType?.value === 1 && (
                                <tr>
                                    {/* Fixed (daily) fields */}
                                    <td>
                                        <Label title="Rate " required={true} />
                                        <Field
                                            type="number"
                                            name="rate"
                                            placeholder="Enter daily rate"
                                            className={`col-1-1 primary ${errors.rate && touched.rate ? "error" : ""
                                                }`}
                                        /> 
                                        <ErrorMessage
                                            name="rate"
                                            component="p"
                                            className="error-messages"
                                        />
                                    </td>
                                </tr>
                            )}
                            {values?.rateType?.value === 2 && (
                                <tr>
                                    {/* OnDemand (hourly fixed) fields */}
                                    <td width="50%">
                                        <Label title="Number Of Hours " required={true} />
                                        <Field
                                            type="number"
                                            name="numberOfHours"
                                            placeholder="Enter number of hours"
                                            className={`col-1-1 primary ${errors.numberOfHours && touched.numberOfHours ? "error" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            name="numberOfHours"
                                            component="p"
                                            className="error-messages"
                                        />
                                    </td>
                                    <td>
                                        <Label title="Hourly Rate " required={true} />
                                        <Field
                                            type="number"
                                            name="hourlyRate"
                                            placeholder="Enter hourlyRate rate"
                                            className={`col-1-1 primary ${errors.hourlyRate && touched.hourlyRate ? "error" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            name="hourlyRate"
                                            component="p"
                                            className="error-messages"
                                        />
                                    </td>
                                </tr>
                            )}

                            {values?.rateType?.value === 3 && (
                                <tr>
                                    {/* OnDemand (hourly variable) fields */}
                                    <td width="50%">
                                        <Label title="Number Of Hours " required={true} />
                                        <Field
                                            type="number"
                                            name="numberOfHours"
                                            placeholder="Enter number of hours"
                                            className={`col-1-1 primary ${errors.numberOfHours && touched.numberOfHours ? "error" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            name="numberOfHours"
                                            component="p"
                                            className="error-messages"
                                        />
                                    </td>
                                    <td>
                                        <Label title="Staggered Rate" required={true} />
                                        <Field
                                            type="number"
                                            name="staggeredRate"
                                            placeholder="Enter staggered rate"
                                            className={`col-1-1 primary ${errors.staggeredRate && touched.staggeredRate ? "error" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                            name="staggeredRate"
                                            component="p"
                                            className="error-messages"
                                        />
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={2}>
                                    <div className="flex center-right">
                                        <div>
                                            <Label title="&nbsp;" />
                                            <ButtonLightTextIcon
                                                title="Add Procedure Config"
                                                type="submit"
                                                icon={
                                                    <svg
                                                        fill="#000000"
                                                        version="1.1"
                                                        width="800px"
                                                        height="800px"
                                                        viewBox="0 0 400 400"
                                                        xmlSpace="preserve"
                                                    >
                                                        <g>
                                                            <g>
                                                                <path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
                                                                <path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {status && (
                        <>
                            <br />
                            <p style={{
                                color: "red",
                                marginBottom: "17px"
                            }}>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

const NestedOnCallForm = ({ defaltOnCallTypeId = null, onReplace, onAdd, list, removeOnCallConfig }) => {
    const [status , setStatus] = useState(); 
    const {addToastMessage} = useUI();
    const [initialValues, setInitialValues] = useState({
        onCallTypeId: null,
        numberOfHour: "",
        rate: "",
        daysPerWeek: "",
        dailyRate: "",
        hourlyRate: "",
        rateTierLimit: "",
        staggeredRate: "",
    })
   
    const validationSchema = Yup.object().shape({
        onCallTypeId: Yup.mixed()
            .nullable()
            .test("On Call Type required", "Specialty is required", function (value) {
                return value !== null && value.value;
            }),
        
        daysPerWeek: Yup.string()
            .test("required-if-onCallTypeId-1", "Days per week is required", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 1) {
                    return !!value; // Ensure value exists if onCallTypeId is 1
                }
                return true; 
            })
            .test("valid-decimal-if-onCallTypeId-1", "Days per week must be a valid number with up to two decimal places.", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 1) {
                    return isValidDecimal(value); 
                }
                return true;
            })
            .test("max-daysPerWeek", "Days Per Week cannot exceed 7.", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 1 && value) {
                    return parseFloat(value) <= 7;
                }
                return true; 
            }),
    
        dailyRate: Yup.string()
            .test("required-if-onCallTypeId-1", "Daily rate is required", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 1) {
                    return !!value;
                }
                return true;
            })
            .test("valid-decimal-if-onCallTypeId-1", "Daily rate must be a valid number with up to two decimal places.", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 1) {
                    return isValidDecimal(value);
                }
                return true;
            }),
    
        numberOfHour: Yup.string()
            .test("required-if-onCallTypeId-2", "Number of hours is required", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 2) {
                    return !!value;
                }
                return true;
            })
            .test("valid-decimal-if-onCallTypeId-2", "Number of hours must be a valid number.", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 2) {
                    return isValidNumber(value);
                }
                return true;
            }),
    
        hourlyRate: Yup.string()
            .test("required-if-onCallTypeId-2", "Hourly rate is required", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 2) {
                    return !!value;
                }
                return true;
            })
            .test("valid-decimal-if-onCallTypeId-2", "Hourly Rate must be a valid number.", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 2) {
                    return isValidDecimal(value);
                }
                return true;
            }),
    
        rateTierLimit: Yup.string()
            .test("required-if-onCallTypeId-3", "Tier limit rate is required", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 3) {
                    return !!value;
                }
                return true;
            })
            .test("valid-number-if-onCallTypeId-3", "Tier Limit Rate must be a valid number.", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 3) {
                    return isValidNumber(value);
                }
                return true;
            }),
    
        staggeredRate: Yup.string()
            .test("required-if-3", "Staggered rate is required", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 3) {
                    return !!value;
                }
                return true;
            })
            .test("required-if-3", "Staggered rate must be a valid number with up to two decimal places.", function (value) {
                const { onCallTypeId } = this.parent;
                if (onCallTypeId?.value === 3) {
                    return isValidDecimal(value);
                }
                return true;
            }),
    });
    
    useEffect(() => {
        if (defaltOnCallTypeId) {
            setInitialValues({
                onCallTypeId: defaltOnCallTypeId,
                numberOfHour: "",
                rate: "",
                daysPerWeek: "",
                dailyRate: "",
                hourlyRate: "",
                rateTierLimit: "",
                staggeredRate: "",
            })
        }
    }, [defaltOnCallTypeId])
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={false}
            onSubmit={(values, { setFieldValue }) => { 
                if (list?.length > 0 && values?.onCallTypeId?.value === 1) {
                    setStatus("Fixed (Daily) call type can have only one configuration. ");
                    addToastMessage({
                        status: false,
                        message: "Fixed (Daily) call type can have only one configuration. ",
                    });
                    return false;
                } else {
                    onAdd(values);
                }
                setStatus("");
                setFieldValue("numberOfHour", "");
                setFieldValue("rate", "");
                setFieldValue("daysPerWeek", "");
                setFieldValue("dailyRate", "");
                setFieldValue("hourlyRate", "");
                setFieldValue("rateTierLimit", "");
                setFieldValue("staggeredRate", "");
                
            }}
        >
            {({ errors, touched, values, setFieldError , setFieldTouched, setFieldValue }) => (
                <Form>
                    <table>
                        <tbody>
                            <tr>
                                <td width="50%">
                                    <Label title="On Call Type" required={true} />
                                    <PaginatedDropdown
                                        datakey="records"
                                        url={`${API_URL}/api/1.0.0/reference/onCall-types?`}
                                        mapOptions={(records) =>
                                            mapOptions(
                                                records,
                                                "id",
                                                labelOnCallTypeFunc
                                            )
                                        }
                                        placeHolder='Search'
                                        selectedValue={values.onCallTypeId}
                                        error={errors.onCallTypeId && touched.onCallTypeId}
                                        onChange={(v) => {
                                            setFieldValue(
                                                "onCallTypeId",
                                                v
                                            );
                                            removeOnCallConfig();
                                            setFieldValue("daysPerWeek", "");
                                            setFieldValue("dailyRate", "");
                                            setFieldValue("numberOfHour", "");
                                            setFieldValue("hourlyRate", "");
                                            setFieldValue("rateTierLimit", "");
                                            setFieldValue("staggeredRate", "");
                                        }}
                                    />
                                </td>
                                <td width="50%"></td>
                            </tr>
                            {/* <p>{values.onCallTypeId?.value}</p> */}
                            {/* Conditional Fields */}
                            {values?.onCallTypeId?.value === 1 && (
                                <tr>
                                    {/* Fixed (daily) fields */}
                                    <td width="50%">
                                        <Label title="Days Per Week" required={true} />
                                        <Field
                                            type="number"
                                            values={values.daysPerWeek}
                                            name="daysPerWeek"
                                            placeholder="Enter days per week"
                                            className={`col-1-1 primary ${errors.daysPerWeek && touched.daysPerWeek ? "error" : ""
                                                }`}
                                            
                                        />
                                        <ErrorMessage
                                        name="daysPerWeek"
                                        component="p"
                                        className="error-messages"
                                    />
                                    </td>
                                    <td>
                                        <Label title="Daily Rate" required={true} />
                                        <Field
                                            type="number"
                                            name="dailyRate"
                                            placeholder="Enter daily rate"
                                            className={`col-1-1 primary ${errors.dailyRate && touched.dailyRate ? "error" : ""
                                                }`}
                                        />
                                         <ErrorMessage
                                        name="dailyRate"
                                        component="p"
                                        className="error-messages"
                                    />
                                    </td>
                                </tr>
                            )}
                            {values?.onCallTypeId?.value === 2 && (
                                <tr>
                                    {/* OnDemand (hourly variable) fields */}
                                    <td width="50%">
                                        <Label title="Number of Hours" required={true} />
                                        <Field
                                            type="number"
                                            name="numberOfHour"
                                            placeholder="Enter number of hours"
                                            className={`col-1-1 primary ${errors.numberOfHour && touched.numberOfHour ? "error" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                       name="numberOfHour"
                                       component="p"
                                       className="error-messages"
                                   />
                                    </td>
                                    <td>
                                        <Label title="Hourly Rate" required={true} />
                                        <Field
                                            type="number"
                                            name="hourlyRate"
                                            placeholder="Enter hourlyRate rate"
                                            className={`col-1-1 primary ${errors.hourlyRate && touched.hourlyRate ? "error" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                       name="hourlyRate"
                                       component="p"
                                       className="error-messages"
                                   />
                                    </td>
                                </tr>
                            )}

                            {values?.onCallTypeId?.value === 3 && (
                                <tr>
                                    {/* OnDemand (hourly fixed) fields */}
                                    <td width="50%">
                                        <Label title="Rate Tier Limit" required={true} />
                                        <Field
                                            type="number"
                                            name="rateTierLimit"
                                            placeholder="Enter rate tier limit"
                                            className={`col-1-1 primary ${errors.rateTierLimit && touched.rateTierLimit ? "error" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                       name="rateTierLimit"
                                       component="p"
                                       className="error-messages"
                                   />
                                    </td>
                                    <td>
                                        <Label title="Staggered Rate" required={true} />
                                        <Field
                                            type="number"
                                            name="staggeredRate"
                                            placeholder="Enter staggered rate"
                                            className={`col-1-1 primary ${errors.staggeredRate && touched.staggeredRate ? "error" : ""
                                                }`}
                                        />
                                        <ErrorMessage
                                       name="staggeredRate"
                                       component="p"
                                       className="error-messages"
                                   />
                                    </td>
                                </tr>
                            )}
                            <tr>
                                <td colSpan={2}>
                                    <div className="flex center-right">
                                        <div>
                                            <Label title="&nbsp;" />
                                            <ButtonLightTextIcon
                                                title={`Add On Call Config`}
                                                type="submit"
                                                icon={
                                                    <svg
                                                        fill="#000000"
                                                        version="1.1"
                                                        width="800px"
                                                        height="800px"
                                                        viewBox="0 0 400 400"
                                                        xmlSpace="preserve"
                                                    >
                                                        <g>
                                                            <g>
                                                                <path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
                                                                <path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table> 
                    {status && (
                        <>
                            <br />
                            <p  style={{
                                color: "red",
                                marginBottom: "17px"
                            }}>{status}</p>
                        </>
                    )}
                </Form>
                       
            )}
        </Formik>
    );
};


export default UpdateForm;