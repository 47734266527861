import React, { useState, useEffect, useRef, useCallback } from "react"; 
import LoaderIcon from "./LoaderIcon";

const Caution = ({ title }) => {
	return (
		<p
			className='t-a-c'
			style={{
				fontSize: "12px",
				padding: "5px",
				color: "#777",
			}}>
			{title}
		</p>
	);
};

const PaginatedDropdown = ({
	datakey = "records",
	searchkey = "search",
	url,
	placeHolder = "Search options...",
	selectedValue = null,
	onChange,
	getAll = false,
	mapOptions,
	error = false,
	disabled = false,
}) => {
	const [filteredOptions, setFilteredOptions] = useState([]);
	const [selectedOption, setSelectedOption] = useState(null);
	const [searchQuery, setSearchQuery] = useState("");
	const [page, setPage] = useState(1);
	const [isLoading, setIsLoading] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [noMoreOptions, setNoMoreOptions] = useState(false);
	const dropdownRef = useRef(null);

	useEffect(() => {
		setSelectedOption(selectedValue || null);
		setSearchQuery(selectedValue?.label || "");
	}, [selectedValue]);

	const debounce = (func, delay) => {
		let timeout;
		return (...args) => {
			clearTimeout(timeout);
			timeout = setTimeout(() => func(...args), delay);
		};
	};

	const fetchOptions = async (url, selectedOption, search = "", pageNum = 1) => {
		if (noMoreOptions) return;

		if (pageNum === 1) setFilteredOptions([]);
		try {
			setIsLoading(true);
			const resp = await fetch(
				`${url}sortOrder=ASC&pageSize=${getAll ? 1000 : 10}&pageNo=${
					getAll ? 0 : pageNum - 1
				}${search && !selectedOption ? `&${searchkey}=${search}` : ""}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token")
					},
				}
			);
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
					localStorage.clear(); 
					window.location.href = "/login"; 
				} else if (resp.status === 403) {
					setIsLoading(false);
				}
				return false; 
			}
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode === 200) {
					const options = mapOptions(response.data[datakey]);

					setFilteredOptions((prevOptions) => {
						const mergedOptions =
							pageNum === 1
								? options
								: [...prevOptions, ...options];
						return mergedOptions?.filter(
							(option, index, self) =>
								index ===
								self.findIndex((o) => o.value === option.value)
						);
					});

					if (
						!getAll &&
						response.data.totalPages === response.data.pageNo + 1
					) {
						setNoMoreOptions(true);
					}
				}
			} else {
				console.error(`Failed to fetch options: ${resp.statusText}`);
			}
		} catch (error) {
			console.error("Error fetching options:", error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleScroll = () => {
		if (
			!getAll &&
			dropdownRef.current &&
			dropdownRef.current.scrollTop + dropdownRef.current.clientHeight >=
				dropdownRef.current.scrollHeight - 1
		) {
			setPage((prevPage) => prevPage + 1);
		}
	};

	const debouncedFetchData = useCallback(debounce(fetchOptions, 300), []);

	useEffect(() => {
		if (isOpen) {
			debouncedFetchData(url, selectedOption, searchQuery, page);
		}
	}, [url, selectedOption, searchQuery, page, isOpen, debouncedFetchData]);

	// const handleSearch = (e) => {
	// 	setSearchQuery(e.target.value);
	// 	setPage(1);
	// 	setNoMoreOptions(false);
	// 	setSelectedOption(null);
	// };
	const handleSearch = (e) => {
		setSearchQuery(e.target.value);
		setPage(1);
		setNoMoreOptions(false);
		setSelectedOption(null);
		onChange(null); // Notify parent of unselection
	};

	// const handleSelect = (option) => {
	// 	setSelectedOption(option);
	// 	onChange(option);
	// 	setSearchQuery(option.label);
	// 	setIsOpen(false);
	// };
	const handleSelect = (option) => {
		// if (selectedOption && selectedOption.value === option.value) {
		// 	// If the selected option is clicked again, unselect it
		// 	setSelectedOption(null);
		// 	onChange(null); // Notify parent of unselection
		// 	setSearchQuery(""); // Clear search query
		// } else {
			setSelectedOption(option);
			onChange(option);
			setSearchQuery(option.label);
		// }
		setIsOpen(false);
	};

	const toggleDropdown = () => {
		// setIsOpen(!isOpen); // Toggle dropdown visibility
		// setSearchQuery(""); //
		// onChange(null);
		// setSelectedOption(null);
		setIsOpen((prevOpen) => !prevOpen);
	};


	useEffect(() => {
		const handleClickOutside = (event) => {
			if (
				dropdownRef.current &&
				!dropdownRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const handleRemoveSelected = () => {
        setSelectedOption(null);
        setSearchQuery("");
        onChange(null); 
        setIsOpen(false);
    };

	return (
		<div className='dropdown-container pos-rel'>
			<div className='flex pos-rel center-center'>
				<input
				    disabled={disabled}
					style={{ lineHeight: "12px", cursor:  disabled ? "not-allowed" : "" }}
					type='text'
					value={searchQuery}
					onClick={toggleDropdown}
					onChange={handleSearch}
					placeholder={placeHolder}
					className={`primary t-t-u ${error ? " error" : ""}`}
				/>
				<span
					className='dropdown-arrow'
					onClick={() => {
						if(selectedOption){
							handleRemoveSelected();
						}else{
							toggleDropdown();
						}
					}}
					style={{
						position: "absolute",
						right: "12px",
					}}>
					{!isOpen ? (
						selectedOption ? (
							<span 
							  className="cursor-pointer"
							  style={{ 
								backgroundColor: "#f0f0f0",
								borderRadius: "50%",
								width: "20px",
								height: "20px",
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)", 
							  }}
							>
							  &times; 
							</span>
						  ) : (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='11.934'
							height='7.236'
							viewBox='0 0 11.934 7.236'
							style={{
								height: "6px",
								width: "auto",
								display: "block",
							}}>
							<path
								d='M5.549,7.2a.644.644,0,0,0,.9,0l5.332-5.3a.677.677,0,0,0,0-.93L11.154.332a.677.677,0,0,0-.93,0L6.014,4.543,1.775.332a.677.677,0,0,0-.93,0L.217.961a.677.677,0,0,0,0,.93Z'
								transform='translate(-0.032 -0.147)'
								fill='#3d3368'
							/>
						</svg>)
					) : (
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='11.934'
							height='7.236'
							viewBox='0 0 11.934 7.236'
							style={{
								height: "6px",
								width: "auto",
								display: "block",
							}}>
							<path
								d='M5.549,7.2a.644.644,0,0,0,.9,0l5.332-5.3a.677.677,0,0,0,0-.93L11.154.332a.677.677,0,0,0-.93,0L6.014,4.543,1.775.332a.677.677,0,0,0-.93,0L.217.961a.677.677,0,0,0,0,.93Z'
								transform='translate(11.966 7.383) rotate(180)'
								fill='#3d3368'
							/>
						</svg>
					)}
				</span>
			</div>
			{isOpen && (
				<div
					className='dropdown-options'
					// onScroll={handleScroll}
					ref={dropdownRef}>
					{filteredOptions?.map((option) => (
						<div
							key={option.value}
							className={`dropdown-option cursor-pointer t-t-u ${
								selectedOption &&
								selectedOption.value === option.value
									? "selected"
									: ""
							}`}
							style={{
								width: "auto",
								// whiteSpace: "nowrap",
								overflow: "hidden",
								// textOverflow: "ellipsis",
								color: "#283f54 !important",
							}}
							onClick={() => handleSelect(option)}>
							{option.label}
						</div>
					))}
					{!isLoading && !noMoreOptions && (
						<div
							className='cursor-pointer'
							onClick={() => {
								handleScroll();
							}}>
							<Caution title='Click to load more.' />
						</div>
					)}
					{isLoading && (
						<Caution
							title={
								<>
									Loading{" "}
									<LoaderIcon
										style={{
											width: "10px",
											height: "10px",
										}}
									/>
								</>
							}
						/>
					)}
					{!isLoading && noMoreOptions && (
						<Caution title='No more options' />
					)}
				</div>
			)}
		</div>
	);
};

export default PaginatedDropdown;
