import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useState } from "react";
import {
    ALLOWED_FILE_TYPES,
    API_URL,
    CLEARANCE_OPTIONS,
    EMR_ACCESS_OPTIONS,
    FACILITY_INVOICE_OPTIONS,
    FACILITY_TYPE_OPTIONS,
    PLACE_OF_SERVICE,
    SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import { useUI } from "../../context/ui";
import PhoneNumberInput from "../../components/common/ui/PhoneNumberInput";
import { formatInputPhoneNumber } from "../../opl-utilities/helpers";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon"

const UpdateFacilityForm = ({ location, history, facilityData }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const { addToastMessage } = useUI(); 
    const url =  window.location.href;
    const nextUrl = url.split('next=')[1];   

    const [initialValues, setInitialValues] = useState({
        additionalContacts: [],
        id: null,
        facilityName: "",
        address: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        contactName: "",
        contactEmail: "",
        contactPhone: "",
        contactFax: "",
        gpo: "",
        idn: "",
        npi: "",
        taxPercent: "",
        caseCapacity: "",
        dayConfirmEmail: "",
        facilityType: "",
        financialClearanceFacility: "",
        facilityEmrAccess: "",
        facilityPlaceOfService: "",
        invoiceReq: "",
        facilityAdditionalContact: " ",
        internalIdentifier: "",
        taxonomy:"",
    }); 

    useEffect(() => {
        setInitialValues({ 
            id: facilityData?.id,
            facilityName: facilityData?.facilityName,
            address: facilityData?.address,
            address2: facilityData?.address2,
            state: facilityData?.stateRecord ? {
                value: facilityData?.stateRecord?.code,
                label: facilityData?.stateRecord?.name
            }
                : null,
            city: facilityData?.city ? {
                value: facilityData?.city,
                label: facilityData?.city,
            } : null,
           zipCode: facilityData?.zipCode,
            // contactName: facilityData?.contactName,
            // contactEmail: facilityData?.contactEmail,
            // contactPhone: facilityData?.contactPhone,
            contactFax: facilityData?.contactFax,
            gpo: facilityData?.gpo,
            idn: facilityData?.idn,
            npi: facilityData?.npi,
            taxPercent: facilityData?.taxPercent,
            caseCapacity: facilityData?.caseCapacity,
            dayConfirmEmail: facilityData?.dayConfirmEmail,
            facilityType: facilityData?.facilityType,
            financialClearanceFacility: facilityData?.financialClearanceFacility,
            facilityEmrAccess: facilityData?.facilityEmrAccess,
            facilityPlaceOfService: facilityData?.facilityPlaceOfService,
            invoiceReq: facilityData?.invoiceReq,
            facilityAdditionalContact: facilityData?.facilityAdditionalContact,
            internalIdentifier:facilityData?.internalIdentifier,
            taxonomy: facilityData?.taxonomy,
            additionalContacts: facilityData?.additionalContacts
                ? facilityData?.additionalContacts.map((item) => ({
                    contactTitle: item.title || null,
                    contactEmail: item.email || null,
                    contactPhone: item.phone || null,
                    contactName: item.name || null,
                }))
                : [],
        });   
                 
    }, [facilityData]);


    const validationSchema = Yup.object({
        facilityName: Yup.string().required("Facility Name is required"),
        npi: Yup.string().required("NPI is required"),
        facilityPlaceOfService: Yup.string().required("Place Of Service is required"),
    });

    const updateFacility = async (values) => {
        setLoading(true);
        setStatus("");

        try {
            const payload = JSON.stringify({
                facilityName: values.facilityName,
                address: values.address,
                address2: values.address2,
                city: values.city?.label,
                state: values.state?.value,
                zipCode: values.zipCode,
                // contactName: values.contactName,
                // contactEmail: values.contactEmail,
                // contactPhone: values.contactPhone,
                contactFax: values.contactFax,
                gpo: values.gpo,
                idn: values.idn,
                npi: values.npi,
                taxPercent: values.taxPercent,
                caseCapacity: values.caseCapacity,
                dayConfirmEmail: values.dayConfirmEmail,
                facilityType: values.facilityType,
                financialClearanceFacility: values.financialClearanceFacility||"",
                facilityEmrAccess: values.facilityEmrAccess,
                facilityPlaceOfService: values.facilityPlaceOfService,
                invoiceReq: values.invoiceReq,
                facilityAdditionalContact:  values.facilityAdditionalContact,
                internalIdentifier:  values.internalIdentifier,
                taxonomy: values.taxonomy,
                additionalContacts: values.additionalContacts ? values.additionalContacts.map((item) => ({
                    email: item?.contactEmail || null,
                    phone: item?.contactPhone || null,
                    name: item?.contactName || null,
                    title: item?.contactTitle || null,
                })) : [],
            });

            const resp = await fetch(API_URL + `/api/1.0.0/facility/${values?.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token")
                },
                body: payload,
            });
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                  addToastMessage({
                    status: false,
                    message:
                      "Access denied. You do not have permission to perform this action.",
                  });
                    setLoading(false);
                    
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    if (response.result.responseCode === 200) {
                        return true;
                    } else {
                        addToastMessage({
                            status: false,
                            message: `Something went wrong, please try again later.`,
                        });
                        return false;
                    }
                }
            }
            else {
                const response = await resp.json();
                if (response) {
                    addToastMessage({
                        status: false,
                        message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
            }
        } catch (e) {
            addToastMessage({
                status: false,
                message: "Something went wrong, please try again later.",
            });
            console.log("Error:", e);
            return false;
        } finally {
            setLoading(false);
        }

    };
    const Label = ({ title, required }) => {
        return (
            <p
                className='color-primary f-w-600'
                style={{ paddingBottom: "5px" }}>
                {title}
                {required && (
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                )}
            </p>
        );
    };

    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelStateFunc = (o) => `${o.name}`;
    const labelCityFunc = (o) => `${o.name}`
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                const api = await updateFacility(values);
                if (api) {
                    resetForm();
                    setStatus(
                        "You have successfully updated a facility, thank you."
                    );
                    addToastMessage({
                        status: true,
                        message: `You have successfully updated a facility, thank you.`,
                    }) 
                    if (nextUrl) {   
                        history.push(nextUrl); 
                    } else { 
                        history.push("/facilities");
                    }
                } else {
                    setStatus("Something went wrong, please try again later.");
                }
            }}
        >
            {({
                values,
                setFieldValue,
                errors,
                touched,
                handleSubmit,
                validateForm,
                setFieldTouched,
            }) => (
                <Form>
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Facility Name' required={true} />
                                        <Field
                                            type='text'
                                            placeholder='Enter Facility Name'
                                            name='facilityName'
                                            className={`col-1-1 primary ${errors.facilityName &&
                                                touched.facilityName
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Address Line1' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Address Line1'
                                            name='address'
                                            className={`col-1-1 primary ${errors.address &&
                                                touched.address
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Address Line2' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Address Line1'
                                            name='address2'
                                            className={`col-1-1 primary ${errors.address2 &&
                                                touched.address2
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td>
                                        <Label
                                            title='Zip Code'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter ZipCode'
                                            name='zipCode'
                                            className={`col-1-1 primary ${errors.zipCode &&
                                                touched.zipCode
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='State' />
                                        <PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/states/US?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "code",
                                                    labelStateFunc
                                                )
                                            }
                                            placeHolder='Search'
                                            selectedValue={values.state}
                                            onChange={(v) => {
                                                setFieldValue("state", v);
                                                setFieldValue("city", null);
                                            }}
                                        />
                                    </td>
                                    <td>
                                        <Label
                                            title='City'
                                        />
                                        <PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.state?.value}?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "name",
                                                    labelCityFunc
                                                )
                                            }
                                            placeHolder='Search'
                                            selectedValue={values.city}
                                            onChange={(v) => {
                                                setFieldValue(
                                                        "city",
                                                        v
                                                    );
                                            }}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>
                                        <Label
                                            title='Primary Contact Name'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter Primary Contact Name'
                                            name='contactName'
                                            className={`col-1-1 primary ${errors.contactName &&
                                                touched.contactName
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td>
                                        <Label
                                            title='Primary Contact Phone'
                                        />
                                        <PhoneNumberInput
                                            name="contactPhone"
                                            placeholder="Eg: 888-888-8888"
                                            error={errors.contactPhone && touched.contactPhone}
                                            value={values.contactPhone}
                                            setTouched={()=> setFieldTouched("contactPhone",true)}
                                            callback={(v)=>setFieldValue("contactPhone", v)}
                                        />
                                        <ErrorMessage
                                        name="contactPhone"
                                        component="p"
                                        className="error-messages"
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label
                                            title='Primary Contact Fax'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter Primary Contact Fax'
                                            name='contactFax'
                                            className={`col-1-1 primary ${errors.contactFax &&
                                                touched.contactFax
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Primary Contact Email' />
                                        <Field
                                            type='email'
                                            placeholder='Enter Primary Contact Email'
                                            name='contactEmail'
                                            className={`col-1-1 primary ${errors.contactEmail &&
                                                touched.contactEmail
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr> */}
                                <tr>

                                    <td width='50%'>
                                        <Label title='GPO' />
                                        <Field
                                            type='text'
                                            placeholder='Enter GPO'
                                            name='gpo'
                                            className={`col-1-1 primary ${errors.gpo &&
                                                touched.gpo
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='IDN' />
                                        <Field
                                            type='text'
                                            placeholder='Enter IDN'
                                            name='idn'
                                            className={`col-1-1 primary ${errors.idn &&
                                                touched.idn
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>

                                    <td width='50%'>
                                        <Label title='Facility NPI' required={true} />
                                        <Field
                                            type='text'
                                            placeholder='Enter Facility NPI'
                                            name='npi'
                                            className={`col-1-1 primary ${errors.npi &&
                                                touched.npi
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Taxonomy Id' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Taxonomy Id'
                                            name='taxonomy'
                                            className={`col-1-1 primary ${errors.taxonomy &&
                                                touched.taxonomy
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Sales Tax Percent' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Sales Tax Percent'
                                            name='taxPercent'
                                            className={`col-1-1 primary ${errors.taxPercent &&
                                                touched.taxPercent
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Case Capacity' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Case Capacity'
                                            name='caseCapacity'
                                            className={`col-1-1 primary ${errors.caseCapacity &&
                                                touched.caseCapacity
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Day Confirmation Email Address' />
                                        <Field
                                            type='email'
                                            placeholder='Enter Confirmation Email'
                                            name='dayConfirmEmail'
                                            className={`col-1-1 primary ${errors.dayConfirmEmail &&
                                                touched.dayConfirmEmail
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    <td width='50%'>
                                        <Label title='Facility Type' />
                                        <Field
                                            as='select'
                                            name='facilityType'
                                            className={`col-1-1 primary ${errors.facilityType &&
                                                touched.facilityType
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {FACILITY_TYPE_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Financial Clearance' />
                                        <Field
                                            as='select'
                                            name='financialClearanceFacility'
                                            className={`col-1-1 primary ${errors.financialClearanceFacility &&
                                                touched.financialClearanceFacility
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {CLEARANCE_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                    <td width='50%'>
                                        <Label title='EMR Access' />
                                        <Field
                                            as='select'
                                            name='facilityEmrAccess'
                                            className={`col-1-1 primary ${errors.facilityEmrAccess &&
                                                touched.facilityEmrAccess
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {EMR_ACCESS_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                </tr>
                                <tr>
                                    <td width='50%'>
                                        <Label title='Place Of Service' required={true} />
                                        <Field
                                            as='select'
                                            name='facilityPlaceOfService'
                                            className={`col-1-1 primary ${errors.facilityPlaceOfService &&
                                                touched.facilityPlaceOfService
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {PLACE_OF_SERVICE.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                    <td>
                                        <Label
                                            title='Fax'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter Primary Contact Fax'
                                            name='contactFax'
                                            className={`col-1-1 primary ${errors.contactFax &&
                                                touched.contactFax
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                    {/* <td width='50%'>
                                        <Label title='Adition Contact(s)' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Adition Contact(s)'
                                            name='facilityAdditionalContact'
                                            className={`col-1-1 primary ${errors.facilityAdditionalContact &&
                                                touched.facilityAdditionalContact
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td> */}
                                </tr>
                                <tr>

                                    <td width='50%'>
                                        <Label title='Invoice Procedure CPT Requirements' />
                                        <Field
                                            as='select'
                                            name='invoiceReq'
                                            className={`col-1-1 primary ${errors.invoiceReq &&
                                                touched.invoiceReq
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {FACILITY_INVOICE_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>
                                    <td width='50%'>
                                        <Label title='Internal Identifier' />
                                        <Field
                                            type='text'
                                            placeholder='Enter Internal Identifier'
                                            name='internalIdentifier'
                                            className={`col-1-1 primary ${errors.internalIdentifier &&
                                                touched.internalIdentifier
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td>
                                        <Label
                                            title='Fax'
                                        />
                                        <Field
                                            type='text'
                                            placeholder='Enter Primary Contact Fax'
                                            name='contactFax'
                                            className={`col-1-1 primary ${errors.contactFax &&
                                                touched.contactFax
                                                ? "error"
                                                : ""
                                                }`}
                                        />
                                    </td>
                                </tr> */}
                                <tr>
                                    <td colSpan="2">
                                        <NestedPersonalDetailForm
                                            onAdd={(newProcedure) => {
                                                setFieldValue('additionalContacts', [
                                                    ...values.additionalContacts,
                                                    newProcedure,
                                                ]);
                                            }}
                                        />

                                    </td>
                                </tr>
                                {values.additionalContacts && (
                                    <tr>
                                        <td colSpan="2" >
                                            <div className="contect-date">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Title</th>
                                                            <th>Name</th>
                                                            <th>Email</th>
                                                            <th>Phone</th>
                                                            <th style={{ textAlign: 'center' }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                         {values.additionalContacts.map((contact, index) => (
                                                            <tr>
                                                                <td><span>{contact?.contactTitle || "-"}</span></td>
                                                                <td><span>{contact?.contactName || "-"}</span></td>
                                                                <td><span>{contact?.contactEmail || "-"}</span></td>
                                                                <td><span>{contact.contactPhone ? formatInputPhoneNumber(contact.contactPhone.trim()) : "-"}</span></td>

                                                                <td>
                                                                    <div className="flex center-center">
                                                                        <span className="cursor-pointer"
                                                                            onClick={() => {
                                                                                const updatedConfiguration = values.additionalContacts.filter((_, i) => i !== index);
                                                                                setFieldValue("additionalContacts", updatedConfiguration);
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                height="15px"
                                                                                width="15px"
                                                                                viewBox="0 0 330 330"
                                                                                fill="red"
                                                                            >
                                                                                <g id="XMLID_6_">
                                                                                    <g id="XMLID_11_">
                                                                                        <path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
                                                                                    </g>
                                                                                    <g id="XMLID_18_">
                                                                                        <path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
                                                                                    </g>
                                                                                    <g id="XMLID_23_">
                                                                                        <path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </td>
                                    </tr>
                                )}

                            </tbody>
                        </table>
                    </div>

                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => {
                                if (nextUrl) {   
                                    history.push(nextUrl); 
                                } else { 
                                  history.push("/facilities");
                                }
                                }}
                            icon={
                                <svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
                            }
                        />
                        <ButtonTextIcon
                            type='submit'
                            disabled={loading}
                            title='Update Now'
                            onClick={async () => {
                                const errors = await validateForm(); 
                                if (Object.keys(errors).length > 0) {
                                  const firstErrorMessage = Object.values(errors)[0];
                                  addToastMessage({
                                    status: false,
                                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                                  });
                                }
                                handleSubmit(); 
                              }}
                            className='mar-l-a'
                            icon={
                                loading ? (
                                    <LoaderIcon />
                                ) : (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='19.995'
                                        height='19.996'
                                        viewBox='0 0 19.995 19.996'>
                                        <path
                                            d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                                            transform='translate(-2.014 -1.995)'
                                        />
                                    </svg>
                                )
                            }
                        />
                    </div>
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

const NestedPersonalDetailForm = ({ onAdd }) => {
    
    const Label = ({ title, required }) => {
        return (
            <p
                className='color-primary f-w-600'
                style={{ paddingBottom: "5px" }}>
                {title}
                {required && (
                    <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                )}
            </p>
        );
    };

    const validationSchema = Yup.object().shape({
        contactName: Yup.string().required("Person Name Required"),
        contactPhone: Yup.string()
            .matches(/^\d+$/, "Phone Number must contain only numbers")
            .max(15, "Phone Number must be at most 15 digits")
            .required("Phone Number is required"),
        contactEmail: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        contactTitle: Yup.string().required("Title is Required"),
    });

    return (
        <Formik
            initialValues={{
                contactName: "",
                contactEmail: "",
                contactPhone: "",
                contactTitle: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
                try {
                    onAdd(values);
                    resetForm();
                } catch (error) {
                    console.error("Error during form submission:", error);
                }
            }}
        >
            {({ errors, touched, values, setFieldTouched, setFieldValue }) => (
                <Form>
                    <table>
                        <tbody>
                            <tr>
                            <td>
                                    <Label
                                        title='Primary Contact Title'
                                    />
                                    <Field
                                        type='text'
                                        placeholder='Enter Primary Contact Title'
                                        name='contactTitle'
                                        className={`col-1-1 primary ${errors.contactTitle &&
                                            touched.contactTitle
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                </td>
                                <td>
                                    <Label
                                        title='Primary Contact Name'
                                    />
                                    <Field
                                        type='text'
                                        placeholder='Enter Primary Contact Name'
                                        name='contactName'
                                        className={`col-1-1 primary ${errors.contactName &&
                                            touched.contactName
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                </td>
                            </tr>
                            <tr>

                                <td width='50%'>
                                    <Label title='Primary Contact Email' />
                                    <Field
                                        type='email'
                                        placeholder='Enter Primary Contact Email'
                                        name='contactEmail'
                                        className={`col-1-1 primary ${errors.contactEmail &&
                                            touched.contactEmail
                                            ? "error"
                                            : ""
                                            }`}
                                    />
                                </td>
                                <td>
                                    <Label
                                        title='Primary Contact Phone'
                                    />
                                    <PhoneNumberInput
                                        name="contactPhone"
                                        placeholder="Eg: 888-888-8888"
                                        error={errors.contactPhone && touched.contactPhone}
                                        value={values.contactPhone}
                                        setTouched={() => setFieldTouched("contactPhone", true)}
                                        callback={(v) => setFieldValue("contactPhone", v)}
                                    />
                                    <ErrorMessage
                                        name="contactPhone"
                                        component="p"
                                        className="error-messages"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={6}>
                                    <div className="flex bottom-right">
                                        <div>
                                            {/* <Label title="&nbsp;" /> */}
                                            <ButtonLightTextIcon
                                                title="Add Contact Details"
                                                type="submit"
                                                icon={
                                                    <svg
                                                        fill="#000000"
                                                        version="1.1"
                                                        width="800px"
                                                        height="800px"
                                                        viewBox="0 0 400 400"
                                                        xmlSpace="preserve"
                                                    >
                                                        <g>
                                                            <g>
                                                                <path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
                                                                <path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                }
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Form>
            )}
        </Formik>
    );
};

export default UpdateFacilityForm;
