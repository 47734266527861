import { useEffect, useState } from "react";
import { API_URL, LARAVEL_API_URL } from "../opl-utilities/constants";
import { withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import VideoURL from "../static/video.mp4";
import LogoImg from "../static/logo-full.png";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";

const LoginAzure = ({ history, location }) => {
	const [loading, setLoading] = useState(false);
	const [status, setStatus] = useState("");
	const [openDirectLogin, setOpenDirectLogin] = useState(false);
	const [inputVal, setInputVal] = useState("");

	const handleLogin = async () => {
		setLoading(true);
		setStatus("");
		try {
			const resp = await fetch(
				API_URL + `/api/1.0.0/login/client/azure`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						redirectURL: window.location.origin + "/auth/callback",
						state: "web",
					}),
				}
			);
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
					localStorage.clear();
					window.location.href = "/login";
				} else if (resp.status === 403) {
					setStatus("Access denied. You do not have permission to perform this action.");
					setLoading(false);
				}
				return false; 
			}
			if (resp.ok) {
				const response = await resp.json();
				if (response) {
					window.location.href = response.data;
				} else {
					setStatus("No response from server, please try again.");
				}
			} else {
				setStatus("Something went wrong please try again.");
			}
		} catch (e) {
			console.log("Error occurred", e);
		} finally {
			setLoading(false);
		}
	};

	const getClientName = () => {
		let url = window.location.href;
		url = url.split(".");

		let firstPart = url[0].replace("http://", "").replace("https://", "");
		if (
			firstPart.indexOf("/") < 0 &&
			firstPart !== "usp" &&
			firstPart !== "staging" &&
			firstPart !== "dev" &&
			firstPart !== "web"
		) {
			return firstPart;
		} else {
			if (firstPart === "usp") {
				return "Prod Master";
			} else if (firstPart === "staging") {
				return "Stage Master";
			} else if (firstPart === "dev") {
				return "Dev Master";
			} else if (firstPart === "web") {
				return "Web Master";
			} else {
				return firstPart;
			}
		}
	};

	useEffect(() => {
		localStorage.clear();
	}, []);

	function isValidEmail(email) {
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	}

	const submitDirectIP = async () => {
		if (inputVal !== "" && isValidEmail(inputVal)) {
			setLoading(true);
			try {
				const resp = await fetch(LARAVEL_API_URL + `login`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						email: inputVal,
						api_type: "react",
					}),
				});
				if (resp.status === 401 || resp.status === 403) {
					if (resp.status === 401) {
						localStorage.clear();
						window.location.href = "/login";
					} else if (resp.status === 403) {
						setStatus("Access denied. You do not have permission to perform this action.");
						setLoading(false);
					}
					return false; 
				}
				if (resp.ok) {
					const response = await resp.json();
					if (response && response.success) {
						localStorage.setItem("user", response.data.email);
						localStorage.setItem(
							"accessToken",
							response.data.token
						);
						localStorage.setItem(
							"LOGGED_IN_USER",
							JSON.stringify(response.data)
						);
						localStorage.setItem("LOGGED_IN_TIME", new Date());
						history.push("/scheduling");
					}
				}
			} catch (e) {
				console.log("Error", e);
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<section id='opl_coremax'>
			<section id='login-v2' className='flex center-center'>
				<video
					className='bg-video'
					playsInline={true}
					controls={false}
					autoPlay={true}
					muted={true}
					loop={true}>
					<source src={VideoURL} type='video/mp4' />
				</video>
				<div>
					<div className='form'>
						<img className='logo' src={LogoImg} alt='coremax' />
						<h5
							className='color-primary f-w-700 t-a-c'
							style={{
								marginBottom: "10px",
							}}>
							Login to your account.
						</h5>
						<p className='color-light'>
							You will be redirected to microsoft online portal.
							Enter the email associated with CoreMax to confirm
							your login.
						</p>
						<hr />
						<div>
							<ButtonTextIcon
								onClick={() => handleLogin()}
								title='Click to login'
								style={{
									width: "100%",
									height: "52px",
								}}
								disabled={loading}
								icon={
									<>
										{loading ? (
											<LoaderIcon />
										) : (
											<svg
												xmlns='http://www.w3.org/2000/svg'
												width='19.995'
												height='19.996'
												viewBox='0 0 19.995 19.996'>
												<path
													d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
													transform='translate(-2.014 -1.995)'
												/>
											</svg>
										)}
									</>
								}
							/>
						</div>
						{status && (
							<>
								<p
									className='color-secondary l-h-1-2'
									style={{ paddingTop: "15px" }}>
									{status}
								</p>
							</>
						)}
						{getClientName() !== "Prod Master" &&
							openDirectLogin && (
								<form
									onSubmit={(e) => {
										e.preventDefault();
										submitDirectIP();
									}}>
									<div
										className='flex center-left'
										style={{ paddingTop: "12px" }}>
										<input
											style={{
												flex: "1",
												fontSize: "12px",
												border: "solid 1px #f0f0f0",
												lineHeight: "36px",
												height: "36px",
												padding: "0 12px",
												fontFamily:
													'"Inter", sans-serif',
											}}
											value={inputVal}
											placeholder='Enter Email'
											onChange={(e) => {
												setInputVal(e.target.value);
											}}
										/>
										<ButtonTextIcon
											type='submit'
											disabled={loading}
											style={{
												borderRadius: "0",
												padding: "0 0 0 12px",
											}}
											icon={
												<svg
													xmlns='http://www.w3.org/2000/svg'
													width='24'
													height='24'
													viewBox='0 0 24 24'>
													<path d='M12.451 17.337l-2.451 2.663h-2v2h-2v2h-6v-5l6.865-6.949c1.08 2.424 3.095 4.336 5.586 5.286zm11.549-9.337c0 4.418-3.582 8-8 8s-8-3.582-8-8 3.582-8 8-8 8 3.582 8 8zm-3-3c0-1.104-.896-2-2-2s-2 .896-2 2 .896 2 2 2 2-.896 2-2z' />
												</svg>
											}
										/>
									</div>
								</form>
							)}
					</div>
					<p
						className='t-a-c color-white'
						style={{
							position: "relative",
							zIndex: 3,
							paddingTop: "12px",
							fontSize: "11px",
						}}>
						<span style={{ opacity: 0.4 }}>Client: </span>
						<span>{getClientName()}</span>
					</p>
					{getClientName() !== "Prod Master" && (
						<p
							className='color-white t-a-c'
							style={{
								position: "relative",
								zIndex: 3,
								paddingTop: "0px",
								fontSize: "11px",
							}}
							onClick={() =>
								setOpenDirectLogin(!openDirectLogin)
							}>
							<span className='cursor-pointer'>
								{`${
									openDirectLogin ? "Close" : "Open"
								} Direct Login`}
							</span>
						</p>
					)}
				</div>
			</section>
		</section>
	);
};

export default withRouter(LoginAzure);
