import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import CustomModal from "../components/common/CustomModal";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import FullPageLoader from "../components/common/FullPageLoader";
import Header from "../components/common/ui/Header";
import { utilityService } from "../javascript/services/utility";
import FileInput from "../components/common/ui/FileInput";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import OPLTabs from "../components/common/OPLTabs";
import MultiSelectDropdown from "../components/common/ui/MultiSelectDropdown";
import { withRouter } from "react-router-dom";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import PhoneNumberInput from "../components/common/ui/PhoneNumberInput";
import { formatInputPhoneNumber } from "../opl-utilities/helpers";
const Subscribers = ({ history, location, activePage }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState("");
	const [data, setData] = useState("");
	const [groupOptions, setGroupOptions] = useState([]);
	const [userAccessOptions, setUserAccessOptions] = useState([]);

	const callApi = async () => {
		try {
			setLoading(true);
			const api = await fetch(API_URL + "api/1.0.0/subscriber", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (api.status === 401 || api.status === 403) {
				if (api.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (api.status === 403) {
				}
				return false;
			  }
			const response = await api.json();
			if (response) {
				setData(response.data);
			} else {
				setError("Something happened, shoot!");
			}
		} catch (e) {
			console.log("Error", e);
		} finally {
			setLoading(false);
		}
	};

	const fetchUserAccessOptions = async () => {
		try {
			const api = await fetch(
				API_URL + "api/1.0.0/subscriber/user-subscription-config",
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (api.status === 401 || api.status === 403) {
				if (api.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (api.status === 403) {
				}
				return false;
			  }
			const response = await api.json();
			if (api.ok && response.result.responseCode === 200) {
				let groupOptions = response.data.map((o) => ({
					value: o.subscriptionAmount,
					label: o.noOfUsers,
				}));
				setUserAccessOptions(groupOptions);
			} else {
				setUserAccessOptions([]);
			}
		} catch (e) {
			console.log("Error", e);
			setUserAccessOptions([]);
		}
	};

	const fetchGroupOptions = async () => {
		try {
			const api = await fetch(API_URL + "api/1.0.0/groups", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
			});
			if (api.status === 401 || api.status === 403) {
				if (api.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (api.status === 403) {
				}
				return false;
			  }
			const response = await api.json();
			if (api.ok && response.result.responseCode === 200) {
				let groupOptions = response.data
					.filter((g) => g.id !== 5 && g.id !== 6)
					.map((g) => ({
						value: g.id,
						label: g.groupName,
						subscriptionAmount: g.subscriptionAmount,
					}));
				setGroupOptions(groupOptions);
			} else {
				setGroupOptions([]);
			}
		} catch (e) {
			console.log("Error", e);
			setGroupOptions([]);
		}
	};

	const verifyTransaction = async () => {
		setLoading(true);
		try {
			const resp = await fetch(
				API_URL +
					"api/1.0.0/transaction/" +
					localStorage.getItem("transactionId") +
					"/check",
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
				}
			);
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (resp.status === 403) {
					utilityService.showHTMLMessage(
						"Access denied",
						"You do not have permission to perform this action.",
						"error"
					);
					setLoading(false);
				}
				return false;
			  }
			if (resp.ok) {
				const response = await resp.json();
				if (response.result.responseCode == 0) {
					utilityService.showHTMLMessage(
						"Payment Successful",
						"Your payment was processed successfully. Thank you for your purchase!",
						"success"
					);
					localStorage.removeItem("transactionId");
				} else {
					utilityService.showHTMLMessage(
						"Payment Failed",
						"Unfortunately, your payment could not be processed. Please try again or contact support if the issue persists.",
						"error"
					);
					localStorage.removeItem("transactionId");
				}
			}
		} catch (e) {
			utilityService.showHTMLMessage(
				"Payment Failed",
				"Unfortunately, your payment could not be processed. Please try again or contact support if the issue persists.",
				"error"
			);
			localStorage.removeItem("transactionId");
		} finally {
			setLoading(false);
			localStorage.removeItem("transactionId");
		}
	};

	useEffect(() => {
		callApi();
		fetchGroupOptions();
		fetchUserAccessOptions();
		if (localStorage.getItem("transactionId")) {
			verifyTransaction();
		} else {
			localStorage.removeItem("transactionId");
		}
	}, []);

	return (
		<section id='opl_coremax'>
			<section className='main-section'>
				<Header active={activePage} location={location} />
				<section id='octopi-coremax-ui'>
					<div
						className='flex center-left'
						style={{ padding: "20px" }}>
						<div style={{ marginLeft: "auto" }}>
							<CustomModal
								selector={
									<ButtonTextIcon
										title='Add '
										icon={
											<svg viewBox='0 0 24 24'>
												<path
													d='M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Zm4-9H13V8a1,1,0,0,0-2,0v3H8a1,1,0,0,0,0,2h3v3a1,1,0,0,0,2,0V13h3a1,1,0,0,0,0-2Z'
													fill='#6563ff'
												/>
											</svg>
										}
									/>
								}
								content={
									<AddSubscriberModal
										groupOptions={groupOptions}
										userAccessOptions={userAccessOptions}
										loading={loading}
										setLoading={setLoading}
									/>
								}
							/>
						</div>
					</div>
					<FullPageLoader isActive={loading} />
					{error && (
						<div>
							<h1>Error</h1>
						</div>
					)}
					{data && (
						<div className='rs-table-holder'>
							<table className='rs-table'>
								<thead>
									<tr>
										<th>Fullname</th>
										<th>Phone No.</th>
										<th>Email</th>
										<th>Company</th>
										<th>Subscription [Start - End]</th>
										<th>Total Users</th>
									</tr>
								</thead>
								<tbody>
									{data.map((d, i) => (
										<tr key={i}>
											<td>
												{" "}
												<div
													className='flex center-left'
													style={{
														gap: "8px",
													}}>
													<div
														className='b-r-100 of-hid default-image '
														style={{
															height: "32px",
															width: "32px",
															backgroundImage:
																d.companyLogoUrl
																	? `url(${d.companyLogoUrl})`
																	: null,
														}}></div>
													&nbsp;
													<p className='f-w-600 color-primary'>
														{" "}
														{d.firstName}{" "}
														{d.lastName}
													</p>
												</div>
											</td>
											<td>{d.phoneNumber ? formatInputPhoneNumber(d.phoneNumber.trim()) : "-"}</td>

											<td>{d.email}</td>
											<td>
												<div>
													<p>{d.companyName}</p>
													<p>
														{d.companyDescription}
													</p>
												</div>
											</td>
											<td>
												{d.startDate} - {d.endDate}
											</td>
											<td>{d.totalUsers}</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					)}
				</section>
			</section>
		</section>
	);
};

const mapDispatchToProps = (dispatch) => {
	return {
		someAction: () => dispatch(),
	};
};

export default withRouter(Subscribers);

// ========= CONST

const SUBSCRIPTION_PLANS = [
	{ label: "1 month", value: 1 },
	{ label: "2 month", value: 2 },
	{ label: "3 months", value: 3 },
	{ label: "4 months", value: 4 },
	{ label: "6 months", value: 6 },
	{ label: "12 months", value: 12 },
];

function calculateEndDate(startDate, durationInMonths) {
	const start = new Date(startDate);
	const end = new Date(start);
	end.setMonth(start.getMonth() + parseInt(durationInMonths));
	if (end.getMonth() > 11) {
		const overflowMonths = end.getMonth() % 12;
		const additionalYears = Math.floor(end.getMonth() / 12);
		end.setFullYear(end.getFullYear() + additionalYears, overflowMonths);
	}
	if (end.getDate() !== start.getDate()) {
		end.setDate(0);
	}
	const year = end.getFullYear();
	const month = String(end.getMonth() + 1).padStart(2, "0");
	const day = String(end.getDate()).padStart(2, "0");
	return `${year}-${month}-${day}`;
}

const AddSubscriberModal = ({
	setShow,
	groupOptions,
	activeStep = 1,
	userAccessOptions,
	loading,
	setLoading,
}) => {
	const [step, setStep] = useState(activeStep);
	function getUserAccessValueFromLabel(label) {
		const range = userAccessOptions.find((item) => item.label == label);
		return range ? range.value : 0;
	}
	const validationSchemaOne = Yup.object({
		companyName: Yup.string().required("Company name is required"),
		companyDescription: Yup.string().required(
			"Company description is required"
		),
		firstName: Yup.string().required("First name is required"),
		lastName: Yup.string().required("Last name is required"),
		phoneNumber: Yup.string()
			.matches(
				/^\d{10}$/,
				"Phone number must be exactly 10 digits and contain only numbers."
			)
			.required("Phone number is required!")
			.min(10, "Phone number must be exactly 10 digits.")
			.max(10, "Phone number must be exactly 10 digits."),
		email: Yup.string()
			.email("Invalid email address")
			.required("Email is required"),
		password: Yup.string()
			.min(8, "Password must be at least 8 characters")
			.required("Password is required"),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref("password"), null], "Passwords must match")
			.required("Confirm Password is required"),
	});

	const validationSchemaTwo = Yup.object({
		groupIds: Yup.array()
			.min(1, "At least one group must be selected")
			.required("Group selection is required"),
	});

	const validationSchemaThree = Yup.object({
		startDate: Yup.date().required("Start date is required"), //.min(new Date(), 'Start date must be today or in the future'),
		totalUsers: Yup.string().required("No. of users is required"),
		subscriptionPlan: Yup.string().required(
			"Subscription plan is required"
		),
	});

	const handleSubmit = async (values) => {
		if (step === 3) {
			setLoading(true);
			try {
				const groupIds = values.groupIds.map((group) => group.value);
				const endDate = calculateEndDate(
					values.startDate,
					values.subscriptionPlan
				);
				let body = {
					firstName: values.firstName,
					lastName: values.lastName,
					phoneNumber: values.phoneNumber,
					email: values.email,
					companyName: values.companyName,
					companyDescription: values.companyDescription,
					startDate: values.startDate,
					endDate: endDate,
					totalUsers: values.totalUsers,
					password: values.password,
					groupIds: groupIds,
					companyLogoUrl: companyLogoUploadedURL,
				};
				const resp = await fetch(API_URL + "api/1.0.0/subscriber", {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": localStorage.getItem("token"),
					},
					body: JSON.stringify(body),
				});
				if (resp.status === 401 || resp.status === 403) {
					if (resp.status === 401) {
					  localStorage.clear();
					  window.location.href = "/login";
					} else if (resp.status === 403) {
						utilityService.showHTMLMessage(
							"Access denied",
							"You do not have permission to perform this action.",
							"error"
						);
						setLoading(false);
					}
					return false;
				  }
				if (resp.ok) {
					const response = await resp.json();
					if (response.result.responseCode === 200) {
						utilityService.showHTMLMessage(
							"Subscriber Created Successfully",
							"The subscriber has been created successfully. Thank you for using our service.",
							"success"
						);
						//Payment
						const payAmount = (
							(values.groupIds
								.filter(
									(group) => group.subscriptionAmount !== null
								)
								.reduce(
									(sum, group) =>
										sum + group.subscriptionAmount,
									0
								) +
								getUserAccessValueFromLabel(
									values.totalUsers
								)) *
							values.subscriptionPlan
						).toFixed(2);
						initiatePaymentFlow(response.data, payAmount);
					} else {
						utilityService.showHTMLMessage(
							"Creation Failed",
							"An error occurred while creating the subscriber. Please review the details and try again.",
							"error"
						);
						setLoading(false);
					}
				} else {
					utilityService.showHTMLMessage(
						"Network or Server Error",
						"There was an issue communicating with the server. Please check your connection and try again.",
						"error"
					);
					setLoading(false);
				}
			} catch (e) {
				utilityService.showHTMLMessage(
					"Network or Server Error",
					"There was an issue communicating with the server. Please check your connection and try again.",
					"error"
				);
				setLoading(false);
			}
		} else {
			setStep(step + 1);
		}
	};

	const initiatePaymentFlow = async (data, amount) => {
		setLoading(true);
		try {
			let url = window.location.href;
			let baseUrl = url.split(window.location.pathname)[0];
			const response = await fetch(API_URL + "api/1.0.0/transaction", {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					"X-JWT-Assertion": localStorage.getItem("token"),
				},
				body: JSON.stringify({
					amount: amount * 100, //10 USD
					currencyCode: "USD",
					invoiceNumber: data.id,
					comment1: "Subscription Payment.",
					address1: `${data.companyName} - ${data.companyDescription}`,
					city: "Bangalore",
					country: "India",
					name: `${data.firstName} ${data.lastName}`,
					state: "KA",
					zipCode: "560100",
					returnURL: `${baseUrl}/subscribers`,
				}),
				redirect: "follow",
			});
			if (response.status === 401 || response.status === 403) {
				if (response.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (response.status === 403) {
				}
				return false;
			  }
			if (response.ok) {
				const data = await response.json();
				if (data) {
					localStorage.setItem("transactionId", data.transactionId);
					window.location.href = data.redirectURL;
				} else {
					utilityService.showHTMLMessage(
						"Error",
						"Something went wrong while taking you to payment gateway.",
						"error"
					);
				}
			} else {
				const errorData = await response.json();
				const errorMessage = errorData.responseDescription;
				utilityService.showHTMLMessage(
					"Error",
					errorMessage ||
						"Something went wrong while taking you to payment gateway.",
					"error"
				);
			}
		} catch (e) {
			utilityService.showHTMLMessage(
				"Error",
				"Something went wrong while taking you to payment gateway.",
				"error"
			);
			setLoading(false);
		}
	};

	const FieldTitle = ({ title }) => {
		return (
			<p
				className='title f-w-700 color-primary'
				style={{ fontSize: "14px" }}>
				{title}
			</p>
		);
	};

	const [apiLoading, setApiLoading] = useState(false);
	const [companyLogoFile, setCompanyLogoFile] = useState(null);
	const [companyLogoUploadedURL, setCompanyLogoUploadedURL] = useState("");

	const handleCompanyLogoUpload = async () => {
		setApiLoading(true);
		try {
			const formdata = new FormData();
			formdata.append("image", companyLogoFile, companyLogoFile.name);
			const resp = await fetch(API_URL + `image-upload`, {
				method: "POST",
				headers: {
					Authorization:
						"Bearer " + localStorage.getItem("accessToken"),
				},
				body: formdata,
				redirect: "follow",
			});
			if (resp.status === 401 || resp.status === 403) {
				if (resp.status === 401) {
				  localStorage.clear();
				  window.location.href = "/login";
				} else if (resp.status === 403) {
					utilityService.showHTMLMessage(
						"Access denied",
						"You do not have permission to perform this action.",
						"error"
					);
					setApiLoading(false);
				}
				return false;
			  }
			if (resp.ok) {
				const responseData = await resp.json();
				if (responseData.success === true) {
					setCompanyLogoUploadedURL(responseData.url);
					console.log(responseData.url);
					utilityService.showHTMLMessage(
						"Success",
						"Company logo successfully uploaded.",
						"success"
					);
				}
			} else {
				const errorData = await resp.json();
				const errorMessage = errorData.responseDescription;
				utilityService.showHTMLMessage(
					"Error",
					errorMessage ||
						"Something went wrong while uploading company logo. Please try again.",
					"error"
				);
			}
		} catch (e) {
			console.log("Error", e);
			utilityService.showHTMLMessage(
				"Error",
				"Could not upload the company logo at the moment.",
				"error"
			);
		} finally {
			setApiLoading(false);
		}
	};

	return (
		<div
			style={{
				height: "auto",
				width: "646px",
				padding: "40px",
			}}>
			<h4 className='color-primary'>Add Subscriber</h4>
			<br />

			<Formik
				initialValues={{
					companyName: "",
					companyDescription: "",
					firstName: "",
					lastName: "",
					phoneNumber: "",
					email: "",
					password: "",
					confirmPassword: "",
					groupIds: [
						{
							value: 13,
							label: "Admin/Management",
							subscriptionAmount: null,
						},
					],
					startDate: "",
					totalUsers: "",
					subscriptionPlan: "",
				}}
				validationSchema={
					step === 1
						? validationSchemaOne
						: step === 2
						? validationSchemaTwo
						: validationSchemaThree
				}
				onSubmit={handleSubmit}>
				{({ setFieldValue, values, errors, touched ,setFieldTouched}) => (
					<Form>
						<OPLTabs
							activeStep={"tab-" + step}
							disableTabAction={true}
							options={[
								{
									title: (
										<span
											onClick={() => {
												setStep(step);
											}}>
											Basic Details
										</span>
									),
									id: "tab-1",
									content: (
										<div
											style={{
												height: "410px",
												padding: "10px",
											}}>
											<div
												className='flex center-center'
												style={{ gap: "10px" }}>
												<div
													style={{
														height: "100px",
														width: "100%",
													}}>
													<FieldTitle title='Company Name:' />
													<Field
														type='text'
														name='companyName'
														placeholder='e.g., Acme Corp'
														className={
															"col-1-1 " +
															(errors.companyName &&
															touched.companyName
																? " error"
																: "")
														}
													/>
													<ErrorMessage
														name='companyName'
														component='p'
														className='error-messages'
													/>
												</div>
												<div
													style={{
														height: "100px",
														width: "100%",
													}}>
													<FieldTitle title='Company Description:' />
													<Field
														type='text'
														name='companyDescription'
														placeholder='e.g., We build amazing things'
														className={
															"col-1-1 " +
															(errors.companyDescription &&
															touched.companyDescription
																? " error"
																: "")
														}
													/>
													<ErrorMessage
														name='companyDescription'
														component='p'
														className='error-messages'
													/>
												</div>
											</div>
											<div
												className='flex center-center'
												style={{ gap: "10px" }}>
												<div
													style={{
														height: "100px",
														width: "100%",
													}}>
													<FieldTitle title='Company Logo:' />
													<div
														className='flex center-left'
														style={{
															gap: "12px",
														}}>
														<div
															className='default-image b-r-100 of-hid'
															style={{
																height: "40px",
																width: "40px",
																border: "solid 1px #f0f0f0",
																backgroundImage:
																	companyLogoUploadedURL
																		? `url(${companyLogoUploadedURL})`
																		: null,
															}}></div>
														<div
															className='flex center-center'
															style={{
																flex: 1,
																gap: "4px",
															}}>
															<FileInput
																defaultText='Select profile photo'
																accept='image/jpeg, image/jpg, image/png'
																maxSizeAllowed={
																	2 *
																	1024 *
																	1024
																}
																callback={(
																	f
																) => {
																	setCompanyLogoFile(
																		f
																	);
																}}
															/>
															<ButtonLightTextIcon
																type='button'
																data-name={
																	companyLogoUploadedURL
																		? "verified"
																		: ""
																}
																title={
																	companyLogoUploadedURL
																		? "Uploaded"
																		: "Upload"
																}
																disabled={
																	apiLoading ||
																	!companyLogoFile
																}
																icon={
																	<>
																		{apiLoading ? (
																			<LoaderIcon />
																		) : (
																			<>
																				{" "}
																				{companyLogoUploadedURL ? (
																					<svg
																						xmlns='http://www.w3.org/2000/svg'
																						viewBox='0 0 24 24'
																						fill='none'
																						stroke='currentColor'
																						stroke-width='2'
																						stroke-linecap='round'
																						stroke-linejoin='round'
																						class='ci ci-circle-check'
																						width='24'
																						height='24'>
																						<circle
																							cx='12'
																							cy='12'
																							r='10'
																						/>
																						<path d='M9 12l2 2l4-4' />
																					</svg>
																				) : (
																					<svg
																						xmlns='http://www.w3.org/2000/svg'
																						viewBox='0 0 24 24'
																						fill='none'
																						stroke='currentColor'
																						stroke-width='2'
																						stroke-linecap='round'
																						stroke-linejoin='round'
																						class='ci ci-location-arrow'
																						width='24'
																						height='24'>
																						<path d='M12 2l7 20-7-5-7 5z' />
																					</svg>
																				)}{" "}
																			</>
																		)}
																	</>
																}
																onClick={
																	handleCompanyLogoUpload
																}
															/>
														</div>
													</div>
												</div>
											</div>

											<div
												className='flex center-center'
												style={{ gap: "10px" }}>
												<div
													style={{ height: "100px" }}>
													<FieldTitle title='First Name:' />
													<Field
														type='text'
														name='firstName'
														placeholder='e.g., John'
														className={
															"col-1-1 " +
															(errors.firstName &&
															touched.firstName
																? " error"
																: "")
														}
													/>
													<ErrorMessage
														name='firstName'
														component='p'
														className='error-messages'
													/>
												</div>
												<div
													style={{ height: "100px" }}>
													<FieldTitle title='Last Name:' />
													<Field
														type='text'
														name='lastName'
														placeholder='e.g., Doe'
														className={
															"col-1-1 " +
															(errors.lastName &&
															touched.lastName
																? " error"
																: "")
														}
													/>
													<ErrorMessage
														name='lastName'
														component='p'
														className='error-messages'
													/>
												</div>
												<div
													style={{ height: "100px" }}>
													<FieldTitle title='Phone Number:' />
													<PhoneNumberInput
														name="phoneNumber"
														placeholder="Eg: 888-888-8888"
														error={errors.phoneNumber && touched.phoneNumber}
														value={values.phoneNumber}
														setTouched={()=> setFieldTouched("phoneNumber",true)}
														callback={(v)=>setFieldValue("phoneNumber", v)}
													/>
													<ErrorMessage
														name="phoneNumber"
														component="p"
														className="error-messages"
													/>
												</div>
											</div>
											<div
												className='flex center-center'
												style={{ gap: "10px" }}>
												<div
													style={{ height: "100px" }}>
													<FieldTitle title='Email:' />
													<Field
														type='email'
														name='email'
														placeholder='e.g., example@mail.com'
														className={
															"col-1-1 " +
															(errors.email &&
															touched.email
																? " error"
																: "")
														}
													/>
													<ErrorMessage
														name='email'
														component='p'
														className='error-messages'
													/>
												</div>
												<div
													style={{ height: "100px" }}>
													<FieldTitle title='Password:' />
													<Field
														type='password'
														name='password'
														placeholder='Enter Password'
														className={
															"col-1-1 " +
															(errors.password &&
															touched.password
																? " error"
																: "")
														}
													/>
													<ErrorMessage
														name='password'
														component='p'
														className='error-messages'
													/>
												</div>
												<div
													style={{ height: "100px" }}>
													<FieldTitle title='Confirm Password:' />
													<Field
														type='password'
														name='confirmPassword'
														placeholder='Enter Confirm Password'
														className={
															"col-1-1 " +
															(errors.confirmPassword &&
															touched.confirmPassword
																? " error"
																: "")
														}
													/>
													<ErrorMessage
														name='confirmPassword'
														component='p'
														className='error-messages'
													/>
												</div>
											</div>
										</div>
									),
								},
								{
									title: (
										<span
											onClick={() => {
												setStep(step);
											}}>
											Group Models
										</span>
									),
									id: "tab-2",
									content: (
										<div>
											<div
												style={{
													height: "390px",
													padding: "10px",
												}}>
												<div
													style={{ height: "300px" }}>
													<div
														className='flex center-center'
														style={{ gap: "10px" }}>
														<div
															style={{
																width: "100%",
															}}>
															<FieldTitle title='Group IDs:' />
															<Field name='groupIds'>
																{({
																	field,
																}) => (
																	<MultiSelectDropdown
																		selectedOptions={
																			field.value
																		}
																		onChange={(
																			value
																		) =>
																			setFieldValue(
																				"groupIds",
																				value
																			)
																		}
																		options={
																			groupOptions
																		}
																		dropdownOpen={
																			true
																		}
																	/>
																)}
															</Field>
															<ErrorMessage
																name='groupIds'
																component='p'
																className='error-messages'
															/>
														</div>
													</div>
												</div>
											</div>
											<div
												style={{
													paddingLeft: "10px",
													paddingRight: "10px",
												}}>
												<div
													className='flex center-left'
													style={{ gap: "10px" }}>
													<div>
														<p>
															{" "}
															<b>
																Total License
																Module Amount
																(per month):{" "}
															</b>
														</p>
													</div>
													<div
														style={{
															marginLeft: "auto",
														}}>
														<p>
															{values.groupIds
																.filter(
																	(group) =>
																		group.subscriptionAmount !==
																		null
																)
																.reduce(
																	(
																		sum,
																		group
																	) =>
																		sum +
																		group.subscriptionAmount,
																	0
																)
																.toFixed(
																	2
																)}{" "}
															USD
														</p>
													</div>
												</div>
											</div>
										</div>
									),
								},
								{
									title: (
										<span
											onClick={() => {
												setStep(step);
											}}>
											License Details
										</span>
									),
									id: "tab-3",
									content: (
										<div
											style={{
												height: "410px",
												padding: "10px",
											}}>
											<div
												className='flex center-center'
												style={{ gap: "10px" }}>
												<div
													style={{
														width: "100%",
														height: "100px",
													}}>
													<FieldTitle title='Start Date:' />
													<Field
														type='date'
														name='startDate'
														className={
															"col-1-1 " +
															(errors.startDate &&
															touched.startDate
																? " error"
																: "")
														}
													/>
													<ErrorMessage
														name='startDate'
														component='p'
														className='error-messages'
													/>
												</div>
											</div>
											<div
												className='flex center-center'
												style={{ gap: "10px" }}>
												<div
													style={{
														width: "100%",
														height: "100px",
													}}>
													<FieldTitle title='Subscription Plans:' />
													<Field
														as='select'
														name='subscriptionPlan'
														className={
															"col-1-1 " +
															(errors.subscriptionPlan &&
															touched.subscriptionPlan
																? " error"
																: "")
														}>
														<option value=''>
															Select Subscription
															Plans
														</option>
														{SUBSCRIPTION_PLANS &&
															SUBSCRIPTION_PLANS.map(
																(a, i) => (
																	<option
																		value={
																			a.value
																		}
																		key={i}>
																		{
																			a.label
																		}
																	</option>
																)
															)}
													</Field>
													<ErrorMessage
														name='subscriptionPlan'
														component='p'
														className='error-messages'
													/>
												</div>
											</div>
											<div
												className='flex center-center'
												style={{ gap: "10px" }}>
												<div
													style={{
														width: "100%",
														height: "100px",
													}}>
													<FieldTitle title='No. Of Users:' />
													<Field
														as='select'
														name='totalUsers'
														className={
															"col-1-1 " +
															(errors.totalUsers &&
															touched.totalUsers
																? " error"
																: "")
														}>
														<option value=''>
															Select No. Of Users
														</option>
														{userAccessOptions &&
															userAccessOptions.map(
																(a, i) => (
																	<option
																		value={
																			a.label
																		}
																		key={i}>
																		{
																			a.label
																		}{" "}
																		- (
																		{a.value.toFixed(
																			2
																		)}{" "}
																		USD)
																	</option>
																)
															)}
													</Field>

													<ErrorMessage
														name='totalUsers'
														component='p'
														className='error-messages'
													/>
												</div>
											</div>
											<div
												className='flex center-left'
												style={{ gap: "10px" }}>
												<div>
													<p>
														{" "}
														<b>
															Total License Module
															Amount :{" "}
														</b>
													</p>
												</div>
												<div
													style={{
														marginLeft: "auto",
													}}>
													<p>
														{values.subscriptionPlan &&
														values.totalUsers
															? (
																	values.groupIds
																		.filter(
																			(
																				group
																			) =>
																				group.subscriptionAmount !==
																				null
																		)
																		.reduce(
																			(
																				sum,
																				group
																			) =>
																				sum +
																				group.subscriptionAmount,
																			0
																		) *
																	values.subscriptionPlan
															  ).toFixed(2)
															: 0.0}{" "}
														USD
													</p>
												</div>
											</div>
											<div
												className='flex center-left'
												style={{ gap: "10px" }}>
												<div>
													<p>
														{" "}
														<b>
															No Of User Access
															Amount :{" "}
														</b>
													</p>
												</div>
												<div
													style={{
														marginLeft: "auto",
													}}>
													<p>
														{values.subscriptionPlan &&
														values.totalUsers
															? (
																	getUserAccessValueFromLabel(
																		values.totalUsers
																	) *
																	values.subscriptionPlan
															  ).toFixed(2)
															: 0.0}{" "}
														USD
													</p>
												</div>
											</div>
											<hr />
											<div
												className='flex center-left'
												style={{ gap: "10px" }}>
												<div>
													<p>
														{" "}
														<b>Total : </b>
													</p>
												</div>
												<div
													style={{
														marginLeft: "auto",
													}}>
													<p>
														{values.subscriptionPlan &&
														values.totalUsers
															? (
																	(values.groupIds
																		.filter(
																			(
																				group
																			) =>
																				group.subscriptionAmount !==
																				null
																		)
																		.reduce(
																			(
																				sum,
																				group
																			) =>
																				sum +
																				group.subscriptionAmount,
																			0
																		) +
																		getUserAccessValueFromLabel(
																			values.totalUsers
																		)) *
																	values.subscriptionPlan
															  ).toFixed(2)
															: 0.0}{" "}
														USD
													</p>
												</div>
											</div>
										</div>
									),
								},
							]}
						/>
						<br />
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
								gap: "12px",
							}}>
							<ButtonLightTextIcon
								type='button'
								title={step === 1 ? "Close" : "Go back"}
								icon={
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='26.562'
										height='17.809'
										viewBox='0 0 26.562 17.809'>
										<path d='M0,8.88,10.686,0l1.937,2.293L6.453,7.4H26.562v3H6.454l6.166,5.108-1.934,2.3Z' />
									</svg>
								}
								onClick={() => {
									if (step === 1) {
										setShow(false);
									} else {
										setStep(step - 1);
									}
								}}
							/>
							{loading ? (
								<ButtonTextIcon
									type='button'
									title='Validating...'
									disabled={loading}
									icon={<LoaderIcon />}
								/>
							) : (
								<ButtonTextIcon
									type='submit'
									title={step === 3 ? "Submit" : "Next"}
									disabled={
										step !== 1
											? false
											: !companyLogoUploadedURL
									}
									icon={
										<svg
											xmlns='http://www.w3.org/2000/svg'
											width='24'
											height='24'
											fill='none'
											viewBox='0 0 24 24'
											stroke='currentColor'>
											<path d='M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zM12 22.5C6.201 22.5 1.5 17.799 1.5 12S6.201 1.5 12 1.5 22.5 6.201 22.5 12 17.799 22.5 12 22.5z' />
											<path d='M10.94 16.19a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 1 1 1.06-1.06l2.47 2.47 6.47-6.47a.75.75 0 1 1 1.06 1.06l-7 7z' />
										</svg>
									}
								/>
							)}
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};
