import { useEffect, useState } from "react";
import { formatRateUSD } from "../../opl-utilities/helpers";
import BillCard from "./BillCard";
import { API_URL } from "../../opl-utilities/constants";
import LoaderIcon from "../../components/common/ui/LoaderIcon";


export default function Invoice({ id, invoiceNo, facility, amount }) {
    
  const [bills, setBills] = useState(null);
  const [loading, setLoading] = useState(true); 

  const fetchData = async (id) => {
    setLoading(true); 
    setBills(null);
    try {
      const resp = await fetch(
        API_URL + `/api/1.0.0/bill?pageSize=1000&pageNo=0&invoiceId=${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-JWT-Assertion": localStorage.getItem("token"),
        },
      });
      if (resp.status === 401 || resp.status === 403) {
        if (resp.status === 401) {
          localStorage.clear();
          window.location.href = "/login";
        } else if (resp.status === 403) {
          setLoading(false); 
        }
        return false;
      }
      if (resp.ok) {
        const response = await resp.json();
        if (response.result.responseCode === 200) { 
          if(response.data.list.length !== 0){
            setBills(response.data.list);
            return true; 
          }
        }
      } 
    } catch (e) { 
      setBills(null); 
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(id){
      fetchData(id);
      console.log("fetching data for invoice id: ", id);
    }
    // id
  }, []);

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "960px",
        margin: "0 auto",
        padding: "16px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "0px 0px 6px 4px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <span>Invoice {invoiceNo}</span>
          <span style={{ fontSize: "1.25rem", fontWeight: "normal" }}>
            {/* {formatRateUSD(amount)} */}
          </span>
        </div>
        <div
          className="flex color-light"
          style={{
            alignItems: "center", 
            marginBottom: "16px",
            gap: "5px",
          }}
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              style={{
                height: "15px",
                width: "15px",
              }}
            >
              <path d="M12 6v4"></path>
              <path d="M14 14h-4"></path>
              <path d="M14 18h-4"></path>
              <path d="M14 8h-4"></path>
              <path d="M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2"></path>
              <path d="M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18"></path>
            </svg>
          </span>
          <span>{facility}</span>
        </div>
      </div>
      <div
        style={{
          maxHeight: "55vh",
          overflowY: "auto",
        }}
      >
        <div style={{ marginBottom: "16px" }}>
          {loading && (
            <div className="col-1-1 flex center-center" style={{ height: "300px" }} >
              <LoaderIcon
                style={{
                  width: "20px",
                  height: "20px",
                }}
              />
            </div>
          )}
          {!loading && bills && bills?.map((bill) => <BillCard key={bill.id} bill={bill} />)}
          {!loading && !bills && <div className="col-1-1 flex center-center" style={{ height: "300px" }} ><p className="f-w-300 color-primary" style={{ color: "#c0392b" }} >No bill records.</p></div>}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          fontSize: "1.125rem",
          fontWeight: "bold",
          marginTop: "24px",
        }}
      >
        <div>Total Charge Amount:</div>
        <div
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            style={{
              marginRight: "8px",
              width: "20px",
              height: "20px",
              backgroundColor: "#4CAF50",
              borderRadius: "50%",
            }}
          ></span>
          {amount}
        </div>
      </div>
    </div>
  );
}
