import { NavLink, withRouter } from "react-router-dom";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { decodeModuleAccessFromToken, formatInputPhoneNumber, formatServerDate } from "../../opl-utilities/helpers";
import { useEffect, useState } from "react";
import { API_URL } from "../../opl-utilities/constants";
import LoaderIcon from "../../components/common/ui/LoaderIcon";



const getRandomColor = () => {
    const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};
const PatientDetails = ({  id, setShow }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
 
    const InfoItem = ({ icon, label, name, ...rest }) => {
        return (
            <div {...rest}>
                <div className='flex center-left' style={{ gap: "10px" }}>
                    <div>{icon}</div>
                    <div style={{ overflow: "hidden" }}>
                        <p
                            className='f-w-300'
                            style={{
                                color: "#aaaaaa",
                                fontSize: "10px",
                                lineHeight: 1,
                                paddingBottom: "2px",
                            }}>
                            {label}
                        </p>
                        <p
                            style={{
                                width: "200px",
                                lineHeight: 1.2,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}>
                            {name}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const fetchFacilityData = async (id) => {
        try {
            const resp = await fetch(
                API_URL +
                `/api/1.0.0/patient/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                }
            );
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                    setLoading(false);
                    setError("Access denied. You do not have permission to perform this action.");
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    setData(response.data);
                    setLoading(false);
                }
            }
        } catch (e) {
            setError("Failed to fetch facility data.");
            setLoading(false);
            console.log("Error:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) fetchFacilityData(id);
    }, [id]);

    return (
        <section id='opl_coremax'>
            <section id='custom-modal'>
                <div className='inner'>
                    <div className='box' id='activity-detail-box'>
                        <div className='flex center-left'>
                            <div>
                                <h6 className='color-primary f-w-300'>
                                    Patient Details
                                </h6>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <ButtonLightTextIcon
                                    title='Cancel'
                                    onClick={() => setShow(false)}
                                    icon={
                                        <svg
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="2"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width='19.995'
                                            height='19.996'
                                        >
                                            <path
                                                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                                fillRule="nonzero" 
                                            />
                                        </svg>
                                    }
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                height: "60vh",
                                margin: "10px",
                                overflowY: "scroll",
                                padding: "20px 5px",
                                overflowX: "hidden",
                                scrollbarWidth: "thin",
                            }}>
                            {loading && (
                                <div
                                    className='col-1-1'
                                    style={{
                                        height: "40px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <LoaderIcon
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </div>
                            )}
                            {!loading && error && (
                                <p
                                    className='f-w-300 color-primary'
                                    style={{ color: "#c0392b" }}>
                                    {error}
                                </p>
                            )}
                            {data && !loading && !error && (
                                <div className='activity-card pos-rel'>
                                    <div
                                        style={{
                                            position: "absolute",
                                            content: "",
                                            height: "4px",
                                            left: "-1px",
                                            right: "-1px",
                                            bottom: "-2px",
                                            borderBottomLeftRadius: "4px",
                                            borderBottomRightRadius: "4px",
                                        }}></div>
                                    <div
                                        className='col-1-1 flex center-left'
                                        style={{
                                            gap: "10px",
                                            paddingBottom: "5px",
                                        }}>
                                        <h5 className='f-w-700'>
                                            <span className='f-w-300'>#</span>
                                            &nbsp;
                                            {data?.id}
                                        </h5>
                                    </div>
                                    <div
                                        style={{
                                            padding: "12px 0 12px 0 ",
                                        }}>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='4'></circle>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                    </svg>
                                                }
                                                label={"Patient"}
                                                name={
                                                    (data?.firstName||" ") + " "+(data?.middleName||" ")+ " " + (data?.lastName||" ")
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
                                                        data-id='72'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='3'></circle>
                                                    </svg>

                                                }
                                                label={"Address"}
                                                name=
                                                {data?.address1 || "NA"}
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
                                                        data-id='72'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='3'></circle>
                                                    </svg>
                                                }
                                                label={"Country"}
                                                name={
                                                    data?.country?.name||"NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
                                                        data-id='72'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d="M12 1c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2h3.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2h3.75l1 2h-3.892l-.858-2z" />
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='3'></circle>
                                                    </svg>

                                                }
                                                label={"State"}
                                                name=
                                                {data?.state?.name || "NA"}
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        stroke='currentColor'
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d="M12 2h2v2h2v3.702l7 2.618v12.68h1v1h-24v-1h1v-11h6v-8h2v-2h2v-2h1v2zm3 3h-7v18h1v-2h5v2h1v-18zm-2 17h-3v1h3v-1zm8 1h1v-11.987l-6-2.243v14.23h1v-2h4v2zm-14-10h-5v10h1v-2h3v2h1v-10zm-2 9h-1v1h1v-1zm15 0h-2v1h2v-1zm-16-5v2h-1v-2h1zm2 0v2h-1v-2h1zm5-10v12h-1v-12h1zm10 11v1h-4v-1h4zm-8-11v12h-1v-12h1zm8 9v1h-4v-1h4zm-17-2v2h-1v-2h1zm2 0v2h-1v-2h1zm15 0v1h-4v-1h4zm0-2v1h-4v-1h4zm-8-9h-3v1h3v-1z" />
                                                    </svg>
                                                }
                                                label={"City"}
                                                name={
                                                    data?.city?.name
                                                    || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        data-id='71'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M8 2v4'></path>
                                                        <path d='M16 2v4'></path>
                                                        <rect
                                                            width='18'
                                                            height='18'
                                                            x='3'
                                                            y='4'
                                                            rx='2'></rect>
                                                        <path d="M24 23h-24v-13.275l2-1.455v-7.27h20v7.272l2 1.453v13.275zm-20-10.472v-9.528h16v9.527l-8 5.473-8-5.472zm14-.528h-12v-1h12v1zm0-3v1h-12v-1h12zm-7-1h-5v-3h5v3zm7 0h-6v-1h6v1zm0-2h-6v-1h6v1z" />

                                                    </svg>
                                                }
                                                label={"Fax"}
                                                name={(data?.fax
                                                    || "NA")
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        data-id='28'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1'></path>
                                                        <path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4'></path>
                                                    </svg>
                                                }
                                                label={"Gender"}
                                                name={
                                                    data?.gender
                                                    || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        data-id='71'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M8 2v4'></path>
                                                        <path d='M16 2v4'></path>
                                                        <rect
                                                            width='18'
                                                            height='18'
                                                            x='3'
                                                            y='4'
                                                            rx='2'></rect>
                                                        <path d='M3 10h18'></path>
                                                    </svg>
                                                }
                                                label={"Date of Birth"}
                                                name={(data?.dateOfBirth
                                                    ? formatServerDate(
                                                        data?.dateOfBirth
                                                    )
                                                    : "NA")
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M4.8 2.3A.3.3 0 1 0 5 2H4a2 2 0 0 0-2 2v5a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6V4a2 2 0 0 0-2-2h-1a.2.2 0 1 0 .3.3'></path>
                                                        <path d='M8 15v1a6 6 0 0 0 6 6v0a6 6 0 0 0 6-6v-4'></path>
                                                        <circle
                                                            cx='20'
                                                            cy='10'
                                                            r='2'></circle>
                                                    </svg>
                                                }
                                                label={"Insurance"}
                                                name={
                                                    data?.patientInsuranceData?.description || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="23"
                                                        height="23"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    >
                                                        <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                                                    </svg>
                                                }
                                                label={"Email"}
                                                name={
                                                    data?.email || "NA"
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d="M5.829 6c-.412 1.165-1.524 2-2.829 2-1.656 0-3-1.344-3-3s1.344-3 3-3c1.305 0 2.417.835 2.829 2h13.671c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5h-4.671c-.412 1.165-1.524 2-2.829 2-1.305 0-2.417-.835-2.829-2h-4.671c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h13.671c.412-1.165 1.524-2 2.829-2 1.656 0 3 1.344 3 3s-1.344 3-3 3c-1.305 0-2.417-.835-2.829-2h-13.671c-2.484 0-4.5-2.016-4.5-4.5s2.016-4.5 4.5-4.5h4.671c.412-1.165 1.524-2 2.829-2 1.305 0 2.417.835 2.829 2h4.671c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5h-13.671zm6.171 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
                                                    </svg>
                                                }
                                                label={"Account Number"}
                                                name={
                                                    data?.accountNumber || "NA"
                                                }
                                            />

                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z'></path>
                                                        <path d='M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5'></path>
                                                        <path d='M4 15v-3a6 6 0 0 1 6-6h0'></path>
                                                        <path d='M14 6h0a6 6 0 0 1 6 6v3'></path>
                                                    </svg>
                                                }
                                                label={"SSN"}
                                                name={
                                                    data?.ssn || "NA"
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                        className='lucide lucide-info h-4 w-4 text-muted-foreground'
                                                        data-id='30'>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                        <path d='M12 16v-4'></path>
                                                        <path d='M12 8h.01'></path>
                                                    </svg>
                                                }
                                                label={"Phone Number (Work)"}
                                                name={
                                                    data?.additionalNumber 
                                                        ? formatInputPhoneNumber(data.additionalNumber.trim()) 
                                                        : "NA"
                                                }
                                                
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-flag h-4 w-4 text-muted-foreground'
                                                        data-id='76'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z'></path>
                                                        <line
                                                            x1='4'
                                                            x2='4'
                                                            y1='22'
                                                            y2='15'></line>
                                                    </svg>
                                                }
                                                label={"Zip"}
                                                name={
                                                    data?.zip || "NA"
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                        className='lucide lucide-info h-4 w-4 text-muted-foreground'
                                                        data-id='30'>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                        <path d='M12 16v-4'></path>
                                                        <path d='M12 8h.01'></path>
                                                    </svg>
                                                }
                                                label={"Phone Number (Home)"}
                                                name={
                                                    data?.phoneNumber 
                                                        ? formatInputPhoneNumber(data.phoneNumber.trim()) 
                                                        : "NA"
                                                }
                                                
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                        className='lucide lucide-info h-4 w-4 text-muted-foreground'
                                                        data-id='30'>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                        <path d='M12 16v-4'></path>
                                                        <path d='M12 8h.01'></path>
                                                    </svg>
                                                }
                                                label={"Cell Phone "}
                                                name={
                                                    data?.cellNumber 
                                                        ? formatInputPhoneNumber(data.cellNumber.trim()) 
                                                        : "NA"
                                                }
                                                
                                            />

                                        </div>

                                        {/* <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='4'></circle>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                    </svg>
                                                }
                                                label={"Sub Specialty"}
                                                name={
                                                    data?.surgeonDetails?.primarySpecialty?.name || "NA"
                                                }
                                            />

                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M12 6v4'></path>
                                                        <path d='M14 14h-4'></path>
                                                        <path d='M14 18h-4'></path>
                                                        <path d='M14 8h-4'></path>
                                                        <path d='M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2'></path>
                                                        <path d='M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18'></path>
                                                    </svg>

                                                }
                                                label={"Facility"}
                                                name={
                                                    data?.surgeonDetails?.facility?.facilityName || "NA"
                                                }
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='4'></circle>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                    </svg>
                                                }
                                                label={"Financial Clearance"}
                                                name={
                                                    data?.surgeonDetails?.financialClearance || "NA"
                                                }
                                            />
                                        </div> */}

                                        <hr
                                            style={{
                                                marginTop: "10px",
                                                paddingBottom: "10px",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Guarantor
                                        </p>

                                        <>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        >
                                                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                            <circle cx="12" cy="10" r="4"></circle>
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    }
                                                    label="Guarantor Name"
                                                    name={data?.guarantor?.guarantorFirstName && data?.guarantor?.guarantorLastName
                                                        ? data.guarantor.guarantorFirstName + " " + data.guarantor.guarantorLastName
                                                        : "NA"}
                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='4'></circle>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                        </svg>
                                                    }
                                                    label={"Spouse/ Parent's Name"}
                                                    name={data?.guarantor?.guarantorSpouseName || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
                                                            data-id='72'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='3'></circle>
                                                        </svg>
                                                    }
                                                    label="Address"
                                                    name={data?.guarantor?.guarantorAddress1 || "NA"}

                                                />
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                        className='lucide lucide-info h-4 w-4 text-muted-foreground'
                                                        data-id='30'>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                        <path d='M12 16v-4'></path>
                                                        <path d='M12 8h.01'></path>
                                                    </svg>
                                                    }
                                                    label="Phone Number"
                                                    name={
                                                        data?.guarantor?.guarantorSpousePhone 
                                                        ? formatInputPhoneNumber(data?.guarantor?.guarantorSpousePhone.trim()) 
                                                        : "NA"
                                                        }
                                                />
                                                
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                               <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Relationship"}
                                                    name={data?.guarantor?.guarantorRelationship || "NA"}
                                                />
                                            </div>

                                            <hr
                                                style={{
                                                    marginTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            />
                                        </>

                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Worker's Comp
                                        </p>

                                        <>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        >
                                                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                            <circle cx="12" cy="10" r="4"></circle>
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    }
                                                    label="Patient Condition"
                                                    name={data?.workerComp?.patientCondition || "NA"}
                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='4'></circle>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                        </svg>
                                                    }
                                                    label={"Employer Name"}
                                                    name={data?.workerComp?.employerName || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Illness Indicator"
                                                    name={data?.workerComp?.illnessIndicator || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Injury Date"}
                                                    name={(data?.workerComp?.dateOfInjury
                                                        ? formatServerDate(
                                                            data?.workerComp?.dateOfInjury
                                                        )
                                                        : "NA")
                                                    }
                                                />
                                            </div>

                                            <hr
                                                style={{
                                                    marginTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            />
                                        </>
                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Primary Insurance
                                        </p>

                                        <>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        >
                                                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                            <circle cx="12" cy="10" r="4"></circle>
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    }
                                                    label="Primary Insurance"
                                                    name={data?.primaryInsurance?.description || "NA"}
                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='4'></circle>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                        </svg>
                                                    }
                                                    label={"Master Payer"}
                                                    name={data?.primaryInsurance?.masterPayerId || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Electronic Payer"
                                                    name={data?.primaryInsurance?.electronicPayerId || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Category"}
                                                    name={data?.primaryInsurance?.category || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Insurance Type"
                                                    name={data?.primaryInsurance?.insuranceType || "NA"}

                                                />
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Address"
                                                    name={data?.primaryInsurance?.addressLine1 || "NA"}

                                                />
                                                {/* <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Contact Details"}
                                                    name={data?.primaryInsurance?.insuranceContactDetails?.information || "NA"}

                                                /> */}
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"City"}
                                                    name={data?.primaryInsurance?.city || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Zip"}
                                                    name={data?.primaryInsurance?.zip || "NA"}

                                                />
                                            </div>

                                            <hr
                                                style={{
                                                    marginTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            />
                                        </>
                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Primary Subscriber's Data
                                        </p>

                                        <>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        >
                                                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                            <circle cx="12" cy="10" r="4"></circle>
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    }
                                                    label="Subscriber Name"
                                                    name={
                                                        data?.primarySubscriberData?.subscriberFirstName || 
                                                        data?.primarySubscriberData?.subscriberLastName
                                                            ? `${data?.primarySubscriberData?.subscriberFirstName ?? ""} ${data?.primarySubscriberData?.subscriberLastName ?? ""}`.trim()
                                                            : "NA"
                                                    }
                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='4'></circle>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                        </svg>
                                                    }
                                                    label={"Group Number"}
                                                    name={data?.primarySubscriberData?.subscriberGroupNumber || "NA"}
                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Member Number"
                                                    name={data?.primarySubscriberData?.subscriberMemberNumber || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Date Of Birth"}
                                                    name={(data?.primarySubscriberData?.subscriberDob
                                                        ? formatServerDate(
                                                            data?.primarySubscriberData?.subscriberDob
                                                        )
                                                        : "NA")
                                                    }
                                                />
                                            </div>
                                            <hr
                                                style={{
                                                    marginTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            />
                                        </>
                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Secondary Insurance
                                        </p>

                                        <>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        >
                                                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                            <circle cx="12" cy="10" r="4"></circle>
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    }
                                                    label="Secondary Insurance"
                                                    name={data?.secondaryInsurance?.description || "NA"}
                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='4'></circle>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                        </svg>
                                                    }
                                                    label={"Master Payer"}
                                                    name={data?.secondaryInsurance?.masterPayerId || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Electronic Payer"
                                                    name={data?.secondaryInsurance?.electronicPayerId || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Category"}
                                                    name={data?.secondaryInsurance?.category || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Insurance Type"
                                                    name={data?.secondaryInsurance?.insuranceType || "NA"}

                                                />
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Address"
                                                    name={data?.secondaryInsurance?.addressLine1 || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"City"}
                                                    name={data?.secondaryInsurance?.city || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Zip"}
                                                    name={data?.secondaryInsurance?.zip || "NA"}

                                                />
                                            </div>

                                            <hr
                                                style={{
                                                    marginTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            />
                                        </>

                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Secondary Subscriber's Data
                                        </p>

                                        <>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        >
                                                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                            <circle cx="12" cy="10" r="4"></circle>
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    }
                                                    label="Subscriber Name"
                                                    name={
                                                        data?.secondarySubscriberData?.subscriberFirstName || 
                                                        data?.secondarySubscriberData?.subscriberLastName
                                                            ? `${data?.secondarySubscriberData?.subscriberFirstName ?? ""} ${data?.secondarySubscriberData?.subscriberLastName ?? ""}`.trim()
                                                            : "NA"
                                                    }
                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='4'></circle>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                        </svg>
                                                    }
                                                    label={"Group Number"}
                                                    name={data?.secondarySubscriberData?.subscriberGroupNumber || "NA"}
                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Member Number"
                                                    name={data?.secondarySubscriberData?.subscriberMemberNumber || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Date Of Birth"}
                                                    name={(data?.secondarySubscriberData?.subscriberDob
                                                        ? formatServerDate(
                                                            data?.secondarySubscriberData?.subscriberDob
                                                        )
                                                        : "NA")
                                                    }
                                                />
                                            </div>
                                            <hr
                                                style={{
                                                    marginTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            />
                                        </>

                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Tertiary Insurance
                                        </p>

                                        <>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        >
                                                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                            <circle cx="12" cy="10" r="4"></circle>
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    }
                                                    label="Tertiary Insurance"
                                                    name={data?.tertiaryInsurance?.description || "NA"}
                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='4'></circle>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                        </svg>
                                                    }
                                                    label={"Master Payer"}
                                                    name={data?.tertiaryInsurance?.masterPayerId || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Electronic Payer"
                                                    name={data?.tertiaryInsurance?.electronicPayerId || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Category"}
                                                    name={data?.tertiaryInsurance?.category || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Insurance Type"
                                                    name={data?.tertiaryInsurance?.insuranceType || "NA"}

                                                />
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Address"
                                                    name={data?.tertiaryInsurance?.addressLine1 || "NA"}

                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"City"}
                                                    name={data?.tertiaryInsurance?.city || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Zip"}
                                                    name={data?.tertiaryInsurance?.zip || "NA"}

                                                />
                                            </div>

                                            <hr
                                                style={{
                                                    marginTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            />
                                        </>

                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Tertiary Subscriber's Data
                                        </p>

                                        <>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}
                                                        >
                                                            <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                            <circle cx="12" cy="10" r="4"></circle>
                                                            <circle cx="12" cy="12" r="10"></circle>
                                                        </svg>
                                                    }
                                                    label="Subscriber Name"
                                                    name={
                                                        data?.tertiarySubscriberData?.subscriberFirstName || 
                                                        data?.tertiarySubscriberData?.subscriberLastName
                                                            ? `${data?.tertiarySubscriberData?.subscriberFirstName ?? ""} ${data?.tertiarySubscriberData?.subscriberLastName ?? ""}`.trim()
                                                            : "NA"
                                                    }
                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                            <circle
                                                                cx='12'
                                                                cy='10'
                                                                r='4'></circle>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                        </svg>
                                                    }
                                                    label={"Group Number"}
                                                    name={data?.tertiarySubscriberData?.subscriberGroupNumber || "NA"}
                                                />
                                            </div>
                                            <div
                                                className="info-row flex center-center"
                                                style={{
                                                    gap: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            >
                                                <InfoItem
                                                    className="col-1-1 info-row-column"
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label="Member Number"
                                                    name={data?.tertiarySubscriberData?.subscriberMemberNumber || "NA"}

                                                />
                                                <InfoItem
                                                    className='col-1-1 info-row-column'
                                                    icon={
                                                        <svg
                                                            color={getRandomColor()}
                                                            xmlns='http://www.w3.org/2000/svg'
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            data-id='71'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <path d='M8 2v4'></path>
                                                            <path d='M16 2v4'></path>
                                                            <rect
                                                                width='18'
                                                                height='18'
                                                                x='3'
                                                                y='4'
                                                                rx='2'></rect>
                                                            <path d='M3 10h18'></path>
                                                        </svg>
                                                    }
                                                    label={"Date Of Birth"}
                                                    name={(data?.tertiarySubscriberData?.subscriberDob
                                                        ? formatServerDate(
                                                            data?.tertiarySubscriberData?.subscriberDob
                                                        )
                                                        : "NA")
                                                    }
                                                />
                                            </div>
                                            <hr
                                                style={{
                                                    marginTop: "10px",
                                                    paddingBottom: "10px",
                                                }}
                                            />
                                        </>


                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "20px",
                                            }}>
                                            <div className='col-1-1 info-row-column'>
                                                <div
                                                    className='flex center-left'
                                                    style={{ gap: "10px" }}>
                                                    <div>
                                                        <svg
                                                            color={getRandomColor()}
                                                            width='24'
                                                            height='24'
                                                            viewBox='0 0 24 24'
                                                            fill='none'
                                                            stroke='currentColor'
                                                            strokeWidth='2'
                                                            strokeLinecap='round'
                                                            strokeLinejoin='round'
                                                            className='lucide lucide-info h-4 w-4 text-muted-foreground'
                                                            data-id='30'
                                                            style={{
                                                                height: "20px",
                                                                width: "20px",
                                                            }}>
                                                            <circle
                                                                cx='12'
                                                                cy='12'
                                                                r='10'></circle>
                                                            <path d='M12 16v-4'></path>
                                                            <path d='M12 8h.01'></path>
                                                        </svg>
                                                    </div>
                                                    <div
                                                        style={{
                                                            overflow: "hidden",
                                                        }}>
                                                        <p
                                                            className='f-w-300'
                                                            style={{
                                                                color: "#aaaaaa",
                                                                fontSize:
                                                                    "10px",
                                                                lineHeight: 1,
                                                                paddingBottom:
                                                                    "2px",
                                                            }}>
                                                            Note
                                                        </p>
                                                        <p
                                                            style={{
                                                                whiteSpace:
                                                                    "normal",
                                                                overflowWrap:
                                                                    "break-word",
                                                                overflow:
                                                                    "hidden",
                                                                textOverflow:
                                                                    "ellipsis",
                                                                width: "100%",
                                                            }}>
                                                            {data
                                                                ?.notes ||
                                                                "NA"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                label={"Created At"}
                                                name={(data?.createdAt
                                                    ? formatServerDate(
                                                        data?.createdAt
                                                    )
                                                    : "NA")
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                label={"Updated At "}
                                                name={(data?.updatedAt
                                                    ? formatServerDate(
                                                        data?.updatedAt
                                                    )
                                                    : "NA")
                                                }
                                            />
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};
export default withRouter(PatientDetails);
