import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ButtonTextIcon from "../../components/common/ui/ButtonTextIcon";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { useEffect, useRef, useState } from "react";
import {
    API_URL,
    CLEARANCE_OPTIONS,
    DEGREE_OPTIONS,
    DEGREE_STATUS_OPTIONS,
    SUFFIX_OPTIONS,
    SYSTEM_TOKEN,
} from "../../opl-utilities/constants";
import PaginatedDropdown from "../../components/common/ui/PaginatedDropdown";
import DragFileUpload from "../../components/common/ui/DragFileUpload";
import { formatServerDate, sleep } from "../../opl-utilities/helpers";
import Tabs from "./Tabs";
import { withRouter } from "react-router-dom";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { useUI } from "../../context/ui";
import { values } from "lodash";
import PhoneNumberInput from "../../components/common/ui/PhoneNumberInput";

const Label = ({ title, required }) => {
    return (
        <p
            className='color-primary f-w-600'
            style={{ paddingBottom: "5px" }}>
            {title}
            {required && (
                <span style={{ color: "red", marginLeft: "4px" }}>*</span>
            )}
        </p>
    );
};

const AddForm = ({ surgeonData, history, location }) => {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [tab, setTab] = useState("basic-information");
    const url =  window.location.href;
    const nextUrl = url.split('next=')[1];   
    const [initialValues, setInitialValues] = useState({
        degrees: [],
        firstName: "",
        lastName: "",
        middleName: "",
        suffix: "",
        designation: "",
        npi: "",
        priority: "",
        additionalContact: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        email: "",
        phone: "",
        fax: "",
        primaryTaxonomy: "",
        secondaryTaxonomy: "",
        primarySpecialty: "",
        subSpecialty: "",
        billingNotes: "",
        license: "",
        financialClearance: "",
        facilityId: "",
    });
    const { addToastMessage } = useUI();

    useEffect(() => {
        setInitialValues({
            id: surgeonData?.id,
            firstName: surgeonData?.firstName,
            lastName: surgeonData?.lastName,
            middleName: surgeonData?.middleName,
            suffix: surgeonData?.suffix,
            designation: surgeonData?.designation,
            npi: surgeonData?.npi,
            priority: surgeonData?.priority || "",
            additionalContact: surgeonData?.surgeonDetails?.additionalContact,
            address: surgeonData?.surgeonDetails?.address,
            state: surgeonData?.surgeonDetails?.state
                ? {
                    value: surgeonData?.surgeonDetails?.state?.code,
                    label: surgeonData?.surgeonDetails?.state?.name,
                }
                : null,
            city: surgeonData?.surgeonDetails?.city
                ? {
                    value: surgeonData?.surgeonDetails?.city?.name,
                    label: surgeonData?.surgeonDetails?.city?.name,
                }
                : null,
            zip: surgeonData?.surgeonDetails?.zip,
            email: surgeonData?.surgeonDetails?.email,
            phone: surgeonData?.surgeonDetails?.phone,
            fax: surgeonData?.surgeonDetails?.fax,
            primaryTaxonomy: surgeonData?.surgeonDetails?.primaryTaxonomy,
            secondaryTaxonomy: surgeonData?.surgeonDetails?.secondaryTaxonomy,
            primarySpecialty: surgeonData?.surgeonDetails?.primarySpecialty
                ? {
                    value: surgeonData?.surgeonDetails?.primarySpecialty?.id,
                    label: surgeonData?.surgeonDetails?.primarySpecialty?.name,
                }
                : null,
            subSpecialty: surgeonData?.surgeonDetails?.subSpecialty?
            {
                value: surgeonData?.surgeonDetails?.subSpecialty?.id,
                label: surgeonData?.surgeonDetails?.subSpecialty?.name,
            }:null,
            billingNotes: surgeonData?.surgeonDetails?.billingNotes,
            license: surgeonData?.surgeonDetails?.license,
            financialClearance: surgeonData?.surgeonDetails?.financialClearance,
            facilityId: surgeonData?.surgeonDetails?.facility
                ? {
                    value: surgeonData?.surgeonDetails?.facility?.id,
                    label: surgeonData?.surgeonDetails?.facility?.facilityName,
                }
                : null,
            degrees: surgeonData?.degrees ? surgeonData?.degrees.map((detail) => ({
                dateAcquired: detail.dateAcquired ? convertToMMDDYYYY(detail.dateAcquired) : "",
                dateExpired: detail.dateExpired ? convertToMMDDYYYY(detail.dateExpired) : null,
                degree: detail.degree || "",
                state: { label: detail?.stateInformation?.name, value: detail?.stateInformation?.code, },
                status: detail.status,
                attachment: detail.attachment,
                s3attachment: detail.s3attachment,
            })) : [],

            // degrees_detail: data.physicianAssistant?.degrees ?
            //     data.physicianAssistant?.degrees.map((detail) => ({
            //         date_aquired: detail.dateAcquired,
            //         date_expiry: detail.dateExpired,
            //         degree: detail.degree || "",
            //         state: { label: detail.state, value: detail.state },
            //         degree_status: detail.status,
            //         attachments: detail.attachment,
            //         s3attachment: detail.s3attachment,
            //     }))
            //     : [],
            // firstName: data.name || "", // required
        });
    }, [surgeonData]);


    const validationSchema = Yup.object({
        firstName: Yup.string().min(2, "First Name must be at least 2 characters long").required('First name is required'),
        lastName: Yup.string().min(2, "Last Name must be at least 2 characters long").required('Last name is required'),
        npi: Yup.string().required('Npi is required'),
        suffix: Yup.string().required('Credential is required'),
        // email: Yup.string().email('Email must be a valid email').required('Email is required'),
        phone: Yup.string()
		.matches(/^\d+$/, "Phone Number must contain only numbers")
        .max(15, "Phone must be at most 15 digits") 
		.notRequired(),

    });

    function convertToMMDDYYYY(dateString) {
        const date = new Date(dateString);
        const formattedDate =
            ("0" + (date.getMonth() + 1)).slice(-2) +
            "/" +
            ("0" + date.getDate()).slice(-2) +
            "/" +
            date.getFullYear();
        return formattedDate;
    }

    function convertTo12HourFormat(timeString) {
        const [hours, minutes] = timeString.split(":");
        const hour = parseInt(hours, 10);
        const period = hour >= 12 ? "PM" : "AM";
        const convertedHour = hour % 12 || 12;
        return `${("0" + convertedHour).slice(-2)}:${minutes} ${period}`;
    }

    const updateSurgeon = async (values) => { 
        setLoading(true);
        setStatus("");
        try {
            const payload = JSON.stringify({
                degrees: values?.degrees.length ? values?.degrees.map((detail) => ({
                    dateAcquired: detail.dateAcquired ? convertToMMDDYYYY(detail.dateAcquired) : "",
                    dateExpired: detail.dateExpired ? convertToMMDDYYYY(detail.dateExpired) : null,
                    degree: detail.degree || "",
                    state: detail.state.value,
                    status: detail.status,
                    attachment: detail.attachment,
                })) : null,
                firstName: values?.firstName || "",
                lastName: values?.lastName || "",
                middleName: values?.middleName || "",
                suffix: values?.suffix || "",
                designation: values?.designation || "",
                npi: values?.npi || "",
                priority: values?.priority || "",
                additionalContact: values?.additionalContact || "",
                address: values?.address || "",
                city: values?.city?.label || "",
                state: values?.state?.value || "",
                zip: values?.zip || "",
                email: values?.email || "",
                phone: values?.phone || "",
                fax: values?.fax || "",
                primaryTaxonomy: values?.primaryTaxonomy || "",
                secondaryTaxonomy: values?.secondaryTaxonomy || "",
                primarySpecialty: values?.primarySpecialty?.value || "",
                subSpecialty: values?.subSpecialty?.value || "",
                billingNotes: values?.billingNotes || "",
                license: values?.license || "",
                financialClearance: values?.financialClearance || "",
                facilityId: values?.facilityId?.value || "",
            });
 

            const resp = await fetch(API_URL + `/api/1.0.0/surgeon/${surgeonData?.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token"),
                },
                body: payload,
            });
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                  addToastMessage({
                    status: false,
                    message:
                      "Access denied. You do not have permission to perform this action.",
                  });
                    setStatus("Access denied. You do not have permission to perform this action.");
                  setLoading(false);
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    return response.result.responseCode === 200 ? true : false;
                } else {
                    const responseData = await resp.json();
                    if (responseData) {
                        setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
                        addToastMessage({
                            status: false,
                            message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
                        });
                        return false;
                    }
                    setStatus("Something went wrong, please try again later.");
                    addToastMessage({
                        status: false,
                        message: `Something went wrong, please try again later.`,
                    });
                    return false;
                }
            } else {
                const responseData = await resp.json();
                if (responseData) {
                    setStatus(`${responseData.responseDescription || "Something went wrong, please try again later."}`);
                    addToastMessage({
                        status: false,
                        message: `${responseData.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
                setStatus("Something went wrong, please try again later.");
                addToastMessage({
                    status: false,
                    message: `Something went wrong, please try again later.`,
                });
                return false;
            }
        } catch (e) {
            setStatus("Something went wrong, please try again later.");
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
            console.log("Error:", e);
            return false;
        } finally {
            setLoading(false);
        }
    };


    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelStateFunc = (o) => `${o.name}`;
    const labelCityFunc = (o) => `${o.name}`;
    const labelSpecilityFunc = (o) => `${o.name}`;
    const labelFacilityFunc = (o) => `${o.facilityName}`;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                setLoading(true); 
                const api = await updateSurgeon(values);
                if (api) {
                    resetForm();
                    setStatus(
                        "You have successfully updated a surgeon, thank you."
                    );
                    addToastMessage({
                        status: true,
                        message: `You have successfully updated a surgeon, thank you.`,
                    })
                      if (nextUrl) {   
                          history.push(nextUrl); 
                      } else { 
                        history.push("/surgeons");
                      }
                } else {
                    setStatus("Something went wrong, please try again later.");
                }
                setLoading(false);
            }}>
            {({
                values,
                setFieldValue,
                errors,
                touched,
                handleSubmit,
                validateForm,
                setFieldTouched,
            }) => (
                <Form>
                    <div className='col-1-1'>
                        {/* <Tabs
                            options={[
                                {
                                    name: "Basic Information",
                                    slug: "basic-information",
                                    content: ( */}
                                    <table>
                                        <tbody> 
                                            <br />
                                            <tr>
                                                <td width='25%'>
                                                    <Label title='First Name' required={true} />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter First Name'
                                                        name='firstName'
                                                        className={`col-1-1 primary ${errors.firstName &&
                                                            touched.firstName
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                    <ErrorMessage
														name='firstName'
														component='p'
														className='error-messages'
													/>
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Middle Name' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter middle Name'
                                                        name='middleName'
                                                        className={`col-1-1 primary ${errors.middleName &&
                                                            touched.middleName
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Last Name' required={true} />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Last Name'
                                                        name='lastName'
                                                        className={`col-1-1 primary ${errors.lastName &&
                                                            touched.lastName
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                    <ErrorMessage
														name='lastName'
														component='p'
														className='error-messages'
													/>
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Email' />
                                                    <Field
                                                        type='email'
                                                        placeholder='Enter Email'
                                                        name='email'
                                                        className={`col-1-1 primary ${errors.email &&
                                                            touched.email
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width='25%'>
                                                    <Label title='Credential' required={true} />
                                                    <Field
                                                        as='select'
                                                        name='suffix'
                                                        className={`col-1-1 primary ${errors.suffix &&
                                                            touched.suffix
                                                            ? "error"
                                                            : ""
                                                            }`}>
                                                        <option value=''>Select</option>
                                                        {SUFFIX_OPTIONS.map((d, i) => (
                                                            <option value={d.value} key={i}>
                                                                {d.label}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Designation' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Designation'
                                                        name='designation'
                                                        className={`col-1-1 primary ${errors.designation &&
                                                            touched.designation
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Phone' />
                                                    <PhoneNumberInput
														name="phone"
														placeholder="Eg: 888-888-8888"
														error={errors.phone && touched.phone}
														value={values.phone}
														setTouched={()=> setFieldTouched("phone",true)}
														callback={(v)=>setFieldValue("phone", v)}
													/>
													<ErrorMessage
													name="phone"
													component="p"
													className="error-messages"
													/>
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Fax' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Fax'
                                                        name='fax'
                                                        className={`col-1-1 primary ${errors.fax &&
                                                            touched.fax
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>

                                                <td width='25%'>
                                                    <Label title='Zip' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Zip'
                                                        name='zip'
                                                        className={`col-1-1 primary ${errors.zip &&
                                                            touched.zip
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Address' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Address'
                                                        name='address'
                                                        className={`col-1-1 primary ${errors.address &&
                                                            touched.address
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='State' />
                                                    <PaginatedDropdown
                                                        datakey="records"
                                                        url={`${API_URL}/api/1.0.0/reference/states/US?`}
                                                        mapOptions={(records) =>
                                                            mapOptions(
                                                                records,
                                                                "code",
                                                                labelStateFunc
                                                            )
                                                        }
                                                        error={errors.state && touched.state}
                                                        placeHolder='Search'
                                                        selectedValue={values.state}
                                                        onChange={(v) => {
                                                                setFieldValue("state", v);
                                                                setFieldValue("city", null);
                                                            }
                                                        }
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='City' />
                                                    <PaginatedDropdown
                                                        datakey="records"
                                                        url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.state?.value}?`}
                                                        mapOptions={(records) =>
                                                            mapOptions(
                                                                records,
                                                                "name",
                                                                labelCityFunc
                                                            )
                                                        }
                                                        error={errors.city && touched.city}
                                                        placeHolder='Search'
                                                        selectedValue={values.city}
                                                        onChange={(v) => {
                                                                setFieldValue(
                                                                    "city",
                                                                    v
                                                                );
                                                            }
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                            </tr>
                                            <tr>
                                                <td width='25%'>
                                                    <Label title='Additional Contact' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Additional Contact'
                                                        name='additionalContact'
                                                        className={`col-1-1 primary ${errors.additionalContact &&
                                                            touched.additionalContact
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Primary Taxonomy' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Primary Taxonomy'
                                                        name='primaryTaxonomy'
                                                        className={`col-1-1 primary ${errors.primaryTaxonomy &&
                                                            touched.primaryTaxonomy
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Secondary Taxonomy' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Secondary Taxonomy'
                                                        name='secondaryTaxonomy'
                                                        className={`col-1-1 primary ${errors.secondaryTaxonomy &&
                                                            touched.secondaryTaxonomy
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='NPI #'  required={true} />
                                                    <Field
                                                        type="text"
                                                        placeholder='Enter NPI #'
                                                        name='npi'
                                                        className={`col-1-1 primary ${errors.npi &&
                                                            touched.npi
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width='25%'>
                                                    <Label title='License' />
                                                    <Field
                                                        type="text"
                                                        placeholder='Enter License'
                                                        name='license'
                                                        className={`col-1-1 primary ${errors.license &&
                                                            touched.license
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Finacial Clearance' />
                                                    <Field
                                                        as='select'
                                                        name='financialClearance'
                                                        className={`col-1-1 primary ${errors.financialClearance &&
                                                            touched.financialClearance
                                                            ? "error"
                                                            : ""
                                                            }`}>
                                                        <option value=''>Select</option>
                                                        {CLEARANCE_OPTIONS.map((d, i) => (
                                                            <option value={d.value} key={i}>
                                                                {d.label}
                                                            </option>
                                                        ))}
                                                    </Field>
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Primary Specialty' />
                                                    <PaginatedDropdown
                                                        datakey="records"
                                                        url={`${API_URL}/api/1.0.0/reference/specialities?`}
                                                        mapOptions={(records) =>
                                                            mapOptions(
                                                                records,
                                                                "id",
                                                                labelSpecilityFunc
                                                            )
                                                        }
                                                        placeHolder='Search'
                                                        selectedValue={values.primarySpecialty}
                                                        onChange={(v) => {
                                                                setFieldValue(
                                                                    "primarySpecialty",
                                                                    v
                                                                );
                                                            }
                                                        }
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Sub Specialty' />
                                                    <PaginatedDropdown
                                                        datakey="records"
                                                        url={`${API_URL}/api/1.0.0/reference/specialities?`}
                                                        mapOptions={(records) =>
                                                            mapOptions(
                                                                records,
                                                                "id",
                                                                labelSpecilityFunc
                                                            )
                                                        }
                                                        placeHolder='Search'
                                                        selectedValue={values.subSpecialty}
                                                        onChange={(v) => {
                                                                setFieldValue(
                                                                    "subSpecialty",
                                                                    v
                                                                );
                                                            }
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width='25%'>
                                                    <Label title='Special Billing Notes' />
                                                    <Field
                                                        type='text'
                                                        placeholder='Enter Notes'
                                                        name='billingNotes'
                                                        className={`col-1-1 primary ${errors.billingNotes &&
                                                            touched.billingNotes
                                                            ? "error"
                                                            : ""
                                                            }`}
                                                    />
                                                </td>
                                                <td width='25%'>
                                                    <Label title='Facility Preferance' />
                                                    <PaginatedDropdown
                                                        url={`${API_URL}/api/1.0.0/reference/facility?`}
                                                        mapOptions={(records) =>
                                                            mapOptions(
                                                                records,
                                                                "id",
                                                                labelFacilityFunc
                                                            )
                                                        }
                                                        error={errors.facilityId && touched.facilityId}
                                                        placeHolder='Search Facility'
                                                        selectedValue={values.facilityId}
                                                        onChange={(v) =>
                                                            setFieldValue("facilityId", v)
                                                        }
                                                    />
                                                </td>

                                            </tr> 
                                            <br />
                                        </tbody>
                                    </table>
                                    {/* )
                                },
                                {
                                    name: "Degrees",
                                    slug: "degrees",
                                    content: (
                                        <NestedDegreeForm
                                            onAdd={(newData) => {
                                                setFieldValue('degrees', [
                                                    ...values.degrees,
                                                    newData,
                                                ]);
                                            }}
                                            list={values?.degrees}
                                            onRemove={(index) => {
                                                const updatedData = values?.degrees.filter((_, i) => i !== index);
                                                setFieldValue("degrees", updatedData);
                                            }}
                                        />)
                                },
                            ].map((o) => ({
                                title: o.name,
                                id: o.slug,
                                content: o.content,
                            }))}
                            active={tab}
                            setActive={(v) => {
                                updateQueryParam("tab", v);
                            }}
                        /> */}
                    </div>
                    <br />
                    <div className='flex center-left'>
                        <ButtonLightTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => {
                                if (nextUrl) {   
                                    history.push(nextUrl); 
                                } else { 
                                  history.push("/surgeons");
                                }
                              }}
                            icon={
                                <svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
                            }
                        />
                        {tab === 'basic-information' ? (
                            <ButtonTextIcon
                            type='button'
                            disabled={loading}
                            title='Update Now'
                            onClick={async () => {
                                const errors = await validateForm(); 
                                if (Object.keys(errors).length > 0) {
                                  const firstErrorMessage = Object.values(errors)[0];
                                  addToastMessage({
                                    status: false,
                                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                                  });
                                }
                                handleSubmit(); 
                              }}
                            className='mar-l-a'
                            icon={
                                loading ? (
                                    <LoaderIcon />
                                ) : (
                                    <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='19.995'
                                        height='19.996'
                                        viewBox='0 0 19.995 19.996'>
                                        <path
                                            d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                                            transform='translate(-2.014 -1.995)'
                                        />
                                    </svg>
                                )
                            }
                        />
                        )
                        //  : tab === 'degrees' ? (
                        //     <ButtonTextIcon
                        //         type='button'
                        //         disabled={loading}
                        //         title='Update Now'
                        //         onClick={async () => {
                        //             const errors = await validateForm();
                        //             handleSubmit();
                        //         }}
                        //         className='mar-l-a'
                        //         icon={
                        //             loading ? (
                        //                 <LoaderIcon />
                        //             ) : (
                        //                 <svg
                        //                     xmlns='http://www.w3.org/2000/svg'
                        //                     width='19.995'
                        //                     height='19.996'
                        //                     viewBox='0 0 19.995 19.996'>
                        //                     <path
                        //                         d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z'
                        //                         transform='translate(-2.014 -1.995)'
                        //                     />
                        //                 </svg>
                        //             )
                        //         }
                        //     />
                        // ) 
                        :""}
                    </div>
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>
    );
};

// const NestedDegreeForm = ({ onAdd, list, onRemove }) => {
//     const fileUploadRef = useRef();
//     const validationSchema = Yup.object().shape({
//         state: Yup.mixed()
//             .nullable()
//             .test("state-required", "Required",
//                 function (value) { return value !== null && value.value; }),
//         degree: Yup.string().required('Degree is required').typeError('Degree must be a valid string'),
//         dateAcquired: Yup.date().required('Date aquired is required').typeError('Date aquired must be a valid date'),
//         status: Yup.string().required('Degree status is required').typeError('Degree status must be a valid string'),

//     });
//     const handleReset = () => {
//         if (fileUploadRef.current) {
//             fileUploadRef.current.resetFile();
//         }
//     };


//     const mapOptions = (records, valueKey, labelFormatter) =>
//         records.map((record) => ({
//             value: record[valueKey],
//             label: labelFormatter(record),
//         }));

//     const labelStateFunc = (o) => `${o.name}`;

//     return (
//         <Formik
//             initialValues={{
//                 degree: '',
//                 dateAcquired: '',
//                 dateExpired: '',
//                 state: null,
//                 attachment: '',
//                 status: '',
//             }}
//             validationSchema={validationSchema}
//             onSubmit={(values, { resetForm }) => {
//                 onAdd(values);
//                 resetForm();
//                 handleReset();
//             }}
//         >
//             {({ errors, touched, setFieldValue, values, submitForm }) => (
//                 <Form>
//                     <table>
//                         <tbody>
//                             <br />
//                             <tr>
//                                 <td width='33%'>
//                                     <Label title='Degree' required={true} />
//                                     <Field
//                                         as='select'
//                                         name='degree'
//                                         className={`col-1-1 primary ${errors.degree &&
//                                             touched.degree
//                                             ? "error"
//                                             : ""
//                                             }`}>
//                                         <option value=''>Select Degree</option>
//                                         {DEGREE_OPTIONS.map((d, i) => (
//                                             <option value={d.value} key={i}>
//                                                 {d.label}
//                                             </option>
//                                         ))}
//                                     </Field>
//                                 </td>
//                                 <td width='33%'>
//                                     <Label title='Date Graduated ' required={true} />
//                                     <Field
//                                         type='date'
//                                         placeholder='Enter Date Graduated '
//                                         name='dateAcquired'
//                                         className={`col-1-1 primary ${errors.dateAcquired &&
//                                             touched.dateAcquired
//                                             ? "error"
//                                             : ""
//                                             }`}
//                                     />
//                                 </td>
//                                 <td width='33%'>
//                                     <Label title='Date Expired - If Applicable' />
//                                     <Field
//                                         type='date'
//                                         placeholder='Date Expired - If Applicable'
//                                         name='dateExpired'
//                                         className={`col-1-1 primary ${errors.dateExpired &&
//                                             touched.dateExpired
//                                             ? "error"
//                                             : ""
//                                             }`}
//                                     />
//                                 </td>

//                             </tr>
//                             <tr>
//                                 <td width='33%'>
//                                     <Label title='State' required={true} />
//                                     <PaginatedDropdown
//                                         datakey="records"
//                                         url={`${API_URL}/api/1.0.0/reference/states/US?`}
//                                         mapOptions={(records) =>
//                                             mapOptions(
//                                                 records,
//                                                 "code",
//                                                 labelStateFunc
//                                             )
//                                         }
//                                         error={errors.state && touched.state}
//                                         placeHolder='Search'
//                                         selectedValue={values.state}
//                                         onChange={(v) => {
//                                             if (v) {
//                                                 setFieldValue("state", v);
//                                             }
//                                         }}
//                                     />
//                                 </td>
//                                 <td width='33%'>
//                                     <Label title='Degree Status' />
//                                     <Field
//                                         as='select'
//                                         name='status'
//                                         className={`col-1-1 primary ${errors.status &&
//                                             touched.status
//                                             ? "error"
//                                             : ""
//                                             }`}>
//                                         <option value=''>Select Degree status</option>
//                                         {DEGREE_STATUS_OPTIONS.map((d, i) => (
//                                             <option value={d.value} key={i}>
//                                                 {d.label}
//                                             </option>
//                                         ))}
//                                     </Field>
//                                 </td>
//                             </tr>
//                             <tr>
//                                 <td colSpan='3'>
//                                     <div
//                                         className='flex center-left'
//                                         style={{ gap: "8px" }}>
//                                         <div
//                                             className='opl-tooltip'
//                                             data-tooltip='Please only select PDF files for upload.'>
//                                             <Label title='Attachment' />
//                                         </div>
//                                         <div
//                                             className='opl-tooltip'
//                                             data-tooltip='Please only select PDF files for upload.'>
//                                             <svg
//                                                 xmlns='http://www.w3.org/2000/svg'
//                                                 width='24'
//                                                 height='24'
//                                                 viewBox='0 0 24 24'
//                                                 style={{
//                                                     height: "12px",
//                                                     width: "12px",
//                                                     marginBottom: "5px",
//                                                 }}>
//                                                 <path
//                                                     d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z'
//                                                     fill='#5dc6b3'
//                                                 />
//                                             </svg>
//                                         </div>
//                                     </div>
//                                     <DragFileUpload
//                                         ref={fileUploadRef}
//                                         fileType={"DEGREE_DOC"}
//                                         error={errors.attachment && touched.attachment}
//                                         afterUpload={(url) => {
//                                             setFieldValue("attachment", url);
//                                         }}
//                                     />
//                                 </td>
//                             </tr>
//                             <tr>

//                                 <td colSpan="3">
//                                     <div className="flex center-right">
//                                         <div>
//                                             <Label title="&nbsp;" />
//                                             <ButtonTextIcon
//                                                 title="Add Degree"
//                                                 type="button"
//                                                 onClick={submitForm}
//                                                 icon={<svg
//                                                     fill="#000000"
//                                                     version="1.1"
//                                                     width="800px"
//                                                     height="800px"
//                                                     viewBox="0 0 400 400"
//                                                     xmlSpace="preserve"
//                                                 >
//                                                     <g>
//                                                         <g>
//                                                             <path d="M199.995,0C89.716,0,0,89.72,0,200c0,110.279,89.716,200,199.995,200C310.277,400,400,310.279,400,200 C400,89.72,310.277,0,199.995,0z M199.995,373.77C104.182,373.77,26.23,295.816,26.23,200c0-95.817,77.951-173.77,173.765-173.77 c95.817,0,173.772,77.953,173.772,173.77C373.769,295.816,295.812,373.77,199.995,373.77z" />
//                                                             <path d="M279.478,186.884h-66.363V120.52c0-7.243-5.872-13.115-13.115-13.115s-13.115,5.873-13.115,13.115v66.368h-66.361 c-7.242,0-13.115,5.873-13.115,13.115c0,7.243,5.873,13.115,13.115,13.115h66.358v66.362c0,7.242,5.872,13.114,13.115,13.114 c7.242,0,13.115-5.872,13.115-13.114v-66.365h66.367c7.241,0,13.114-5.873,13.114-13.115 C292.593,192.757,286.72,186.884,279.478,186.884z" />
//                                                         </g>
//                                                     </g>
//                                                 </svg>}
//                                             />
//                                         </div>
//                                     </div>
//                                 </td>
//                             </tr>
//                             {list && (
//                                 <tr>
//                                     <td colSpan="4" >
//                                         <div className="inner-table-date">
//                                             <table>
//                                                 <thead>
//                                                     <tr>
//                                                         <th>Degree</th>
//                                                         <th>Date Aquired</th>
//                                                         <th>Date Expired</th>
//                                                         <th>State</th>
//                                                         <th>Status</th>
//                                                         <th>Attachments</th>
//                                                         <th style={{ textAlign: 'center' }}>Action</th>
//                                                     </tr>
//                                                 </thead>
//                                                 <tbody>
//                                                     {list.map((contact, index) => (
//                                                         <tr>
//                                                             <td>{contact.degree}</td>
//                                                             <td>{contact.dateAcquired ? formatServerDate(contact.dateAcquired) : "-"}</td>
//                                                             <td>{contact.dateExpired ? formatServerDate(contact.dateExpired) : "-"}</td>
//                                                             <td>{contact.state.label}</td>
//                                                             <td>{contact.status}</td>
//                                                             <td>{contact?.attachment.split('/').pop() || "-"}</td>
//                                                             <td>
//                                                                 <div className="flex center-center">
//                                                                     <span className="cursor-pointer"
//                                                                         onClick={() => {
//                                                                             onRemove(index);
//                                                                         }}
//                                                                     >
//                                                                         <svg
//                                                                             height="15px"
//                                                                             width="15px"
//                                                                             viewBox="0 0 330 330"
//                                                                             fill="red"
//                                                                         >
//                                                                             <g id="XMLID_6_">
//                                                                                 <g id="XMLID_11_">
//                                                                                     <path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
//                                                                                 </g>
//                                                                                 <g id="XMLID_18_">
//                                                                                     <path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
//                                                                                 </g>
//                                                                                 <g id="XMLID_23_">
//                                                                                     <path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
//                                                                                 </g>
//                                                                             </g>
//                                                                         </svg>
//                                                                     </span>
//                                                                 </div>
//                                                             </td>
//                                                         </tr>
//                                                     ))}
//                                                 </tbody>
//                                             </table>
//                                         </div>
//                                     </td>
//                                 </tr>
//                             )}
//                         </tbody>
//                     </table>
//                 </Form>
//             )}
//         </Formik>
//     );
// };

export default withRouter(AddForm);
