import "../styles/base.scss";
import "../styles/pages.scss";
import "../styles/animations.scss";
import "../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../components/common/ui/LoaderIcon";
import Header from "../components/common/ui/Header";
import ButtonTextIcon from "../components/common/ui/ButtonTextIcon";
import ResultsPerPage from "../components/common/ResultsPerPage";
import Pagination from "../components/common/Pagination";
import ActivityFilter from "./activities/ActivityFilter";
import PaginatedDropdown from "../components/common/ui/PaginatedDropdown";
import ButtonLightTextIcon from "../components/common/ui/ButtonLightTextIcon";
import ConfirmationModal from "../components/common/ui/ConfirmationModal";
import { decodeModuleAccessFromToken, formatServerDate } from "../opl-utilities/helpers";
import UserFilter from "./setupuser/UserFilter";
import PopUpModal from "../components/common/PopUpModal";
import UserView from "./setupuser/ViewUser";
import ButtonTertiary from "../components/common/ui/ButtonTertiary";

const jsonToQueryParams = (json) => {
    const queryParams = Object.keys(json)
        .filter((key) => json[key] !== null && json[key] !== "")
        .map(
            (key) =>
                `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`
        )
        .join("&");

    return queryParams;
};

const ArchivedUser = ({ history, location, activePage }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const pageNo = new URLSearchParams(location.search).get("pageNo") || 0;
    const pageSize = new URLSearchParams(location.search).get("pageSize") || 12;
    const queryParams = new URLSearchParams(location.search);
	const [viewActivity, setViewActivity] = useState(false);
	const [ueserid, setueserid] = useState(null);
	// Decode permissions for the "users" module
	const permissions = decodeModuleAccessFromToken("users");

	// Redirect to login if no permissions
	useEffect(() => {
		if (!permissions) {
			history.push("/login");
		}
	}, [permissions, history]);
    const search = queryParams.get("search") || "";

    const resetFilters = () => {
        updateQueryParam("search", "");
        updateQueryParam("pageNo", 0);
    };
    const updateQueryParam = (key, value) => {
        value ? queryParams.set(key, value) : queryParams.delete(key);
        history.push({ search: queryParams.toString() });
    }; 
    const callListApi = async () => {
        setLoading(true);
        const query = jsonToQueryParams({
            search: search, 
        });
        try {
            const resp = await fetch(
                `${API_URL}/api/1.0.0/users/list?pageSize=${pageSize}&pageNo=${pageNo}&${query}&showArchivedUsers=show`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                }
          );
          if (resp.status === 401 || resp.status === 403) {
            if (resp.status === 401) {
                localStorage.clear();
                window.location.href = "/login";
            } else if (resp.status === 403) {
                setLoading(false);
            }
            return false; 
        }
        

            if (resp.ok) {
                const response = await resp.json();
                if (response.result.responseCode === 200) {
                    setData(response.data);
                } else {
                    setData(null);
                }
            }
        } catch (e) {
            console.error("Error", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        callListApi();
    }, [location.search]);

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const handleDelete = (id) => {
        setShowDeleteModal(true);
    };

    const handleConfirmDelete = async () => {
        alert("Deleted");
        setShowDeleteModal(false);
    };

    return (
        <section id='opl_coremax'>
            <section className='main-section'>
          <Header active={activePage} location={location} />
                <section id='activity-v2'>
                    <div className='col-1-1 flex center-left pad-0-10 main-section-top-row'>
                        <h5 className='page-active color-primary f-w-700'>
                Archived Users
              </h5>
            </div>
            <UserFilter
              resetFilters={() => {
                resetFilters();
              }}
                    /><br />

            <div>
              {data && !loading && (
                <div
                                className='flex center-left'
                  style={{
                    padding: "10px 15px 10px 15px",
                    gap: "12px",
                                }}>
                                <p
                                    className='f-w-300'
                                    style={{ fontSize: "11px" }}>
                    Page no:
                  </p>
                  <Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                  />
                </div>
              )}
            </div>
            {loading ? (
              <section
                style={{
                  height: "100vh",
                  paddingBottom: "20vh",
                                backgroundColor: "#fafaff"
                }}
                            className='flex center-center'>
                            <LoaderIcon
                                style={{ height: "32px", width: "32px" }}
                            />
              </section>
            ) : data?.records.length && data?.records.length ? (
              <div className="rs-table-holder">
                <table className="rs-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Type</th>
                      <th>Active Start</th>
                      <th>Active End</th>
                      <th>Notes</th>
                      <th style={{ textAlign: 'center' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                                    {data && data.records.map((data, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{data.fullName}</td>
                          <td>{data.email}</td>
                          <td>{data.type}</td>
                                            <td>{data.activeStart ? formatServerDate(data.activeStart) : "NA"}</td>
                                            <td>{data.activeEnd ? formatServerDate(data.activeEnd) : "NA"}</td>
                          <td>{data.notes}</td>
                          <td style={{ textAlign: 'center' }}>
                            {permissions?.isReadAllowed /* Later add view pop up as well... */ && (
                              <div
                                onClick={() => {
                                  setueserid(data.id);
                                  setViewActivity(true);
                                }}
                              >
                                <ButtonTertiary
                                  actionType="view"
                                  title="View"
                                />
                              </div>
                            )}
                            </td>
                            {/* <td>
                                                <div className="flex center-center" style={{ gap: '12px' }}>
                                                    <div className="flex color-light font-size-10 opl-tooltip" data-tooltip='Click to Edit user Details.'>
                                                        <NavLink
                                                            to={{
                                                                pathname: "/edit-user",
                                                                state: { id: data.id }
                                                            }}
                                                        >
                                                            <svg
                                                                width="20"
                                                                height="14"
                                                                style={{ height: '16px', width: '16px' }}
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                            >
                                                                <path
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z"
                                                                    fill="#000000"
                                                                />
                                                            </svg>
                                                        </NavLink>
                                                    </div>
                                                    <div className="color-secondary transition hover-color-primary cursor-pointer opl-tooltip" data-tooltip='Click to Delete Patient.'>
                                                        <span
                                                            onClick={() => handleDelete(data.id)}
                                                        >
                                                            <svg
                                                                height="15px"
                                                                width="15px"
                                                                style={{ height: '16px', width: '16px' }}
                                                                viewBox="0 0 330 330"
                                                            >
                                                                <g id="XMLID_6_">
                                                                    <g id="XMLID_11_">
                                                                        <path d="M240,121.076H30V275c0,8.284,6.716,15,15,15h60h37.596c19.246,24.348,49.031,40,82.404,40c57.897,0,105-47.103,105-105 C330,172.195,290.816,128.377,240,121.076z M225,300c-41.355,0-75-33.645-75-75s33.645-75,75-75s75,33.645,75,75 S266.355,300,225,300z" />
                                                                    </g>
                                                                    <g id="XMLID_18_">
                                                                        <path d="M240,90h15c8.284,0,15-6.716,15-15s-6.716-15-15-15h-30h-15V15c0-8.284-6.716-15-15-15H75c-8.284,0-15,6.716-15,15v45H45 H15C6.716,60,0,66.716,0,75s6.716,15,15,15h15H240z M90,30h90v30h-15h-60H90V30z" />
                                                                    </g>
                                                                    <g id="XMLID_23_">
                                                                        <path d="M256.819,193.181c-5.857-5.858-15.355-5.858-21.213,0L225,203.787l-10.606-10.606c-5.857-5.858-15.355-5.858-21.213,0 c-5.858,5.858-5.858,15.355,0,21.213L203.787,225l-10.606,10.606c-5.858,5.858-5.858,15.355,0,21.213 c2.929,2.929,6.768,4.394,10.606,4.394c3.839,0,7.678-1.465,10.607-4.394L225,246.213l10.606,10.606 c2.929,2.929,6.768,4.394,10.607,4.394c3.839,0,7.678-1.465,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L246.213,225 l10.606-10.606C262.678,208.535,262.678,199.039,256.819,193.181z" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                {showDeleteModal && (
                                                    <ConfirmationModal
                                                        onConfirm={handleConfirmDelete} // Confirm delete action
                                                        onCancel={setShowDeleteModal} // Cancel delete action
                                                        message="Are you sure you want to delete this user?"
                                                        setShow={setShowDeleteModal}
                                                    />
                                                )}
                                            </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <section
                style={{
                  height: "80vh",
                  paddingBottom: "20vh",
                  backgroundColor: "#fff",
                }}
                            className='flex center-center'>
                <p>No records found.</p>
              </section>
            )}
            <div>
              {data && !loading && (
                <div
                                className='flex center-center'
                  style={{
                    padding: "24px 15px 32px 15px",
                    gap: "12px",
                                }}>
                                <p
                                    className='f-w-300'
                                    style={{ fontSize: "11px" }}>
                    Page no:
                  </p>
                  <Pagination
                    totalRecords={data.totalRecords}
                    pageNo={data.pageNo}
                    pageSize={data.pageSize}
                  />
                </div>
              )}
            </div>
          </section>
        </section>
        {viewActivity && (
          <PopUpModal>
            <UserView
              history={history}
              ueserid={ueserid}
                        setViewActivity={setViewActivity}
                        redirectUrl="/archived-user"
                        editbutton={false}
            />
          </PopUpModal>
        )}
      </section>
    );
};

export default withRouter(ArchivedUser);
