import React, { useState, useEffect, useRef, useCallback } from "react";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { Label } from "reactstrap";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import { set } from "lodash";
import { API_URL } from "../../opl-utilities/constants";

const Caution = ({ title }) => (
  <p
    className="t-a-c"
    style={{
      fontSize: "12px",
      padding: "5px",
      color: "#777",
    }}
  >
    {title}
  </p>
);

const InsuranceSelect = ({
  setTouched,
  getAll = false,
  selectedValue = null,
  callback,
  setShow,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [noMoreOptions, setNoMoreOptions] = useState(false);
  const [page, setPage] = useState(1);

  const debounceTimeout = useRef(null);
  useEffect(() => {
    const handleSearchDebounce = (searchQuery, page) => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
      debounceTimeout.current = setTimeout(() => {
        fetchData(searchQuery, page);
        console.log("page", page);
      }, 500);
    };
    handleSearchDebounce(searchQuery, page);
  }, [searchQuery, page]);

  useEffect(() => {
    setSearchQuery(selectedValue?.description || "");
  }, [selectedValue]);

  const fetchData = async (search = "", pageNum = 1) => {
    if (noMoreOptions) return;
    try {
      setIsLoading(true);
      const resp = await fetch(
        `${API_URL}/api/1.0.0/insurance?sortOrder=ASC&pageSize=${getAll ? 1000 : 10}&pageNo=${
          getAll ? 0 : pageNum - 1
        }${search ? `&description=${search}` : ""}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        }
      );

      if (resp.ok) {
        const response = await resp.json();
        if (response.result.responseCode === 200) {
          const data = response.data.list || [];
          if (data.length === 0) setNoMoreOptions(true);
          setFilteredOptions((prev) =>
            pageNum === 1 ? data : [...prev, ...data]
          );
        } else {
          console.error(
            "Error in response:",
            response.result.responseDescription
          );
        }
      } else {
        console.error(`Failed to fetch data: ${resp.statusText}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setPage(1);
    setNoMoreOptions(false);
  };

  useEffect(() => {
    setTouched();
  }, []);
  
  const handleSelect = (item) => {
    const returnItem = {
      value: item.id,
      label: item.description,
      obj: item,
    };
    setTouched();
    callback(returnItem);
    setShow(false);
  };
  const handleScroll = () => {
    setPage((prevPage) => prevPage + 1);
  };
  const Label = ({ title }) => {
    return (
      <p
        className="color-primary f-w-300 l-h-1"
        style={{ fontSize: "12px", paddingBottom: "6px" }}
      >
        &nbsp;{title}
      </p>
    );
  };
  return (
    <section id="opl_coremax">
      <section id="custom-modal">
        <div className="inner">
          <div
            className="box "
            id="activity-detail-box"
            style={{
              maxHeight: "80vh",
              width: "70%",
            }}
          >
            <div className="flex center-left">
              <div>
                <h6 className="color-primary f-w-300">Select Insurance</h6>
              </div>
              <div style={{ marginLeft: "auto" }}>
                <ButtonLightTextIcon
                  title="Cancel"
                  onClick={() => setShow(false)}
                  icon={
                    <svg
                      clipRule="evenodd"
                      fillRule="evenodd"
                      strokeLinejoin="round"
                      strokeMiterlimit="2"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                      width="19.995"
                      height="19.996"
                    >
                      <path
                        d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                        fillRule="nonzero"
                      />
                    </svg>
                  }
                />
              </div>
            </div>
            <br />

            <div>
              <div className="flex center-center pad-10-0">
                <input
                  style={{ lineHeight: "12px" }}
                  placeholder="Search"
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  className="primary"
                />
              </div>
              <div
                className="rs-table-holder"
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                  padding: "0px",
                }}
              >
                <table className="rs-table">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Electronic Payer ID</th>
                      <th>Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredOptions.length > 0 && (
                      filteredOptions.map((item) => (
                        <tr
                          key={item.id}
                          className=" cursor-pointer"
                          onClick={() => handleSelect(item)}
                        >
                          <td>{item?.description}</td>
                          <td>{item?.electronicPayerId}</td>
                          <td>{(item?.addressLine1 ? item?.addressLine1 + ", " : "") +
                              (item?.addressLine2 ? item?.addressLine2 + ", " : "") +
                              (item?.city ? item?.city + ", " : "") +
                              (item?.state ? item?.state + ", " : "") +
                              (item?.zip ? item?.zip : "")
                          }</td>
                        </tr>
                      ))
                    ) }
                    {!isLoading && !noMoreOptions && (
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              handleScroll();
                            }}
                          >
                            <Caution title="Click to load more." />
                          </div>
                        </td>
                      </tr>
                    )}
                    {isLoading && (
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          <Caution
                            title={
                              <>
                                Loading{" "}
                                <LoaderIcon
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                  }}
                                />
                              </>
                            }
                          />
                        </td>
                      </tr>
                    )}
                    {!isLoading && noMoreOptions && (
                      <tr>
                        <td colSpan="3" style={{ textAlign: "center" }}>
                          <Caution title="No more options" />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default InsuranceSelect;
