import React, { useState, useEffect, useRef } from "react";

const Caution = ({ title }) => {
    return (
        <p
            className="t-a-c"
            style={{
                fontSize: "12px",
                padding: "5px",
                color: "#777",
            }}
        >
            {title}
        </p>
    );
};

const CustomSelect = ({
    options = [],
    placeHolder = "Search options...",
    selectedValue = null,
    onChange,
    setTouched=()=>{},
    error = false,
    disabled = false,
}) => {
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        setSelectedOption(selectedValue || null);
        setSearchQuery(selectedValue?.label || "");
    }, [selectedValue]);

    useEffect(() => {
        if (searchQuery && !selectedOption) {
            const filtered = options.filter((option) =>
                option.label.toLowerCase().includes(searchQuery.toLowerCase())
            );
            setFilteredOptions(filtered);
        } else {
            setFilteredOptions(options);
        }
    }, [options, searchQuery]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setSelectedOption(null);
        onChange(null); 
        setTouched();
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
        onChange(option);
        setSearchQuery(option.label);
        setIsOpen(false);
        setTouched();
    };

    const toggleDropdown = () => {
        setIsOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleRemoveSelected = () => {
        setSelectedOption(null);
        setSearchQuery("");
        onChange(null);
        setTouched();
        setIsOpen(false);
    };

    return (
      <div className="dropdown-container pos-rel">
        <div className="flex pos-rel center-center">
          <input
            disabled={disabled}
            style={{
              lineHeight: "12px",
              cursor: disabled ? "not-allowed" : "",
            }}
            type="text"
            value={searchQuery}
            onClick={() => {
              toggleDropdown();
              setTouched();
            }}
            onChange={handleSearch}
            placeholder={placeHolder}
            className={`primary ${error ? " error" : ""}`}
          />
          <span
            className="dropdown-arrow"
            onClick={() => {
                if(selectedOption){
                    handleRemoveSelected();
                }else{
                    toggleDropdown();
                }
            }}
            style={{
              position: "absolute",
              right: "12px",
            }}
          >
            {!isOpen ? (
              selectedOption ? (
                <span
                  className="cursor-pointer"
                  style={{ 
                    backgroundColor: "#f0f0f0",
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.2)", 
                  }}
                >
                  &times; 
                </span>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="11.934"
                  height="7.236"
                  viewBox="0 0 11.934 7.236"
                  style={{
                    height: "6px",
                    width: "auto",
                    display: "block",
                  }}
                >
                  <path
                    d="M5.549,7.2a.644.644,0,0,0,.9,0l5.332-5.3a.677.677,0,0,0,0-.93L11.154.332a.677.677,0,0,0-.93,0L6.014,4.543,1.775.332a.677.677,0,0,0-.93,0L.217.961a.677.677,0,0,0,0,.93Z"
                    transform="translate(-0.032 -0.147)"
                    fill="#3d3368"
                  />
                </svg>
              )
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="11.934"
                height="7.236"
                viewBox="0 0 11.934 7.236"
                style={{
                  height: "6px",
                  width: "auto",
                  display: "block",
                }}
              >
                <path
                  d="M5.549,7.2a.644.644,0,0,0,.9,0l5.332-5.3a.677.677,0,0,0,0-.93L11.154.332a.677.677,0,0,0-.93,0L6.014,4.543,1.775.332a.677.677,0,0,0-.93,0L.217.961a.677.677,0,0,0,0,.93Z"
                  transform="translate(11.966 7.383) rotate(180)"
                  fill="#3d3368"
                />
              </svg>
            )}
          </span>
        </div>
        {isOpen && (
          <div className="dropdown-options" ref={dropdownRef}>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((option) => (
                <div
                  key={option.value}
                  className={`dropdown-option cursor-pointer ${
                    selectedOption && selectedOption.value === option.value
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </div>
              ))
            ) : (
              <Caution title="No options available" />
            )}
          </div>
        )}
      </div>
    );
};

export default CustomSelect;
