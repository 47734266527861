import { useEffect, useState } from "react";  
import { SYSTEM_TOKEN } from "../opl-utilities/constants";
import { useUI } from "../context/ui";

export const useFetch = (url, options, systemToken = false) => {
	const [data, setData] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(true); 
	const { addToastMessage } = useUI();
	// handleLogout, 
	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;

		const fetchData = async () => {
			setLoading(true);

			try {
				const resp = await fetch(url, {
					...options,
					method: options.method,
					headers: {
						"Content-Type": "application/json",
						"X-JWT-Assertion": systemToken ? SYSTEM_TOKEN : localStorage.getItem("token"),
					},
					signal,
				});
				if (resp.status === 401 || resp.status === 403) {
					if (resp.status === 401) {
						localStorage.clear();
						window.location.href = "/login";
					} else if (resp.status === 403) {
						addToastMessage({
							status: false, 
							message: `Access denied. You do not have permission to perform this action.`,
						});
						setLoading(false);
					}
					return false; 
				}
				
				if (!resp.ok) {
					if(resp.status === 401){
						addToastMessage({
							status: false,
							message: "Session expired. Please log in again.",
						});
						// handleLogout();
						throw new Error(`Error: ${resp.statusText}`);
					}else{
						throw new Error(`Error: ${resp.statusText}`);
					}
				}

				const response = await resp.json();

				if (response.result.responseCode === 403) {
					if (localStorage.removeItem("token") === undefined) {
						// navigate("/login");
					}
				} else {
					setData(response);
				}
			} catch (err) {
				if (err.name === "AbortError") {
					console.log("Fetch aborted");
				} else {
					setError(err);
				}
			} finally {
				if (!signal.aborted) {
					setLoading(false);
				}
			}
		};

		fetchData();

		return () => {
			controller.abort();
		};
	}, [url]);

	return { data, error, loading };
};
