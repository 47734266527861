import { useEffect, useState } from "react";  
import { API_URL } from "../../opl-utilities/constants"; 
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { formatRateUSD, formatServerDate } from "../../opl-utilities/helpers";

const FullScreenBillDetailsModal = ({
  id=null, 
  title = "", 
  setShow,
}) => {
 
    const [billData, setBillData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
  
    const fetchBillData = async (id) => {
      try {
        const resp = await fetch(
          API_URL + `/api/1.0.0/bill/${id}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "X-JWT-Assertion": localStorage.getItem("token"),
          },
        });
        if (resp.status === 401 || resp.status === 403) {
          if (resp.status === 401) {
            localStorage.clear();
            window.location.href = "/login";
          } else if (resp.status === 403) {
            setLoading(false);
            setError("Access denied. You do not have permission to perform this action.");
          }
          return false;
        }
        if (resp.ok) {
          const response = await resp.json();
          if (response) {
            setBillData(response.data);
            setLoading(false);
          }else{
            setError("Failed to fetch bill data.");
          }
        }else{
          setError("Failed to fetch bill data.");
        }
      } catch (e) {
        setError("Failed to fetch bill data.");
        setLoading(false);
        console.log("Error:", e);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchBillData(id);
    }, [id]);

  return (
    <section id="opl_coremax">
      <section
        style={{
          position: "fixed",
          inset: 0,
          zIndex: 9999,
          backgroundColor: "rgba(255, 255, 255, 0.86)",
        }}
      >
        <section
          className="col-1-1 flex center-center"
          style={{
            height: "100vh",
            width: "100vw",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              height: "100vh",
              width: "100vw",
              margin: "10px",
              padding: "15px",
              border: "solid 1px #f0f0f0",
              background: "white",
              borderRadius: "8px",
              margin: "0 auto",
              boxShadow: "0px 0px 20px rgba(0,0,0,0.08)",
            }}
          >
            <div
              className="col-1-1 flex center-left"
              style={{
                borderBottom: "solid 1px #f0f0f0",
                paddingBottom: "12px",
                marginBottom: "16px",
                gap: "10px",
              }}
            >
              <h6 className="color-primary f-w-700 t-t-u l-s-n">
                {title || ""}
              </h6>
              <div
                className="mar-l-a cursor-pointer "
                onClick={() => setShow(false)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.151 17.943l-4.143-4.102-4.117 4.159-1.833-1.833 4.104-4.157-4.162-4.119 1.833-1.833 4.155 4.102 4.106-4.16 1.849 1.849-4.1 4.141 4.157 4.104-1.849 1.849z"
                    fill="#283f54"
                  />
                </svg>
              </div>
            </div>
            <section id="add-activity">
              <div className="col-1-1">
                {!error && !loading && billData && <MedicalBill   data={billData} />}

                {!error && loading && !billData && (
                  <p
                    style={{
                      height: "100vh",
                      paddingBottom: "20vh",
                      backgroundColor: "#fafaff",
                    }}
                    className="flex center-center"
                  >
                    <LoaderIcon
                      style={{
                        height: "32px",
                        width: "32px",
                      }}
                    />
                  </p>
                )}
                {error && !loading && !billData && (
                  <p
                    style={{
                      height: "100vh",
                      paddingBottom: "20vh",
                      backgroundColor: "#fafaff",
                    }}
                    className="flex center-center"
                  >
                    {error || "Error"}
                  </p>
                )}
              </div>
            </section>
          </div>
        </section>
      </section>
    </section>
  );
};

export default FullScreenBillDetailsModal;

 

const MedicalBill = ({data}) =>  { 
  

  return (
    <div
      style={{
        maxWidth: "700px",
        margin: "20px auto",
        padding: "20px",
        backgroundColor: "white",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        fontSize: "11px",
        border: "1px solid #ccc",
        textTransform: "uppercase",
      }}
    > 
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          borderBottom: "1px solid #ccc",
          paddingBottom: "10px",
        }}
      >
        <div>
          <h1 style={{ fontSize: "18px", fontWeight: "bold", color: "#333" }}>Medical Bill</h1>
          {/* <p style={{ fontSize: "10px", color: "#666", marginTop: "4px" }}>
            Bill Id: #{data.id}
          </p> */}
        </div> 
      </div> 
      
 

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gap: "16px",
            marginTop: "20px",
          }}
        >
          <div>
            <h2
              style={{
                fontWeight: "600",
                fontSize: "12px",
                color: "#555",
                marginBottom: "8px",
                textTransform: "uppercase",
              }}
            >
              Patient Information
            </h2>
            <div style={{ color: "#666", lineHeight: "1.5" }}>
              {[
                { label: "Name:", value: `${data?.activity?.patient?.firstName || "NA"} ${data?.activity?.patient?.middleName || ""} ${data?.activity?.patient?.lastName || ""}` },
                { label: "DOB:", value: data?.activity?.patient?.dateOfBirth ? formatServerDate(data?.activity?.patient?.dateOfBirth) : "NA" },
                { label: "Chart No:", value: data?.activity?.patient?.patientChartNo || "NA" },
                { label: "Service Date:", value: data?.activity?.patient?.serviceDate ? formatServerDate(data?.activity?.patient?.serviceDate) : "NA" },
              ].map((item, idx) => (
                <p key={idx} style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: "100px",
                      fontWeight: "500",
                      flexShrink: 0,
                    }}
                  >
                    {item.label}
                  </span>
                  <span>{item.value}</span>
                </p>
              ))}
            </div>
          </div>
          <div>
            <h2
              style={{
                fontWeight: "600",
                fontSize: "12px",
                color: "#555",
                marginBottom: "8px",
                textTransform: "uppercase",
              }}
            >
              Activity Details
            </h2>
            <div style={{ color: "#666", lineHeight: "1.5" }}>
              {[
                { label: "Facility:", value: data?.facility?.facilityName || "NA" },
                { label: "Specialty:", value: data?.speciality?.name || "NA" },
                { label: "Procedures:", value: data?.procedures?.length ? data?.procedures.map(proc => proc.procedureName).join(", ") : "NA" },
              ].map((item, idx) => (
                <p key={idx} style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
                  <span
                    style={{
                      display: "inline-block",
                      minWidth: "100px",
                      fontWeight: "500",
                      flexShrink: 0,
                    }}
                  >
                    {item.label}
                  </span>
                  <span>{item.value}</span>
                </p>
              ))}
            </div>
          </div>
        </div>


      <hr style={{ border: "0.5px solid #ccc", margin: "20px 0" }} />
 
      <div>
        <h2
          style={{
            fontWeight: "600",
            fontSize: "12px",
            color: "#555",
            marginBottom: "12px",
            textTransform: "uppercase",
          }}
        >
          Charges
        </h2>
        <table style={{ width: "100%", fontSize: "11px", color: "#666", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ backgroundColor: "#f9f9f9" }}>
              <th className="color-primary" style={{ textAlign: "left", padding: "8px", fontWeight: "600", color: "#555" }}>Description</th>
              <th className="color-primary" style={{ textAlign: "right", padding: "8px", fontWeight: "600", color: "#555" }}>Amount</th>
            </tr>
          </thead>
          <tbody>

            {data.oncallCharge ?
              <tr>
                <td  className="color-primary" style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>On-call Charge</td>
                <td  className="color-primary" style={{ textAlign: "right", padding: "8px", borderBottom: "1px solid #ddd" }}>
                  {formatRateUSD(data.oncallCharge)}
                </td>
              </tr> : <></>
            }
              
            {data.procedureCharge ?
              <tr>
                <td  className="color-primary" style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>Specialty Charge</td>
                <td  className="color-primary" style={{ textAlign: "right", padding: "8px", borderBottom: "1px solid #ddd" }}>
                  {formatRateUSD(data.procedureCharge)}
                </td>
              </tr> : <></>
            }
            
            <tr style={{ fontWeight: "bold", color: "#333" }}>
              <td  className="color-primary" style={{ padding: "8px" }}>Total Amount Due</td>
              <td  className="color-primary" style={{ textAlign: "right", padding: "8px" }}>
                {formatRateUSD(data.amount)}
              </td>
            </tr>
            
          </tbody>
        </table>
      <hr style={{ border: "0.5px solid #ccc", margin: "5px 0" }} />
        <br/>
        <div>
        <h2
          style={{
            fontWeight: "600",
            fontSize: "12px",
            color: "#555",
            marginBottom: "12px",
            textTransform: "uppercase",
          }}
        >
          Comments:-
        </h2>
          <p style={{ fontSize: "11px", color: "#666", lineHeight: "1.5" }}>{data?.comment || "-"}</p>
        </div>
      </div>
       
    </div>
  );
}
