import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import PaginatedDropdown from '../../components/common/ui/PaginatedDropdown';
import ButtonTextIcon from '../../components/common/ui/ButtonTextIcon';
import LoaderIcon from '../../components/common/ui/LoaderIcon';
import { API_URL, ORGANIZATION_PAY_OPTIONS, SYSTEM_TOKEN } from '../../opl-utilities/constants';
import { useUI } from '../../context/ui';


function EditPracticeForm({ practiceData, history, }) {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState("");
    const [id, setId] = useState(null);
	const { addToastMessage } = useUI(); 
    const url =  window.location.href;
    const nextUrl = url.split('next=')[1];   

    const Label = ({ title, required }) => (
        <p className='color-primary f-w-600' style={{ paddingBottom: '5px' }}>
            {title}
            {required && <span style={{ color: 'red', marginLeft: '4px' }}>*</span>}
        </p>
    );

    const [initialValues, setInitialValues] = useState({
        organizationIndividual: '',
        billingAddress: '',
        billingAddress2: '',
        cliaNumber: '',
        einSsn: '',
        payToCity: '',
        payToFirstName: '',
        payToName: '',
        payToNpi: '',
        payToState: '',
        payToZip: '',
        taxIdentification: '',
        taxonomyCode: '',
        // organization:''
    });


    useEffect(() => {
        setInitialValues({
            // id: practiceData?.id ||null,
            id: setId(practiceData?.id),
            organizationIndividual: practiceData?.organizationIndividual ? practiceData?.organizationIndividual : null,
            billingAddress: practiceData?.billingAddress || null,
            billingAddress2: practiceData?.billingAddress2 || null,
            cliaNumber: practiceData?.cliaNumber || null,
            einSsn: practiceData?.einSsn || null,
            // payToCity: practiceData?.payToCity || null,
            payToFirstName: practiceData?.payToFirstName || null,
            payToName: practiceData?.payToName || null,
            payToNpi: practiceData?.payToNpi || null,
            payToState: practiceData?.payToState ? {
                value: practiceData?.payToState?.code,
                label: practiceData?.payToState?.name
            }
                : null,
            payToCity: practiceData?.payToCity ? {
                value: practiceData?.payToCity?.code,
                label: practiceData?.payToCity?.name
            }
                : null,
            payToZip: practiceData?.payToZip || null,
            taxIdentification: practiceData?.taxIdentification || null,
            taxonomyCode: practiceData?.taxonomyCode || null,

        });

    }, [practiceData]);
    const validationSchema = Yup.object({
        // organizationIndividual: Yup.string().required('This field is required'),
        einSsn: Yup.string()
            .matches(/^\d+$/, 'EIN/SSN must only contain numbers') // Regex to match digits only
            .notRequired(),
        payToNpi: Yup.string().min(3, "At least three characters is required")
        .required('NPI is required'),
        payToName: Yup.string().required('Organization/Individual/Group Practice is required').min(3, "At least three characters is required"),
    });

    const updatePractice = async (values) => {
        setLoading(true);
        setStatus("");
        try {
            const payload = JSON.stringify({
                id: values.id,
                organizationIndividual: values.organizationIndividual,
                billingAddress: values.billingAddress,
                billingAddress2: values.billingAddress2,
                cliaNumber: values.cliaNumber,
                einSsn: values.einSsn ? values.einSsn : null,
                payToFirstName: values.payToFirstName ? values.payToFirstName : null,
                payToName: values.payToName,
                payToNpi: values.payToNpi,
                payToState: values.payToState?.value ||"",
                payToCity: values.payToCity?.label ||"",
                // payToCity:"",
                // payToState:"",
                payToZip: values.payToZip,
                taxIdentification: values.taxIdentification,
                taxonomyCode: values.taxonomyCode,
            }); 

            const resp = await fetch(API_URL + `/api/1.0.0/practice/${id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "X-JWT-Assertion": localStorage.getItem("token")
                },
                body: payload,
            });
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                  addToastMessage({
                    status: false,
                    message:
                      "Access denied. You do not have permission to perform this action.",
                  });
                    setLoading(false);
                    setStatus(
                        "Access denied. You do not have permission to perform this action."
                    )
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    return response.result.responseCode === 200 ? true : false;
                } else {
                    const response = await resp.json();
                    if (response) {
                        setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
                        addToastMessage({
                            status: false,
                            message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                        });
                        return false;
                    }
                    setStatus("Something went wrong, please try again later.");
                    addToastMessage({
                        status: false,
                        message: `Something went wrong, please try again later.`,
                    });
                    return false;
                }
            }else {
                const response = await resp.json();
                if (response) {
                    setStatus(`${response.responseDescription || "Something went wrong, please try again later."}`);
                    addToastMessage({
                        status: false,
                        message: `${response.responseDescription || "Something went wrong, please try again later."}`,
                    });
                    return false;
                }
                setStatus("Something went wrong, please try again later.");
                addToastMessage({
                    status: false,
                    message: `Something went wrong, please try again later.`,
                });
                return false;
            }
        } catch (e) {
            setStatus("Something went wrong, please try again later.");
            addToastMessage({
                status: false,
                message: `Something went wrong, please try again later.`,
            });
            console.log("Error:", e);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const mapOptions = (records, valueKey, labelFormatter) =>
        records.map((record) => ({
            value: record[valueKey],
            label: labelFormatter(record),
            obj: record,
        }));

    const labelStateFunc = (o) => `${o.name}`;
    const labelStateCodeFunc = (o) => `${o.code}`;
    const labelCityFunc = (o) => `${o.name}`;

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={async (values, { resetForm }) => {
                setLoading(true);
                const api = await updatePractice(values);
                if (api) {
                    resetForm();
                    setStatus(
                        "You have successfully updated a practice, thank you."
                    );
                    addToastMessage({
                        status: true,
                        message: "You have successfully updated a practice, thank you.",
                    });
                    if (nextUrl) {   
                        history.push(nextUrl); 
                    } else { 
                        history.push("/practices");
                    }
                }  
            }}
        >
            {({ errors, touched, values, setFieldValue,validateForm, handleSubmit }) => (
                <Form >
                    <div className='col-1-1'>
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Label title="Pay to NPI Number" required />
                                        <Field
                                            name="payToNpi"
                                            type="text"
                                            placeholder="NPI"
                                            className={`primary ${errors.payToNpi && touched.payToNpi ? 'error' : ''}`}
                                        />
                                    </td>
                                    <td>
                                        <Label title="Organization/Individual/Group Practice"
                                            required={true} />

                                        <Field
                                            name="payToName"
                                            type="text"
                                            placeholder="Eg: Acme Corp"
                                            className={`primary ${errors.payToName && touched.payToName ? 'error' : ''}`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="Billing Address" />
                                        <Field
                                            name="billingAddress"
                                            // as='textarea' 
                                            type='text'
                                            placeholder="Billing Address"
                                            className={`primary  ${errors.billingAddress && touched.billingAddress ? 'error' : ''}`}
                                        />
                                    </td>
                                    <td>
                                        <Label title="Billing Address 2" />
                                        <Field
                                            name="billingAddress2"
                                            // as='textarea' 
                                            type='text'

                                            placeholder="Billing Address 2"
                                            className={`primary`}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="State" />
                                        <PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/states/US?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "code",
                                                    labelStateFunc
                                                )
                                            }
                                            placeHolder='Search'
                                            selectedValue={values.payToState}
                                            onChange={(v) => {
                                                    setFieldValue("payToState", v);
                                                    setFieldValue("payToCity", null);
                                                }
                                            }
                                        />
                                    </td>
                                    <td>
                                        <Label title="City" />
                                        <PaginatedDropdown
                                            datakey="records"
                                            url={`${API_URL}/api/1.0.0/reference/cities/US/${values?.payToState?.value}?`}
                                            mapOptions={(records) =>
                                                mapOptions(
                                                    records,
                                                    "name",
                                                    labelCityFunc
                                                )
                                            }
                                            placeHolder='Search'
                                            selectedValue={values.payToCity}
                                            onChange={(v) => {
                                                    setFieldValue(
                                                        "payToCity",
                                                        v
                                                    );
                                                }
                                            }
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="ZIP Code" />
                                        <Field
                                            name="payToZip"
                                            type="text"
                                            placeholder="ZIP Code"
                                            className={`primary ${errors.payToZip && touched.payToZip ? 'error' : ''}`}
                                        />

                                    </td>
                                    <td>
                                        <Label title="EIN/SSN" />
                                        <Field
                                            name="einSsn"
                                            type="text"
                                            placeholder="EIN/SSN"
                                            className={`primary ${errors.einSsn && touched.einSsn ? 'error' : ''}`}
                                        />
                                        <ErrorMessage
                                            name='einSsn'
                                            component='p'
                                            className='error-messages'
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Label title="Taxonomy Code" />
                                        <Field
                                            name="taxonomyCode"
                                            value={values.taxonomyCode}
                                            type="text"
                                            placeholder="Taxonomy Code"
                                            className={`primary ${errors.taxonomyCode && touched.taxonomyCode ? 'error' : ''}`}
                                        />
                                    </td>
                                    <td>
                                        <Label title="Pay to Organization/Individual"
                                        />
                                        <Field
                                            as='select'
                                            name='organizationIndividual'
                                            value={values.organizationIndividual}
                                            className={`col-1-1 primary ${errors.organizationIndividual &&
                                                touched.organizationIndividual
                                                ? "error"
                                                : ""
                                                }`}>
                                            <option value=''>Select</option>
                                            {ORGANIZATION_PAY_OPTIONS.map((d, i) => (
                                                <option value={d.value} key={i}>
                                                    {d.label}
                                                </option>
                                            ))}
                                        </Field>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br />
                    <div className='flex center-left'>
                        <ButtonTextIcon
                            type='button'
                            className='light'
                            title='Cancel'
                            onClick={() => {
                                if (nextUrl) {   
                                    history.push(nextUrl); 
                                } else { 
                                    history.push("/practices");
                                }
                            }}
                            icon={
								<svg
									clipRule="evenodd"
									fillRule="evenodd"
									strokeLinejoin="round"
									strokeMiterlimit="2"
									viewBox="0 0 24 24"
									xmlns="http://www.w3.org/2000/svg"
									width='19.995'
									height='19.996'
								>
									<path
										d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
										fillRule="nonzero" 
									/>
								</svg>
							}
                        />
                        <ButtonTextIcon
                            type='submit'
                            onClick={async () => {
                                const errors = await validateForm(); 
                                if (Object.keys(errors).length > 0) {
                                  const firstErrorMessage = Object.values(errors)[0];
                                  addToastMessage({
                                    status: false,
                                    message: `${firstErrorMessage || "Something went wrong, please try again later."}`,
                                  });
                                }
                                handleSubmit(); 
                              }}
                            disabled={loading}
                            title='Update Now'
                            className='mar-l-a'
                            icon={loading ? <LoaderIcon /> : <svg xmlns='http://www.w3.org/2000/svg' width='19.995' height='19.996' viewBox='0 0 19.995 19.996'><path d='M12.012,2a10,10,0,1,0,10,10,10,10,0,0,0-10-10Zm0,1.5a8.5,8.5,0,1,1-8.5,8.5,8.5,8.5,0,0,1,8.5-8.5ZM13.54,8.21s1.5,1.505,3.255,3.259a.752.752,0,0,1,0,1.061c-1.753,1.754-3.254,3.258-3.254,3.258a.742.742,0,0,1-.527.217.752.752,0,0,1-.534-1.278l1.978-1.977H7.764a.75.75,0,0,1,0-1.5h6.694L12.479,9.271a.746.746,0,0,1,.006-1.054.754.754,0,0,1,.531-.222.738.738,0,0,1,.524.215Z' transform='translate(-2.014 -1.995)' /></svg>}
                        />
                    </div>
                    {status && (
                        <>
                            <br />
                            <p className='color-primary f-w-700'>{status}</p>
                        </>
                    )}
                </Form>
            )}
        </Formik>

    );
}

export default EditPracticeForm;
