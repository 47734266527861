import "../../styles/base.scss";
import "../../styles/pages.scss";
import "../../styles/animations.scss";
import "../../styles/responsive.scss";
import { useEffect, useState } from "react";
import { API_URL, SYSTEM_TOKEN } from "../../opl-utilities/constants";
import { NavLink, withRouter } from "react-router-dom";
import LoaderIcon from "../../components/common/ui/LoaderIcon";
import { decodeModuleAccessFromToken, formatInputPhoneNumber, formatServerDate, jsonToQueryParams } from "../../opl-utilities/helpers";
import ButtonLightTextIcon from "../../components/common/ui/ButtonLightTextIcon";
import FullScreenFileViewerModal from "../../components/common/ui/FullScreenFileViewerModal";
import CustomModal from "../../components/common/CustomModal";

const getRandomColor = () => {
    const array = ["#84B8A4", "#72B5E5", "#CBA5DB", "#CFA96D", "#E8823D"];
    const randomIndex = Math.floor(Math.random() * array.length);
    return array[randomIndex];
};
 
const StatusIcon = ({ id }) => {
	switch (id) {
		case 1:
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11,17,6,11.7l1.4-1.43,3.574,3.736L17.545,7,19,8.4Z'
							fill={"#27ae60"}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color: "#27ae60",
						}}>
						Active
					</p>
				</div>
			);
		case 0:
			return (
				<div className='flex center-left' style={{ gap: "6px" }}>
					<svg
						xmlns='http://www.w3.org/2000/svg'
						width='24'
						height='24'
						viewBox='0 0 24 24'
						style={{
							height: "14px",
							width: "auto",
						}}>
						<path
							d='M14,22a2,2,0,1,1-2-2A2,2,0,0,1,14,22ZM12,0a2,2,0,1,0,2,2A2,2,0,0,0,12,0ZM22,10a2,2,0,1,1-2,2A2,2,0,0,1,22,10ZM0,12a2,2,0,1,0,2-2A2,2,0,0,0,0,12ZM19,3a2,2,0,1,1-2,2A2,2,0,0,1,19,3Zm0,14a2,2,0,1,1-2,2A2,2,0,0,1,19,17ZM5,3A2,2,0,1,1,3,5,2,2,0,0,1,5,3ZM5,17a2,2,0,1,1-2,2A2,2,0,0,1,5,17Z'
							fill={ "#E65464"}
						/>
					</svg>
					<p
						className='f-w-300'
						style={{
							fontSize: "12px",
							color:  "#E65464",
						}}>
						Inactive
					</p>
				</div>
			);
		default:
			return <></>;
	}
};


const PhysicianAssistantDetails = ({ setViewAssistant, id,location, history }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false); 
    
    // Decode permissions for the "facilities" module
    const permissions = decodeModuleAccessFromToken("physicians_assistance");

    // Redirect to login if no permissions
    useEffect(() => {
        if (!permissions) {
            history.push("/login");
        }
    }, [permissions, history]);

    const InfoItem = ({ icon, label, name, ...rest }) => {
        return (
            <div {...rest}>
                <div className='flex center-left' style={{ gap: "10px" }}>
                    <div>{icon}</div>
                    <div style={{ overflow: "hidden" }}>
                        <p
                            className='f-w-300'
                            style={{
                                color: "#aaaaaa",
                                fontSize: "10px",
                                lineHeight: 1,
                                paddingBottom: "2px",
                            }}>
                            {label}
                        </p>
                        <p
                            style={{
                                width: "200px",
                                lineHeight: 1.2,
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                            }}>
                            {name}
                        </p>
                    </div>
                </div>
            </div>
        );
    };

    const fetchFacilityData = async (id) => {
        try {
            const resp = await fetch(
                API_URL +
                `/api/1.0.0/physician-assistant/${id}`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "X-JWT-Assertion": localStorage.getItem("token")
                    },
                }
            );
            if (resp.status === 401 || resp.status === 403) {
                if (resp.status === 401) {
                  localStorage.clear();
                  window.location.href = "/login";
                } else if (resp.status === 403) {
                        setLoading(false);
                        setError(
                            "Access denied. You do not have permission to perform this action."
                        );
                }
                return false;
              }
            if (resp.ok) {
                const response = await resp.json();
                if (response) {
                    setData(response.data);
                    setLoading(false);
                }
            }
        } catch (e) {
            setError("Failed to fetch facility data.");
            setLoading(false);
            console.log("Error:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (id) fetchFacilityData(id);
    }, [id]);

    return (
        <section id='opl_coremax'>
            <section id='custom-modal'>
                <div className='inner'>
                    <div className='box' id='activity-detail-box'>
                        <div className='flex center-left'>
                            <div>
                                <h6 className='color-primary f-w-300'>
                                    Physician Assistant Details
                                </h6>
                            </div>
                            <div style={{ marginLeft: "auto" }}>
                                <ButtonLightTextIcon
                                    title='Cancel'
                                    onClick={() => setViewAssistant(false)}
                                    icon={
                                        <svg
                                            clipRule="evenodd"
                                            fillRule="evenodd"
                                            strokeLinejoin="round"
                                            strokeMiterlimit="2"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width='19.995'
                                            height='19.996'
                                        >
                                            <path
                                                d="m12.002 2.005c5.518 0 9.998 4.48 9.998 9.997 0 5.518-4.48 9.998-9.998 9.998-5.517 0-9.997-4.48-9.997-9.998 0-5.517 4.48-9.997 9.997-9.997zm0 1.5c-4.69 0-8.497 3.807-8.497 8.497s3.807 8.498 8.497 8.498 8.498-3.808 8.498-8.498-3.808-8.497-8.498-8.497zm0 7.425 2.717-2.718c.146-.146.339-.219.531-.219.404 0 .75.325.75.75 0 .193-.073.384-.219.531l-2.717 2.717 2.727 2.728c.147.147.22.339.22.531 0 .427-.349.75-.75.75-.192 0-.384-.073-.53-.219l-2.729-2.728-2.728 2.728c-.146.146-.338.219-.53.219-.401 0-.751-.323-.751-.75 0-.192.073-.384.22-.531l2.728-2.728-2.722-2.722c-.146-.147-.219-.338-.219-.531 0-.425.346-.749.75-.749.192 0 .385.073.531.219z"
                                                fillRule="nonzero" 
                                            />
                                        </svg>
                                    }
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                height: "60vh",
                                margin: "10px",
                                overflowY: "scroll",
                                padding: "20px 5px",
                                overflowX: "hidden",
                                scrollbarWidth: "thin",
                            }}>
                            {loading && (
                                <div
                                    className='col-1-1'
                                    style={{
                                        height: "40px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}>
                                    <LoaderIcon
                                        style={{
                                            width: "20px",
                                            height: "20px",
                                        }}
                                    />
                                </div>
                            )}
                            {!loading && error && (
                                <p
                                    className='f-w-300 color-primary'
                                    style={{ color: "#c0392b" }}>
                                    {error}
                                </p>
                            )}
                            {data && !loading && !error && (
                                <div className='activity-card pos-rel'>
                                    <div
                                        style={{
                                            position: "absolute",
                                            content: "",
                                            height: "4px",
                                            left: "-1px",
                                            right: "-1px",
                                            bottom: "-2px",
                                            borderBottomLeftRadius: "4px",
                                            borderBottomRightRadius: "4px",
                                        }}></div>
                                    <div
                                        className='col-1-1 flex center-left'
                                        style={{
                                            gap: "10px",
                                            paddingBottom: "5px",
                                        }}>
                                        <h5 className='f-w-700'>
                                            <span className='f-w-300'>#</span>
                                            &nbsp;
                                            {data?.id}
                                        </h5>
                                        <hr
                                            style={{
                                                outline: "none",
                                                border: "none",
                                                height: "16px",
                                                width: "2px",
                                                background: "#EBEBEB",
                                            }}
                                        />
                                         <StatusIcon id={data.physicianAssistant.statusId} />
                                    </div>
                                    <div
                                        style={{
                                            padding: "12px 0 12px 0 ",
                                        }}>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='4'></circle>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                    </svg>
                                                }
                                                label={"Physician Assistant"}
                                                name={
                                                    data?.name + " " + data?.lastName
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
                                                        data-id='72'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='3'></circle>
                                                    </svg>

                                                }
                                                label={"Address"}
                                                name=
                                                {data?.physicianAssistant?.homeAddress || "NA"}
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        data-id='28'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1'></path>
                                                        <path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4'></path>
                                                    </svg>
                                                }
                                                label={"Type"}
                                                name={
                                                    data?.type
                                                    || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        data-id='28'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1'></path>
                                                        <path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4'></path>
                                                    </svg>
                                                }
                                                label={"Work Phone"}
                                                name={
                                                    data?.physicianAssistant?.workCellPhone
                                                        ? formatInputPhoneNumber(data.physicianAssistant.workCellPhone.trim())
                                                        : "NA"
                                                }
                                                
                                            />
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                                <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        data-id='28'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M19 7V4a1 1 0 0 0-1-1H5a2 2 0 0 0 0 4h15a1 1 0 0 1 1 1v4h-3a2 2 0 0 0 0 4h3a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1'></path>
                                                        <path d='M3 5v14a2 2 0 0 0 2 2h15a1 1 0 0 0 1-1v-4'></path>
                                                    </svg>
                                                }
                                                label={"Cell Phone"}
                                                name={
                                                    data?.physicianAssistant?.cellPhone
                                                        ? formatInputPhoneNumber(data.physicianAssistant.cellPhone.trim())
                                                        : "NA"
                                                }
                                                
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="23"
                                                        height="23"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    >
                                                        <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                                                    </svg>
                                                }
                                                label={"Work Email"}
                                                name={
                                                    data?.physicianAssistant?.workEmail || "NA"
                                                }
                                            />
                                            
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                                <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="23"
                                                        height="23"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="currentColor"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                    >
                                                        <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                                                    </svg>
                                                }
                                                label={"Email"}
                                                name={
                                                    data?.email || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d="M5.829 6c-.412 1.165-1.524 2-2.829 2-1.656 0-3-1.344-3-3s1.344-3 3-3c1.305 0 2.417.835 2.829 2h13.671c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5h-4.671c-.412 1.165-1.524 2-2.829 2-1.305 0-2.417-.835-2.829-2h-4.671c-1.38 0-2.5 1.12-2.5 2.5s1.12 2.5 2.5 2.5h13.671c.412-1.165 1.524-2 2.829-2 1.656 0 3 1.344 3 3s-1.344 3-3 3c-1.305 0-2.417-.835-2.829-2h-13.671c-2.484 0-4.5-2.016-4.5-4.5s2.016-4.5 4.5-4.5h4.671c.412-1.165 1.524-2 2.829-2 1.305 0 2.417.835 2.829 2h4.671c1.38 0 2.5-1.12 2.5-2.5s-1.12-2.5-2.5-2.5h-13.671zm6.171 5c.552 0 1 .448 1 1s-.448 1-1 1-1-.448-1-1 .448-1 1-1z" />
                                                    </svg>
                                                }
                                                label={"License"}
                                                name={
                                                    data?.physicianAssistant?.drivingLicense || "NA"
                                                }
                                            />

                                            
                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                                <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M2 18a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v2z'></path>
                                                        <path d='M10 10V5a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v5'></path>
                                                        <path d='M4 15v-3a6 6 0 0 1 6-6h0'></path>
                                                        <path d='M14 6h0a6 6 0 0 1 6 6v3'></path>
                                                    </svg>
                                                }
                                                label={"Primary Taxonomy"}
                                                name={
                                                    data?.physicianAssistant?.primaryTaxonomy || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}
                                                        className='lucide lucide-info h-4 w-4 text-muted-foreground'
                                                        data-id='30'>
                                                        <circle
                                                            cx='12'
                                                            cy='12'
                                                            r='10'></circle>
                                                        <path d='M12 16v-4'></path>
                                                        <path d='M12 8h.01'></path>
                                                    </svg>
                                                }
                                                label={"Secondary Taxonomy"}
                                                name={
                                                    data?.physicianAssistant?.secondaryTaxonomy || "NA"
                                                }
                                            />
                                            
                                        </div>

                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                                <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-flag h-4 w-4 text-muted-foreground'
                                                        data-id='76'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z'></path>
                                                        <line
                                                            x1='4'
                                                            x2='4'
                                                            y1='22'
                                                            y2='15'></line>
                                                    </svg>
                                                }
                                                label={"Certificate"}
                                                name={
                                                    data?.physicianAssistant?.certificate?.name || "NA"
                                                }
                                            />
                                            <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-map-pin h-4 w-4 text-muted-foreground'
                                                        data-id='72'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M20 10c0 6-8 12-8 12s-8-6-8-12a8 8 0 0 1 16 0Z'></path>
                                                        <circle
                                                            cx='12'
                                                            cy='10'
                                                            r='3'></circle>
                                                    </svg>
                                                }
                                                label={"Region"}
                                                name={
                                                    data?.physicianAssistant?.region?.name || "NA"
                                                }
                                            />

                                        </div>
                                        <div
                                            className='info-row flex center-center'
                                            style={{
                                                gap: "10px",
                                                paddingBottom: "10px",
                                            }}>
                                                <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M12 6v4'></path>
                                                        <path d='M14 14h-4'></path>
                                                        <path d='M14 18h-4'></path>
                                                        <path d='M14 8h-4'></path>
                                                        <path d='M18 12h2a2 2 0 0 1 2 2v6a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2v-9a2 2 0 0 1 2-2h2'></path>
                                                        <path d='M18 22V4a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2v18'></path>
                                                    </svg>

                                                }
                                                label={"Zip"}
                                                name={
                                                    data?.physicianAssistant?.homeZip || "NA"
                                                }
                                            />
                                             <InfoItem
                                                className='col-1-1 info-row-column'
                                                icon={
                                                    <svg
                                                        color={getRandomColor()}
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='24'
                                                        height='24'
                                                        viewBox='0 0 24 24'
                                                        fill='none'
                                                        stroke='currentColor'
                                                        strokeWidth='2'
                                                        strokeLinecap='round'
                                                        strokeLinejoin='round'
                                                        className='lucide lucide-flag h-4 w-4 text-muted-foreground'
                                                        data-id='76'
                                                        style={{
                                                            height: "20px",
                                                            width: "20px",
                                                        }}>
                                                        <path d='M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z'></path>
                                                        <line
                                                            x1='4'
                                                            x2='4'
                                                            y1='22'
                                                            y2='15'></line>
                                                    </svg>
                                                }
                                                label={"Credential"}
                                                name={
                                                    data?.physicianAssistant?.credential || "NA"
                                                }
                                            />
                                        </div>
                                        <hr
                                            style={{
                                                marginTop: "10px",
                                                paddingBottom: "10px",
                                            }}
                                        />
                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Facility
                                        </p>
                                        {data?.physicianAssistant?.facilities?.length ? (
                                            data.physicianAssistant?.facilities.map((facilities, index) => (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}
                                                                >
                                                                    <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                                    <circle cx="12" cy="10" r="4"></circle>
                                                                    <circle cx="12" cy="12" r="10"></circle>
                                                                </svg>
                                                            }
                                                            label="Facility"
                                                            name={facilities.name || "NA"}
                                                        />
                                                        <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                color={getRandomColor()}
                                                                width='24'
                                                                height='24'
                                                                viewBox='0 0 24 24'
                                                                fill='none'
                                                                stroke='currentColor'
                                                                strokeWidth='2'
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                style={
{
                                                                    height: "20px",
                                                                    width: "20px",
                                                                }}>
                                                                 <path d="M4.82 19.407c-2.966-1.857-4.94-5.153-4.94-8.907 0-5.795 4.705-10.5 10.5-10.5 3.605 0 6.789 1.821 8.68 4.593 2.966 1.857 4.94 5.153 4.94 8.907 0 5.795-4.705 10.5-10.5 10.5-3.599 0-6.778-1.815-8.67-4.579l-.01-.014zm8.68-15.407c5.243 0 9.5 4.257 9.5 9.5s-4.257 9.5-9.5 9.5-9.5-4.257-9.5-9.5 4.257-9.5 9.5-9.5zm.5 15h-1.021v-.871c-2.343-.302-2.599-2.179-2.599-2.744h1.091c.025.405.157 1.774 2.182 1.774.599 0 1.088-.141 1.453-.419.361-.276.536-.612.536-1.029 0-.793-.513-1.367-2.07-1.718-2.368-.536-2.923-1.398-2.923-2.533 0-1.509 1.223-2.216 2.33-2.406v-1.054h1.021v1.015c2.491.195 2.695 2.215 2.695 2.771h-1.098c0-1.161-.918-1.766-1.996-1.766-1.077 0-1.854.532-1.854 1.408 0 .781.439 1.165 1.994 1.554 1.879.473 2.999 1.101 2.999 2.681 0 1.744-1.509 2.393-2.74 2.493v.844zm2.85-15.453c-1.696-1.58-3.971-2.547-6.47-2.547-5.243 0-9.5 4.257-9.5 9.5 0 2.633 1.073 5.017 2.806 6.739l-.004-.01c-.44-1.159-.682-2.416-.682-3.729 0-5.795 4.705-10.5 10.5-10.5 1.171 0 2.298.192 3.35.547z" />
                                                            </svg>
                                                            }
                                                            label={"Paid Fee"}
                                                            name={facilities.feePaid || "NA"}

                                                        />
                                                    </div>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    data-id='71'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M8 2v4'></path>
                                                                    <path d='M16 2v4'></path>
                                                                    <rect
                                                                        width='18'
                                                                        height='18'
                                                                        x='3'
                                                                        y='4'
                                                                        rx='2'></rect>
                                                                    <path d='M3 10h18'></path>
                                                                </svg>
                                                            }
                                                            label="Renewal Date"
                                                            name={(facilities?.renewalDate
                                                                ? formatServerDate(
                                                                    facilities?.renewalDate
                                                                )
                                                                : "NA")
                                                            }

                                                        />
                                                        <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    data-id='71'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M8 2v4'></path>
                                                                    <path d='M16 2v4'></path>
                                                                    <rect
                                                                        width='18'
                                                                        height='18'
                                                                        x='3'
                                                                        y='4'
                                                                        rx='2'></rect>
                                                                    <path d='M3 10h18'></path>
                                                                </svg>
                                                            }
                                                            label={"Expired Date"}
                                                            name={(facilities?.expiryDate
                                                                ? formatServerDate(
                                                                    facilities?.expiryDate
                                                                )
                                                                : "NA")
                                                            }
                                                        />
                                                    </div>
                                                    {facilities?.s3attachment && (
                                                        <CustomModal
                                                            selector={
                                                                <InfoItem
                                                                    className="col-1-1 info-row-column cursor-pointer"
                                                                    icon={
                                                                        <svg
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 23 24"
                                                                            stroke="currentColor"
                                                                            color={getRandomColor()} 
                                                                            style={{
                                                                                height: "23px",
                                                                                width: "23px",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M17.5 11c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm-5.346 6.999c-.052.001-.104.001-.156.001-4.078 0-7.742-3.093-9.854-6.483-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 4.143 0 7.796 3.09 9.864 6.493.092.156.138.332.138.507 0 .179-.062.349-.15.516-.58-.634-1.297-1.14-2.103-1.472-1.863-2.476-4.626-4.544-7.749-4.544-3.465 0-6.533 2.632-8.404 5.5 1.815 2.781 4.754 5.34 8.089 5.493.09.529.25 1.034.471 1.506zm3.071-2.023 1.442 1.285c.095.085.215.127.333.127.136 0 .271-.055.37-.162l2.441-2.669c.088-.096.131-.217.131-.336 0-.274-.221-.499-.5-.499-.136 0-.271.055-.37.162l-2.108 2.304-1.073-.956c-.096-.085-.214-.127-.333-.127-.277 0-.5.224-.5.499 0 .137.056.273.167.372zm-3.603-.994c-2.031-.19-3.622-1.902-3.622-3.982 0-2.208 1.792-4 4-4 1.804 0 3.331 1.197 3.829 2.84-.493.146-.959.354-1.389.615-.248-1.118-1.247-1.955-2.44-1.955-1.38 0-2.5 1.12-2.5 2.5 0 1.363 1.092 2.472 2.448 2.499-.169.47-.281.967-.326 1.483z"
                                                                                // fillRule="nonzero"
                                                                            />
                                                                        </svg>
                                                                    }
                                                                    label={"Attachment"}
                                                                    name="Click to View"
                                                                />
                                                            }
                                                            content={
                                                                <FullScreenFileViewerModal
                                                                    title={`Document`}
                                                                    fileUrl={facilities?.s3attachment || null}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                    <hr
                                                        style={{
                                                            marginTop: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    />
                                                </>

                                            ))
                                        ) : (
                                            <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: "5px 12px",
                                              backgroundColor: "rgb(245, 245, 245)",
                                              borderRadius: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "12px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  No Data Available
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Vaccination
                                        </p>
                                        {data?.physicianAssistant?.vaccinations?.length ? (
                                            data.physicianAssistant?.vaccinations.map((vaccinations, index) => (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}
                                                                >
                                                                    <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                                    <circle cx="12" cy="10" r="4"></circle>
                                                                    <circle cx="12" cy="12" r="10"></circle>
                                                                </svg>
                                                            }
                                                            label="Vaccinations"
                                                            name={vaccinations.name || "NA"}
                                                        />

                                                        {vaccinations?.s3attachment && (
                                                            <CustomModal
                                                                selector={
                                                                    <InfoItem
                                                                        className="col-1-1 info-row-column cursor-pointer"
                                                                        icon={
                                                                            <svg
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 23 24"
                                                                            stroke="currentColor"
                                                                            color={getRandomColor()} 
                                                                            style={{
                                                                                height: "23px",
                                                                                width: "23px",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M17.5 11c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm-5.346 6.999c-.052.001-.104.001-.156.001-4.078 0-7.742-3.093-9.854-6.483-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 4.143 0 7.796 3.09 9.864 6.493.092.156.138.332.138.507 0 .179-.062.349-.15.516-.58-.634-1.297-1.14-2.103-1.472-1.863-2.476-4.626-4.544-7.749-4.544-3.465 0-6.533 2.632-8.404 5.5 1.815 2.781 4.754 5.34 8.089 5.493.09.529.25 1.034.471 1.506zm3.071-2.023 1.442 1.285c.095.085.215.127.333.127.136 0 .271-.055.37-.162l2.441-2.669c.088-.096.131-.217.131-.336 0-.274-.221-.499-.5-.499-.136 0-.271.055-.37.162l-2.108 2.304-1.073-.956c-.096-.085-.214-.127-.333-.127-.277 0-.5.224-.5.499 0 .137.056.273.167.372zm-3.603-.994c-2.031-.19-3.622-1.902-3.622-3.982 0-2.208 1.792-4 4-4 1.804 0 3.331 1.197 3.829 2.84-.493.146-.959.354-1.389.615-.248-1.118-1.247-1.955-2.44-1.955-1.38 0-2.5 1.12-2.5 2.5 0 1.363 1.092 2.472 2.448 2.499-.169.47-.281.967-.326 1.483z"
                                                                                // fillRule="nonzero"
                                                                            />
                                                                        </svg>
                                                                        }
                                                                        label={"Attachment"}
                                                                        name="Click to View"
                                                                    />
                                                                }
                                                                content={
                                                                    <FullScreenFileViewerModal
                                                                        title={`Document`}
                                                                        fileUrl={vaccinations?.s3attachment || null}
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                        {/* {vaccinations?.s3attachment &&
                                                            <a href={vaccinations?.s3attachment} download>
                                                                <InfoItem
                                                                    className='col-1-1 info-row-column'
                                                                    icon={
                                                                        <svg
                                                                            color={getRandomColor()}
                                                                            width='24'
                                                                            height='24'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            strokeWidth='2'
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                            style={{
                                                                                height: "20px",
                                                                                width: "20px",
                                                                            }}>
                                                                            <path d="M17.843 1c2.159 0 3.912 1.753 3.912 3.912 0 .395-.053 1.704-1.195 2.813l-8.465 8.465c-.596.671-2.12 1.279-3.299.099-1.178-1.177-.586-2.685.088-3.29l4.409-4.409.707.707-3.164 3.163.014.003-1.411 1.413.004.003c-.97 1.151.618 2.93 1.977 1.572l8.383-8.384c.656-.652.94-1.393.94-2.155 0-1.601-1.299-2.9-2.9-2.9-.783 0-1.495.311-2.018.818l-.003-.003c-.573.573-11.502 11.494-11.534 11.527l-.002-.002c-.795.812-1.286 1.923-1.286 3.148 0 2.483 2.017 4.5 4.5 4.5.65 0 1.84.007 3.52-1.668l10.273-10.267.707.707-10.477 10.477c-1.004 1.077-2.435 1.751-4.023 1.751-3.035 0-5.5-2.465-5.5-5.5 0-1.577.666-3 1.731-4.004 10.668-10.667 10.835-10.839 11.295-11.297.277-.278 1.215-1.199 2.817-1.199" />
                                                                        </svg>
                                                                    }
                                                                    label={"Attachment"}
                                                                    // name={vaccinations.s3attachment || "NA"}
                                                                    name="Click to Download"

                                                                />
                                                            </a>
                                                        } */}

                                                    </div>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    data-id='71'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M8 2v4'></path>
                                                                    <path d='M16 2v4'></path>
                                                                    <rect
                                                                        width='18'
                                                                        height='18'
                                                                        x='3'
                                                                        y='4'
                                                                        rx='2'></rect>
                                                                    <path d='M3 10h18'></path>
                                                                </svg>
                                                            }
                                                            label="Administered Date"
                                                            name={(vaccinations?.administeredDate
                                                                ? formatServerDate(
                                                                    vaccinations?.administeredDate
                                                                )
                                                                : "NA")
                                                            }

                                                        />
                                                        <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    data-id='71'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M8 2v4'></path>
                                                                    <path d='M16 2v4'></path>
                                                                    <rect
                                                                        width='18'
                                                                        height='18'
                                                                        x='3'
                                                                        y='4'
                                                                        rx='2'></rect>
                                                                    <path d='M3 10h18'></path>
                                                                </svg>
                                                            }
                                                            label={"Expired Date"}
                                                            name={(vaccinations?.expiryDate
                                                                ? formatServerDate(
                                                                    vaccinations?.expiryDate
                                                                )
                                                                : "NA")
                                                            }
                                                        />
                                                    </div>
                                                    <hr
                                                        style={{
                                                            marginTop: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    />


                                                </>

                                            ))
                                        ) : (
                                            <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: "5px 12px",
                                              backgroundColor: "rgb(245, 245, 245)",
                                              borderRadius: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "12px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  No Data Available
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Background Check
                                        </p>
                                        {data?.physicianAssistant?.backgroundChecks?.length ? (
                                            data.physicianAssistant?.backgroundChecks.map((backgroundChecks, index) => (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                color={getRandomColor()}
                                                                width='24'
                                                                height='24'
                                                                viewBox='0 0 24 24'
                                                                fill='none'
                                                                stroke='currentColor'
                                                                strokeWidth='2'
                                                                fillRule="evenodd"
                                                                clipRule="evenodd"
                                                                style={
{
                                                                    height: "20px",
                                                                    width: "20px",
                                                                }}>
                                                                 <path d="M4.82 19.407c-2.966-1.857-4.94-5.153-4.94-8.907 0-5.795 4.705-10.5 10.5-10.5 3.605 0 6.789 1.821 8.68 4.593 2.966 1.857 4.94 5.153 4.94 8.907 0 5.795-4.705 10.5-10.5 10.5-3.599 0-6.778-1.815-8.67-4.579l-.01-.014zm8.68-15.407c5.243 0 9.5 4.257 9.5 9.5s-4.257 9.5-9.5 9.5-9.5-4.257-9.5-9.5 4.257-9.5 9.5-9.5zm.5 15h-1.021v-.871c-2.343-.302-2.599-2.179-2.599-2.744h1.091c.025.405.157 1.774 2.182 1.774.599 0 1.088-.141 1.453-.419.361-.276.536-.612.536-1.029 0-.793-.513-1.367-2.07-1.718-2.368-.536-2.923-1.398-2.923-2.533 0-1.509 1.223-2.216 2.33-2.406v-1.054h1.021v1.015c2.491.195 2.695 2.215 2.695 2.771h-1.098c0-1.161-.918-1.766-1.996-1.766-1.077 0-1.854.532-1.854 1.408 0 .781.439 1.165 1.994 1.554 1.879.473 2.999 1.101 2.999 2.681 0 1.744-1.509 2.393-2.74 2.493v.844zm2.85-15.453c-1.696-1.58-3.971-2.547-6.47-2.547-5.243 0-9.5 4.257-9.5 9.5 0 2.633 1.073 5.017 2.806 6.739l-.004-.01c-.44-1.159-.682-2.416-.682-3.729 0-5.795 4.705-10.5 10.5-10.5 1.171 0 2.298.192 3.35.547z" />
                                                            </svg>
                                                            }
                                                            label="Paid Fee"
                                                            name={backgroundChecks.feePaid || "NA"}
                                                        />
                                                        <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                                    <circle
                                                                        cx='12'
                                                                        cy='10'
                                                                        r='4'></circle>
                                                                    <circle
                                                                        cx='12'
                                                                        cy='12'
                                                                        r='10'></circle>
                                                                </svg>
                                                            }
                                                            label={"Notes"}
                                                            name={backgroundChecks.notes || "NA"}

                                                        />
                                                    </div>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                         {backgroundChecks?.s3attachment && (
                                                            <CustomModal
                                                                selector={
                                                                    <InfoItem
                                                                        className="col-1-1 info-row-column cursor-pointer"
                                                                        icon={
                                                                            <svg
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 23 24"
                                                                            stroke="currentColor"
                                                                            color={getRandomColor()} 
                                                                            style={{
                                                                                height: "23px",
                                                                                width: "23px",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M17.5 11c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm-5.346 6.999c-.052.001-.104.001-.156.001-4.078 0-7.742-3.093-9.854-6.483-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 4.143 0 7.796 3.09 9.864 6.493.092.156.138.332.138.507 0 .179-.062.349-.15.516-.58-.634-1.297-1.14-2.103-1.472-1.863-2.476-4.626-4.544-7.749-4.544-3.465 0-6.533 2.632-8.404 5.5 1.815 2.781 4.754 5.34 8.089 5.493.09.529.25 1.034.471 1.506zm3.071-2.023 1.442 1.285c.095.085.215.127.333.127.136 0 .271-.055.37-.162l2.441-2.669c.088-.096.131-.217.131-.336 0-.274-.221-.499-.5-.499-.136 0-.271.055-.37.162l-2.108 2.304-1.073-.956c-.096-.085-.214-.127-.333-.127-.277 0-.5.224-.5.499 0 .137.056.273.167.372zm-3.603-.994c-2.031-.19-3.622-1.902-3.622-3.982 0-2.208 1.792-4 4-4 1.804 0 3.331 1.197 3.829 2.84-.493.146-.959.354-1.389.615-.248-1.118-1.247-1.955-2.44-1.955-1.38 0-2.5 1.12-2.5 2.5 0 1.363 1.092 2.472 2.448 2.499-.169.47-.281.967-.326 1.483z"
                                                                                // fillRule="nonzero"
                                                                            />
                                                                        </svg>
                                                                        }
                                                                        label={"Attachment"}
                                                                        name="Click to View"
                                                                    />
                                                                }
                                                                content={
                                                                    <FullScreenFileViewerModal
                                                                        title={`Document`}
                                                                        fileUrl={backgroundChecks?.s3attachment}
                                                                    />
                                                                }
                                                            />
                                                        )}

                                                        
                                                        <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    data-id='71'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M8 2v4'></path>
                                                                    <path d='M16 2v4'></path>
                                                                    <rect
                                                                        width='18'
                                                                        height='18'
                                                                        x='3'
                                                                        y='4'
                                                                        rx='2'></rect>
                                                                    <path d='M3 10h18'></path>
                                                                </svg>
                                                            }
                                                            label={"Performed Date"}
                                                            name={(backgroundChecks?.datePerformed
                                                                ? formatServerDate(
                                                                    backgroundChecks?.datePerformed
                                                                )
                                                                : "NA")
                                                            }
                                                        />
                                                    </div>
                                                    <hr
                                                        style={{
                                                            marginTop: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    />
                                                </>

                                            ))
                                        ) : (
                                            <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: "5px 12px",
                                              backgroundColor: "rgb(245, 245, 245)",
                                              borderRadius: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "12px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  No Data Available
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            PTO's
                                        </p>
                                        {data?.physicianAssistant?.ptos?.length ? (
                                            data.physicianAssistant?.ptos.map((ptos, index) => (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}
                                                                >
                                                                    <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                                    <circle cx="12" cy="10" r="4"></circle>
                                                                    <circle cx="12" cy="12" r="10"></circle>
                                                                </svg>
                                                            }
                                                            label="Type"
                                                            name={ptos.type || "NA"}
                                                        />
                                                        <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='1'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                   <path d="M4 6v16h20v-16h-20zm10 13c-2.761 0-5-2.239-5-5s2.239-5 5-5 5 2.239 5 5-2.239 5-5 5zm.292-2.114v.614h-.584v-.582c-.603-.01-1.229-.154-1.75-.424l.266-.958c.558.216 1.3.445 1.881.315.671-.152.809-.842.068-1.175-.543-.251-2.205-.469-2.205-1.891 0-.795.605-1.508 1.74-1.663v-.622h.584v.593c.422.011.896.085 1.426.246l-.212.96c-.448-.156-.943-.3-1.425-.27-.868.051-.946.803-.339 1.118.998.469 2.301.818 2.301 2.068.001 1.002-.784 1.537-1.751 1.671zm6.708-12.886h-19v15h-2v-17h21v2z"/>
                                                                </svg>
                                                            }
                                                            label={"Amount"}
                                                            name={ptos.amount || "NA"}

                                                        />
                                                    </div>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        {ptos?.s3attachment && (
                                                            <CustomModal
                                                                selector={
                                                                    <InfoItem
                                                                        className="col-1-1 info-row-column cursor-pointer"
                                                                        icon={
                                                                            <svg
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 23 24"
                                                                            stroke="currentColor"
                                                                            color={getRandomColor()} 
                                                                            style={{
                                                                                height: "23px",
                                                                                width: "23px",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M17.5 11c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm-5.346 6.999c-.052.001-.104.001-.156.001-4.078 0-7.742-3.093-9.854-6.483-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 4.143 0 7.796 3.09 9.864 6.493.092.156.138.332.138.507 0 .179-.062.349-.15.516-.58-.634-1.297-1.14-2.103-1.472-1.863-2.476-4.626-4.544-7.749-4.544-3.465 0-6.533 2.632-8.404 5.5 1.815 2.781 4.754 5.34 8.089 5.493.09.529.25 1.034.471 1.506zm3.071-2.023 1.442 1.285c.095.085.215.127.333.127.136 0 .271-.055.37-.162l2.441-2.669c.088-.096.131-.217.131-.336 0-.274-.221-.499-.5-.499-.136 0-.271.055-.37.162l-2.108 2.304-1.073-.956c-.096-.085-.214-.127-.333-.127-.277 0-.5.224-.5.499 0 .137.056.273.167.372zm-3.603-.994c-2.031-.19-3.622-1.902-3.622-3.982 0-2.208 1.792-4 4-4 1.804 0 3.331 1.197 3.829 2.84-.493.146-.959.354-1.389.615-.248-1.118-1.247-1.955-2.44-1.955-1.38 0-2.5 1.12-2.5 2.5 0 1.363 1.092 2.472 2.448 2.499-.169.47-.281.967-.326 1.483z"
                                                                                // fillRule="nonzero"
                                                                            />
                                                                        </svg>
                                                                        }
                                                                        label={"Attachment"}
                                                                        name="Click to View"
                                                                    />
                                                                }
                                                                content={
                                                                    <FullScreenFileViewerModal
                                                                        title={`Document`}
                                                                        fileUrl={ptos?.s3attachment || null}
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                         {/* {ptos?.s3attachment &&
                                                            <a href={ptos?.s3attachment} download>
                                                                <InfoItem
                                                                    className='col-1-1 info-row-column'
                                                                    icon={
                                                                        <svg
                                                                            color={getRandomColor()}
                                                                            width='24'
                                                                            height='24'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            strokeWidth='2'
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                            style={{
                                                                                height: "20px",
                                                                                width: "20px",
                                                                            }}>
                                                                            <path d="M17.843 1c2.159 0 3.912 1.753 3.912 3.912 0 .395-.053 1.704-1.195 2.813l-8.465 8.465c-.596.671-2.12 1.279-3.299.099-1.178-1.177-.586-2.685.088-3.29l4.409-4.409.707.707-3.164 3.163.014.003-1.411 1.413.004.003c-.97 1.151.618 2.93 1.977 1.572l8.383-8.384c.656-.652.94-1.393.94-2.155 0-1.601-1.299-2.9-2.9-2.9-.783 0-1.495.311-2.018.818l-.003-.003c-.573.573-11.502 11.494-11.534 11.527l-.002-.002c-.795.812-1.286 1.923-1.286 3.148 0 2.483 2.017 4.5 4.5 4.5.65 0 1.84.007 3.52-1.668l10.273-10.267.707.707-10.477 10.477c-1.004 1.077-2.435 1.751-4.023 1.751-3.035 0-5.5-2.465-5.5-5.5 0-1.577.666-3 1.731-4.004 10.668-10.667 10.835-10.839 11.295-11.297.277-.278 1.215-1.199 2.817-1.199" />
                                                                        </svg>
                                                                    }
                                                                    label={"Attachment"}
                                                                    name="Click to Download"

                                                                />
                                                            </a>
                                                        } */}
                                                       <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    data-id='71'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M8 2v4'></path>
                                                                    <path d='M16 2v4'></path>
                                                                    <rect
                                                                        width='18'
                                                                        height='18'
                                                                        x='3'
                                                                        y='4'
                                                                        rx='2'></rect>
                                                                    <path d='M3 10h18'></path>
                                                                </svg>
                                                            }
                                                            label={"Date"}
                                                            name={(ptos?.date
                                                                ? formatServerDate(
                                                                    ptos?.date
                                                                )
                                                                : "NA")
                                                            }
                                                        />
                                                    </div>
                                                    <hr
                                                        style={{
                                                            marginTop: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    />


                                                </>

                                            ))
                                        ) : (
                                            <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: "5px 12px",
                                              backgroundColor: "rgb(245, 245, 245)",
                                              borderRadius: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "12px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  No Data Available
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                        <p
                                            style={{
                                                fontWeight: "700",
                                                paddingBottom: "8px",
                                            }}>
                                            Education
                                        </p>
                                        {data?.physicianAssistant?.degrees?.length ? (
                                            data.physicianAssistant?.degrees.map((degree, index) => (
                                                <>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    stroke='currentColor'
                                                                    fillRule="evenodd"
                                                                    clipRule="evenodd"
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d="M12 2h2v2h2v3.702l7 2.618v12.68h1v1h-24v-1h1v-11h6v-8h2v-2h2v-2h1v2zm3 3h-7v18h1v-2h5v2h1v-18zm-2 17h-3v1h3v-1zm8 1h1v-11.987l-6-2.243v14.23h1v-2h4v2zm-14-10h-5v10h1v-2h3v2h1v-10zm-2 9h-1v1h1v-1zm15 0h-2v1h2v-1zm-16-5v2h-1v-2h1zm2 0v2h-1v-2h1zm5-10v12h-1v-12h1zm10 11v1h-4v-1h4zm-8-11v12h-1v-12h1zm8 9v1h-4v-1h4zm-17-2v2h-1v-2h1zm2 0v2h-1v-2h1zm15 0v1h-4v-1h4zm0-2v1h-4v-1h4zm-8-9h-3v1h3v-1z" />
                                                                </svg>
                                                            }
                                                            label="Name Of School"
                                                            name={degree.nameOfSchool || "NA"}
                                                        />
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    width="24"
                                                                    height="24"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="currentColor"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}
                                                                >
                                                                    <path d="M18 20a6 6 0 0 0-12 0"></path>
                                                                    <circle cx="12" cy="10" r="4"></circle>
                                                                    <circle cx="12" cy="12" r="10"></circle>
                                                                </svg>
                                                            }
                                                            label="Degree"
                                                            name={degree.degree || "NA"}
                                                        />
                                                        
                                                    </div>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className="col-1-1 info-row-column"
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    data-id='71'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M8 2v4'></path>
                                                                    <path d='M16 2v4'></path>
                                                                    <rect
                                                                        width='18'
                                                                        height='18'
                                                                        x='3'
                                                                        y='4'
                                                                        rx='2'></rect>
                                                                    <path d='M3 10h18'></path>
                                                                </svg>
                                                            }
                                                            label="Date Graduated"
                                                            name={(degree?.dateAcquired
                                                                ? formatServerDate(
                                                                    degree?.dateAcquired
                                                                )
                                                                : "NA")
                                                            }

                                                        />
                                                        <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    xmlns='http://www.w3.org/2000/svg'
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    data-id='71'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M8 2v4'></path>
                                                                    <path d='M16 2v4'></path>
                                                                    <rect
                                                                        width='18'
                                                                        height='18'
                                                                        x='3'
                                                                        y='4'
                                                                        rx='2'></rect>
                                                                    <path d='M3 10h18'></path>
                                                                </svg>
                                                            }
                                                            label={"Date Expired"}
                                                            name={(degree?.dateExpired
                                                                ? formatServerDate(
                                                                    degree?.dateExpired
                                                                )
                                                                : "NA")
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        key={index}
                                                        className="info-row flex center-center"
                                                        style={{
                                                            gap: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    >
                                                        <InfoItem
                                                            className='col-1-1 info-row-column'
                                                            icon={
                                                                <svg
                                                                    color={getRandomColor()}
                                                                    width='24'
                                                                    height='24'
                                                                    viewBox='0 0 24 24'
                                                                    fill='none'
                                                                    stroke='currentColor'
                                                                    strokeWidth='2'
                                                                    strokeLinecap='round'
                                                                    strokeLinejoin='round'
                                                                    style={{
                                                                        height: "20px",
                                                                        width: "20px",
                                                                    }}>
                                                                    <path d='M18 20a6 6 0 0 0-12 0'></path>
                                                                    <circle
                                                                        cx='12'
                                                                        cy='10'
                                                                        r='4'></circle>
                                                                    <circle
                                                                        cx='12'
                                                                        cy='12'
                                                                        r='10'></circle>
                                                                </svg>
                                                            }
                                                            label={"Status"}
                                                            name={degree.status || "NA"}

                                                        />
                                                        {degree?.s3attachment && (
                                                            <CustomModal
                                                                selector={
                                                                    <InfoItem
                                                                        className="col-1-1 info-row-column cursor-pointer"
                                                                        icon={
                                                                            <svg
                                                                            width="24"
                                                                            height="24"
                                                                            viewBox="0 0 23 24"
                                                                            stroke="currentColor"
                                                                            color={getRandomColor()} 
                                                                            style={{
                                                                                height: "23px",
                                                                                width: "23px",
                                                                            }}
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M17.5 11c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5-4.5-2.016-4.5-4.5 2.016-4.5 4.5-4.5zm-5.346 6.999c-.052.001-.104.001-.156.001-4.078 0-7.742-3.093-9.854-6.483-.096-.159-.144-.338-.144-.517s.049-.358.145-.517c2.111-3.39 5.775-6.483 9.853-6.483 4.143 0 7.796 3.09 9.864 6.493.092.156.138.332.138.507 0 .179-.062.349-.15.516-.58-.634-1.297-1.14-2.103-1.472-1.863-2.476-4.626-4.544-7.749-4.544-3.465 0-6.533 2.632-8.404 5.5 1.815 2.781 4.754 5.34 8.089 5.493.09.529.25 1.034.471 1.506zm3.071-2.023 1.442 1.285c.095.085.215.127.333.127.136 0 .271-.055.37-.162l2.441-2.669c.088-.096.131-.217.131-.336 0-.274-.221-.499-.5-.499-.136 0-.271.055-.37.162l-2.108 2.304-1.073-.956c-.096-.085-.214-.127-.333-.127-.277 0-.5.224-.5.499 0 .137.056.273.167.372zm-3.603-.994c-2.031-.19-3.622-1.902-3.622-3.982 0-2.208 1.792-4 4-4 1.804 0 3.331 1.197 3.829 2.84-.493.146-.959.354-1.389.615-.248-1.118-1.247-1.955-2.44-1.955-1.38 0-2.5 1.12-2.5 2.5 0 1.363 1.092 2.472 2.448 2.499-.169.47-.281.967-.326 1.483z"
                                                                                // fillRule="nonzero"
                                                                            />
                                                                        </svg>
                                                                        }
                                                                        label={"Attachment"}
                                                                        name="Click to View"
                                                                    />
                                                                }
                                                                content={
                                                                    <FullScreenFileViewerModal
                                                                        title={`Document`}
                                                                        fileUrl={degree?.s3attachment || null}
                                                                    />
                                                                }
                                                            />
                                                        )}
                                                        
                                                    </div>
                                                    
                                                         {/* {degree?.s3attachment &&
                                                            <a href={degree?.s3attachment} download>
                                                                <InfoItem
                                                                    className='col-1-1 info-row-column'
                                                                    icon={
                                                                        <svg
                                                                            color={getRandomColor()}
                                                                            width='24'
                                                                            height='24'
                                                                            viewBox='0 0 24 24'
                                                                            fill='none'
                                                                            stroke='currentColor'
                                                                            strokeWidth='2'
                                                                            strokeLinecap='round'
                                                                            strokeLinejoin='round'
                                                                            style={{
                                                                                height: "20px",
                                                                                width: "20px",
                                                                            }}>
                                                                            <path d="M17.843 1c2.159 0 3.912 1.753 3.912 3.912 0 .395-.053 1.704-1.195 2.813l-8.465 8.465c-.596.671-2.12 1.279-3.299.099-1.178-1.177-.586-2.685.088-3.29l4.409-4.409.707.707-3.164 3.163.014.003-1.411 1.413.004.003c-.97 1.151.618 2.93 1.977 1.572l8.383-8.384c.656-.652.94-1.393.94-2.155 0-1.601-1.299-2.9-2.9-2.9-.783 0-1.495.311-2.018.818l-.003-.003c-.573.573-11.502 11.494-11.534 11.527l-.002-.002c-.795.812-1.286 1.923-1.286 3.148 0 2.483 2.017 4.5 4.5 4.5.65 0 1.84.007 3.52-1.668l10.273-10.267.707.707-10.477 10.477c-1.004 1.077-2.435 1.751-4.023 1.751-3.035 0-5.5-2.465-5.5-5.5 0-1.577.666-3 1.731-4.004 10.668-10.667 10.835-10.839 11.295-11.297.277-.278 1.215-1.199 2.817-1.199" />
                                                                        </svg>
                                                                    }
                                                                    label={"Attachment"}
                                                                    name="Click to Download"

                                                                />
                                                            </a>
                                                        } */}
                                                    
                                                    <hr
                                                        style={{
                                                            marginTop: "10px",
                                                            paddingBottom: "10px",
                                                        }}
                                                    />
                                                </>

                                            ))
                                        ) : (
                                            <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              justifyContent: "center",
                                              padding: "5px 12px",
                                              backgroundColor: "rgb(245, 245, 245)",
                                              borderRadius: "8px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "12px",
                                              }}
                                            >
                                              <div>
                                                <div
                                                  style={{
                                                    fontSize: "12px",
                                                    fontWeight: "400",
                                                  }}
                                                >
                                                  No Data Available
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        )}

                                    </div>
                                </div>
                            )}
                        </div>
                        <div>
                            {permissions?.isUpdateAllowed &&
                                <NavLink
                                    to={
                                        data?.physicianAssistant?.id
                                            ? {
                                                pathname: `/edit-physician-assistant`,
                                                search: `?next=${window.location.pathname + window.location.search}`,
                                                state: {
                                                    id: data?.physicianAssistant?.id,
                                                },
                                            }
                                            : "#"
                                    }>
                                    <ButtonLightTextIcon
                                        title='Edit Details'
                                        icon={
                                            <svg
                                                width='15'
                                                height='15'
                                                style={{
                                                    height: "15px",
                                                    width: "15px",
                                                }}
                                                viewBox='0 0 24 24'>
                                                <path
                                                    fillRule='evenodd'
                                                    clipRule='evenodd'
                                                    d='M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z'
                                                    fill='#283f54'
                                                />
                                            </svg>
                                        }
                                    />
                                </NavLink>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default withRouter(PhysicianAssistantDetails);